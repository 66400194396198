import { Link } from 'react-router-dom'
import styles from './footer.module.css'

export default function Footer() {
    return (
        <div className={styles.footer}>
            <p className={styles.text}>{process.env.REACT_APP_API_URL == "production" ? "Pépinière Carco © 2024" : "Il est interdit d'enregistrer, de reproduire ou de partager cette présentation sans autorisation écrite de MBE & CONNECT. Merci de votre compréhension."}</p>
            <Link className={styles.notactive} to={"/pageConnecte/administration"}>Administration</Link>
        </div>
    )
}