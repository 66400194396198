import { useEffect, useState } from "react";
import styles from "./fichiersVisu.module.css";
import Pagination from "../../Pagination/Pagination";
import downloadBleu from "../../../assets/downloadBleu.png"
import poubBleu from "../../../assets/poubBleu.png"

export default function FichiersVisu({ fichiers, removeOverlayFn }) {
  const [numberElementsMax, setNumberElementsMax] = useState(3);
  const [prevNum, setPrevNum] = useState(0);
  const [nextNum, setNextNum] = useState(3);


  const nextPage = () => {
    if (numberElementsMax >= fichiers.length) {
      // setNextNum((prev) => prev + 3)
      setNumberElementsMax(3);
    } else {
      setNumberElementsMax((prev) => prev + 3);
    }
  };

  useEffect(() => {
    if (fichiers.length == 0) {
      setNextNum(0);
    } else if (numberElementsMax > fichiers.length) {
      setNextNum(fichiers.length);
    } else {
      setNextNum(numberElementsMax);
    }
    if (numberElementsMax > fichiers.length) {
      const numsCandidate = [fichiers.length - 1, fichiers.length - 2];
      let smallerNumber;
      for (let i of numsCandidate) {
        if (i % 3 === 0) {
          smallerNumber = i + 1;
          break;
        }
      }
      if (smallerNumber == undefined) {
        setPrevNum(0);
      } else {
        setPrevNum(smallerNumber);
      }
    } else {
      if (fichiers.length == 0) {
        setPrevNum(0);
      } else {
        setPrevNum(numberElementsMax - 2);
      }
    }
  }, [numberElementsMax, fichiers]);

  const prevPage = () => {
    if (numberElementsMax == 3) {
      if (fichiers.length % 3 == 0) {
        setNumberElementsMax(fichiers.length);
      } else {
        const numsCandidate = [fichiers.length + 1, fichiers.length + 2];
        let greatestNumber;
        for (let i of numsCandidate) {
          if (i % 3 === 0) {
            greatestNumber = i;
            break;
          }
        }
        setNumberElementsMax(greatestNumber);
      }
    } else {
      setNumberElementsMax((prev) => prev - 3);
    }
  };

  const downloadFile = (link) => {
    window.open(link, '_blank')   
  }



  return (
    <div className={styles.table}>
      {fichiers.length != 0 && (
        <table className={styles.tableSearch}>
            <thead>
              <tr>
              <th style={{width:"5%"}}></th>
                <th style={{width:"85%"}}></th>
                <th style={{width:"10%"}}></th>
              </tr>
            </thead>
          <tbody>
            {fichiers.slice(numberElementsMax-3,numberElementsMax).map((fichier, idx) => {
              return (
                <tr key={fichier[2]}> 
                  <td><img src={fichier[0]} className={styles.logoStatic} /></td>
                  <td>{fichier[2]}</td>
                  <td>
                    <div className={styles.logoContainer}>
                      {fichier[1] != "" && <img src={downloadBleu} className={styles.logo} onClick={() => downloadFile(fichier[1])} />}
                      {fichier[1] != "" ? <img src={poubBleu} className={styles.logo} onClick={() => removeOverlayFn(fichier)} /> : <img src={poubBleu} className={styles.logo} onClick={() => removeOverlayFn(idx)} />}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <div>
        <Pagination nextPage={nextPage} prevPage={prevPage} />
        <p className={styles.nums}>
          {prevNum} à {nextNum} - Nombre Total d'éléments : {fichiers.length}{" "}
        </p>
      </div>
    </div>
  );
}


