import { useEffect, useState } from "react";
import styles from "./TreeLocaux.module.css";
import { Treemap, Tooltip } from "recharts";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

export default function TreeLocaux({ data }) {

  async function getLocauxId() {
    // const link = `http://localhost:8080/stats/locauxId`
    const link = `${process.env.REACT_APP_API_URL}/stats/locauxId`
    const response = await fetch(link)
    const data = response.json()
    return data
  }

  const { data:locauxId = {} } = useQuery({
      queryKey: ["Statistiques", "Locaux", "ID"],
      queryFn: () => getLocauxId(),
      refetchOnWindowFocus: false
  })



  const navigate = useNavigate();
  const [hoveredCell, setHoveredCell] = useState(null);
  const [isTreemapHovered, setIsTreemapHovered] = useState(false);

  const handleTreemapMouseEnter = () => {
    setIsTreemapHovered(true);
  };

  const handleTreemapMouseLeave = () => {
    setIsTreemapHovered(false);
  };

  const handleMouseEnter = (name) => {
    setHoveredCell(name);
  };

  const handleMouseLeave = () => {
    setHoveredCell(null);
  };

  const floorColors = {
    "Etage 1": "#007bff", // Light Blue
    "Etage 2": "#ff7f0e", // Light Green
    "Etage 3": "#6f42c1", // Light Pink
    "Etage 4": "#ffc107",
    "Etage 5": "#17a2b8",
    // Add more as needed
  };

  const COLORS_SURFACE_LIBRE = ["#7ed957", "#e50e0e"];

  const getColor = (size, surfaceOcc) => {
    if (surfaceOcc > 0) {
      return COLORS_SURFACE_LIBRE[1];
    } else if (surfaceOcc == 0) {
      return COLORS_SURFACE_LIBRE[0];
    }
  };

  const CustomizedContent = ({
    depth,
    x,
    y,
    width,
    height,
    parentName,
    name,
    size,
    surfaceOcc,
  }) => {
    const isHovered = depth == 2 ? hoveredCell == name : null;
    let color;
    let opacity = 0.8;
    const etage = depth == 1 ? name[0]["etage"] : null;
    const pourcentage = depth == 1 ? name[0]["pourcentage"] : null;
    if (depth === 1) {
      color = floorColors[etage];
    } else {
      color = isHovered ? getColor(size, surfaceOcc) : floorColors[parentName];
      opacity = isHovered || hoveredCell === null ? 1 : 0.5;
    }

    return (
      <g
        onMouseEnter={() => handleMouseEnter(name)}
        onMouseLeave={handleMouseLeave}
        onClick={(e) => {
          navigate(`../recherchePatrimoine/patrimoine/${locauxId[name]}`);
        }}
      >
        {depth == 1 && !isTreemapHovered && (
          <>
            <rect
              x={x}
              y={y}
              width={width}
              height={height}
              style={{
                fill: color,
                stroke: "#fff",
                strokeWidth: 2 / (depth + 1e-10),
                strokeOpacity: 1 / (depth + 1e-10),
                opacity: opacity,
              }}
            />
            <text
              x={x + width / 2}
              y={y + height / 2 + 7}
              textAnchor="middle"
              fill="#fff"
              fontSize={14}
            >
              {etage} - {pourcentage}
            </text>
          </>
        )}
        {depth === 2 && isTreemapHovered && (
          <>
            <rect
              x={x}
              y={y}
              width={width}
              height={height}
              style={{
                fill: color,
                stroke: "#fff",
                strokeWidth: 2 / (depth + 1e-10),
                strokeOpacity: 1 / (depth + 1e-10),
                opacity: opacity,
              }}
            />
            <text
              x={x + width / 2}
              y={y + height / 2}
              textAnchor="middle"
              fill="#fff"
              fontSize=".8rem"
            >
              {name}
            </text>
          </>
        )}
      </g>
    );
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "10px",
            border: "1px solid #ccc",
            fontSize: "1rem",
          }}
        >
          <p>Libellé: {data.name}</p>
          <p>Surface: {data.size}</p>
          <p>Surface Occupée: {data.surfaceOcc}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div
      onMouseEnter={handleTreemapMouseEnter}
      onMouseLeave={handleTreemapMouseLeave}
    >
      <Treemap
        width={1000}
        height={1200}
        data={data}
        dataKey={"size"}
        aspectRatio={4 / 3}
        stroke="#fff"
        fill="#8884d8"
        content={<CustomizedContent />}
      >
        <Tooltip content={<CustomTooltip />} />
      </Treemap>
    </div>
  );
}
