import styles from "./EquipementsVisu.module.css";

export default function EquipementsVisu({
  equipements
}) {


  return (
    <>
        {equipements.length != 0 && <div className={styles.table}>
        <table className={styles.tableSearch}>
          <thead>
            <tr>
            <th style={{ width: "33%" }}>
              <b>Local</b>
            </th>
            <th style={{ width: "33%" }}>
              <b>Équipement</b>
            </th>
            <th style={{ width: "33%" }}>
              <b>Prix</b>
            </th>
            {/* <th style={{ width: "10%" }}></th> */}
            </tr>
          </thead>
          <tbody>
            {equipements
              .map((equipement) => {
                return (
                  <tr key={equipement["eqRef"]}>
                    <td>{equipement["ugInt"]}</td>
                    <td>{equipement["eqRef"]}</td>
                    <td>{equipement["rubconvMnt"]}</td>
                    {/* <td>
                      <div className={styles.logoContainer}>
                        <img
                          src={modifBleu}
                          className={styles.logo}
                          onClick={() => {
                            modifDirOverlayFn(equipement);
                          }}
                        />
                        <img
                          src={poubBleu}
                          className={styles.logo}
                          onClick={() => removeDirOverlayFn(equipement)}
                        />
                      </div>
                    </td> */}
                  </tr>
                );
              })}
          </tbody>
        </table>
    </div>}

    </>
  );
}
