import styles from "./projetsVisu.module.css"
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import Pagination from "../../../components/Pagination/Pagination";
import informationLogo from "../../../assets/informationLogo.png"
import OverlayInfosProjet from "../OverlayInfosProjet/OverlayInfosProjet";
import poubBleu from "../../../assets/poubBleu.png"
import { useParams } from "react-router-dom";

export default function ProjetsVisu({ projets, removeOverlayFn }) {
    const { id } = useParams() 
    const [numberElementsMax, setNumberElementsMax] = useState(3)
    const [prevNum, setPrevNum] = useState(0)
    const [nextNum, setNextNum] = useState(3)
    const [seeInfo, setSeeInfo] = useState(false)
    const [infos, setInfos] = useState("")
    const queryClient = useQueryClient(); 

    const nextPage = () => {
        if (numberElementsMax >= projets.length) {
            setNumberElementsMax(3)
        } else {
            setNumberElementsMax((prev) => prev + 3)
        }
    }

    useEffect(() => {
        if (projets.length == 0) {
            setNextNum(0)
        } else if (numberElementsMax > projets.length) {
            setNextNum(projets.length)
        } else {
            setNextNum(numberElementsMax)
        }
        if (numberElementsMax > projets.length) {
            const numsCandidate = [projets.length - 1, projets.length - 2]            
            let smallerNumber
            for (let i of numsCandidate) {
                if (i % 3 === 0) {
                smallerNumber = i + 1;
                break; 
                }
            }
            if (smallerNumber == undefined) {
                setPrevNum(0)
            } else {
                setPrevNum(smallerNumber)
            }
        } else {
            if (projets.length == 0) {
                setPrevNum(0)
            } else {
                setPrevNum(numberElementsMax - 2)
            }
        }
    }, [numberElementsMax, projets])

    const prevPage = () => {
            if (numberElementsMax == 3) {
                if (projets.length % 3 == 0) {
                    setNumberElementsMax((prev) => projets.length)
                } else {
                const numsCandidate = [projets.length + 1, projets.length + 2]            
                let greatestNumber
                for (let i of numsCandidate) {
                    if (i % 3 === 0) {
                    greatestNumber = i;
                    break; 
                    }
                }
                setNumberElementsMax(greatestNumber)
            }
            } else {
                setNumberElementsMax((prev) => prev - 3)
            }
        // }
    }

    const openOverlayFn = async (infos) => {
        if (seeInfo == false) {
            setInfos(infos)
        } else {
            queryClient.invalidateQueries(["Tiers", id]);
        }
        setSeeInfo(!seeInfo)
    }

    return (
            <div className={styles.table}>
                {seeInfo && <OverlayInfosProjet infos={infos} openOverlayFn={openOverlayFn} />}
                <table className={styles.tableSearch}>
                    <thead>
                        <th>Dénomination entreprise</th>
                        <th>Activité</th>
                        <th>Date de création</th>
                        <th style={{width:"5rem"}}></th>
                        <th style={{width:"5rem"}}></th>
                    </thead>
                    <tbody>
                        {projets.length != 0 && projets.slice(numberElementsMax-3,numberElementsMax).map((data) => {
                        return <tr> 
                            <td>{data["RsoPrj"]}</td>
                            <td>{data["ActPrj"]}</td>
                            <td>{data["DatCrePrj"]?.split('-').reverse().join('-')}</td>
                            <td onClick={() => openOverlayFn(data)}><img src={informationLogo} className={styles.infoLogo} /></td>
                            <td onClick={() => removeOverlayFn(data)}><img src={poubBleu} className={styles.infoLogo} /></td>
                        </tr>
                        })}
                    </tbody>
                </table>
                <div>
                    <Pagination nextPage={nextPage} prevPage={prevPage} />
                    <p className={styles.nums}>{prevNum} à {nextNum} - Nombre Total d'éléments : {projets.length} </p>
                </div> 
            </div>
    )
}