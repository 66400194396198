import { useContext, } from "react";
import styles from "./BarChartSynOccu.module.css";
import { BarChart, Bar, LabelList, YAxis, Rectangle, Legend } from "recharts";
import { HeaderContext } from "../../../App"
import { useNavigate } from "react-router-dom";

export default function BarChartSynOccu({ data }) {
  const CustomBarShape = (props) => {
    const { fill, x, y, width, height } = props;
    return <Rectangle x={x} y={y} width={width} height={height} fill={fill} />;
  };
  const navigate = useNavigate()
  const { setFilterFieldsPatrimoine } = useContext(HeaderContext);
    

  return (
    <BarChart
      width={200}
      height={500}
      data={data}
      className={styles.barchartContainer}
    >
      <YAxis domain={[0, 100]} hide={true} />
      <Bar
        dataKey="valeur"
        width="100"
        fill="#446aa2"
        className={styles.barchartfirstBar}
        shape={<CustomBarShape />}
        onClick={(e) => {
          setFilterFieldsPatrimoine({dateDispo:"", loué:true, disponible:false})
          navigate(`../recherchePatrimoine`);
        }}

      >
        <LabelList
          dataKey="valeur"
          position="inside"
          fill="white"
          className={styles.labelValue}
        />
      </Bar>
      <Bar
        dataKey="objectif"
        width="100"
        fill="#8faedc"
        className={styles.barchartSecond}
        shape={<CustomBarShape />}
        onClick={(e) => {
          setFilterFieldsPatrimoine({dateDispo:"", loué:false, disponible:true})
          navigate(`../recherchePatrimoine`);
        }}

      >
        <LabelList
          dataKey="objectif"
          position="inside"
          fill="white"
          className={styles.labelValue}
        />
      </Bar>
      <Legend wrapperStyle={{ fontSize: '1rem' }} />
    </BarChart>
  );
}
