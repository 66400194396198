import { useEffect, useState } from "react";
import styles from "./DirigeantsVisu.module.css";
import Pagination from "../../Pagination/Pagination";
import modifBleu from "../../../assets/modifbleu.png";
import poubBleu from "../../../assets/poubBleu.png";
import { useLocation } from "react-router-dom";

export default function DirigeantsVisu({
  dirigeants,
  removeDirOverlayFn,
  modifDirOverlayFn,
}) {
  const [numberElementsMax, setNumberElementsMax] = useState(3);
  const location = useLocation();

  const [prevNum, setPrevNum] = useState(0);
  const [nextNum, setNextNum] = useState(3);

  const nextPage = () => {
    if (numberElementsMax >= dirigeants.length) {
      // setNextNum((prev) => prev + 3)
      setNumberElementsMax(3);
    } else {
      setNumberElementsMax((prev) => prev + 3);
    }
  };

  useEffect(() => {
    if (dirigeants.length == 0) {
      setNextNum(0);
    } else if (numberElementsMax > dirigeants.length) {
      setNextNum(dirigeants.length);
    } else {
      setNextNum(numberElementsMax);
    }
    if (numberElementsMax > dirigeants.length) {
      const numsCandidate = [dirigeants.length - 1, dirigeants.length - 2];
      let smallerNumber;
      for (let i of numsCandidate) {
        if (i % 3 === 0) {
          smallerNumber = i + 1;
          break;
        }
      }
      if (smallerNumber == undefined) {
        setPrevNum(0);
      } else {
        setPrevNum(smallerNumber);
      }
    } else {
      if (dirigeants.length == 0) {
        setPrevNum(0);
      } else {
        setPrevNum(numberElementsMax - 2);
      }
    }
  }, [numberElementsMax, dirigeants]);

  const prevPage = () => {
    if (numberElementsMax == 3) {
      if (dirigeants.length % 3 == 0) {
        setNumberElementsMax(dirigeants.length);
      } else {
        const numsCandidate = [dirigeants.length + 1, dirigeants.length + 2];
        let greatestNumber;
        for (let i of numsCandidate) {
          if (i % 3 === 0) {
            greatestNumber = i;
            break;
          }
        }
        setNumberElementsMax(greatestNumber);
      }
    } else {
      setNumberElementsMax((prev) => prev - 3);
    }
    // }
  };

  const openNewWindow = (path) => {
    const url = location.pathname.split("/").slice(0, -1).join("/") + path;
    window.open(url, "_blank");
  };

  return (
    <>
      {dirigeants.length != 0 && (
        <div className={styles.table}>
          <table className={styles.tableSearch}>
            <thead>
              <tr>
                <th style={{ width: "30%" }}>
                  <b>Libellé</b>
                </th>
                <th style={{ width: "20%" }}>
                  <b>Fonction</b>
                </th>
                <th style={{ width: "20%" }}>
                  <b>Date de début de fonction</b>
                </th>
                <th style={{ width: "20%" }}>
                  <b>Date de fin de fonction</b>
                </th>
                <th style={{ width: "10%" }}></th>
              </tr>
            </thead>
            <tbody>
              {dirigeants
                .slice(numberElementsMax - 3, numberElementsMax)
                .map((dirigeant) => {
                  return (
                    <tr
                      key={dirigeant["tiLib"]}
                      onClick={() => openNewWindow(`/${dirigeant["tippId"]}`)}
                    >
                      <td>{dirigeant["tiLib"]}</td>
                      <td>{dirigeant["relTyp"]}</td>
                      <td>
                        {dirigeant["relDtd"].split("-").reverse().join("-")}
                      </td>
                      <td>
                        {dirigeant["relDtf"].split("-").reverse().join("-")}
                      </td>
                      <td>
                        <div className={styles.logoContainer}>
                          <img
                            src={modifBleu}
                            className={styles.logo}
                            onClick={() => {
                              modifDirOverlayFn(dirigeant);
                            }}
                          />
                          <img
                            src={poubBleu}
                            className={styles.logo}
                            onClick={() => removeDirOverlayFn(dirigeant)}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div>
            <Pagination nextPage={nextPage} prevPage={prevPage} />
            <p className={styles.nums}>
              {prevNum} à {nextNum} - Nombre Total d'éléments :{" "}
              {dirigeants.length}{" "}
            </p>
          </div>
        </div>
      )}
    </>
  );
}
