import styles from "./ProjetInfos.module.css";

export default function CreationProjetInfos( {inputValues, pageError, changeFn} ) {

    
  return (
    <div className={styles.container}>
        <p className={styles.textTitle}>INFORMATIONS SUR LE PROJET</p>
        <div className={styles.table}>
        <table className={styles.tableSearch}>
            <thead>
              <tr>
                <th style={{ width: "20%" }}></th>
                <th style={{ width: "30%" }}></th>
                <th style={{ width: "20%" }}></th>
                <th style={{ width: "30%" }}></th>
              </tr>
            </thead>
            <tbody>
            <tr>
                <td>
                <b>Dénomination entreprise</b>
                </td>
                <td>
                <input
                      type="text"
                      id="RsoPrj"
                      name="RsoPrj"
                      value={inputValues["RsoPrj"]}
                      onChange={(e) => changeFn(e, "projet")}
                      className={styles.input}
                    />
              </td>
                <td>
                <b>Activité*</b>
                </td>
                <td>
                <input
                      type="text"
                      id="ActPrj"
                      name="ActPrj"
                      value={inputValues["ActPrj"]}
                      onChange={(e) => changeFn(e, "projet")}
                      className={styles.input}
                    />
                  {(inputValues["ActPrj"] == undefined || inputValues["ActPrj"] == "") &&
                    pageError == true && (
                      <small style={{marginLeft: "1rem", color:"red"}}>
                        Ce champs est requis
                      </small>
                    )}
                </td>
            </tr>
            <tr>
                <td>
                <b>Statut Juridique</b>
                </td>
                <td>
                <select
                      value={inputValues["StatutJurPrj"]}
                      onChange={(e) => changeFn(e, "projet")}
                      name="StatutJurPrj"
                      id="StatutJurPrj"
                      className={styles.inputSelect}
                    >
                      <option value="">----------------------</option>
                      <option value="EI">EI</option>
                      <option value="EI micro">EI micro</option>
                      <option value="Association">Association</option>
                      <option value="EURL">EURL</option>
                      <option value="SARL">SARL</option>
                      <option value="SASU">SASU</option>
                      <option value="SAS">SAS</option>
                      <option value="SA">SA</option>
                      <option value="SNC">SNC</option>
                      <option value="SCS">SCS</option>
                      <option value="SCA">SCA</option>
                      <option value="Champ Libre">Champ libre</option>
                    </select>
                    {inputValues["StatutJurPrj"] == "Champ Libre" && (
                      <input
                        type="text"
                        id="champLibre"
                        name="champLibre"
                        value={inputValues["champLibre"]}
                        onChange={(e) => changeFn(e, "projet")}
                        className={styles.input}
                        style={{marginLeft: "1rem"}}
                      />
                    )}

                  </td>
                <td>
                <b>Date de création</b>
                </td>
                <td>
                <input
                      type="date"
                      id="DatCrePrj"
                      name="DatCrePrj"
                      value={inputValues["DatCrePrj"]}
                      onChange={(e) => changeFn(e, "projet")}
                      className={styles.input}
                    />
                </td>
            </tr>
            <tr>
            <td>
              <b>Nombre de dirigeant</b>
            </td>
            <td>
              <input
                type="number"
                id="NbDirPrj"
                name="NbDirPrj"
                value={inputValues["NbDirPrj"]}
                onChange={(e) => changeFn(e, "projet")}
                className={styles.input}
                min="0"
              />
            </td>
            <td>
              <b>Effectif prévisionnel</b>
            </td>
            <td>
            <input
                      type="number"
                      id="EffPrj"
                      name="EffPrj"
                      value={inputValues["EffPrj"]}
                      onChange={(e) => changeFn(e, "projet")}
                      className={styles.input}
                      min="0"
                    />
                </td>
          </tr>
            </tbody>
            </table>
        </div>
    </div>
  );
}

