import styles from "./InfosConv.module.css";
import { useQuery } from "@tanstack/react-query";

export default function InfosConv({
  changeFn,
  inputValues,
  pageError,
}) {

  async function allPMs() {
    const link = `${process.env.REACT_APP_API_URL}/conv/PMs`
    // const link = "http://localhost:8080/conv/PMs"
    const response = await fetch(link)
    if (!response.ok) {
        throw "il y a un pb"
    }
    const data = await response.json()
    return data
}

  const {data: PMs = []} = useQuery({
      queryKey:["PMs"],
      queryFn:() => allPMs(),
      refetchOnWindowFocus: false
  })


  return (
    <div className={styles.container}>
    <p className={styles.textTitle}>CONVENTION D'OCCUPATION TEMPORAIRE</p>
    <div className={styles.table}>
      <table className={styles.tableSearch}>
        <thead>
          <tr>
          <th style={{ width: "20%" }}></th>
          <th style={{ width: "30%" }}></th>
          <th style={{ width: "20%" }}></th>
          <th style={{ width: "30%" }}></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>Entreprise*</b>
            </td>
            <td>
              <input list="options" value={inputValues["convtipmId"]} data-testid="convtipmId" onChange={(e) => changeFn(e)} name="convtipmId" id="convtipmId" className={styles.input} />
              <datalist id="options">
                  <option value="">---------------</option>
                  {PMs.length != 0 && PMs.map((data) => {
                      return <option value={`${data.tiId} - ${data.tiLib}`} key={data.tiId}>{data.tiId} - {data.tiLib}</option>
                  })}
              </datalist>
              {(inputValues["convtipmId"] == undefined ||
                inputValues["convtipmId"] == "") &&
                pageError == true && (
                  <small style={{ marginLeft: "1rem" }}>
                    Ce champs est requis
                  </small>
                )}
            </td>
            <td>
              <b>Date de début*</b>
            </td>
            <td>
                <input
                  type="date"
                  id="convDtd"
                  name="convDtd"
                  value={inputValues["convDtd"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
                {(inputValues["convDtd"] == undefined ||
                inputValues["convDtd"] == "") &&
                pageError == true && (
                  <small style={{ marginLeft: "1rem" }}>
                    Ce champs est requis
                  </small>
              )}

            </td>
          </tr>
          <tr>
            <td>
              <b>Date de signature*</b>
            </td>
            <td>
              <input
                  type="date"
                  id="convDatsig"
                  name="convDatsig"
                  value={inputValues["convDatsig"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
              {(inputValues["convDatsig"] == undefined ||
                inputValues["convDatsig"] == "") &&
                pageError == true && (
                  <small style={{ marginLeft: "1rem" }}>
                    Ce champs est requis
                  </small>
              )}
            </td>
            <td>
              <b>Formule</b>
            </td>
            <td>
              <p>{inputValues["convForm"]}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    </div>
  );
}
