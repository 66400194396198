import styles from "./pagination.module.css"
import droite from "../../assets/droite.svg"
import gauche from "../../assets/gauche.svg"

import {useState} from "react"

export default function Pagination ({nextPage, prevPage}) {

    const [transition, setTransition] = useState('');

    const handleButtonClick = (isPrev) => {
        if (isPrev) {
            setTransition(styles['transition-prev']);
            prevPage()
        } else {
            setTransition(styles['transition-next']);
            nextPage()
        }
      setTimeout(() => setTransition(''), 300);
    };
  
    return (
      <div className={`${styles["pagination-wrapper"]} ${transition}`}>
      <img
        className={`${styles.btn} ${styles['btn--prev']}`}
        src={gauche}
        onClick={() => handleButtonClick(true)}
      >
        </img>
  
        <div className={`${styles['pagination-container']}`}>
        <div className={`${styles['little-dot']} ${styles['little-dot--first']}`}></div>
        <div className={`${styles['little-dot']}`}>
          <div className={`${styles['big-dot-container']}`}>
            <div className={`${styles['big-dot']}`}></div>
          </div>
        </div>
        <div className={`${styles['little-dot']} ${styles['little-dot--last']}`}></div>
      </div>

      <img
        className={`${styles.btn} ${styles['btn--next']}`}
        src={droite}
        onClick={() => handleButtonClick(false)}
      >
        {/* Add path for next button */}
      </img>      
      </div>
    );
  };