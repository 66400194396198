import ReactDOM from "react-dom";
import styles from "./OverlayInfoPrix.module.css";

export default function OverlayInfoPrix({ openOverlayFn, infos }) {


  return ReactDOM.createPortal(
    <div className={styles.overlay} onClick={() => openOverlayFn()}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <p className={styles.textHistPrix}>DÉTAILS HISTORIQUE</p>
        <div className={styles.infosDate}>
          <div style={{width: "100%"}}>
            <label htmlFor="Date de début" className={styles.label}>
              Date de début
            </label>
            <input
              type="text"
              id="Date de début"
              name="Date de début"
              readOnly={true}
              min="0"
              value={infos["prixDdeb"]}
              className={styles.input}
            />
          </div>
          <div style={{ marginLeft: "1rem", width: "100%" }}>
            <label htmlFor="Date de fin" className={styles.label}>
              Date de fin
            </label>
            <input
              type="text"
              id="Date de fin"
              name="Date de fin"
              readOnly={true}
              min="0"
              value={infos["prixDfin"]}
              className={styles.input}
            />
          </div>
          <div style={{ marginLeft: "1rem", width: "100%" }}>
            <label htmlFor="Formule" className={styles.label}>
              Formule
            </label>
            <input
              type="text"
              id="Formule"
              name="Formule"
              readOnly={true}
              value={infos["prixType"]}
              className={styles.input}
            />
          </div>
        </div>
        <div className={styles.table}>
          <table className={styles.tableSearch}>
            <thead>
              <th style={{ width: "25%" }}>Superficie</th>
              <th style={{ width: "25%" }}>
                {infos.prixType == "PEPINIERE"
                  ? "Montant HT Année 1"
                  : infos.prixType == "CENTRE" || infos.prixType == "COWORKING"
                  ? "Montant HT"
                  : null}
              </th>
              <th style={{ width: "25%" }}>
                {infos.prixType == "PEPINIERE" ? "Montant HT Année 2" : null}
              </th>
              <th style={{ width: "25%" }}>
                {infos.prixType == "PEPINIERE" ? "Montant HT Année 3" : null}
              </th>
            </thead>
            <tbody>
              {infos.prixType != "COWORKING"
                ? Object.entries(infos).map(([key, prices]) => {
                    if (!isNaN(parseFloat(key)) && isFinite(key)) {
                      return (
                        <tr>
                          <td>
                            <b>{key}m²</b>
                          </td>
                          <td>{prices[0]}</td>
                          <td>{prices[1]}</td>
                          <td>{prices[2]}</td>
                        </tr>
                      );
                    } else {
                      return null;
                    }
                  })
                : Object.entries(infos).map(([key, prices]) => {
                    if (key == "24hmois") {
                      return (
                        <tr>
                          <td>
                            <b>Formule 24h/mois</b>
                          </td>
                          <td>{prices[0]}</td>
                          <td></td>
                          <td></td>
                        </tr>
                      );
                    } else {
                      return null;
                    }
                  })}
            </tbody>
          </table>
        </div>
        <button
          className={styles.modifierButton}
          onClick={() => openOverlayFn()}
        >
          Retour
        </button>
      </div>
    </div>,
    document.getElementById("overlay")
  );
}
