import styles from "./FormuleInfos.module.css";

export default function CreationFormuleInfos( {inputValues, pageError, changeFn} ) {

    
  return (
    <div className={styles.container}>
        <p className={styles.textTitle}>FORMULE DU PORTEUR DE PROJET</p>
        <div className={styles.table}>
        <table className={styles.tableSearch}>
            <thead>
              <tr>
                <th style={{ width: "25%" }}></th>
                <th style={{ width: "25%" }}></th>
                <th style={{ width: "25%" }}></th>
                <th style={{ width: "25%" }}></th>
              </tr>
            </thead>
            <tbody>
            <tr>
                <td>
                <b>Date de début*</b>
                </td>
                <td>
                <input
                  type="date"
                  id="entDat"
                  name="entDat"
                  value={inputValues["entDat"]}
                  onChange={(e) => changeFn(e, "formule")}
                  className={styles.input}
                />
                  {((inputValues["entDat"] == "" || inputValues["entDat"] == undefined) && pageError == true) && (
                    <small style={{marginLeft: "1rem", color:"red"}}>Ce champs est requis</small>
                  )}

              </td>
                <td>
                <b>Catégorie*</b>
                </td>
                <td>
                <select name="formule" id="formule" onChange={(e) => changeFn(e, "formule")} className={styles.inputSelect} value={inputValues["formule"]}>
                        <option value="">-----------</option>
                        <option value="Porteur de projet">Porteur de projet</option>
                        <option value="Formule Pépinière">Formule Pépinière</option>
                        <option value="Extra-Muros">Extra-Muros</option>
                        <option value="Formule Coworking">Formule Coworking</option>
                        <option value="Formule Centre d'Affaires">Formule Centre d'Affaires</option>
                    </select>
                    {((inputValues["formule"] == undefined || inputValues["formule"] == "") && pageError == true) && (
                    <small style={{marginLeft: "1rem", color:"red"}}>Ce champs est requis</small>
                  )}
                </td>
            </tr>
            </tbody>
            </table>
        </div>
    </div>
  );
}

