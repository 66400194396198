import styles from "./rechercheTiers.module.css"
import Pagination from "../../components/Pagination/Pagination"
import { useContext, useEffect, useState } from "react"
import { HeaderContext } from "../../App"
import { BounceLoader } from 'react-spinners'
import { useLocation, useNavigate, useParams } from "react-router-dom"
import SearchBox from "../../components/SearchBox/SearchBox"
import Box from "../../components/Box"

export default function RechercheTiers () {
    const { type, rubrique }  = useParams()
    const location = useLocation();
    const pathURL = location.pathname;   
    // const [numberElementsMax, setNumberElementsMax] = useState(10)
    const navigate = useNavigate()
    // const [prevNum, setPrevNum] = useState(0)
    // const [nextNum, setNextNum] = useState(10)
    const { searchData, isLoading,  numberElementsMaxTiers: numberElementsMax, setNumberElementsMaxTiers: setNumberElementsMax, 
        prevNumTiers : prevNum, setPrevNumTiers : setPrevNum, nextNumTiers : nextNum, setNextNumTiers : setNextNum } = useContext(HeaderContext);
    const { filterFieldTiers } = useContext(HeaderContext);
    const [searchClicked, setSearchClicked] = useState(type == "recherche" ? true : false);
    const [rubriqueChosen, setRubriqueChosen] = useState(rubrique == "Formule Pépinière" ? "Formule Pépinière" : rubrique == "Extra-Muros" ? "Extra-Muros" : 
    rubrique == "Formule Centre d'Affaires" ? "Formule Centre d'Affaires" : rubrique == "Formule Coworking" ? "Formule Coworking" : "Porteur de projet");


    const filteredSearchData = (searchData[0] != "empty" && searchData[0] != "loading") ? searchData.filter((data) => {
          if (filterFieldTiers.porteurProjet && filterFieldTiers.PM && (data["formule"] == rubriqueChosen || (data["formule"] == null && rubriqueChosen == "Porteur de projet"))) { 
            return true
          } else if (filterFieldTiers.porteurProjet && !filterFieldTiers.PM && data["tiQual"] == "PP" && (data["formule"] == rubriqueChosen || (data["formule"] == null && rubriqueChosen == "Porteur de projet"))) {
            return true
          } else if (!filterFieldTiers.porteurProjet && filterFieldTiers.PM && data["tiQual"] == "PM" && (data["formule"] == rubriqueChosen || (data["formule"] == null && rubriqueChosen == "Porteur de projet"))) {
            return true
          } else {
            return false
          }
         })
        :[]

    const nextPage = () => {
        if (numberElementsMax >= filteredSearchData.length) {
            setNumberElementsMax(10)
        } else {
            setNumberElementsMax((prev) => prev + 10)
        }
    }

    // useEffect(() => {
    //     setNumberElementsMax(10)
    // }, [rubriqueChosen])

    useEffect(() => {
        if (isLoading == false) {
            if (filteredSearchData.length == 0) {
                setNextNum(0)
            } else if (numberElementsMax > filteredSearchData.length) {
                setNextNum(filteredSearchData.length)
            } else {
                setNextNum(numberElementsMax)
            }
            if (numberElementsMax > filteredSearchData.length) {
                const numsCandidate = [filteredSearchData.length - 1, filteredSearchData.length - 2,filteredSearchData.length - 3, filteredSearchData.length - 4,
                    filteredSearchData.length - 5, filteredSearchData.length - 6,filteredSearchData.length - 7, filteredSearchData.length - 8,
                    filteredSearchData.length - 9]            
                let smallerNumber
                for (let i of numsCandidate) {
                    if (i % 10 === 0) {
                    smallerNumber = i + 1;
                    break; 
                    }
                  }
                if (smallerNumber == undefined) {
                    setPrevNum(0)
                } else {
                    setPrevNum(smallerNumber)
                }
            } else {
                if (filteredSearchData.length == 0) {
                    setPrevNum(0)
                } else {
                    setPrevNum(numberElementsMax - 9)
                }
            }
    
        }
    }, [numberElementsMax, filteredSearchData])


    const prevPage = () => {
            if (numberElementsMax == 10) {
                if (filteredSearchData.length % 10 == 0) {
                    setNumberElementsMax(filteredSearchData.length)
                } else {
                const numsCandidate = [filteredSearchData.length + 1, filteredSearchData.length + 2,filteredSearchData.length + 3, filteredSearchData.length + 4,
                        filteredSearchData.length + 5, filteredSearchData.length + 6,filteredSearchData.length + 7, filteredSearchData.length + 8,
                        filteredSearchData.length + 9]            
                let greatestNumber
                for (let i of numsCandidate) {
                    if (i % 10 === 0) {
                    greatestNumber = i;
                    break; 
                    }
                  }
                setNumberElementsMax(greatestNumber)
            }
            } else {
                setNumberElementsMax((prev) => prev - 10)
            }
    }

    const rubriques = ["Porteur de projet", "Formule Pépinière","Extra-Muros","Formule Coworking","Formule Centre d'Affaires"]

    const rubriqueFn = (text) => {
        setRubriqueChosen(text)
        setNumberElementsMax(10)
        navigate(`../rechercheTiers/recherche/${text}`)
    }

    return (
        <div className={styles.container}>
            <div className={styles.boxesContainer}>
                <div
                className={`${searchClicked ? styles.box : styles.boxNotClicked}`}
                onClick={() => setSearchClicked(true)}
                >
                <p
                    className={`${searchClicked ? styles.text : styles.textNotClicked}`}
                >
                    Bénéficiaires
                </p>
                {/* {searchClicked && <div className={styles.downloadContainer}>
                    <img src={downloadBlanc} className={styles.logo} onClick={() => console.log("download")} />
                </div>} */}
                </div>
                <div
                className={`${searchClicked ? styles.boxNotClicked : styles.box}`}
                onClick={() => setSearchClicked(false)}
                >
                <p
                    className={`${searchClicked ? styles.textNotClicked : styles.text}`}
                >
                    Formulaire
                </p>
                </div>
            </div>
            {searchClicked ?
            <>
            <div className={styles.boxesContainer}>
                {rubriques.map((rubrique) => {
                    return (<div
                        className={`${rubriqueChosen == rubrique ? styles.box : styles.boxNotClicked}`}
                        onClick={() => rubriqueFn(rubrique)}
                        key={rubrique}
                        >
                        <p
                            className={`${rubriqueChosen == rubrique ? styles.text : styles.textNotClicked}`}
                        >
                            {rubrique}
                        </p>
                        </div>)
                })}
            </div>
            <SearchBox />
            <div className={styles.table}>
                <table className={styles.tableSearch}>
                    <thead>
                        <tr>
                        <th style={{width: "5%"}}>Identifiant</th>
                        <th style={{width: "50%"}}>Libellé</th>
                        <th style={{width: "5%"}}>Qualité</th>
                        <th style={{width: "20%"}}>Email</th>
                        <th style={{width: "20%"}}>Téléphone Portable</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredSearchData.length != 0 && filteredSearchData.slice(numberElementsMax-10,numberElementsMax).map((data) => {
                            let liste = []
                            for (const [key, value] of Object.entries(data)) {
                                    liste.push(value)    
                            }
                        return <tr onClick={() => navigate(`../rechercheTiers/tiers/${liste[0]}`)} key={liste[0]}> 
                            <td>{liste[0]}</td>
                            <td>{liste[1]}</td>
                            <td>{liste[2]}</td>
                            <td>{liste[3]}</td>
                            <td>{liste[4]}</td>
                        </tr>
                        })}
                    </tbody>
                </table>
                </div>
                {(isLoading || searchData[0] == "loading") ?
                        <div className={styles.bounce}>
                        <BounceLoader color="black" />
                        </div> : (searchData[0] == "empty" || filteredSearchData.length != 0) ? <div>
                <Pagination nextPage={() => nextPage()} prevPage={() => prevPage()} />
                <p className={styles.nums}>{prevNum} à {nextNum} - Nombre Total d'éléments : {filteredSearchData.length} </p>
                </div> : null
                }
            </>
            :
            <div className={styles.addContainer}>
                <p className={styles.textAdd}>Que souhaitez-vous ajouter ?</p>
                <Box text="Un porteur de projet" link="/pageConnecte/creerTiers/PP" />
                <Box text="Une personne morale" link="/pageConnecte/creerTiers/PM" />
                <Box text="Un porteur de projet et une personne morale" link="/pageConnecte/creerTiers/PPPM" />
                <Box text="Un fichier Excel" link="/pageConnecte/creerTiers/excel" />
            </div>
            }
        </div>
    )
}