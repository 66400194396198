import ReactDOM from 'react-dom';
import styles from "./overlayAddPrj.module.css"
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { BounceLoader } from "react-spinners";

export default function OverlayAddPrj({ openOverlayFn, nbprjs }) {
    const { id } = useParams()
    const [newPrj, setNewPrj] = useState({tiId: id, IdPrj: nbprjs + 1, RsoPrj: "", ActPrj: "", DatCrePrj: "", NbDirPrj: "", EffPrj: "", StatutJurPrj:"", champLibre: ""})
    const [pageError, setPageError] = useState(false);
    const queryClient = useQueryClient();

    const changePrj = (e) => {
        setNewPrj((prev) => {return {...prev, [e.target.name]: e.target.value}})
    }

    const checkAndSend = () => {
        if (newPrj["ActPrj"] == "") {
            setPageError(true)
        } else {
            setPageError(false)
            mutationPostPrj.mutate(newPrj)
        }
    }

    async function addPrj(data) {
        const link = `${process.env.REACT_APP_API_URL}/tiers/Prj`
        // const link = `http://localhost:8080/tiers/Prj`
        const response = await fetch(link, {
            method:"POST",
            body:JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
            credentials:"include"
        })
        if (!response.ok) {
            throw 'Il y a un problème avec le serveur'
        }
        const dataReceived = await response.json()
        return dataReceived
    }

    const mutationPostPrj = useMutation({
        mutationFn: (data) => addPrj(data),
        onSuccess: () => {
                queryClient.invalidateQueries(["Tiers", id]);
                openOverlayFn((prev) => !prev)
        }
    })


    return ReactDOM.createPortal(
        <div className={styles.overlay} onClick={() => openOverlayFn(false)}>
            <div className={styles.container} onClick={(e) => e.stopPropagation()}>
                <p className={styles.textOverlayTitle}>AJOUTER PROJET</p>
                <div>
                    <div className={styles.rowContainer}>
                        <div className={styles.fieldContainer}>
                            <label className={styles.label} htmlFor="eqNat">
                            Dénomination entreprise
                            </label>
                            <input
                                type="text"
                                id="RsoPrj"
                                name="RsoPrj"
                                value={newPrj["RsoPrj"]}
                                onChange={(e) => changePrj(e)}
                                className={styles.input}
                            />
                            {/* {((newPrj["RsoPrj"] == "")  && pageError == true) && <small className={styles.small}>Ce champs est requis</small>} */}
                        </div>
                        <div className={styles.fieldContainer}>
                            <label className={styles.label} htmlFor="eqRef">
                            Activité*
                            </label>
                            <input
                                type="text"
                                id="ActPrj"
                                name="ActPrj"
                                value={newPrj["ActPrj"]}
                                onChange={(e) => changePrj(e)}
                                className={styles.input}
                            />
                            {((newPrj["ActPrj"] == "")  && pageError == true) && <small className={styles.small}>Ce champs est requis</small>}
                        </div>
                    </div>
                    <div className={styles.rowContainer}>
                        <div className={styles.fieldContainer}>
                            <label className={styles.label} htmlFor="StatutJurPrj">
                            Statut juridique
                            </label>
                            <select
                                value={newPrj["StatutJurPrj"]}
                                onChange={(e) => changePrj(e)}
                                name="StatutJurPrj"
                                id="StatutJurPrj"
                                className={styles.inputSelect}
                                >
                                <option value="">----------------------</option>
                                <option value="EI">EI</option>
                                <option value="EI micro">EI micro</option>
                                <option value="Association">Association</option>
                                <option value="EURL">EURL</option>
                                <option value="SARL">SARL</option>
                                <option value="SASU">SASU</option>
                                <option value="SAS">SAS</option>
                                <option value="SA">SA</option>
                                <option value="SNC">SNC</option>
                                <option value="SCS">SCS</option>
                                <option value="SCA">SCA</option>
                                <option value="Champ Libre">Champ libre</option>
                            </select>
                            {newPrj["StatutJurPrj"] == "Champ Libre" && (
                                <input
                                type="text"
                                id="champLibre"
                                name="champLibre"
                                value={newPrj["champLibre"]}
                                onChange={(e) => changePrj(e)}
                                className={styles.input}
                                />
                            )}
                        </div>
                        <div className={styles.fieldContainer}>
                            <label className={styles.label} htmlFor="eqPrix">
                            Date de création
                            </label>
                            <input
                                type="date"
                                id="DatCrePrj"
                                name="DatCrePrj"
                                value={newPrj["DatCrePrj"]}
                                onChange={(e) => changePrj(e)}
                                className={styles.input}
                            />
                            {/* {(newPrj["DatCrePrj"] == "" && pageError == true) && <small className={styles.small}>Ce champs est requis</small>} */}
                        </div>
                    </div>
                    <div className={styles.rowContainer}>
                    <div className={styles.fieldContainer}>
                        <label className={styles.label} htmlFor="eqPrix">
                        Nombre de dirigeant
                        </label>
                        <input
                            type="number"
                            id="NbDirPrj"
                            name="NbDirPrj"
                            value={newPrj["NbDirPrj"]}
                            onChange={(e) => changePrj(e)}
                            className={styles.input}
                            min="0"
                        />
                        {/* {(newPrj["NbDirPrj"] == "" && pageError == true) && <small className={styles.small}>Ce champs est requis</small>} */}
                    </div>
                    <div className={styles.fieldContainer}>
                        <label className={styles.label} htmlFor="eqPrix">
                        Effectif prévisionnel
                        </label>
                        <input
                            type="number"
                            id="EffPrj"
                            name="EffPrj"
                            value={newPrj["EffPrj"]}
                            onChange={(e) => changePrj(e)}
                            className={styles.input}
                            min="0"
                        />
                        {/* {(newPrj["EffPrj"] == "" && pageError == true) && <small className={styles.small}>Ce champs est requis</small>} */}
                    </div>
                    </div>
                </div>
                <div style={{width:"100%", display:"flex", justifyContent:"space-evenly"}}>
                    <button className={styles.buttonValid} disabled={mutationPostPrj.isPending} onClick={() => checkAndSend()}>Ajouter</button>       
                    <button className={styles.buttonValid} onClick={() => openOverlayFn(false)}>Retour</button>    
                </div>
                {mutationPostPrj.isPending == true && (
                    <div className={styles.bounce}>
                        <BounceLoader color="white" />
                    </div>
                )}
            </div>
        </div>,
        document.getElementById('overlay')
        )
}