import ReactDOM from "react-dom";
import styles from "./overlayFilterSuivi.module.css";
import { useContext, useState } from "react";
import { HeaderContext } from "../../../App";

export default function OverlayFilterSuivi({
  setFilter,
}) {
  const { filterFieldSuivi, setFilterFieldsSuivi } = useContext(HeaderContext);
  const [localFilter, setLocalFilter] = useState(filterFieldSuivi)
  const changeFilterSuivi = (e) => {
    setLocalFilter((prev) => {
          let newState = JSON.parse(JSON.stringify(prev))
          newState[e.target.name] = e.target.value
          return newState 
          });      
    }
  
  return (ReactDOM.createPortal(
      <div className={styles.overlay} onClick={() => setFilter("")}>
        <div className={styles.container} onClick={(e) => e.stopPropagation()}>
          <p className={styles.textHistPrix}>Filtres</p>
          <div className={styles.infosDate}>
            <div style={{ width: "50%" }}>
              <label htmlFor="dateDebut" className={styles.label}>
                Date de début
              </label>
              <input
                type="date"
                id="dateDebut"
                name="dateDebut"
                value={localFilter["dateDebut"]}
                onChange={(e) => changeFilterSuivi(e)}
                className={styles.input}
              />
            </div>
            <div style={{ width: "50%" }}>
              <label htmlFor="dateFin" className={styles.label}>
                Date de fin
              </label>
              <input
                type="date"
                id="dateFin"
                name="dateFin"
                value={localFilter["dateFin"]}
                onChange={(e) => changeFilterSuivi(e)}
                className={styles.input}
              />
            </div>
            
          </div>
          <div className={styles.field} >
            <label htmlFor="sujet" className={styles.label}>
                    Sujet abordé
            </label>
            <select
                value={localFilter["sujet"]}
                onChange={(e) => changeFilterSuivi(e)}
                name="sujet"
                id="sujet"
                className={styles.inputSelect}
            >
              <option value="">
                    ---------------------
                    </option>
                    <option value="Analyse financière">
                    Analyse financière
                    </option>
                    <option value="Communication / Marketing">
                    Communication / Marketing
                    </option>
                    <option value="Comptabilité / Gestion">
                    Comptabilité / Gestion
                    </option>
                    <option value="Conseils juridique">
                    Conseils juridique
                    </option>
                    <option value="Croissance / Développement">
                    Croissance / Développement
                    </option>
                    <option value="Design">
                    Design
                    </option>
                    <option value="Développement international">
                    Développement international
                    </option>
                    <option value="Financement">
                    Financement
                    </option>
                    <option value="Fiscalité">
                    Fiscalité
                    </option>
                    <option value="Gestion administrative">
                    Gestion administrative
                    </option>
                    <option value="Gestion des achats">
                    Gestion des achats
                    </option>
                    <option value="Gestion du temps">
                    Gestion du temps
                    </option>
                    <option value="Immobilier">
                    Immobilier
                    </option>
                    <option value="Design">
                    Design
                    </option>
                    <option value="Innovation">
                    Innovation
                    </option>
                    <option value="Management RH">
                    Management RH
                    </option>
                    <option value="Posture entrepreneuriale">
                    Posture entrepreneuriale
                    </option>
                    <option value="Relation clients">
                    Relation clients
                    </option>
                    <option value="Relations publiques">
                    Relations publiques
                    </option>
                    <option value="Stratégie commerciale">
                    Stratégie commerciale
                    </option>
                    <option value="Stratégie digitale">
                    Stratégie digitale
                    </option>
                    <option value="Autres">
                    Autres
                    </option>        
                  </select>
            </div>
          <div className={styles.buttonContainer}>
            <button onClick={() => {
              setFilterFieldsSuivi(localFilter)
              setFilter("")
              }} className={styles.buttonValid}>
              Valider
            </button>
            <button onClick={() => {
              setFilterFieldsSuivi({dateDebut:"", dateFin:"", sujet:""})
              setFilter("")}} className={styles.buttonValid}>
              Annuler
            </button>
          </div>
        </div>
      </div>,
      document.getElementById("overlay")
    ))}
