import styles from "./StatutPost.module.css";

export default function StatutPostVisu({
  statutPost
}) {

  return (
    <>
    { statutPost.length != 0 &&  <div className={styles.table}>
        <table className={styles.tableSearch}>
          <thead>
            <tr>
            <th>
              <b>Date</b>
            </th>
            <th>
              <b>Motif</b>
            </th>
            </tr>
          </thead>
          <tbody>
              <tr>
                <td>{statutPost[0]["actDat"]?.split('-').reverse().join('-')}</td>
                <td>{statutPost[0]["statut"]}</td>
              </tr>
          </tbody>
        </table>
    </div>
}
    </>
  );
}
