import ReactDOM from "react-dom";
import styles from "./overlayRemoveEq.module.css";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { BounceLoader } from "react-spinners";

export default function OverlayRemoveEq({ removeOverlayFn, eqToRemove }) {
  const { ugNum } = useParams();
  const queryClient = useQueryClient();

  async function removeEq(data) {
    const link = `${process.env.REACT_APP_API_URL}/ug/equipement`
    // const link = "http://localhost:8080/ug/equipement";
    const response = await fetch(link, {
      method: "DELETE",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const dataReceived = await response.json();
    return dataReceived;
  }

  const mutationDataDelete = useMutation({
    mutationFn: (data) => removeEq(data),
    onSuccess: (data) => {
      removeOverlayFn();
      queryClient.invalidateQueries(["Patrimoine", ugNum]);
    },
  });

  return ReactDOM.createPortal(
    <div className={styles.overlay} onClick={() => removeOverlayFn()}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <p className={styles.textOverlayTitle}>SUPPRIMER ÉQUIPEMENT</p>
        <div>
            <label htmlFor="Nature" className={styles.label}>
                Date de disponibilité
            </label>
            <input
            type="text"
            id="Nature"
            name="Nature"
            readOnly={true}
            value={eqToRemove["eqNat"]}
            className={styles.input}
            />
        </div>
        <div>
            <label htmlFor="Référence" className={styles.label}>
                Référence
            </label>
            <input
            type="text"
            id="Référence"
            name="Référence"
            readOnly={true}
            value={eqToRemove["eqRef"]}
            className={styles.input}
            />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <button
            className={styles.buttonValid}
            onClick={() => mutationDataDelete.mutate(eqToRemove)}
          >
            Oui
          </button>
          <button
            className={styles.buttonValid}
            onClick={() => removeOverlayFn()}
          >
            Non
          </button>
        </div>
        {mutationDataDelete.isPending == true && (
          <div className={styles.bounce}>
            <BounceLoader color="white" />
          </div>
        )}
      </div>
    </div>,
    document.getElementById("overlay")
  );
}
