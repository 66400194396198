import { useQuery } from "@tanstack/react-query";
import PrixPepiniere from "../../components/administrationComps/prixPepiniere/prixPep";
import styles from "./administration.module.css";
import { useState } from "react";
import { BounceLoader } from "react-spinners";
import PrixCentre from "../../components/administrationComps/prixCentreAffaires/prixCentre";
import PrixCoworking from "../../components/administrationComps/prixCoworking/prixCoworking";
import HistoriquePrix from "../../components/administrationComps/historiquePrix/historiquePrix";

export default function Admin() {

  const [adminClicked, setAdminClicked] = useState(true);

  async function getPrix() {
    const link = `${process.env.REACT_APP_API_URL}/admin/prixUGs`;
    // const link = "http://localhost:8080/admin/prixUGs";
    const response = await fetch(link);
    if (!response.ok) {
      throw "il y a un pb";
    }

    const data = await response.json(); // data: [{"Surface":[Montant Année 1, Montant Année 2, Mnt An 3]}, {"prixDdeb": "", "prixDfin": "", "prixReval": false, "prixNatfac":""}]
    return data;
  }

  const { data: prixData = {}, isLoading } = useQuery({
    queryKey: ["PrixUGs", "Admin"],
    queryFn: () => getPrix(),
    refetchOnWindowFocus: false,
  });

  async function getHistoriquePrix() {
    const link = `${process.env.REACT_APP_API_URL}/admin/historiquePrix`;
    // const link = "http://localhost:8080/admin/historiquePrix";
    const response = await fetch(link);
    if (!response.ok) {
      throw "il y a un pb";
    }

    const data = await response.json(); // data: [{"Surface":[Montant Année 1, Montant Année 2, Mnt An 3]}, {"prixDdeb": "", "prixDfin": "", "prixReval": false, "prixNatfac":""}]
    return data;
  }

  const { data: historiquePrixData = {}, isLoadingHistorique } = useQuery({
    queryKey: ["HistoriquePrix", "Admin"],
    queryFn: () => getHistoriquePrix(),
    refetchOnWindowFocus: false,
  });

  // const historiquePrixData = [{prixType: "Pépinière", prixDdeb: "2024-03-02", prixDfin: "2024-05-06", "16": ["10", "15", "20"], "32": ["10", "15", "20"], "25": ["10", "15", "20"]}, {prixType: "Pépinière", prixDdeb: "2024-03-02", prixDfin: "","16": ["10", "15", "20"], "32": ["10", "15", "20"], "25": ["10", "15", "20"]},
  // {prixType: "Coworking", prixDdeb: "2024-03-02", prixDfin: "2024-05-06", "16": ["10"], "32": ["10"], "25": ["10"]}, {prixType: "Pépinière", prixDdeb: "2024-03-02", prixDfin: "", "16": ["10", "15", "20"], "32": ["10", "15", "20"], "25": ["10", "15", "20"]},
  // {prixType: "Centre d'affaires", prixDdeb: "2024-03-02", prixDfin: "2024-05-06", prixMnt:"25"}, {prixType: "Pépinière", prixDdeb: "2024-03-02", prixDfin: "", "16": ["10", "15", "20"], "32": ["10", "15", "20"], "25": ["10", "15", "20"]}]


  // const prixData2 = {"coworking": ["2024-02-07", {"24h/mois":[""]}]}
//   const prixData = {"pepiniere": ["2024-02-07", {"16":[250, 300, 350],"32":[300, 350, 400]}]}

  return (
    <div className={styles.container}>
      <div className={styles.boxesContainer}>
        <div
          className={`${adminClicked ? styles.box : styles.boxNotClicked}`}
          onClick={() => setAdminClicked(true)}
        >
          <p
            className={`${adminClicked ? styles.text : styles.textNotClicked}`}
          >
            Administration
          </p>
        </div>
        <div
          className={`${adminClicked ? styles.boxNotClicked : styles.box}`}
          onClick={() => setAdminClicked(false)}
        >
          <p
            className={`${adminClicked ? styles.textNotClicked : styles.text}`}
          >
            Historique
          </p>
        </div>
      </div>
      {adminClicked ? (
        <>
          <p className={styles.textTypePrix}>
            LES PRIX DES LOCAUX (MENSUEL ET HT) - FORMULE PÉPINIÈRE
          </p>
          {prixData["pepiniere"] !== undefined && <PrixPepiniere prix={prixData["pepiniere"]} />}
          {isLoading && 
            <div className={styles.bounce}>
              <BounceLoader color="black" />
            </div>
          }
          <p className={styles.textTypePrix}>
            LES PRIX DES LOCAUX (MENSUEL ET HT) - FORMULE CENTRE D’AFFAIRES      
          </p>
          {prixData["centre"] !== undefined && <PrixCentre prix={prixData["centre"]} />}
          <p className={styles.textTypePrix}>
            TARIFICATION (MENSUEL ET HT) - FORMULE COWORKING 
          </p>
          {prixData["coworking"] !== undefined && <PrixCoworking prix={prixData["coworking"]} />}
          {isLoading && 
            <div className={styles.bounce}>
              <BounceLoader color="black" />
            </div>
          }
        </>
      ) : (
        <HistoriquePrix prix={historiquePrixData} />
      )}
    </div>
  );
}
