import ReactDOM from "react-dom";
import styles from "./overlayAddForm.module.css";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { BounceLoader } from "react-spinners";

export default function OverlayAddForm({
  openOverlayFn,
  relations,
  formules,
}) {

  const { id } = useParams();
  const [actForm, setnewActForm] = useState({
    tiId: id,
    formId: formules.length != 0 ? formules[0]["formId"] : "",
    entDat: formules.length != 0 ? formules[0]["entDat"] : "",
    sorDat: formules.length != 0 ? formules[0]["sorDat"] : "",
    formule: formules.length != 0 ? formules[0]["formule"] : "",
  });
  const [newForm, setnewForm] = useState({
    tiId: id,
    entDat: "",
    sorDat: "",
    formule: "Porteur de projet",
  });

  const [pageError, setPageError] = useState(false);
  const [pageNewError, setPageNewError] = useState(false);
  const queryClient = useQueryClient();

  const changeActForm = (e) => {
    setnewActForm((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const changeForm = (e) => {
    setnewForm((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const checkDates = (dateA, dateB) => {
    if (dateA != "" && dateB != "") {
      if (new Date(dateA) <= new Date(dateB)) {
        return false
      } else {
        return true
      }
    } else if (dateA != "" && dateB == "") {
      return false
    } else {
      return true
    }
  }

  const checkAndSendAct = (actForm) => {
    if (formules.length == 0) {
        setPageError(true);
    } else if (actForm["formule"] == "") {
        setPageError(true);
    } else if (checkDates(actForm["entDat"], actForm["sorDat"])) {
        setPageError(true);
    } else {
        setPageError(false);
        mutationUpdateForm.mutate(actForm)
    }
  }

  const checkAndSend = (newForm) => {
     if (newForm["entDat"] == "" || newForm["formule"] == "" || (formules.length != 0 && (formules[0]["entDat"] == "" || formules[0]["sorDat"] == "" || formules[0]["formule"] == ""))) {
      setPageNewError(true);
    } else if (newForm["entDat"] != "" && (checkDates(newForm["entDat"], newForm["sorDat"]) || (actForm["sorDat"] != "" && checkDates(actForm["sorDat"], newForm["entDat"])))) {
      setPageNewError(true);
    } else {
        setPageNewError(false);
        mutationPostForm.mutate(newForm)
    }
  }


  async function addForm(data) {
    const link = `${process.env.REACT_APP_API_URL}/tiers/formule`
    // const link = `http://localhost:8080/tiers/formule`;
    const response = await fetch(link, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const dataReceived = await response.json();
    return dataReceived;
  }

  const mutationPostForm = useMutation({
    mutationFn: (data) => addForm(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["Tiers", id]);
      openOverlayFn((prev) => !prev)
    },
  });

  async function ModifyForm(data) {
    const link = `${process.env.REACT_APP_API_URL}/tiers/formule`
    // const link = `http://localhost:8080/tiers/formule`;
    const response = await fetch(link, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const dataReceived = await response.json();
    return dataReceived;
  }

  const mutationUpdateForm = useMutation({
    mutationFn: (data) => ModifyForm(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["Tiers", id]);
      openOverlayFn((prev) => !prev);
    },
  })


  return ReactDOM.createPortal(
    <div className={styles.overlay} onClick={() => openOverlayFn(false)}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <div className={styles.containerFormule}>
        <p className={styles.textOverlayTitle}>
          MODIFIER FORMULE ACTUELLE
        </p>
        <div>
            <div className={styles.rowContainer}>
                <div className={styles.fieldContainer}>
                    <label className={styles.label} htmlFor="entDat">
                    Date d'entrée*
                    </label>
                    <input
                    type="date"
                    id="entDat"
                    name="entDat"
                    value={actForm["entDat"]}
                    onChange={(e) => changeActForm(e)}
                    className={styles.input}
                    />
                    {(actForm["entDat"] == "" && pageError == true && formules.length != 0) && (
                    <small className={styles.small}>Ce champs est requis</small> // l'utilisateur veut actualiser mais a enlevé la date de début
                    )}
                    {((formules.length != 0 && formules[0]["entDat"] == "") && pageNewError == true) && (
                    <small className={styles.small}>Veuillez mettre une date de début</small> // l'utilisateur veut ajouter une formule mais il y a des formules et l'utilisateur a enlevé la date
                    )} 
                    
                    {/* {allYears.includes(newForm["entDat"]) && pageError == true && (
                    <small className={styles.small}>
                        Cette année est déjà présente
                    </small>
                    )} */}
                </div>
                <div className={styles.fieldContainer}>
                    <label className={styles.label} htmlFor="sorDat">
                    Date de sortie
                    </label>
                    <input
                    type="date"
                    id="sorDat"
                    name="sorDat"
                    value={actForm["sorDat"]}
                    onChange={(e) => changeActForm(e)}
                    className={styles.input}
                    min={actForm["entDat"] != "" ? actForm["entDat"]: ""}
                    />
                    {((formules.length != 0 && formules[0]["sorDat"] == "") && pageNewError == true ) && (
                    <small className={styles.small}>Veuillez mettre une date de fin</small>
                    )}
                    {(actForm["entDat"] != "" && (checkDates(actForm["entDat"], actForm["sorDat"])) && pageError == true) && (
                    <small className={styles.small}>La date de sortie est incohérente</small>
                    )}
                    
                    {/* {newForm["sorDat"] == "" && pageError == true && (
                    <small className={styles.small}>Ce champs est requis (virgule = .)</small>
                    )} */}
                </div>
            </div>
            <div className={styles.rowContainer}>
                <div className={styles.fieldContainer}>
                    <label className={styles.label} htmlFor="formule">
                    Formule*
                    </label>
                    <select name="formule" id="formule" onChange={(e) => changeActForm(e)} className={styles.inputSelect} value={actForm["formule"]}>
                        <option value="">---------------</option>
                        <option value="Porteur de projet">Porteur de projet</option>
                        <option value="Formule Pépinière">Formule Pépinière</option>
                        <option value="Extra-Muros">Extra-Muros</option>
                        <option value="Formule Coworking">Formule Coworking</option>
                        <option value="Formule Centre d'Affaires">Formule Centre d'Affaires</option>
                    </select>
                    {(actForm["formule"] == "" && pageError == true && formules.length != 0) && (
                    <small className={styles.small}>Ce champs est requis</small>
                    )}
                    {((formules.length != 0 && formules[0]["formule"] == "") && pageNewError == true) && (
                    <small className={styles.small}>Veuillez mettre la formule</small>
                    )}
                </div>
                <div
                  className={styles.containerBouton}
                >
                    <button
                      className={styles.buttonValid}
                      onClick={() => checkAndSendAct(actForm)}
                      disabled={mutationUpdateForm.isPending}
                    >
                      Actualiser
                    </button>
                  <div style={{height:"10rem"}}>
                  {(formules.length == 0 && pageError == true) && (
                    <small className={styles.small}>Si vous souhaitez ajouter une formule, veuillez utiliser la rubrique ci-dessous</small>
                   )} 
                  {(formules.length != 0 && (formules[0]["entDat"] == "" || formules[0]["sorDat"] == "" || formules[0]["formule"] == "") && pageNewError == true) && (
                  <small className={styles.small}>Veuillez tout d'abord actualiser correctement la formule actuelle</small>
                  )}

                  {mutationUpdateForm.isPending == true && (
                    <div className={styles.bounce}>
                      <BounceLoader color="white" />
                    </div>
                   )} 
                  </div>
                </div>
            </div>
        </div>
        </div>
        <div className={styles.containerFormule}>
        <p className={styles.textOverlayTitle}>
          NOUVELLE FORMULE
        </p>
        <div>
            <div className={styles.rowContainer}>
                <div className={styles.fieldContainer}>
                    <label className={styles.label} htmlFor="entDat">
                    Date d'entrée*
                    </label>
                    <input
                    type="date"
                    id="entDat"
                    name="entDat"
                    value={newForm["entDat"]}
                    onChange={(e) => changeForm(e)}
                    className={styles.input}
                    min={actForm["sorDat"] != "" ? actForm["sorDat"] : ""}
                    />
                    {newForm["entDat"] == "" && pageNewError == true && (
                    <small className={styles.small}>Ce champs est requis</small>
                    )}
                    {(newForm["entDat"] != "" && (actForm["sorDat"] != "" && checkDates(actForm["sorDat"], newForm["entDat"])) && pageNewError == true) && (
                    <small className={styles.small}>La date d'entrée est incohérente</small>
                    )}
                    {/* {allYears.includes(newForm["entDat"]) && pageError == true && (
                    <small className={styles.small}>
                        Cette année est déjà présente
                    </small>
                    )} */}
                </div>
                <div className={styles.fieldContainer}>
                    <label className={styles.label} htmlFor="sorDat">
                    Date de sortie
                    </label>
                    <input
                    type="date"
                    id="sorDat"
                    name="sorDat"
                    value={newForm["sorDat"]}
                    onChange={(e) => changeForm(e)}
                    className={styles.input}
                    min={newForm["entDat"] != "" ? newForm["entDat"] : ""}
                    />
                    {/* {newForm["sorDat"] == "" && pageError == true && (
                    <small className={styles.small}>Ce champs est requis (virgule = .)</small>
                    )} */}
                    {((newForm["entDat"] != "" && newForm["sorDat"] != "") && (checkDates(newForm["entDat"], newForm["sorDat"])) && pageNewError == true) && (
                    <small className={styles.small}>La date de sortie est incohérente</small>
                    )}
                    
                </div>
            </div>
            <div className={styles.rowContainer}>
                <div className={styles.fieldContainer}>
                    <label className={styles.label} htmlFor="formule">
                    Formule*
                    </label>
                    <select name="formule" id="formule" onChange={(e) => changeForm(e)} className={styles.inputSelect} value={newForm["formule"]}>
                        <option value="Porteur de projet">Porteur de projet</option>
                        <option value="Formule Pépinière">Formule Pépinière</option>
                        <option value="Extra-Muros">Extra-Muros</option>
                        <option value="Formule Coworking">Formule Coworking</option>
                        <option value="Formule Centre d'Affaires">Formule Centre d'Affaires</option>
                    </select>
                    {newForm["formule"] == "" && pageNewError == true && (
                    <small className={styles.small}>Ce champs est requis</small>
                    )}
                </div>
                <div
                  className={styles.containerBouton}
                >
                    <button
                      className={styles.buttonValid}
                      onClick={() => checkAndSend(newForm)}
                      disabled={mutationPostForm.isPending}
                    >
                      Ajouter
                    </button>
                    <div style={{height:"10rem"}}>
                    {mutationPostForm.isPending == true && (
                      <div className={styles.bounce}>
                        <BounceLoader color="white" />
                      </div>
                     )}
                     </div>
                </div>
            </div>
        </div>
        </div>

        
        <p className={styles.textOverlayTitle}>
          En cliquant sur l'une des lignes vous pourrez modifier de la même manière les formules liées à cette personne
        </p>
        <div className={styles.table}>
          <table className={styles.tableSearch}>
            <thead>
              <th style={{ width: "10%" }}>ID</th>
              <th style={{ width: "50%" }}>Libellé</th>
              <th style={{ width: "40%" }}>Fonction</th>
            </thead>
            <tbody>
              {relations.map((relation) => {
                return (
                <tr onClick={() => window.open(`${process.env.REACT_APP_API_URL == "production" ? "https://carcoapp.mbe-consult.fr" : "http://localhost:3000"}/pageConnecte/rechercheTiers/tiers/${relation["tiId"]}`, '_blank')}>
                  <td>
                  {relation["tiId"]}
                  </td>
                  <td>
                  {relation["tiLib"]}
                  </td>
                  <td>
                  {relation["relTyp"]}
                  </td>
                </tr>)
              })}
            </tbody>
          </table>
        </div>
        <button
          className={styles.buttonValid}
          onClick={() => openOverlayFn(false)}
        >
          Retour
        </button>

      </div>
    </div>,
    document.getElementById("overlay")
  );
}
