import styles from "./AutresInfos.module.css";

export default function AutresInfos({
  changeFn,
  editable,
  inputValues,
}) {

  return (
    <div className={styles.table}>
      <table className={styles.tableSearch}>
        <tbody>
          <tr>
            <td style={{ width: "20%" }}>
              <b>Date de premier entretien</b>
            </td>
            <td>
            {editable ? 
              <input
              type="date"
              name="premEntretienDate"
              id="premEntretienDate"
              onChange={(e) => changeFn(e)}
              value={inputValues["premEntretienDate"]}
              className={styles.input}
              />
              :
              <div className={styles.inputValue}>
                {inputValues["premEntretienDate"]?.split('-').reverse().join('-')}
              </div>}
            </td>
            <td style={{ width: "20%" }}>
              <b>Heure de début</b>
            </td>
            <td>
              {editable ? 
              <input
              type="time"
              name="premEntretienHDeb"
              id="premEntretienHDeb"
              onChange={(e) => changeFn(e)}
              value={inputValues["premEntretienHDeb"]}
              className={styles.input}
              />
              :
              <div className={styles.inputValue}>
                {inputValues["premEntretienHDeb"]}
              </div>}

            </td>
            <td style={{ width: "20%" }}>
              <b>Heure de fin</b>
            </td>
            <td>
              {editable ? 
              <input
              type="time"
              name="premEntretienHFin"
              id="premEntretienHFin"
              onChange={(e) => changeFn(e)}
              value={inputValues["premEntretienHFin"]}
              className={styles.input}
              />
              :
              <div className={styles.inputValue}>
                {inputValues["premEntretienHFin"]}
              </div>}
            </td>
          </tr>
          <tr>
            <td>
              <b>Prescripteur</b>
            </td>
            <td colSpan="5">
            {editable ? 
            <>
                        <select
                  value={inputValues["Prescripteur"]}
                  onChange={(e) => changeFn(e)}
                  name="Prescripteur"
                  id="Prescripteur"
                  className={styles.inputSelect}
                  style={{width:"20%"}}
                >
                    <option value="">
                      ----------------------
                    </option>
                    <option value="Téléphone">
                      Téléphone
                    </option>
                    <option value="Site">
                      Site
                    </option>
                    <option value="Partenaires">
                    Partenaires
                    </option>
                    <option value="Bouche à oreille">
                    Bouche à oreille
                    </option>
                    <option value="Champ libre Prescripteur">
                    Champ libre
                    </option>
                    {!["Bouche à oreille", "Téléphone","Site","Partenaires"].includes(inputValues["Prescripteur"]) && <option value={inputValues["Prescripteur"]}>
                    {inputValues["Prescripteur"]}
                    </option>}
                </select>
                {inputValues["Prescripteur"] == "Champ libre Prescripteur" && (
                  <input
                    type="text"
                    id="champLibrePre"
                    name="champLibrePre"
                    value={inputValues["champLibrePre"]}
                    onChange={(e) => changeFn(e)}
                    className={styles.input}
                    style={{marginLeft: "1rem", width:"20%"}}
                  />
                )}
            </> 
              :
              <div className={styles.inputValue}>
                {inputValues["Prescripteur"]}
              </div>}
            </td>
          </tr>
          <tr>
            <td>
              <b>Commentaire(s)</b>
            </td>
            <td colSpan="5" style={{ padding: "2rem 0rem" }}>
                {editable ? 
                <textarea 
                name="premEntretienCom" 
                id="premEntretienCom" 
                rows="4"
                value={inputValues["premEntretienCom"]}
                onChange={(e) => changeFn(e)}
                className={styles.input}
                />
                :  
                <div className={styles.inputValue}>
                  {inputValues["premEntretienCom"]}
                </div>                
              }
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
