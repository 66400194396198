import styles from "./AddAccSuivi.module.css";
import { useRef, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import wordLogo from "../../../assets/word.png";
import excelLogo from "../../../assets/excel.png";
import pdfLogo from "../../../assets/pdf.png";
import docLambda from "../../../assets/documentLambda.png";
import importerBlanc from "../../../assets/importerBlanc.png";
import { BounceLoader } from "react-spinners";
import FichiersVisu from "../../VisuUG/fichiersVisu/fichiersVisu";


function getCurrentTime() {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0'); // Convert hours to string and pad with zero if needed
    const minutes = String(now.getMinutes()).padStart(2, '0'); // Convert minutes to string and pad with zero if needed
    return `${hours}:${minutes}`;
  }

export default function AddAccSuivi({ setAddAccClicked }) {
    
  const { id } = useParams();
  const inputFile = useRef(null) 
  const [files, setFiles] = useState([]);
  const [inputValues, setInputValues] = useState({"tiId": id, "DateAccSuivi": "", "TypeAccSuivi": "", "HDebAccSuivi": getCurrentTime(), "HFinAccSuivi" : "",
  "SujetAccSuivi": "", "ComAccSuivi": "", "champLibre": ""})
  const [showMessage, setShowMessage] = useState(false)
  const [pageError, setPageError] = useState(false)
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const changeFnSuivi = (e) => {
        setInputValues((prev) => {
          prev[e.target.name] = e.target.value;
          return {...prev};
        });
  }


  const handleFileUpload = async (event) => {
    const files = event.target.files;
    setFiles((prev) => {
          const listFiles = [];
          const formatsWord = [
            ".doc",
            ".docx",
            ".dotx",
            ".dotm",
            ".docm",
            ".rtf",
            ".txt",
            ".xml",
          ];
          const excelFormats = [
            ".xls",
            ".xlsx",
            ".xlsm",
            ".xlsb",
            ".xlt",
            ".xltx",
            ".xltm",
            ".csv",
            ".xml",
          ];
          const pdfFormat = [".pdf"];
          for (let obj of files) {
            const filename = obj["name"];
            if (formatsWord.some((format) => filename.includes(format))) {
              listFiles.push([wordLogo, "", filename, obj]);
            } else if (excelFormats.some((format) => filename.includes(format))) {
              listFiles.push([excelLogo, "", filename, obj]);
            } else if (pdfFormat.some((format) => filename.includes(format))) {
              listFiles.push([pdfLogo, "", filename, obj]);
            } else {
              listFiles.push([docLambda, "", filename, obj]);
            }
          }
          return [...prev, ...listFiles];
    })
  };

  const checkAndSend = (inputValues) => {
    if (inputValues["DateAccSuivi"] == "" || inputValues["TypeAccSuivi"] == "" || inputValues["HDebAccSuivi"] == "" || inputValues["HFinAccSuivi"] == ""
    || inputValues["SujetAccSuivi"] == "") {
    setPageError(true)
  } else {
    setPageError(false)
    mutationDataPost.mutate({ data: inputValues, files: files })
    }
  }

  async function PostAccSuivi({data, files}) {
    const link = `${process.env.REACT_APP_API_URL}/tiers/suivi/${id}`;
    // const link = `http://localhost:8080/tiers/suivi/${id}`

    const formData = new FormData();
    for (const key in inputValues) {
      if (inputValues.hasOwnProperty(key)) {
        formData.append(key, inputValues[key]);
      }
    }  
    files.forEach((file, index) => {
      formData.append(`files`, file[3]);
    });
    const response = await fetch(link, {
      method: "POST",
      body: formData,
      credentials: "include",
    });
    if (!response.ok) {
        setShowMessage(true)
        setTimeout(() => {
        setShowMessage(false);
            }, 5000)    
        throw "Il y a un problème avec le serveur";
    }
    const dataReceived = await response.json();
    return dataReceived;
  }

  const mutationDataPost = useMutation({
    mutationFn: (data) => PostAccSuivi(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["Suivi", id])
      setAddAccClicked(prev => !prev)
      // navigate(`/pageConnecte/rechercheTiers/tiers/${data.ugId}`)
    },
  });

  const removeOverlayFn = (idx) => {
    setFiles(prev => {
      return [...prev.slice(0, idx), ...prev.slice(idx + 1, prev.length)]
    })
  }

  return (
    <div className={styles.container}>
        <p className={styles.textTitle}>INFORMATIONS ACCOMPAGNEMENT</p>
        <div className={styles.table}>
        <table className={styles.tableSearch}>
            <thead>
            <th style={{ width: "20%" }}></th>
            <th style={{ width: "30%" }}></th>
            <th style={{ width: "20%" }}></th>
            <th style={{ width: "30%" }}></th>
            </thead>
            <tbody>
            <tr>
                <td>
                <b>Date*</b>
                </td>
                <td>
                <input
                    type="date"
                    id="DateAccSuivi"
                    name="DateAccSuivi"
                    value={inputValues["DateAccSuivi"]}
                    onChange={(e) => changeFnSuivi(e)}
                    className={styles.input}
                    />
                {(inputValues["DateAccSuivi"] == undefined || inputValues["DateAccSuivi"] == "")  && pageError == true && (
                    <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>
                )}
                </td>
                <td>
                <b>Type d'accompagnement*</b>
                </td>
                <td>
                <select
                    value={inputValues["TypeAccSuivi"]}
                    onChange={(e) => changeFnSuivi(e)}
                    name="TypeAccSuivi"
                    id="TypeAccSuivi"
                    className={styles.inputSelect}
                    >
                        <option value="">
                        ---------------------
                        </option>
                        <option value="Entretien">
                        Entretien
                        </option>
                        <option value="Action Collective">
                        Action Collective
                        </option>
                    </select>
                {(inputValues["TypeAccSuivi"] == undefined ||
                    inputValues["TypeAccSuivi"] == "") &&
                    pageError == true && <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>}
                </td>
            </tr>
            <tr>
                <td>
                <b>Heure de début*</b>
                </td>
                <td>
                    <input
                    type="time"
                    id="HDebAccSuivi"
                    name="HDebAccSuivi"
                    value={inputValues["HDebAccSuivi"]}
                    onChange={(e) => changeFnSuivi(e)}
                    className={styles.input}
                    />
                    {(inputValues["HDebAccSuivi"] == undefined ||
                    inputValues["HDebAccSuivi"] == "") &&
                    pageError == true && <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>}
                </td>
                <td>
                <b>Heure de fin*</b>
                </td>
                <td>
                    <input
                    type="time"
                    id="HFinAccSuivi"
                    name="HFinAccSuivi"
                    value={inputValues["HFinAccSuivi"]}
                    onChange={(e) => changeFnSuivi(e)}
                    className={styles.input}
                    />
                    {(inputValues["HFinAccSuivi"] == undefined ||
                    inputValues["HFinAccSuivi"] == "") &&
                    pageError == true && <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>}
                </td>
            </tr>
            <tr>
                <td>
                <b>Sujet abordé*</b>
                </td>
                <td>
                    
                            {inputValues["TypeAccSuivi"] == "Entretien" ? 
                          <>
                          <select
                            value={inputValues["SujetAccSuivi"]}
                            onChange={(e) => changeFnSuivi(e)}
                            name="SujetAccSuivi"
                            id="SujetAccSuivi"
                            className={styles.inputSelect}
                        >
                          <option value="">
                                ---------------------
                                </option>
                                <option value="Analyse financière">
                                Analyse financière
                                </option>
                                <option value="Communication / Marketing">
                                Communication / Marketing
                                </option>
                                <option value="Comptabilité / Gestion">
                                Comptabilité / Gestion
                                </option>
                                <option value="Conseils juridique">
                                Conseils juridique
                                </option>
                                <option value="Croissance / Développement">
                                Croissance / Développement
                                </option>
                                <option value="Design">
                                Design
                                </option>
                                <option value="Développement international">
                                Développement international
                                </option>
                                <option value="Financement">
                                Financement
                                </option>
                                <option value="Fiscalité">
                                Fiscalité
                                </option>
                                <option value="Gestion administrative">
                                Gestion administrative
                                </option>
                                <option value="Gestion des achats">
                                Gestion des achats
                                </option>
                                <option value="Gestion du temps">
                                Gestion du temps
                                </option>
                                <option value="Immobilier">
                                Immobilier
                                </option>
                                <option value="Design">
                                Design
                                </option>
                                <option value="Innovation">
                                Innovation
                                </option>
                                <option value="Management RH">
                                Management RH
                                </option>
                                <option value="Posture entrepreneuriale">
                                Posture entrepreneuriale
                                </option>
                                <option value="Relation clients">
                                Relation clients
                                </option>
                                <option value="Relations publiques">
                                Relations publiques
                                </option>
                                <option value="Stratégie commerciale">
                                Stratégie commerciale
                                </option>
                                <option value="Stratégie digitale">
                                Stratégie digitale
                                </option>
                                <option value="Champ libre">
                                Champ libre
                                </option>        
                                </select>
                                {inputValues["SujetAccSuivi"] == "Champ libre" && (
                                  <input
                                    type="text"
                                    id="champLibre"
                                    name="champLibre"
                                    value={inputValues["champLibre"]}
                                    onChange={(e) => changeFnSuivi(e)}
                                    className={styles.input}
                                    style={{marginLeft: "1rem"}}
                                  />
                                )}                    
                          </>
                            : inputValues["TypeAccSuivi"] == "Action Collective" ?
                            <>
                            <select
                                value={inputValues["SujetAccSuivi"]}
                                onChange={(e) => changeFnSuivi(e)}
                                name="SujetAccSuivi"
                                id="SujetAccSuivi"
                                className={styles.inputSelect}
                            >
                                <option value="">
                                ---------------------
                                </option>
                                <option value="Champ libre">
                                    Champ libre
                                </option>   
                            </select>
                            {inputValues["SujetAccSuivi"] == "Champ libre" && (
                              <input
                                type="text"
                                id="champLibre"
                                name="champLibre"
                                value={inputValues["champLibre"]}
                                onChange={(e) => changeFnSuivi(e)}
                                className={styles.input}
                                style={{marginLeft: "1rem"}}
                              />
                            )}

                            </>
                            :
                            <select
                            value={inputValues["SujetAccSuivi"]}
                            onChange={(e) => changeFnSuivi(e)}
                            name="SujetAccSuivi"
                            id="SujetAccSuivi"
                            className={styles.inputSelect}
                        >
                                <option value="">
                                ---------------------
                                </option>
                                </select>
                            }
                    
                    {(inputValues["SujetAccSuivi"] == undefined ||
                        inputValues["SujetAccSuivi"] == "") &&
                        pageError == true && <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>}
                </td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td>
                <b>Résumé de l'entretien</b>
                </td>
                <td colSpan="3">
                    <textarea
                    id="ComAccSuivi"
                    name="ComAccSuivi"
                    value={inputValues["ComAccSuivi"]}
                    onChange={(e) => changeFnSuivi(e)}
                    className={styles.input}
                    rows="4"
                    />
                </td>
            </tr>
            </tbody>
        </table>
        </div>
        <p className={styles.textTitle}>FICHIERS</p>
          <input
            type="file"
            style={{ display: "none" }}
            ref={inputFile}
            onChange={handleFileUpload}
            multiple
          />
          <div
            className={styles.buttonAddContainer}
            style={{ marginBottom: `${files.length == 0 ? "10rem" : ""}` }}
          >
            <button
              onClick={() => inputFile.current.click()}
              className={styles.buttonImport}
            >
              <p>Importer</p>
              <img
                src={importerBlanc}
                className={styles.logo}
                style={{ height: "2rem" }}
              />
            </button>
          </div>
          {files.length != 0 && (
            <FichiersVisu fichiers={files} removeOverlayFn={removeOverlayFn} />
          )}
          <div className={styles.buttonContainer}>
          <button
            className={styles.buttonValid}
            onClick={() => checkAndSend(inputValues)}
          >
            Enregistrer
          </button>
          <button
            className={styles.buttonValid}
            onClick={() => setAddAccClicked((prev) => !prev)}
          >
            Retour
          </button>
          </div>
          {mutationDataPost.isPending == true && (
            <div className={styles.bounce} style={{ marginBottom: "3rem" }}>
              <BounceLoader color="black" />
            </div>
          )}
          {showMessage && <p className={styles.textError}>Une erreur s'est produite</p>}
    </div>
  );
}

