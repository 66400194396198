import { useEffect, useState } from "react";
import styles from "./OccupantVisu.module.css";
import Pagination from "../../Pagination/Pagination";
import { useNavigate } from "react-router-dom";
import modifBleu from "../../../assets/modifbleu.png"

export default function OccupantsVisu({ occupants }) {
  const [numberElementsMax, setNumberElementsMax] = useState(3);
  const navigate = useNavigate();
  const [prevNum, setPrevNum] = useState(0);
  const [nextNum, setNextNum] = useState(3);

  const nextPage = () => {
    if (numberElementsMax >= occupants.length) {
      // setNextNum((prev) => prev + 3)
      setNumberElementsMax(3);
    } else {
      setNumberElementsMax((prev) => prev + 3);
    }
  };

  useEffect(() => {
    if (occupants.length == 0) {
      setNextNum(0);
    } else if (numberElementsMax > occupants.length) {
      setNextNum(occupants.length);
    } else {
      setNextNum(numberElementsMax);
    }
    if (numberElementsMax > occupants.length) {
      const numsCandidate = [occupants.length - 1, occupants.length - 2];
      let smallerNumber;
      for (let i of numsCandidate) {
        if (i % 3 === 0) {
          smallerNumber = i + 1;
          break;
        }
      }
      if (smallerNumber == undefined) {
        setPrevNum(0);
      } else {
        setPrevNum(smallerNumber);
      }
    } else {
      if (occupants.length == 0) {
        setPrevNum(0);
      } else {
        setPrevNum(numberElementsMax - 2);
      }
    }
  }, [numberElementsMax, occupants]);

  const prevPage = () => {
    if (numberElementsMax == 3) {
      if (occupants.length % 3 == 0) {
        setNumberElementsMax(occupants.length);
      } else {
        const numsCandidate = [occupants.length + 1, occupants.length + 2];
        let greatestNumber;
        for (let i of numsCandidate) {
          if (i % 3 === 0) {
            greatestNumber = i;
            break;
          }
        }
        setNumberElementsMax(greatestNumber);
      }
    } else {
      setNumberElementsMax((prev) => prev - 3);
    }
    // }
  };

  return (
    <div className={styles.table}>
      {occupants.length != 0 && (
        <table className={styles.tableSearch}>
          <thead>
            <tr>
            <th style={{ width: "30%" }}>
              <b>Dénomination entreprise</b>
            </th>
            <th style={{ width: "20%" }}>
              <b>Date de début</b>
            </th>
            <th style={{ width: "20%" }}>
              <b>Date de fin</b>
            </th>
            <th style={{ width: "20%" }}>
              <b>Surface louée</b>
            </th>
            <th style={{ width: "10%" }}></th>
            </tr>
          </thead>
          <tbody>
            {occupants.slice(numberElementsMax-3,numberElementsMax).map((occupant) => {
              return (
                <tr key={occupant["tiLibs"]}> 
                  <td>{occupant["tiLibs"].replace(",", "").replace(/ ,/g, " ").trim().replace(/ ET$/, "")}</td>
                  <td>{occupant["ugconvDtd"] != null ? occupant["ugconvDtd"].split('-').reverse().join('-') : ""}</td>
                  <td>{occupant["ugconvDtf"] != null ? occupant["ugconvDtf"].split('-').reverse().join('-') : ""}</td>
                  <td>{occupant["ugconvSurf"]}</td>
                  <td>
                    <div className={styles.logoContainer}>
                      <img src={modifBleu} className={styles.logo} onClick={() => {navigate(`../rechercheConvention/convention/${occupant["convId"]}/latest`) 
                      window.scrollTo(0, 0)}} />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <div>
        <Pagination nextPage={nextPage} prevPage={prevPage} />
        <p className={styles.nums}>
          {prevNum} à {nextNum} - Nombre Total d'éléments : {occupants.length}{" "}
        </p>
      </div>
    </div>
  );
}

