import ReactDOM from "react-dom";
import styles from "./overlay.module.css";
import { BounceLoader } from "react-spinners";
import { useContext, useState } from "react";
import { HeaderContext } from "../../App";
import { useLocation } from "react-router-dom";

export default function FilterOverlay({
  pathURL,
  status,
  children,
  setFilter,
  message = "",
  searchText,
}) {
  const {
    filterFieldConv,
    setFilterFieldsConv,
    setSearchDataConv,
    filterFieldTiers,
    setFilterFieldsTiers,
    setSearchData,
    filterFieldPatrimoine,
    setFilterFieldsPatrimoine,
    setSearchDataPatrimoine,
  } = useContext(HeaderContext);
  const [localFilterTiers, setLocalFilterTiers] = useState(filterFieldTiers);
  const [localFilterPatrimoine, setLocalFilterPatrimoine] = useState(filterFieldPatrimoine);
  const [localFilterConv, setLocalFilterConv] = useState(filterFieldConv);
  const location = useLocation();
  const pathname = location.pathname;

  const changeFilterTiers = (e) => {
    if ('PM' == e.target.name || 'porteurProjet' == e.target.name) {
      setLocalFilterTiers((prev) => {
            return { ...prev, [e.target.name]: e.target.checked };
          });      
    } else {
      setLocalFilterTiers((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
          });      
    }
  };

  const changeFilterPatrimoine = (e) => {
    if ('loué' == e.target.name || 'disponible' == e.target.name) {
        setLocalFilterPatrimoine((prev) => {
            return { ...prev, [e.target.name]: e.target.checked };
          });      
    } else {
        setLocalFilterPatrimoine((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
          });      
    }
  };

  const changeFilterConv = (e) => {
    if ('resilié' == e.target.name || 'active' == e.target.name) {
        setLocalFilterConv((prev) => {
            return { ...prev, [e.target.name]: e.target.checked };
          });      
    } else {
        setLocalFilterConv((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
          });      
    }
  };

  async function getSearch() {
    let link;
    if (pathURL.includes("Tiers")) {
      link = `${process.env.REACT_APP_API_URL}/tiers/search?search=${searchText}&dateDebut=${localFilterTiers["dateDebut"]}&dateFin=${localFilterTiers["dateFin"]}`
      // link = `http://localhost:8080/tiers/search?search=${searchText}&dateDebut=${localFilterTiers["dateDebut"]}&dateFin=${localFilterTiers["dateFin"]}`
    } else if (pathURL.includes("Patrimoine")) {
      link = `${process.env.REACT_APP_API_URL}/ug/search?search=${searchText}&dateDispo=${localFilterPatrimoine["dateDispo"]}`
      // link = `http://localhost:8080/ug/search?search=${searchText}&dateDispo=${localFilterPatrimoine["dateDispo"]}`;
      // link = `http://localhost:8080/ug/search?search=${searchText}&dateDebut=${localFilterPatrimoine["dateDispo"]}&dateFin=${localFilterPatrimoine["dateFin"]}`;
    } else if (pathURL.includes("Convention")) {
      link = `${process.env.REACT_APP_API_URL}/conv/search?search=${searchText}&dateDebut=${localFilterConv["dateDebut"]}&dateFin=${localFilterConv["dateFin"]}`
      // link = `http://localhost:8080/conv/search?search=${searchText}&dateDebut=${localFilterConv["dateDebut"]}&dateFin=${localFilterConv["dateFin"]}`
    }
    const response = await fetch(link);
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const data = await response.json();
    if (pathURL.includes("Tiers")) {
      setSearchData(data)
      setFilter("");
      setFilterFieldsTiers(localFilterTiers);  
    } else if (pathURL.includes("Patrimoine")) {
      setSearchDataPatrimoine(data);
      setFilter("");
      setFilterFieldsPatrimoine(localFilterPatrimoine);  
    } else if (pathURL.includes("Convention")) {
      setSearchDataConv(data);
      setFilter("");
      setFilterFieldsConv(localFilterConv);  
    }
  }

  if (status == "pending") {
    return ReactDOM.createPortal(
      <div className={styles.overlay}>
        <BounceLoader color="white" />
      </div>,
      document.getElementById("overlay")
    );
  } else if (status == "filter" && pathname.includes("Tiers")) {
    return ReactDOM.createPortal(
      <div className={styles.overlay} onClick={() => setFilter("")}>
        <div className={styles.container} onClick={(e) => e.stopPropagation()}>
          <p className={styles.textHistPrix}>Filtres</p>
          <div className={styles.infosDate}>
            <div style={{ width: "50%" }}>
              <label htmlFor="dateDebut" className={styles.label}>
                Date de début
              </label>
              <input
                type="date"
                id="dateDebut"
                name="dateDebut"
                value={localFilterTiers["dateDebut"]}
                onChange={changeFilterTiers}
                className={styles.input}
              />
            </div>
            <div style={{ width: "50%" }}>
              <label htmlFor="dateFin" className={styles.label}>
                Date de fin
              </label>
              <input
                type="date"
                id="dateFin"
                name="dateFin"
                value={localFilterTiers["dateFin"]}
                onChange={changeFilterTiers}
                className={styles.input}
              />
            </div>
            
          </div>
          <div className={styles.field} >
            <input
              type="checkbox"
              id="porteurProjet"
              name="porteurProjet"
              checked={localFilterTiers["porteurProjet"]}
              onChange={changeFilterTiers}
              className={styles.filterCheck}
            />
            <label htmlFor="porteurProjet" className={styles.inputFilter}>
            Porteurs de projet
            </label>
          </div>
          <div className={styles.field}>
            <input
              type="checkbox"
              id="PM"
              name="PM"
              checked={localFilterTiers["PM"]}
              onChange={changeFilterTiers}
              className={styles.filterCheck}
            />
            <label htmlFor="PM" className={styles.inputFilter}>
            Personnes morales
            </label>
          </div>
          <button onClick={() => {
            getSearch()
            }} className={styles.buttonValid}>
            Valider
          </button>
        </div>
      </div>,
      document.getElementById("overlay")
    );
  } else if (status == "filter" && pathname.includes("Patrimoine")) {
    return ReactDOM.createPortal(
      <div className={styles.overlay} onClick={() => setFilter("")}>
        <div className={styles.container} onClick={(e) => e.stopPropagation()}>
          <p className={styles.textHistPrix}>Filtres</p>
          {/* <div className={styles.infosDate}>
            <div style={{ width: "50%" }}>
              <label htmlFor="dateDebut" className={styles.label}>
                Date de début
              </label>
              <input
                type="date"
                id="dateDebut"
                name="dateDebut"
                value={localFilterPatrimoine["dateDebut"]}
                onChange={changeFilterPatrimoine}
                className={styles.input}
              />
            </div>
            <div style={{ width: "50%" }}>
              <label htmlFor="dateFin" className={styles.label}>
                Date de fin
              </label>
              <input
                type="date"
                id="dateFin"
                name="dateFin"
                value={localFilterPatrimoine["dateFin"]}
                onChange={changeFilterPatrimoine}
                className={styles.input}
              />
            </div>
            
          </div> */}
          <div className={styles.infosDate}>
            <div style={{ width: "100%" }}>
              <label htmlFor="dateDispo" className={styles.label}>
                Date de disponibilité
              </label>
              <input
                type="date"
                id="dateDispo"
                name="dateDispo"
                value={localFilterPatrimoine["dateDispo"]}
                onChange={changeFilterPatrimoine}
                className={styles.input}
              />
            </div>
            
          </div>
          <div className={styles.field} >
            <input
              type="checkbox"
              id="loué"
              name="loué"
              checked={localFilterPatrimoine["loué"]}
              onChange={changeFilterPatrimoine}
              className={styles.filterCheck}
            />
            <label htmlFor="loué" className={styles.inputFilter}>
              Loué
            </label>
          </div>
          <div className={styles.field}>
            <input
              type="checkbox"
              id="disponible"
              name="disponible"
              checked={localFilterPatrimoine["disponible"]}
              onChange={changeFilterPatrimoine}
              className={styles.filterCheck}
            />
            <label htmlFor="disponible" className={styles.inputFilter}>
              Disponible
            </label>
          </div>
          <button onClick={() => getSearch()} className={styles.buttonValid}>
            Valider
          </button>
        </div>
      </div>,
      document.getElementById("overlay")
    );
  } else if (status == "filter" && pathname.includes("Convention")) {
    return ReactDOM.createPortal(
      <div className={styles.overlay} onClick={() => setFilter("")}>
        <div className={styles.container} onClick={(e) => e.stopPropagation()}>
          <p className={styles.textHistPrix}>Filtres</p>
          <div className={styles.infosDate}>
            <div style={{ width: "50%" }}>
              <label htmlFor="dateDebut" className={styles.label}>
                Date de début
              </label>
              <input
                type="date"
                id="dateDebut"
                name="dateDebut"
                value={localFilterConv["dateDebut"]}
                onChange={changeFilterConv}
                className={styles.input}
              />
            </div>
            <div style={{ width: "50%" }}>
              <label htmlFor="dateFin" className={styles.label}>
                Date de fin
              </label>
              <input
                type="date"
                id="dateFin"
                name="dateFin"
                value={localFilterConv["dateFin"]}
                onChange={changeFilterConv}
                className={styles.input}
              />
            </div>
            
          </div>
          <div className={styles.field} >
            <input
              type="checkbox"
              id="active"
              name="active"
              checked={localFilterConv["active"]}
              onChange={changeFilterConv}
              className={styles.filterCheck}
            />
            <label htmlFor="active" className={styles.inputFilter}>
            Active
            </label>
          </div>
          <div className={styles.field}>
            <input
              type="checkbox"
              id="resilié"
              name="resilié"
              checked={localFilterConv["resilié"]}
              onChange={changeFilterConv}
              className={styles.filterCheck}
            />
            <label htmlFor="resilié" className={styles.inputFilter}>
            Résiliée
            </label>
          </div>
          <button onClick={() => {
            getSearch()
            }} className={styles.buttonValid}>
            Valider
          </button>
        </div>
      </div>,
      document.getElementById("overlay")
    );
  } else if (status == "creationUG" && pathname.includes("Patrimoine")) {
    return ReactDOM.createPortal(
      <div className={styles.overlay} onClick={() => setFilter("")}>
        <div className={styles.container} onClick={(e) => e.stopPropagation()}>
          <div className={styles.messageContainer}>
            <p>{message}</p>
            <button
              onClick={() =>
                setFilter({ message: undefined, status: undefined })
              }
              className={styles.buttonValid}
            >
              Fermer la fenêtre
            </button>
          </div>
        </div>
      </div>,
      document.getElementById("overlay")
    );
  } else if (status == "creationTiers" && pathname.includes("Tiers")) {
    return ReactDOM.createPortal(
      <div className={styles.overlay} onClick={() => setFilter("")}>
        <div className={styles.container} onClick={(e) => e.stopPropagation()}>
          <div className={styles.messageContainer}>
            <p>{message}</p>
            <button
              onClick={() =>
                setFilter({ message: undefined, status: undefined })
              }
              className={styles.buttonValid}
            >
              Fermer la fenêtre
            </button>
          </div>
        </div>
      </div>,
      document.getElementById("overlay")
    );
  } else {
    return <div>{children}</div>;
  }
}
