import styles from "./LocauxVisu.module.css";

export default function LocauxVisu({
  locaux
}) {
  

  return (
    <>
        {locaux.length != 0 && <div className={styles.table}>
        <table className={styles.tableSearch}>
          <thead>
            <tr>
            <th style={{ width: "20%" }}>
              <b>Intitulé</b>
            </th>
            <th style={{ width: "20%" }}>
              <b>Date de début</b>
            </th>
            <th style={{ width: "20%" }}>
              <b>Date de fin</b>
            </th>
            <th style={{ width: "20%" }}>
              <b>Surface loué</b>
            </th>
            <th style={{ width: "20%" }}>
              <b>Prix Mensuel</b>
            </th>
            </tr>
          </thead>
          <tbody>
            {locaux
              .map((local) => {
                return (
                  <tr key={local["ugInt"]}>
                    <td>{local["ugInt"]}</td>
                    <td>{local["ugconvDtd"]?.split('-').reverse().join('-')}</td>
                    <td>{local["ugconvDtf"]?.split('-').reverse().join('-')}</td>
                    <td>{local["ugconvSurf"]}</td>
                    <td>{local["rubconvMnt"]}</td>
                    {/* <td>
                      <div className={styles.logoContainer}>
                        <img
                          src={modifBleu}
                          className={styles.logo}
                          onClick={() => {
                            modifDirOverlayFn(local);
                          }}
                        />
                        <img
                          src={poubBleu}
                          className={styles.logo}
                          onClick={() => removeDirOverlayFn(local)}
                        />
                      </div>
                    </td> */}
                  </tr>
                );
              })}
          </tbody>
        </table>
    </div>}

    </>
  );
}
