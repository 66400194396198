import Authentication from "../../components/Authentication"
// import Logo from "../../assets/carcoLogo.png"
import Logo from "../../assets/carcoAppOriginal.png"
import styles from "./Login.module.css"

function Login () {
    return (
        <div className={styles.container}>
            <div className={styles.containerLogo}>
                <img src={Logo} alt="Logo Pépinière Carco" className={styles.Logo}/>
            </div>
            <Authentication />
            <p className={styles.versionText}>Pépinière d’entreprises Carco © 2023 - MBE & CONNECT</p>
        </div>
    )
}

export default Login