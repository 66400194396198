import styles from "./visualisationug.module.css";
import UgVisu from "../../components/VisuUG/ugVisu/ugVisu";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import UgEqVisu from "../../components/VisuUG/ugEqVisu/ugEqVisu";
import OverlayAddEqUG from "../../components/VisuUG/overlayAddEq/overlayAddEqUG";
import importerBlanc from "../../assets/importerBlanc.png";
import modifLogo from "../../assets/modifLogo.png";
import saveLogo from "../../assets/saveLogo.png";
import shareLogo from "../../assets/shareLogo.png";
import wordLogo from "../../assets/word.png";
import excelLogo from "../../assets/excel.png";
import pdfLogo from "../../assets/pdf.png";
import docLambda from "../../assets/documentLambda.png";
import { BounceLoader } from "react-spinners";
import TarificationLocal from "../../components/VisuUG/tarification/tarificationLocal";
import OverlayRemoveEq from "../../components/VisuUG/overlayRemoveEq/overlayRemoveEq";
import OccupantsVisu from "../../components/VisuUG/occupantVisu/OccupantVisu";
import FichiersVisu from "../../components/VisuUG/fichiersVisu/fichiersVisu";
import OverlayRemoveFile from "../../components/VisuUG/overlayRemoveFile/overlayRemoveFile";


export default function VisualisationUG() {
  const { ugNum } = useParams();
  const [editable, setEditable] = useState(false);
  // const [isQueryEnabled, setIsQueryEnabled] = useState(true);
  const [newEq, setNewEq] = useState(false);
  const [removeEq, setRemoveEq] = useState(false);
  const [eqToRemove, setEqToRemove] = useState({});
  const [removeFile, setRemoveFile] = useState(false);
  const [fileToRemove, setFileToRemove] = useState([]);
  const [pageError, setPageError] = useState(false);
  const [inputValues, setInputValues] = useState([]);
  const [IsLoadingPostFile, setIsLoadingPost] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const inputFile = useRef(null);
  const location = useLocation()
  const path = process.env.REACT_APP_API_URL == "production" ? "https://carcoapp.mbe-consult.fr" + location.pathname : "http://localhost:3000" + location.pathname


  const handleFileUpload = async (event) => {
    setIsLoadingPost(true);
    const files = event.target.files;  
    try {
      const presignedUrlPromises = Array.from(files).map(file => {
        return fetch(`${process.env.REACT_APP_API_URL}/ug/generatePresignedUrl/${ugNum}/${encodeURIComponent(file.name)}`)
          .then(response => response.json());
      });
  
      const presignedUrls = await Promise.all(presignedUrlPromises);

      const uploadPromises = presignedUrls.map((presignedUrl, index) => {
        return fetch(presignedUrl.url, {
          method: 'PUT',
          headers: {
            'Content-Type': files[index].type,
          },
          body: files[index],
        });
      });
  
      const uploadResponses = await Promise.all(uploadPromises);
  
      const allUploadsSuccessful = uploadResponses.every(response => response.ok);
  
      if (allUploadsSuccessful) {
        queryClient.invalidateQueries(["Local", "Files", ugNum]);
        // console.log("All files uploaded successfully");
      } else {
        console.error("File upload failed for some files");
      }
    } catch (error) {
      console.error("Error during file upload:", error);
    } finally {
      setIsLoadingPost(false); // End loading
    }
  };

  // const handleFileUpload = async (event) => {
  //   setIsLoadingPost(true);
  //   const files = event.target.files;
  //   const formData = new FormData();
  //   for (let i = 0; i < files.length; i++) {
  //     formData.append("files", files[i]);
  //   }
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_API_URL}/ug/uploadFiles/${ugNum}`, {
  //         method: 'POST',
  //         body: formData,
  //     });
  //     // const response = await fetch(
  //     //   `http://localhost:8080/ug/uploadFiles/${ugNum}`,
  //     //   {
  //     //     method: "POST",
  //     //     body: formData,
  //     //   }
  //     // );
  //     if (response.ok) {
  //       queryClient.invalidateQueries(["Local", "Files", ugNum]);
  //     } else {
  //       console.error("File upload failed:", response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error during file upload:", error);
  //   } finally {
  //     setIsLoadingPost(false); // End loading
  //   }
  // };

  async function getFiles(id) {
    const link = `${process.env.REACT_APP_API_URL}/ug/files/${id}`
    // const link = `http://localhost:8080/ug/files/${id}`;
    const response = await fetch(link);
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const data = await response.json();
    const listFiles = [];
    const formatsWord = [
      ".doc",
      ".docx",
      ".dotx",
      ".dotm",
      ".docm",
      ".rtf",
      ".txt",
      ".xml",
    ];
    const excelFormats = [
      ".xls",
      ".xlsx",
      ".xlsm",
      ".xlsb",
      ".xlt",
      ".xltx",
      ".xltm",
      ".csv",
      ".xml",
    ];
    const pdfFormat = [".pdf"];
    for (let obj of data) {
      const link = obj["url"];
      const filename = obj["filename"];
      if (formatsWord.some((format) => filename.includes(format))) {
        listFiles.push([wordLogo, link, filename]);
      } else if (excelFormats.some((format) => filename.includes(format))) {
        listFiles.push([excelLogo, link, filename]);
      } else if (pdfFormat.some((format) => filename.includes(format))) {
        listFiles.push([pdfLogo, link, filename]);
      } else {
        listFiles.push([docLambda, link, filename]);
      }
    }
    return listFiles;
  }

  const { data: files = [], isLoading: isLoadingFiles } = useQuery({
    queryKey: ["Local", "Files", ugNum],
    queryFn: () => getFiles(ugNum),
    refetchOnWindowFocus: false,
  });

  async function getUGInfos(id) {
    const link = `${process.env.REACT_APP_API_URL}/ug/infos/${id}`
    // const link = `http://localhost:8080/ug/infos/${id}`;
    const response = await fetch(link);
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const data = await response.json();
    setInputValues(data)
    return data;
  }

  async function getOccupantInfos(id) {
    const link = `${process.env.REACT_APP_API_URL}/ug/occupants/${id}`
    // const link = `http://localhost:8080/ug/occupants/${id}`;
    const response = await fetch(link);
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const data = await response.json();
    return data[0];
  }

  const { data: infos = [], isLoading } = useQuery({
    queryKey: ["Patrimoine", ugNum],
    queryFn: () => getUGInfos(ugNum),
    // enabled: isQueryEnabled,
    refetchOnWindowFocus: false,
  });


  // useEffect(() => {
  //   // if (infos) { //infos.length != inputValues.length
  //   //   setInputValues(infos);
  //   // }
  // }, [infos]);


  const { data: occupants = [], isLoading: isLoadingLocataires } = useQuery({
    queryKey: ["Occupants", ugNum],
    queryFn: () => getOccupantInfos(ugNum),
    enabled: infos.length != 0,
    refetchOnWindowFocus: false
  });

  const handleInputChange = (e, idx) => {
    setInputValues((prev) => {
      prev["local"][0][e.target.name] = e.target.value;
      return {"local": prev["local"], "equipement": prev["equipement"]};
    });
  };

  async function updateUG(data) {
    const link = `${process.env.REACT_APP_API_URL}/ug/update/${ugNum}`;
    // const link = `http://localhost:8080/ug/update/${ugNum}`
    const response = await fetch(link, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const dataReceived = await response.json();
    return dataReceived;
  }

  const mutationDataUpdate = useMutation({
    mutationFn: (data) => updateUG(data),
    onMutate: async (newData) => {
      await queryClient.cancelQueries({ queryKey: ["Patrimoine", ugNum] });
      const prevInputValues = queryClient.getQueryData(["Patrimoine", ugNum]);

      queryClient.setQueryData(["Patrimoine", ugNum], newData);

      return { previousInputValues: prevInputValues };
    },
    onError: (err, newData, context) => {
      setInputValues(context.previousInputValues);
    },
    onSettled: () => {
      setEditable(false)
      queryClient.invalidateQueries(["Patrimoine", ugNum]);
    },
  });

  const checkAndSend = (inputValues) => {
    if (inputValues["local"][0]["ugNat"] == "" || inputValues["local"][0]["ugInt"] == "" || inputValues["local"][0]["ugEtag"] == "" || inputValues["local"][0]["ugRef"] == ""
    || inputValues["local"][0]["ugSurf"] == "") {
    setPageError(true)
  } else {
    setPageError(false)
    mutationDataUpdate.mutate(inputValues)
    }
  }

  const removeEqOverlayFn = (data = {}) => {
    setEqToRemove(data);
    setRemoveEq(!removeEq);
  };

  const removeFileOverlayFn = (data = []) => {
    setFileToRemove(data);
    setRemoveFile(!removeFile);
  };

  function copyTextToClipboard(text) {
    navigator.clipboard.writeText(text).then(() => {
      console.log("Text copied to clipboard successfully!");
    }).catch(err => {
      console.error("Failed to copy text to clipboard", err);
    });
  }

  return (
    <div className={styles.container}>
      {newEq && <OverlayAddEqUG openOverlayFn={setNewEq} />}
      {removeEq && (
        <OverlayRemoveEq
          removeOverlayFn={removeEqOverlayFn}
          eqToRemove={eqToRemove}
        />
      )}
      {removeFile && (
        <OverlayRemoveFile
          removeOverlayFn={removeFileOverlayFn}
          fileToRemove={fileToRemove}
        />
      )}
      <div className={styles.box}>
        <p className={styles.textHeader}>
          {inputValues.length != 0 ? inputValues["local"][0]["ugInt"] : null}
        </p>
        <div className={styles.logoContainer}> 
          {editable == false ? <img src={modifLogo} className={styles.logo} onClick={() => setEditable(!editable)} /> : <img src={saveLogo} className={styles.logo} onClick={() => {
    if (!mutationDataUpdate.isPending) {
      checkAndSend(inputValues)
    }
  }} />} 
          <img src={shareLogo} className={styles.logo} onClick={() => copyTextToClipboard(path)} />
        </div>
      </div>
      <div className={styles.mutationBounce}>
        <p className={styles.textTitle} >INFORMATIONS SUR LE LOCAL</p>
        {mutationDataUpdate.isPending == true && (
        <div className={styles.bounce}>
          <BounceLoader color="black" />
        </div>
      )}
      </div>
      {isLoading == true && (
          <div className={styles.bounce}>
            <BounceLoader color="black" />
          </div>
        )}
      
      {inputValues["local"] && (
        <UgVisu
          changeFn={handleInputChange}
          editable={editable}
          inputValues={inputValues["local"][0]}
          pageError={pageError}
        />
      )}
      <p className={styles.textTitle}>TARIFICATION DU LOCAL</p>
      {inputValues["local"] && (
        <TarificationLocal inputValues={inputValues["local"]} />
      )}
      {isLoading == true && (
        <div className={styles.bounce}>
          <BounceLoader color="black" />
        </div>
      )}
      <p className={styles.textTitle}>ÉQUIPEMENTS DU LOCAL</p>
      <div className={styles.buttonAddContainer}>
        <button
          onClick={() => setNewEq((prev) => !prev)}
          className={styles.buttonAdd}
        >
          Ajouter Equipement
        </button>
      </div>
      {inputValues["equipement"] && (
        <UgEqVisu
          removeOverlayFn={removeEqOverlayFn}
          inputValues={inputValues["equipement"]}
        />
      )}
      <p className={styles.textTitle}>LOCATAIRES</p>
      <div className={styles.buttonAddContainer}>
        <button
          onClick={() => navigate("../creerConvention/pepiniere")}
          className={styles.buttonAdd}
        >
          Ajouter un locataire
        </button>
      </div>
      {occupants.length != 0 && <OccupantsVisu occupants={occupants} />}
      {isLoadingLocataires == true && (
        <div className={styles.bounce}>
          <BounceLoader color="black" />
        </div>
      )}
      <p className={styles.textTitle}>FICHIERS</p>
      <input
        type="file"
        style={{ display: "none" }}
        ref={inputFile}
        onChange={handleFileUpload}
        multiple
      />
      <div
        className={styles.buttonAddContainer}
        style={{ marginBottom: `${files.length == 0 ? "10rem" : ""}` }}
      >
        <button
          onClick={() => inputFile.current.click()}
          className={styles.buttonImport}
        >
          <p>Importer</p>
          <img
            src={importerBlanc}
            className={styles.logo}
            style={{ height: "2rem" }}
          />
        </button>
      </div>
      {files.length != 0 && (
        <FichiersVisu fichiers={files} removeOverlayFn={removeFileOverlayFn} />
      )}
      {isLoadingFiles == true && (
        <div className={styles.bounce} style={{ marginBottom: "3rem" }}>
          <BounceLoader color="black" />
        </div>
      )}
      {IsLoadingPostFile == true && (
        <div className={styles.bounce} style={{ marginBottom: "3rem" }}>
          <BounceLoader color="black" />
        </div>
      )}
    </div>
  );
}
