import { useEffect, useState } from "react";
import styles from "./HistoriqueVisu.module.css";
import Pagination from "../../Pagination/Pagination";
import { useLocation, useNavigate } from "react-router-dom";
import informationLogo from "../../../assets/informationLogo.png"

export default function HistoriqueVisu({
  historique
}) {
  const [numberElementsMax, setNumberElementsMax] = useState(3);
  const navigate = useNavigate();
  const location = useLocation()
  // const pathname = location.pathname
  const [prevNum, setPrevNum] = useState(0);
  const [nextNum, setNextNum] = useState(3);

  const nextPage = () => {
    if (numberElementsMax >= historique.length) {
      // setNextNum((prev) => prev + 3)
      setNumberElementsMax(3);
    } else {
      setNumberElementsMax((prev) => prev + 3);
    }
  };

  useEffect(() => {
    if (historique.length == 0) {
      setNextNum(0);
    } else if (numberElementsMax > historique.length) {
      setNextNum(historique.length);
    } else {
      setNextNum(numberElementsMax);
    }
    if (numberElementsMax > historique.length) {
      const numsCandidate = [historique.length - 1, historique.length - 2];
      let smallerNumber;
      for (let i of numsCandidate) {
        if (i % 3 === 0) {
          smallerNumber = i + 1;
          break;
        }
      }
      if (smallerNumber == undefined) {
        setPrevNum(0);
      } else {
        setPrevNum(smallerNumber);
      }
    } else {
      if (historique.length == 0) {
        setPrevNum(0);
      } else {
        setPrevNum(numberElementsMax - 2);
      }
    }
  }, [numberElementsMax, historique]);

  const prevPage = () => {
    if (numberElementsMax == 3) {
      if (historique.length % 3 == 0) {
        setNumberElementsMax(historique.length);
      } else {
        const numsCandidate = [historique.length + 1, historique.length + 2];
        let greatestNumber;
        for (let i of numsCandidate) {
          if (i % 3 === 0) {
            greatestNumber = i;
            break;
          }
        }
        setNumberElementsMax(greatestNumber);
      }
    } else {
      setNumberElementsMax((prev) => prev - 3);
    }
    // }
  };
  
  // const openOverlayFn = (infos) => {
  //     if (seeInfo == false) {
  //         setInfos(infos)
  //     } 
  //     setSeeInfo(!seeInfo)
  // }

      const openNewWindow = (path, convVrsNot = undefined) => {
          // if (convVrsNot) {
          //     const url = location.pathname + path;
          //     window.open(url, '_blank');
          // } else {
              const url = location.pathname.split("/").slice(0,-1).join("/") + path
              window.open(url, '_blank');
          // }
        };

  return (
    <>
        {historique.length != 0 && 
        <div className={styles.table}>
          <table className={styles.tableSearch}>
            <thead>
              <tr>
              <th style={{ width: "30%" }}>
                <b>Date Màj</b>
              </th>
              <th style={{ width: "30%" }}>
                <b>Version</b>
              </th>
              <th style={{ width: "30%" }}>
                <b>Dénomination</b>
              </th>
              <th style={{ width: "10%" }}></th>

              </tr>
            </thead>
            <tbody>
              {historique
              .slice(numberElementsMax-3,numberElementsMax)
                .map((data) => {
                  return (
                    <tr onClick={() => openNewWindow(`/${data["convVrs"]}`)} key={data["convVrs"]}>
                      <td>{data["convDatmaj"]}</td>
                      <td>{data["convVrs"]}</td>
                      <td>{data["convAv"]}</td>
                      <td onClick={() => {
                        // openOverlayFn(data)
                        console.log("oui")
                        }}>
                        <img src={informationLogo} className={styles.infoLogo} />
                        </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div>
              <Pagination nextPage={nextPage} prevPage={prevPage} />
              <p className={styles.nums}>{prevNum} à {nextNum} - Nombre Total d'éléments : {historique.length} </p>
          </div> 
    </div>}

    </>
  );
}
