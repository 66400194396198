import styles from "./LocauxInfos.module.css";
import poubBleu from "../../../assets/poubBleu.png";

export default function LocauxInfos({
  inputValues,
  removeLocalOverlayFn,
  addLocalOverlayFn
}) {

  return (
    <div className={styles.container}>
      <p className={styles.textTitle}>LOCAUX</p>
      <div className={styles.buttonAddContainer}>
        <button
          onClick={() => addLocalOverlayFn(true)}
          className={styles.buttonAdd}
        >
          Ajouter un local
        </button>
      </div>
      <div className={styles.table}>
        <table className={styles.tableSearch}>
          <thead>
            <tr>
            <th style={{ width: "30%" }}>
              <b>Intitulé</b>
            </th>
            <th style={{ width: "15%" }}>
              <b>Date de début</b>
            </th>
            <th style={{ width: "15%" }}>
              <b>Date de fin</b>
            </th>
            <th style={{ width: "15%" }}>
              <b>Surface Louée</b>
            </th>
            <th style={{ width: "15%" }}>
              <b>Prix Mensuel</b>
            </th>
            <th style={{ width: "10%" }}></th>
            </tr>
          </thead>
          <tbody>
            {inputValues.length != 0 &&
              inputValues.map((data, idx) => {
                return (
                  <tr key={data["ugInt"]}>
                    <td>{data["ugInt"]}</td>
                    <td>
                      {data["ugconvDtd"]}
                    </td>
                    <td>
                      {data["ugconvDtf"]}
                    </td>
                    <td>
                    {data["ugconvSurf"]}
                    </td>
                    <td>
                    {data["rubconvMnt"]}
                    </td>
                    <td>
                    <img src={poubBleu} className={styles.logo} onClick={() => removeLocalOverlayFn(idx)} />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {/* {inputValues["dirigeants"].length != 0 && (
        <DirigeantsVisu dirigeants={inputValues["dirigeants"]} removeDirOverlayFn={removeDirOverlayFn} modifDirOverlayFn={modifDirOverlayFn} />
      )} */}
    </div>
  );
}
