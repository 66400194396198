import styles from "./SexeChart.module.css";
import homme from "../../../assets/homme.png"
import femme from "../../../assets/femme.png"

export default function SexeChart({ data }) {
  console.log(data, "sexe")

  let nbHommes = 0
  let nbFemmes = 0
  data.forEach((x) => {
    if (x["name"] == "Mr") {
      nbHommes = x["value"]
    } else if (x["name"] == "Mme") {
      nbFemmes = x["value"]
    }
  })
  
  return (
    <div>
        <div className={styles.imgContainer}>
        <img src={homme} alt="nombre d'hommes" className={styles.img} />
        <p className={styles.nbSex}>{nbHommes}</p>
        </div>
        <div className={styles.imgContainer}>
        <img src={femme} alt="nombre de femmes" className={styles.img} />
        <p className={styles.nbSex}>{nbFemmes}</p>
        </div>
    </div>
  );
}
