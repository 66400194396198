import { useEffect, useState } from "react";
import styles from "./FormulePorteur.module.css";
import Pagination from "../../Pagination/Pagination";
import { useNavigate } from "react-router-dom";
import poubBleu from "../../../assets/poubBleu.png";

export default function FormulePorteur({
  formules, removeOverlayFn
}) {
  const [numberElementsMax, setNumberElementsMax] = useState(3);
  const navigate = useNavigate();
  const [prevNum, setPrevNum] = useState(0);
  const [nextNum, setNextNum] = useState(3);

  const nextPage = () => {
    if (numberElementsMax >= formules.length) {
      // setNextNum((prev) => prev + 3)
      setNumberElementsMax(3);
    } else {
      setNumberElementsMax((prev) => prev + 3);
    }
  };

  useEffect(() => {
    if (formules.length == 0) {
      setNextNum(0);
    } else if (numberElementsMax > formules.length) {
      setNextNum(formules.length);
    } else {
      setNextNum(numberElementsMax);
    }
    if (numberElementsMax > formules.length) {
      const numsCandidate = [formules.length - 1, formules.length - 2];
      let smallerNumber;
      for (let i of numsCandidate) {
        if (i % 3 === 0) {
          smallerNumber = i + 1;
          break;
        }
      }
      if (smallerNumber == undefined) {
        setPrevNum(0);
      } else {
        setPrevNum(smallerNumber);
      }
    } else {
      if (formules.length == 0) {
        setPrevNum(0);
      } else {
        setPrevNum(numberElementsMax - 2);
      }
    }
  }, [numberElementsMax, formules]);

  const prevPage = () => {
    if (numberElementsMax == 3) {
      if (formules.length % 3 == 0) {
        setNumberElementsMax(formules.length);
      } else {
        const numsCandidate = [formules.length + 1, formules.length + 2];
        let greatestNumber;
        for (let i of numsCandidate) {
          if (i % 3 === 0) {
            greatestNumber = i;
            break;
          }
        }
        setNumberElementsMax(greatestNumber);
      }
    } else {
      setNumberElementsMax((prev) => prev - 3);
    }
    // }
  };

  return (
    <>
            {formules.length != 0 && 
          <div className={styles.table}>
        <table className={styles.tableSearch}>
          <thead>
            <tr>
            <th>
              <b>Date d'entrée</b>
            </th>
            <th>
              <b>Date de sortie</b>
            </th>
            <th>
              Formule
            </th>
            <th>
            </th>
            </tr>
          </thead>
          <tbody>
            {formules
              .slice(numberElementsMax - 3, numberElementsMax)
              .map((formule) => {
                return (
                  <tr key={formule["formId"]}>
                    <td>{formule["entDat"].split('-').reverse().join('-')}</td>
                    <td>{formule["sorDat"]?.split('-').reverse().join('-')}</td>
                    <td>{formule["formule"]}</td>
                    <td>
                    <img
                          src={poubBleu}
                          className={styles.logo}
                          onClick={() => removeOverlayFn(formule)}
                        />
                    </td>
                    {/* <td>
                      <div className={styles.logoContainer}>
                        <img
                          src={modifBleu}
                          className={styles.logo}
                          onClick={() => {
                            modifDirOverlayFn(formule);
                          }}
                        />
                       
                      </div>
                    </td> */}
                  </tr>
                );
              })}
          </tbody>
        </table>
      <div>
        <Pagination nextPage={nextPage} prevPage={prevPage} />
        <p className={styles.nums}>
          {prevNum} à {nextNum} - Nombre Total d'éléments : {formules.length}{" "}
        </p>
      </div>
      </div>
    }
    </>
    
  );
}
