import { useState } from "react";
import ReactDOM from "react-dom";
import styles from "./OverlayInfosProjet.module.css";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { BounceLoader } from "react-spinners";

export default function OverlayInfosProjet({ infos, openOverlayFn }) {
  const { id } = useParams()
  const [editable, setEditable] = useState(false);
  const [pageError, setPageError] = useState(false);
  const [inputValues, setInputValues] = useState(infos)
  const queryClient = useQueryClient(); 

  const changeFn = (e) => {
    setInputValues((prev) => {
      let newState = JSON.parse(JSON.stringify(prev))
      newState[e.target.name] = e.target.value
      return newState
    })
  };

  async function updateProjet(data) {
    const link = `${process.env.REACT_APP_API_URL}/tiers/Prj`
    // const link = "http://localhost:8080/tiers/Prj";
    const response = await fetch(link, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }

    const dataReceived = await response.json();
    return dataReceived;
  }

  const mutationDataUpdate = useMutation({
    mutationFn: (data) => updateProjet(data),
    onSuccess: () => {
      setEditable(!editable)
      queryClient.invalidateQueries(["Tiers", id]);
      openOverlayFn()
    },
  });

  const checkAndSend = (inputValues) => {
    if (inputValues["ActPrj"] == "") {
    setPageError(true)
  } else {
    setPageError(false)
    mutationDataUpdate.mutate(inputValues)
    }
  }

  return ReactDOM.createPortal(
    <div className={styles.overlay} onClick={() => openOverlayFn()}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <p className={styles.textHistPrix}>DÉTAILS PROJET</p>
        <div className={styles.table}>
          <table className={styles.tableSearch}>
            <thead>
              <th style={{ width: "25%" }}></th>
              <th style={{ width: "25%" }}></th>
              <th style={{ width: "25%" }}></th>
              <th style={{ width: "25%" }}></th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <b>Dénomination entreprise</b>
                </td>
                <td>
                  {editable ? (
                    <input
                      type="text"
                      id="RsoPrj"
                      name="RsoPrj"
                      value={inputValues["RsoPrj"]}
                      onChange={(e) => changeFn(e)}
                      className={styles.input}
                    />
                  ) : (
                    <div className={styles.inputValue}>{inputValues["RsoPrj"]}</div>
                  )}
                </td>
                <td>
                  <b>Activité{editable == true && "*"}</b>
                </td>
                <td>
                  <div>
                  {editable ? (
                    <input
                      type="text"
                      id="ActPrj"
                      name="ActPrj"
                      value={inputValues["ActPrj"]}
                      onChange={(e) => changeFn(e)}
                      className={styles.input}
                    />
                  ) : (
                    <div className={styles.inputValue}>{inputValues["ActPrj"]}</div>
                  )}
                  {(inputValues["ActPrj"] == undefined || inputValues["ActPrj"] == "") &&
                    pageError == true && (
                      <small>
                        Ce champs est requis
                      </small>
                    )}

                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <b>Statut Juridique</b>
                </td>
                <td>
                  {editable ? (
                    <>
                    <select
                      value={inputValues["StatutJurPrj"]}
                      onChange={(e) => changeFn(e)}
                      name="StatutJurPrj"
                      id="StatutJurPrj"
                      className={styles.inputSelect}
                    >
                      <option value="">----------------------</option>
                      <option value="EI">EI</option>
                      <option value="EI micro">EI micro</option>
                      <option value="Association">Association</option>
                      <option value="EURL">EURL</option>
                      <option value="SARL">SARL</option>
                      <option value="SASU">SASU</option>
                      <option value="SAS">SAS</option>
                      <option value="SA">SA</option>
                      <option value="SNC">SNC</option>
                      <option value="SCS">SCS</option>
                      <option value="SCA">SCA</option>
                      {!["","Champ libre","EI", "EI micro", "Association", "EURL", "SARL", "SASU"
                    ,"SAS", "SA", "SNC", "SCS", "SCA"].includes(inputValues["StatutJurPrj"]) && <option value={inputValues["StatutJurPrj"]}>{inputValues["StatutJurPrj"]}</option>}
                      <option value="Champ libre">Champ libre</option>
                    </select>
                    {inputValues["StatutJurPrj"] == "Champ libre" && (
                      <input
                        type="text"
                        id="champLibre"
                        name="champLibre"
                        value={inputValues["champLibre"]}
                        onChange={(e) => changeFn(e)}
                        className={styles.input}
                      />
                    )}
                    </>
                  ) : (
                    <div className={styles.inputValue}>{inputValues["StatutJurPrj"]}</div>
                  )}
                </td>
                <td>
                  <b>Date de création</b>
                </td>
                <td>
                  {editable ? (
                    <input
                      type="date"
                      id="DatCrePrj"
                      name="DatCrePrj"
                      value={inputValues["DatCrePrj"]}
                      onChange={(e) => changeFn(e)}
                      className={styles.input}
                    />
                  ) : (
                    <div className={styles.inputValue}>
                      {inputValues["DatCrePrj"]}
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Nombre de dirigeant</b>
                </td>
                <td>
                  {editable ? (
                    <input
                      type="number"
                      id="NbDirPrj"
                      name="NbDirPrj"
                      value={inputValues["NbDirPrj"]}
                      onChange={(e) => changeFn(e)}
                      className={styles.input}
                      min="0"
                    />
                  ) : (
                    <div className={styles.inputValue}>
                      {inputValues["NbDirPrj"]}
                    </div>
                  )}
                </td>
                <td>
                  <b>Effectif prévisionnel</b>
                </td>
                <td>
                  {editable ? (
                    <input
                      type="number"
                      id="EffPrj"
                      name="EffPrj"
                      value={inputValues["EffPrj"]}
                      onChange={(e) => changeFn(e)}
                      className={styles.input}
                      min="0"
                    />
                  ) : (
                    <div className={styles.inputValue}>
                      {inputValues["EffPrj"]}
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >

           <button
          className={styles.modifierButton}
          onClick={editable ? () => checkAndSend(inputValues) : () => setEditable(!editable)}
          disabled={mutationDataUpdate.isPending}
        >
          {editable ? "Enregistrer" : "Modifier"}
        </button>
          <button
          className={styles.modifierButton}
          onClick={() => openOverlayFn()}
        >
          Retour
        </button>
        </div>
        {mutationDataUpdate.isPending == true && (
                    <div className={styles.bounce}>
                        <BounceLoader color="white" />
                    </div>
                )}

      </div>
    </div>,
    document.getElementById("overlay")
  );
}
