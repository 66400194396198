import ReactDOM from 'react-dom';
import styles from "./overlayAddDir.module.css"
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { BounceLoader } from "react-spinners";

export default function OverlayAddDir({ openOverlayFn, dirToModify }) {
    const { id } = useParams()
    const [newDir, setnewDir] = useState({tipmId: id, tippId: dirToModify["tippId"] ? dirToModify["tippId"] : "", relDtd: dirToModify["relDtd"] ? dirToModify["relDtd"] : "", relDtf: dirToModify["relDtf"] ? dirToModify["relDtf"] : "", relTyp: dirToModify["relTyp"] ? dirToModify["relTyp"] : "", champLibre: ""})
    const [pageError, setPageError] = useState(false);
    const queryClient = useQueryClient();

    const changeDir = (e) => {
        setnewDir((prev) => {return {...prev, [e.target.name]: e.target.value}})
    }

    async function getPPs() {
        const link = `${process.env.REACT_APP_API_URL}/tiers/PPLib?id=${id}`
        // const link = `http://localhost:8080/tiers/PPLib?id=${id}`;
        const response = await fetch(link);
        if (!response.ok) {
          throw "Il y a un problème avec le serveur";
        }
        const data = await response.json();
        return data;
      }
    
      const { data: PPs = [] } = useQuery({
        queryKey: ["PPs"],
        queryFn: () => getPPs(),
        refetchOnWindowFocus: false
      });
    
    const checkAndSend = () => {
        if (newDir["tippId"] == "" || newDir["relDtd"] == "" || newDir["relTyp"] == "") {
            setPageError(true)
        } else {
            setPageError(false)
            mutationPostDir.mutate(newDir)
        }
    }

    async function addDir(data) {
        const link = `${process.env.REACT_APP_API_URL}/tiers/rel`
        // const link = `http://localhost:8080/tiers/rel`
        const response = await fetch(link, {
            method:"POST",
            body:JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
            credentials:"include"
        })
        if (!response.ok) {
            throw 'Il y a un problème avec le serveur'
        }
        const dataReceived = await response.json()
        return dataReceived
    }

    const mutationPostDir = useMutation({
        mutationFn: (data) => addDir(data),
        onSuccess: () => {
                queryClient.invalidateQueries(["Tiers", id]);
                openOverlayFn((prev) => !prev)
        }
    })

    async function ModifyDir(data) {
        const link = `${process.env.REACT_APP_API_URL}/tiers/rel`
        // const link = `http://localhost:8080/tiers/rel`
        const response = await fetch(link, {
            method:"PUT",
            body:JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
            credentials:"include"
        })
        if (!response.ok) {
            throw 'Il y a un problème avec le serveur'
        }
        const dataReceived = await response.json()
        return dataReceived
    }

    const mutationUpdateDir = useMutation({
        mutationFn: (data) => ModifyDir(data),
        onSuccess: () => {
                queryClient.invalidateQueries(["Tiers", id]);
                openOverlayFn((prev) => !prev)
        }
    })

    return ReactDOM.createPortal(
        <div className={styles.overlay} onClick={() => openOverlayFn(false)}>
            <div className={styles.container} onClick={(e) => e.stopPropagation()}>
                <p className={styles.textOverlayTitle}>{Object.keys(dirToModify).length != 0 ? "MODIFIER":"AJOUTER"} DIRIGEANT</p>
                <div>
                    <div className={styles.fieldContainer}>
                        <label className={styles.label} htmlFor="tippId">
                        Porteur de projet*
                        </label>
                        {Object.keys(dirToModify).length == 0 && <select
                            value={newDir["tippId"]}
                            onChange={(e) => changeDir(e)}
                            name="tippId"
                            id="tippId"
                            disabled={Object.keys(dirToModify).length != 0}
                            className={styles.inputSelect}
                            >
                                <option value="">----------------------</option>
                                {
                                    PPs.map((PP) => {
                                        return <option value={PP.tiId} key={PP.tiId}>{PP.tiLib}</option>
                                    })
                                }
                        </select>}
                        {Object.keys(dirToModify).length != 0 && <select
                            value={dirToModify["tiLib"]}
                            onChange={(e) => changeDir(e)}
                            name="tiLib"
                            id="tiLib"
                            disabled={Object.keys(dirToModify).length != 0}
                            className={styles.inputSelect}
                            >
                                <option value={dirToModify["tiLib"]}>{dirToModify["tiLib"]}</option>
                        </select>}

                        {((newDir["tippId"] == "")  && pageError == true) && <small className={styles.small}>Ce champs est requis</small>}
                    </div>
                    <div className={styles.fieldContainer}>
                        <label className={styles.label} htmlFor="relDtd">
                        Date de début de fonction*
                        </label>
                        <input
                            type="date"
                            id="relDtd"
                            name="relDtd"
                            value={newDir["relDtd"]}
                            readOnly={Object.keys(dirToModify).length != 0}
                            onChange={(e) => changeDir(e)}
                            className={styles.input}
                        />
                        {((newDir["relDtd"] == "")  && pageError == true) && <small className={styles.small}>Ce champs est requis</small>}
                    </div>
                    <div className={styles.fieldContainer}>
                        <label className={styles.label} htmlFor="relDtf">
                        Date de fin de fonction
                        </label>
                        <input
                            type="date"
                            id="relDtf"
                            name="relDtf"
                            value={newDir["relDtf"]}
                            onChange={(e) => changeDir(e)}
                            className={styles.input}
                        />
                    </div>
                    <div className={styles.fieldContainer}>
                        <label className={styles.label} htmlFor="relTyp">
                        Fonction
                        </label>
                        <select
                            value={newDir["relTyp"]}
                            onChange={(e) => changeDir(e)}
                            name="relTyp"
                            id="relTyp"
                            disabled={Object.keys(dirToModify).length != 0}
                            className={styles.inputSelect}
                            >   
                                <option value="">---------------</option>
                                <option value="Président">Président</option>
                                <option value="Directeur Général">Directeur Général</option>
                                <option value="Gérant Majoritaire">Gérant Majoritaire</option>
                                <option value="Gérant Minoritaire">Gérant Minoritaire</option>
                                <option value="Gérant Non Associé">Gérant Non Associé</option>
                                <option value="Chef d'entreprise">Chef d'entreprise</option>
                                <option value="Président Non Associé">Président Non Associé</option> 
                                {!["Président", "Directeur Général", "Gérant Majoritaire", "Gérant Minoritaire", "Gérant Non Associé", "Chef d'entreprise",
                                "Président Non Associé"].includes(newDir["relTyp"]) && newDir["relTyp"] && <option value={newDir["relTyp"]}>{newDir["relTyp"]}</option>}
                                <option value="Champ Libre">Champ Libre</option> 
                        </select>
                        {newDir["relTyp"] == "Champ Libre" && (
                        <input
                            type="text"
                            id="champLibre"
                            name="champLibre"
                            value={newDir["champLibre"]}
                            onChange={(e) => changeDir(e)}
                            className={styles.input}
                        />
                        )}
                        {(newDir["relTyp"] == "" && pageError == true) && <small className={styles.small}>Ce champs est requis</small>}
                    </div>
                </div>
                <div style={{width:"100%", display:"flex", justifyContent:"space-evenly"}}>
                    {Object.keys(dirToModify).length == 0 ? <button className={styles.buttonValid} disabled={mutationPostDir.isPending} onClick={() => checkAndSend()}>Ajouter</button> : <button className={styles.buttonValid} disabled={mutationUpdateDir.isPending} onClick={() => mutationUpdateDir.mutate(newDir)}>Modifier</button>}     
                    <button className={styles.buttonValid} onClick={() => openOverlayFn(false)}>Retour</button>    
                </div>
                {mutationPostDir.isPending == true && (
                    <div className={styles.bounce}>
                        <BounceLoader color="white" />
                    </div>
                )}
                {mutationUpdateDir.isPending == true && (
                    <div className={styles.bounce}>
                        <BounceLoader color="white" />
                    </div>
                )}

            </div>
        </div>,
        document.getElementById('overlay')
        )
}