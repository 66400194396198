import ReactDOM from "react-dom";
import styles from "./OverlayAddLocal.module.css";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

export default function OverlayAddLocal({
  openOverlayFn,
  addLocal,
  ugAlready
}) {
  const [newLocal, setNewLocal] = useState({
    ugInt: "",
    ugId: "",
    ugconvDtd: "",
    ugconvDtf: "",
    ugconvSurf: "",
    ugconvSurfMax: "",
    rubconvMnt: "",
    rubRef: 'Redevance'
  });
  const [pageError, setPageError] = useState(false);

  function isValidDate(dateString) {
    // Regex to check the format
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    if (!dateString.match(regex)) {
        return false; // Fails regex check
    }

    const [year, month, day] = dateString.split('-').map(Number);

    // Check for valid month and day numbers
    if (month < 1 || month > 12 || day < 1 || day > 31 || year < 2000) {
        return false;
    }

    // Create a date instance and check if the numbers match
    const date = new Date(year, month - 1, day);
    return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
}


  async function allUGs(dateDebut) {
    if (isValidDate(dateDebut)) {
      const link = `${process.env.REACT_APP_API_URL}/conv/ugs/${dateDebut}`;        
      // const link = `http://localhost:8080/conv/ugs/${dateDebut}`; 
      const response = await fetch(link)
      if (!response.ok) {
          throw "il y a un pb"
      }
      const data = await response.json()
      return data
    } else {
      return []
    }
  }


  const {data: ugsInit = []} = useQuery({
      queryKey:["Patrimoine", newLocal["ugconvDtd"]],
      queryFn:() => allUGs(newLocal["ugconvDtd"]),
      // enabled: isValidDate(newLocal["ugconvDtd"]),// newLocal["ugconvDtd"] != undefined && newLocal["ugconvDtd"] != "" && newLocal["ugconvDtd"].split(),
      refetchOnWindowFocus: false
  })


  const changeLocal = (e) => {
    setNewLocal((prev) => {
      const newState = JSON.parse(JSON.stringify(prev))
      if (e.target.name == "ugId") {
        const ugId = e.target.value
        newState[e.target.name] = ugId
        const selectedData = ugsInit.find(data => data.ugId == ugId);
        newState["ugInt"] = selectedData["ugInt"]
        newState["ugconvSurfMax"] = selectedData["ugSurf"] - selectedData["TotalSurf"]
        newState["ugconvSurf"] = newState["ugconvSurfMax"]
        newState["rubconvMnt"] = selectedData["prixAn1"] * newState["ugconvSurf"] / selectedData["ugSurf"]
      } else if (e.target.name == "ugconvSurf") {
        const newSurf = e.target.value 
        newState[e.target.name] = newSurf
        newState["rubconvMnt"] = newSurf * prev["rubconvMnt"] / prev["ugconvSurf"]
      } else {
        newState[e.target.name] = e.target.value 
      }
      return newState
    })
  };

  const checkAndSend = () => {
    if (
      newLocal["ugId"] == "" ||
      newLocal["ugconvDtd"] == "" ||
      newLocal["ugconvSurf"] == ""
    ) {
      setPageError(true);
    } else {
      setPageError(false);
      addLocal(newLocal);
      openOverlayFn(false);
    }
  };

  return ReactDOM.createPortal(
    <div className={styles.overlay} onClick={() => openOverlayFn(false)}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <p className={styles.textOverlayTitle}>AJOUTER LOCAL</p>
        <div>
          <div className={styles.fieldContainer}>
            <label className={styles.label} htmlFor="ugconvDtd">
              Date de début*
            </label>
            <input
              type="date"
              id="ugconvDtd"
              name="ugconvDtd"
              value={newLocal["ugconvDtd"]}
              onChange={(e) => changeLocal(e)}
              className={styles.input}
            />
            {newLocal["ugconvDtd"] == "" && pageError == true && (
              <small className={styles.small}>Ce champs est requis</small>
            )}
          </div>
          {ugsInit.length != 0 && 
          <>
          <div className={styles.fieldContainer}>
            <label className={styles.label} htmlFor="ugId">
              Intitulé*
            </label>
            <select
              value={newLocal["ugId"]}
              onChange={(e) => changeLocal(e)}
              name="ugId"
              id="ugId"
              className={styles.inputSelect}
            >
              <option value="">----------------------</option>
                {ugsInit.map((data) => {
                  if (!ugAlready.includes(data.ugId.toString())) {
                    return <option value={data.ugId} key={data.ugId}>{data.ugRef} - {data.ugInt}</option>
                  }
                })}
            </select>
            {newLocal["ugId"] == "" && pageError == true && (
              <small className={styles.small}>Ce champs est requis</small>
            )}
          </div>
          <div className={styles.fieldContainer}>
            <label className={styles.label} htmlFor="ugconvDtf">
              Date de fin
            </label>
            <input
              type="date"
              id="ugconvDtf"
              name="ugconvDtf"
              value={newLocal["ugconvDtf"]}
              onChange={(e) => changeLocal(e)}
              className={styles.input}
            />
          </div>
          <div className={styles.fieldContainer}>
            <label className={styles.label} htmlFor="ugconvSurf">
              Surface louée*
            </label>
            <input
              type="number"
              id="ugconvSurf"
              name="ugconvSurf"
              value={newLocal["ugconvSurf"]}
              onChange={(e) => changeLocal(e)}
              className={styles.input}
              min="0"
              max={newLocal["ugconvSurfMax"]}
            />
            {newLocal["ugconvSurf"] == "" && pageError == true && (
              <small className={styles.small}>Ce champs est requis</small>
            )}
          </div>
          <div className={styles.fieldContainer}>
            <label className={styles.label} htmlFor="rubconvMnt">
              Prix Mensuel*
            </label>
            <input
              type="number"
              id="rubconvMnt"
              name="rubconvMnt"
              value={newLocal["rubconvMnt"]}
              onChange={(e) => changeLocal(e)}
              className={styles.input}
              readOnly={true}
              min="0"
            />
            {newLocal["rubconvMnt"] == "" && pageError == true && (
              <small className={styles.small}>Ce champs est requis</small>
            )}
          </div>
          </>}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <button className={styles.buttonValid} onClick={() => checkAndSend()}>
            Ajouter
          </button>
          <button
            className={styles.buttonValid}
            onClick={() => openOverlayFn(false)}
          >
            Retour
          </button>
        </div>
      </div>
    </div>,
    document.getElementById("overlay")
  );
}
