import styles from "./SearchBox.module.css"
import filtre from "../../assets/filtre.png"
import downloadGrey from "../../assets/downloadGrey.png"

import { useContext, useEffect, useState } from "react"
import { HeaderContext } from "../../App"
import { useLocation } from "react-router-dom"
import FilterOverlay from "../Overlay"

export default function SearchBox() {
    const [searchText, setSearchText] = useState("") 
    const [searchTextPatrimoine, setSearchTextPatrimoine] = useState("") 
    const [searchTextConv, setSearchTextConv] = useState("") 
    const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
    const [debouncedSearchTextPatrimoine, setDebouncedSearchTextPatrimoine] = useState(searchTextPatrimoine);
    const [debouncedSearchTextConv, setDebouncedSearchTextConv] = useState(searchTextConv);
    const { setNumberElementsMaxConv, setNumberElementsMaxTiers, setNumberElementsMaxLocaux, setSearchData, searchData, setSearchDataPatrimoine, searchDataPatrimoine,
    setSearchDataConv, searchDataConv, filterFieldPatrimoine, filterFieldTiers, filterFieldConv, setIsLoadingPatrimoine, setIsLoadingConv, setIsLoading } = useContext(HeaderContext);
    const [overlayFilter, setOverlayFilter] = useState("")
    // const [isLoadingSearch, setIsLoadingSearch] = useState(false)

    const location = useLocation()
    const pathURL = location.pathname;

    const onChangeSearch = (e) => {
        if (pathURL.includes("Tiers")) {
            setSearchText(e.target.value)
        } else if (pathURL.includes("Patrimoine")) {
            setSearchTextPatrimoine(e.target.value)
        } else if (pathURL.includes("Convention")) {
            setSearchTextConv(e.target.value)
        }
    }

    useEffect(() => {
        
        if (debouncedSearchText != searchText) {
            setSearchData(["loading"])
            const handler = setTimeout(() => {
                setDebouncedSearchText(searchText);
            }, 1000)
        
            return () => clearTimeout(handler);
        }
    }, [searchText]); 
    
    useEffect(() => {
        if (debouncedSearchTextPatrimoine != searchTextPatrimoine) {
            setSearchDataPatrimoine(["loading"])
            const handler = setTimeout(() => {
                setDebouncedSearchTextPatrimoine(searchTextPatrimoine);
            }, 1000)
            return () => clearTimeout(handler);
        }
        
    }, [searchTextPatrimoine])
    
    useEffect(() => {
        if (debouncedSearchTextConv != searchTextConv) {
            setSearchDataConv(["loading"])
            const handler = setTimeout(() => {
                setDebouncedSearchTextConv(searchTextConv);
            }, 1000)
        
            return () => clearTimeout(handler);
        }
    }, [searchTextConv])


    async function getSearch()  {
        let link
        if (pathURL.includes("Tiers")) {
            if (debouncedSearchText == "" && searchData[0] == "empty") {
                setIsLoading(true)
            }
            link = `${process.env.REACT_APP_API_URL}/tiers/search?search=${debouncedSearchText}&dateDebut=${filterFieldTiers["dateDebut"]}&dateFin=${filterFieldTiers["dateFin"]}`
            // link = `http://localhost:8080/tiers/search?search=${debouncedSearchText}&dateDebut=${filterFieldTiers["dateDebut"]}&dateFin=${filterFieldTiers["dateFin"]}`
        } else if (pathURL.includes("Patrimoine")) {
            if (debouncedSearchTextPatrimoine == "" && searchDataPatrimoine[0] == "empty") {
                setIsLoadingPatrimoine(true)
            }
            link = `${process.env.REACT_APP_API_URL}/ug/search?search=${debouncedSearchTextPatrimoine}`
            // link = `http://localhost:8080/ug/search?search=${debouncedSearchTextPatrimoine}`
        } else if (pathURL.includes("Convention")) {
            if (debouncedSearchTextConv == "" && searchDataConv[0] == "empty") {
                setIsLoadingConv(true)
            }
            link = `${process.env.REACT_APP_API_URL}/conv/search?search=${debouncedSearchTextConv}`
            // link = `http://localhost:8080/conv/search?search=${debouncedSearchTextConv}`
        }  

        const response = await fetch(link)
        if (!response.ok) {
            throw "Il y a un problème avec le serveur"
        }
            
        const data = await response.json()
        
        if (pathURL.includes("Tiers")) {
            setSearchData(data)
            if (debouncedSearchText != "") {
                setNumberElementsMaxTiers(10)
            } else {
                setIsLoading(false)
            }
        } else if (pathURL.includes("Patrimoine")) {
            setSearchDataPatrimoine(data)
            if (debouncedSearchTextPatrimoine != "") {
                setNumberElementsMaxLocaux(10)
            } else {
                setIsLoadingPatrimoine(false)
            }
        } else if (pathURL.includes("Convention")) {
            setSearchDataConv(data)
            if (debouncedSearchTextConv != "") {
                setNumberElementsMaxConv(10)
            } else {
                setIsLoadingConv(false)
            }
        }
    }

    useEffect(() => {
        getSearch();
    }, [debouncedSearchTextPatrimoine, debouncedSearchTextConv, debouncedSearchText, location]); //location, Depend on debounced text

    

    const downloadSearch = async () => {
        if (pathURL.includes("Tiers") && searchData != ["empty"]) {
            const link = `${process.env.REACT_APP_API_URL}/tiers/downloadSearch?search=${debouncedSearchText}`
            // const link = `http://localhost:8080/tiers/downloadSearch?search=${debouncedSearchTextPatrimoine}&dateDebut=${filterFieldTiers["dateDebut"]}&dateFin=${filterFieldTiers["dateFin"]}`
            window.open(link, '_blank')
        } else if (pathURL.includes("Patrimoine") && searchDataPatrimoine != ["empty"]) {
            const link = `${process.env.REACT_APP_API_URL}/ug/downloadSearch?search=${debouncedSearchTextPatrimoine}&dateDispo=${filterFieldPatrimoine["dateDispo"]}`
            // const link = `http://localhost:8080/ug/downloadSearch?search=${debouncedSearchTextPatrimoine}&dateDispo=${filterFieldPatrimoine["dateDispo"]}`
            window.open(link, '_blank')

        } else if (pathURL.includes("Convention") && searchDataConv != ["empty"]) {
            const link = `${process.env.REACT_APP_API_URL}/conv/downloadSearch?search=${debouncedSearchTextConv}`
            // const link = `http://localhost:8080/conv/downloadSearch?search=${debouncedSearchTextConv}&dateDebut=${filterFieldConv["dateDebut"]}&dateFin=${filterFieldConv["dateFin"]}`
            window.open(link, '_blank')
        }
    }

    return ( 
        <div className={styles.searchBox}>
            {overlayFilter == "filter" && <FilterOverlay pathURL={pathURL} status={overlayFilter} setFilter={setOverlayFilter} searchText={pathURL.includes("Tiers") ? debouncedSearchText : pathURL.includes("Patrimoine") ? debouncedSearchTextPatrimoine : ""} />} 
            {(pathURL.includes("rechercheConvention") || pathURL.includes("rechercheTiers") || pathURL.includes("recherchePatrimoine")) && <input type="text" value={pathURL.includes("recherchePatrimoine") ? searchTextPatrimoine : pathURL.includes("rechercheTiers") ? searchText : pathURL.includes("rechercheConvention") ? searchTextConv : null} onChange={onChangeSearch} placeholder="Entrez votre recherche" className={styles.input}/>}
            {(pathURL.includes("rechercheConvention") || pathURL.includes("rechercheTiers") || pathURL.includes("recherchePatrimoine")) && <img src={filtre} alt="filtre" className={styles.filtre} onClick={() => setOverlayFilter("filter")} />}
            {(pathURL.includes("rechercheConvention") || pathURL.includes("rechercheTiers") || pathURL.includes("recherchePatrimoine")) && <img src={downloadGrey} alt="download" className={styles.filtre} onClick={() => downloadSearch()} />}
        </div>
    )   
}