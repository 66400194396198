import { useNavigate } from "react-router-dom"
import styles from "./box.module.css"
import flecheBas from "../../assets/flecheBas.png"
import { useState } from "react"

export default function Box({ text, image, link }) {
    const navigate = useNavigate()
    const [benefClicked, setBenefClicked] = useState(false)
    
    return (
        <div className={styles.container}>
        {text ==  "Bénéficiaires" ? 
            <div className={styles.boxBenef} onClick={() => setBenefClicked(!benefClicked)}>
                <div className={`${benefClicked ? styles.benefBlockClicked : styles.benefBlock}`} onClick={() => setBenefClicked(true)}>
                    <p>{text}</p>
                    <img src={flecheBas} className={styles.image} /> 
                </div>
                {benefClicked && 
                    <div className={styles.containerDropdown}>
                        <div className={styles.dropDown} onClick={() => navigate(link[0])}>
                            Liste des bénéficiaires
                        </div>
                        <div className={styles.dropDown} onClick={() => navigate(link[1])}>
                            Formulaire
                        </div>
                    </div>
                    }
            </div>
            :
            <div className={styles.box} onClick={() => navigate(link)}>
                <p className={styles.text}>{text}</p>
            </div>
        }
        </div>
    )
}