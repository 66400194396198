import ReactDOM from "react-dom";
import styles from "./OverlayInfoAcc.module.css";
import { useParams } from "react-router-dom";
import poubBleu from "../../../assets/poubBleu.png";
import importerBlanc from "../../../assets/importerBlanc.png";
import wordLogo from "../../../assets/word.png";
import excelLogo from "../../../assets/excel.png";
import pdfLogo from "../../../assets/pdf.png";
import docLambda from "../../../assets/documentLambda.png";
import { BounceLoader } from "react-spinners";
import downloadBleu from "../../../assets/downloadBleu.png";

import { useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export default function OverlayInfoAcc({ openOverlayFn, infos }) {
  const [editable, setEditable] = useState(false);
  const inputFile = useRef(null);
  const [inputValues, setInputValues] = useState(infos);
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [showMessage, setShowMessage] = useState(false)
  const [isLoadingDel, setIsLoadingDel] = useState(false);
  const [pageError, setPageError] = useState(false)
  const [pageDeleteError, setPageDeleteError] = useState(false)
  const [IsLoadingPostFile, setIsLoadingPost] = useState(false);

  console.log(infos)

  // const handleFileUpload = async (event) => {
  //   setIsLoadingPost(true);
  //   const files = event.target.files;
  //   const formData = new FormData();
  //   for (let i = 0; i < files.length; i++) {
  //     formData.append("files", files[i]);
  //   }
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_API_URL}/tiers/suivi/uploadFiles/${id}/${infos["suiviId"]}`, {
  //         method: 'POST',
  //         body: formData,
  //     });
  //     // const response = await fetch(
  //     //   `http://localhost:8080/ug/uploadFiles/${ugNum}`,
  //     //   {
  //     //     method: "POST",
  //     //     body: formData,
  //     //   }
  //     // );
  //     if (response.ok) {
  //       queryClient.invalidateQueries( [
  //         "Suivi",
  //         "Files",
  //         id,
  //         infos["DateAccSuivi"],
  //         infos["HDebAccSuivi"],
  //         infos["SujetAccSuivi"],
  //       ]);
  //     } else {
  //       console.error("File upload failed:", response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error during file upload:", error);
  //   } finally {
  //     setIsLoadingPost(false); // End loading
  //   }
  // };

  const handleFileUpload = async (event) => {
    setIsLoadingPost(true);
    const files = event.target.files;
    const suiviId = infos["suiviId"]; 
  
    try {  
      const presignedUrlPromises = Array.from(files).map(file => {
        return fetch(`${process.env.REACT_APP_API_URL}/tiers/suivi/generatePresignedUrl/${id}/${suiviId}/${encodeURIComponent(file.name)}`)
          .then(response => response.json());
      });
  
      const presignedUrls = await Promise.all(presignedUrlPromises);

      const uploadPromises = presignedUrls.map((presignedUrl, index) => {
        return fetch(presignedUrl.url, {
          method: 'PUT',
          headers: {
            'Content-Type': files[index].type,
          },
          body: files[index],
        });
      });

      const uploadResponses = await Promise.all(uploadPromises);
  
      const allUploadsSuccessful = uploadResponses.every(response => response.ok);
  
      if (allUploadsSuccessful) {
        queryClient.invalidateQueries([
          "Suivi",
          "Files",
          id,
          infos["DateAccSuivi"],
          infos["HDebAccSuivi"],
          infos["SujetAccSuivi"],
        ]);
        // console.log('All files uploaded successfully');
      } else {
        console.error('File upload failed for some files');
      }
    } catch (error) {
      console.error('Error during file upload:', error);
    } finally {
      setIsLoadingPost(false); // End loading
    }
  };


  const deleteFile = async (fileToRemove) => {
    setIsLoadingDel(true);
    let deleteList = [fileToRemove];
    try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/tiers/suivi/files/${id}/${infos["suiviId"]}`, {
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ "files" : deleteList }),
      })
      // const response = await fetch(
      //   `http://localhost:8080/tiers/suivi/files/${id}/${infos["suiviId"]}`,
      //   {
      //     method: "DELETE",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({ files: deleteList }),
      //   }
      // );

      if (response.ok) {
        queryClient.invalidateQueries([
          "Suivi",
          "Files",
          id,
          infos["DateAccSuivi"],
          infos["HDebAccSuivi"],
          infos["SujetAccSuivi"],
        ]);
      } else {
        console.error("File upload failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error during file upload:", error);
    } finally {
      setIsLoadingDel(false);
    }
  };

  async function getFiles(id) {
    const link = `${process.env.REACT_APP_API_URL}/tiers/suivi/files/${id}/${infos["suiviId"]}`
    // const link = `http://localhost:8080/tiers/suivi/files/${id}/${infos["suiviId"]}`;
    const response = await fetch(link);
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const data = await response.json();
    const listFiles = [];
    const formatsWord = [
      ".doc",
      ".docx",
      ".dotx",
      ".dotm",
      ".docm",
      ".rtf",
      ".txt",
      ".xml",
    ];
    const excelFormats = [
      ".xls",
      ".xlsx",
      ".xlsm",
      ".xlsb",
      ".xlt",
      ".xltx",
      ".xltm",
      ".csv",
      ".xml",
    ];
    const pdfFormat = [".pdf"];
    for (let obj of data) {
      const link = obj["url"];
      const filename = obj["filename"];
      if (formatsWord.some((format) => filename.includes(format))) {
        listFiles.push([wordLogo, link, filename]);
      } else if (excelFormats.some((format) => filename.includes(format))) {
        listFiles.push([excelLogo, link, filename]);
      } else if (pdfFormat.some((format) => filename.includes(format))) {
        listFiles.push([pdfLogo, link, filename]);
      } else {
        listFiles.push([docLambda, link, filename]);
      }
    }
    return listFiles;
  }

  const { data: files = [], isLoading: isLoadingFiles } = useQuery({
    queryKey: [
      "Suivi",
      "Files",
      id,
      infos["DateAccSuivi"],
      infos["HDebAccSuivi"],
      infos["SujetAccSuivi"],
    ],
    queryFn: () => getFiles(id),
    refetchOnWindowFocus: false,
  });

  const downloadFile = (link) => {
    window.open(link, "_blank");
  };

  const changeFn = (e) => {
    setInputValues((prev) => {
      let newState = JSON.parse(JSON.stringify(prev))
      newState[e.target.name] = e.target.value;
      return newState;
    });
  };

  async function UpdateAccSuivi(data) {
    const link = `${process.env.REACT_APP_API_URL}/tiers/suivi/${id}`;
    // const link = `http://localhost:8080/tiers/suivi/${id}`
    const response = await fetch(link, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
        setShowMessage(true)
        setTimeout(() => {
        setShowMessage(false);
            }, 5000)    
        throw "Il y a un problème avec le serveur";
    }
    const dataReceived = await response.json();
    return dataReceived;
  }


  const mutationDataUpdate = useMutation({
    mutationFn: (data) => UpdateAccSuivi(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["Suivi", id])
      openOverlayFn()
      // navigate(`/pageConnecte/rechercheTiers/tiers/${data.ugId}`)
    },
  });

  const checkAndSend = (inputValues) => {
    if (inputValues["DateAccSuivi"] == "" || inputValues["TypeAccSuivi"] == "" || inputValues["HDebAccSuivi"] == "" || inputValues["HFinAccSuivi"] == ""
    || inputValues["SujetAccSuivi"] == "") {
    setPageError(true)
  } else {
    setPageError(false)
    setEditable((prev) => !prev)
    mutationDataUpdate.mutate(inputValues)
    }
  }

  async function deleteAccSuivi() {
    const link = `${process.env.REACT_APP_API_URL}/tiers/suivi/${id}/${inputValues["suiviId"]}`;
    // const link = `http://localhost:8080/tiers/suivi/${id}/${inputValues["suiviId"]}`
    const response = await fetch(link, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
        setShowMessage(true)
        setTimeout(() => {
        setShowMessage(false);
            }, 5000)    
        throw "Il y a un problème avec le serveur";
    }
    const dataReceived = await response.json();
    return dataReceived;
  }

  const mutationDataDelete = useMutation({
    mutationFn: () => deleteAccSuivi(),
    onSuccess: () => {
      queryClient.invalidateQueries(["Suivi", id])
      openOverlayFn()
      // navigate(`/pageConnecte/rechercheTiers/tiers/${data.ugId}`)
    },
  });

  const deleteFn = () => {
    if (files.length != 0) {
      setPageDeleteError(true)
    } else {
      setPageDeleteError(false)
      mutationDataDelete.mutate()
    }
  }




  return ReactDOM.createPortal(
    <div className={styles.overlay} onClick={() => openOverlayFn()}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <p className={styles.textHistPrix}>INFORMATIONS ACCOMPAGNEMENT</p>
        <div className={styles.infosDate}>
          <div className={styles.containerFields}>
            <label htmlFor="DateAccSuivi" className={styles.label}>
              Date
            </label>
            <input
              type="date"
              id="DateAccSuivi"
              name="DateAccSuivi"
              readOnly={!editable}
              value={inputValues["DateAccSuivi"]}
              onChange={(e) => changeFn(e)}
              className={styles.input}
              style={{height:"1rem"}}
            />
            {(inputValues["DateAccSuivi"] == undefined || inputValues["DateAccSuivi"] == "")  && pageError == true && (
                <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>
            )}

          </div>
          <div className={styles.containerFields}>
            <label htmlFor="TypeAccSuivi" className={styles.label}>
              Type d'accompagnement
            </label>
            <select
              value={inputValues["TypeAccSuivi"]}
              onChange={(e) => changeFn(e)}
              name="TypeAccSuivi"
              id="TypeAccSuivi"
              disabled={!editable}
              className={styles.inputSelect}
            >
              <option value="">---------------------</option>
              <option value="Entretien">Entretien</option>
              <option value="Action Collective">Action Collective</option>
            </select>
            {(inputValues["TypeAccSuivi"] == undefined || inputValues["TypeAccSuivi"] == "")  && pageError == true && (
                <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>
            )}

          </div>
        </div>
        <div className={styles.infosDate}>
          <div className={styles.containerFields}>
            <label htmlFor="HDebAccSuivi" className={styles.label}>
              Heure de début
            </label>
            <input
              type="time"
              id="HDebAccSuivi"
              name="HDebAccSuivi"
              readOnly={!editable}
              value={inputValues["HDebAccSuivi"]}
              onChange={(e) => changeFn(e)}
              className={styles.input}
            />
             {(inputValues["HDebAccSuivi"] == undefined || inputValues["HDebAccSuivi"] == "")  && pageError == true && (
                <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>
            )}
          </div>
          <div className={styles.containerFields}>
            <label htmlFor="HFinAccSuivi" className={styles.label}>
              Heure de fin
            </label>
            <input
              type="time"
              id="HFinAccSuivi"
              name="HFinAccSuivi"
              readOnly={!editable}
              value={inputValues["HFinAccSuivi"]}
              onChange={(e) => changeFn(e)}
              className={styles.input}
            />
            {(inputValues["HFinAccSuivi"] == undefined || inputValues["HFinAccSuivi"] == "")  && pageError == true && (
                <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>
            )}

          </div>
        </div>
        <div className={styles.infosDate}>
          <div className={styles.containerFields}>
            <label htmlFor="SujetAccSuivi" className={styles.label}>
              Sujet abordé
            </label>
            {inputValues["TypeAccSuivi"] == "Entretien" ? (
              <>
                            <select
                value={inputValues["SujetAccSuivi"]}
                onChange={(e) => changeFn(e)}
                name="SujetAccSuivi"
                id="SujetAccSuivi"
                disabled={!editable}
                className={styles.inputSelect}
              >
                <option value="">---------------------</option>
                <option value="Analyse financière">Analyse financière</option>
                <option value="Communication / Marketing">
                  Communication / Marketing
                </option>
                <option value="Comptabilité / Gestion">
                  Comptabilité / Gestion
                </option>
                <option value="Conseils juridique">Conseils juridique</option>
                <option value="Croissance / Développement">
                  Croissance / Développement
                </option>
                <option value="Design">Design</option>
                <option value="Développement international">
                  Développement international
                </option>
                <option value="Financement">Financement</option>
                <option value="Fiscalité">Fiscalité</option>
                <option value="Gestion administrative">
                  Gestion administrative
                </option>
                <option value="Gestion des achats">Gestion des achats</option>
                <option value="Gestion du temps">Gestion du temps</option>
                <option value="Immobilier">Immobilier</option>
                <option value="Design">Design</option>
                <option value="Innovation">Innovation</option>
                <option value="Management RH">Management RH</option>
                <option value="Posture entrepreneuriale">
                  Posture entrepreneuriale
                </option>
                <option value="Relation clients">Relation clients</option>
                <option value="Relations publiques">Relations publiques</option>
                <option value="Stratégie commerciale">
                  Stratégie commerciale
                </option>
                <option value="Stratégie digitale">Stratégie digitale</option>
                {!["", "Champ libre", "Analyse financière", "Communication / Marketing", "Comptabilité / Gestion", "Conseils juridique",
              "Croissance / Développement", "Design", "Développement international", "Financement", "Fiscalité", "Gestion administrative",
              "Gestion des achats", "Gestion du temps", "Immobilier", "Design", "Innovation", "Management RH", "Posture entrepreneuriale",
              "Relation clients", "Relations publiques","Stratégie commerciale"].includes(inputValues["SujetAccSuivi"]) && <option value={inputValues["SujetAccSuivi"]}>{inputValues["SujetAccSuivi"]}</option>}
                <option value="Champ libre">Champ libre</option>
              </select>
                {inputValues["SujetAccSuivi"] == "Champ libre" && (
                  <input
                    type="text"
                    id="champLibre"
                    name="champLibre"
                    value={inputValues["champLibre"]}
                    onChange={(e) => changeFn(e)}
                    className={styles.input}
                    style={{marginLeft: "1rem"}}
                  />
                )}
              </>
            ) : inputValues["TypeAccSuivi"] == "Action Collective" ? (
              <>
                <select
                value={inputValues["SujetAccSuivi"]}
                onChange={(e) => changeFn(e)}
                name="SujetAccSuivi"
                id="SujetAccSuivi"
                disabled={!editable}
                className={styles.inputSelect}
              >
                <option value="">---------------------</option>
                {!["", "Champ libre"].includes(inputValues["SujetAccSuivi"]) && <option value={inputValues["SujetAccSuivi"]}>{inputValues["SujetAccSuivi"]}</option>}
                <option value="Champ libre">Champ libre</option>
              </select>
              {(inputValues["SujetAccSuivi"] == "Champ libre" && editable == true) && (
                <input
                  type="text"
                  id="champLibre"
                  name="champLibre"
                  value={inputValues["champLibre"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                  style={{marginLeft: "1rem"}}
                />
              )}
              </>
            ) : (
              <select
                value={inputValues["SujetAccSuivi"]}
                onChange={(e) => changeFn(e)}
                name="SujetAccSuivi"
                id="SujetAccSuivi"
                disabled={!editable}
                className={styles.inputSelect}
              >
                <option value="">---------------------</option>
              </select>
            )}
             {(inputValues["SujetAccSuivi"] == undefined || inputValues["SujetAccSuivi"] == "")  && pageError == true && (
                <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>
            )}
          </div>
          <div className={styles.containerFields}></div>
        </div>
        <div className={styles.infosDate}>
          <div className={styles.containerTextArea}>
            <label
              htmlFor="ComAccSuivi"
              className={styles.label}
              style={{ marginBottom: "1rem" }}
            >
              Résumé de l'entretien et préconisation
            </label>
            <textarea
              type="text"
              id="ComAccSuivi"
              name="ComAccSuivi"
              readOnly={!editable}
              value={inputValues["ComAccSuivi"]}
              className={styles.input}
              onChange={(e) => changeFn(e)}
              rows="5"
            />
          </div>
        </div>
        <p className={styles.textHistPrix}>FICHIERS</p>
        <div className={styles.table}>
          <table className={styles.tableSearch}>
            <thead>
              <th style={{ width: "5%" }}></th>
              <th style={{ width: "85%" }}></th>
              <th style={{ width: "10%" }}></th>
            </thead>
            <tbody>
              {files.length != 0 &&
                files.map((fichier, idx) => {
                  return (
                    <tr>
                      <td>
                        <img src={fichier[0]} className={styles.logoStatic} />
                      </td>
                      <td>{fichier[2]}</td>
                      <td>
                        <div className={styles.logoContainer}>
                          <img
                            src={downloadBleu}
                            className={styles.logo}
                            onClick={() => downloadFile(fichier[1])}
                          />
                          {editable && (
                            <img
                              src={poubBleu}
                              className={styles.logo}
                              onClick={() => deleteFile(fichier[2])}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {(files.length != 0 && pageDeleteError == true) && (
                <small style={{marginLeft: "1rem"}}>Veuillez supprimer les fichiers avant</small>
            )}
        </div>
        {editable && (
          <div
            className={styles.buttonAddContainer}
            style={{ margin: "3rem 0rem" }}
          >
            <input
          type="file"
          style={{ display: "none" }}
          ref={inputFile}
          onChange={handleFileUpload}
          multiple
        />
            <button
              onClick={() => inputFile.current.click()}
              className={styles.buttonImport}
            >
              <p>Importer</p>
              <img
                src={importerBlanc}
                className={styles.logo}
                style={{ height: "2rem" }}
              />
            </button>
          </div>
        )}
        <div className={styles.buttonContainer} style={{ marginTop: "2rem" }}>
          {editable == false ? (
            <>
              <button
                className={styles.modifierButton}
                onClick={() => openOverlayFn()}
              >
                Retour
              </button>
              <button
                onClick={() => setEditable((prev) => !prev)}
                className={styles.modifierButton}
              >
                Modifier
              </button>
            </>
          ) : (
            <>
              <button
                className={styles.modifierButton}
                onClick={() => checkAndSend(inputValues)}
                disabled={mutationDataUpdate.isPending}
              >
                Enregistrer
              </button>

              <button
                className={styles.modifierButton}
                onClick={() => deleteFn(files)}
              >
                Supprimer
              </button>

              <button
                className={styles.modifierButton}
                onClick={() => openOverlayFn()}
              >
                Retour
              </button>
            </>
          )}
        </div>
        {isLoadingFiles == true && (
          <div className={styles.bounce} style={{ marginBottom: "3rem" }}>
            <BounceLoader color="white" />
          </div>
        )}
        {mutationDataUpdate.isPending == true && (
          <div className={styles.bounce} style={{ marginBottom: "3rem" }}>
            <BounceLoader color="white" />
          </div>
        )}
        {mutationDataDelete.isPending == true && (
          <div className={styles.bounce} style={{ marginBottom: "3rem" }}>
            <BounceLoader color="white" />
          </div>
        )}

          {IsLoadingPostFile == true && (
          <div className={styles.bounce} style={{ marginBottom: "3rem" }}>
            <BounceLoader color="white" />
          </div>
        )}



        {isLoadingDel == true && (
          <div className={styles.bounce}>
            <BounceLoader color="white" />
          </div>
        )}
      </div>
    </div>,
    document.getElementById("overlay")
  );
}
