import ReactDOM from "react-dom";
import styles from "./overlayAddCA.module.css";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { BounceLoader } from "react-spinners";

export default function OverlayAddCA({
  openOverlayFn,
  caToModify,
  cas,
}) {
  console.log(cas, caToModify)
  const { id } = useParams();
  const [newCA, setnewCA] = useState({
    tipmId: id,
    caDat: caToModify["caDat"] ? caToModify["caDat"] : new Date().getFullYear(),
    ca: caToModify["ca"] ? caToModify["ca"] : "",
  });
  const [pageError, setPageError] = useState(false);
  const queryClient = useQueryClient();

  const changeCA = (e) => {
    setnewCA((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const checkAndSend = () => {
    if (
        allYears.includes(newCA["caDat"].toString()) || newCA["caDat"] == "" || newCA["ca"] == ""
    ) {
      setPageError(true);
    } else {
      setPageError(false);
      mutationPostCA.mutate(newCA);
    }
  };


  async function addCA(data) {
    const link = `${process.env.REACT_APP_API_URL}/tiers/ca`
    // const link = `http://localhost:8080/tiers/ca`;
    const response = await fetch(link, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const dataReceived = await response.json();
    return dataReceived;
  }

  const mutationPostCA = useMutation({
    mutationFn: (data) => addCA(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["Tiers", id]);
      openOverlayFn((prev) => !prev);
    },
  });

  async function ModifyCA(data) {
    const link = `${process.env.REACT_APP_API_URL}/tiers/ca`
    // const link = `http://localhost:8080/tiers/ca`;
    const response = await fetch(link, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const dataReceived = await response.json();
    return dataReceived;
  }

  const mutationUpdateCA = useMutation({
    mutationFn: (data) => ModifyCA(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["Tiers", id]);
      openOverlayFn((prev) => !prev);
    },
  });

  const allYears = cas.map((data) => data.caDat);

  console.log(newCA, allYears)


  return ReactDOM.createPortal(
    <div className={styles.overlay} onClick={() => openOverlayFn(false)}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <p className={styles.textOverlayTitle}>
          {Object.keys(caToModify).length != 0 ? "MODIFIER" : "AJOUTER"}{" "}
          CHIFFRE D'AFFAIRES
        </p>
        <div>
            <div className={styles.rowContainer}>
                <div className={styles.fieldContainer}>
                    <label className={styles.label} htmlFor="caDat">
                    Année*
                    </label>
                    <input
                    type="number"
                    id="caDat"
                    name="caDat"
                    value={newCA["caDat"]}
                    readOnly={Object.keys(caToModify).length != 0}
                    onChange={(e) => changeCA(e)}
                    className={styles.input}
                    min="2000"
                    />
                    {newCA["caDat"] == "" && pageError == true && (
                    <small className={styles.small}>Ce champs est requis</small>
                    )}
                    {allYears.includes(newCA["caDat"].toString()) && pageError == true && (
                    <small className={styles.small}>
                        Cette année est déjà présente
                    </small>
                    )}
                </div>
                <div className={styles.fieldContainer}>
                    <label className={styles.label} htmlFor="ca">
                    Chiffre d'affaires*
                    </label>
                    <input
                    type="number"
                    id="ca"
                    name="ca"
                    value={newCA["ca"]}
                    onChange={(e) => changeCA(e)}
                    className={styles.input}
                    min="0"
                    step="0.01"
                    />
                    {newCA["ca"] == "" && pageError == true && (
                    <small className={styles.small}>Ce champs est requis (virgule = .)</small>
                    )}
                </div>
            </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          {Object.keys(caToModify).length == 0 ? (
            <button
              className={styles.buttonValid}
              onClick={() => checkAndSend()}
              disabled={mutationPostCA.isPending}
            >
              Ajouter
            </button>
          ) : (
            <button
              className={styles.buttonValid}
              onClick={() => mutationUpdateCA.mutate(newCA)}
            >
              Modifier
            </button>
          )}
          <button
            className={styles.buttonValid}
            onClick={() => openOverlayFn(false)}
          >
            Retour
          </button>
        </div>
        {mutationPostCA.isPending == true && (
          <div className={styles.bounce}>
            <BounceLoader color="white" />
          </div>
        )}
        {mutationUpdateCA.isPending == true && (
          <div className={styles.bounce}>
            <BounceLoader color="white" />
          </div>
        )}
      </div>
    </div>,
    document.getElementById("overlay")
  );
}
