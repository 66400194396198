import ReactDOM from "react-dom";
import styles from "./OverlayAddEq.module.css";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

export default function OverlayAddEq({
  openOverlayFn,
  addEq,
  eqAlready,
  ugAlready
}) {
  const [newEq, setNewEq] = useState({
    ugId: "",
    ugInt: "",
    eqRef: "",
    eqNat: "",
    rubconvMnt: "",
    rubRef: 'Charge'
  });
  const [pageError, setPageError] = useState(false);

  async function allEquipments() {
    const link = `${process.env.REACT_APP_API_URL}/conv/equipements`
    // const link = "http://localhost:8080/conv/equipements"
    const response = await fetch(link)
    if (!response.ok) {
        throw "il y a un pb"
    }
    const data = await response.json()
    return data
  }

  const {data: equipements = []} = useQuery({
      queryKey:["Equipements"],
      queryFn:() => allEquipments(),
      refetchOnWindowFocus: false
  })


  const changeLocal = (e) => {
    setNewEq((prev) => {
      const newState = JSON.parse(JSON.stringify(prev))
      const eqRef = e.target.value
      newState[e.target.name] = eqRef
      const selectedData = equipements.find(data => data.eqRef == eqRef);
      newState["ugInt"] = selectedData["ugInt"]
      newState["ugId"] = selectedData["ugId"]
      newState["eqNat"] = selectedData["eqNat"]
      newState["rubconvMnt"] = selectedData["eqPrix"]
      return newState
    })
  };

  const checkAndSend = () => {
    if (
      newEq["eqRef"] == ""
    ) {
      setPageError(true);
    } else {
      setPageError(false);
      addEq(newEq);
      openOverlayFn(false);
    }
  };

  return ReactDOM.createPortal(
    <div className={styles.overlay} onClick={() => openOverlayFn(false)}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <p className={styles.textOverlayTitle}>AJOUTER EQUIPEMENT</p>
        <div>
          {equipements.length != 0 ?
          <>
          <div className={styles.fieldContainer}>
            <label className={styles.label} htmlFor="eqRef">
              Intitulé*
            </label>
            <select
              value={newEq["eqRef"]}
              onChange={(e) => changeLocal(e)}
              name="eqRef"
              id="eqRef"
              className={styles.inputSelect}
            >
              <option value="">----------------------</option>
                {equipements.map((data) => {
                  if (ugAlready.includes(data.ugId.toString()) && !eqAlready.includes(data.eqRef.toString())) {
                    return <option value={data.eqRef} key={data.eqRef}>{data.eqRef} - {data.eqNat}</option>
                  }
                })}
            </select>
            {newEq["ugId"] == "" && pageError == true && (
              <small className={styles.small}>Ce champs est requis</small>
            )}
          </div>
          <div className={styles.fieldContainer}>
            <label className={styles.label} htmlFor="rubconvMnt">
              Prix*
            </label>
            <input
              type="number"
              id="rubconvMnt"
              name="rubconvMnt"
              value={newEq["rubconvMnt"]}
              onChange={(e) => changeLocal(e)}
              className={styles.input}
              readOnly={true}
              min="0"
            />
            {newEq["rubconvMnt"] == "" && pageError == true && (
              <small className={styles.small}>Ce champs est requis</small>
            )}
          </div>
          </>:
          <div>
            Il n'y a pas d'équipements pour les locaux sélectionnés
          </div>
          }
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          {equipements.length != 0 && <button className={styles.buttonValid} onClick={() => checkAndSend()}>
            Ajouter
          </button>}
          <button
            className={styles.buttonValid}
            onClick={() => openOverlayFn(false)}
          >
            Retour
          </button>
        </div>
      </div>
    </div>,
    document.getElementById("overlay")
  );
}
