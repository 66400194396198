import ReactDOM from "react-dom";
import styles from "./OverlayAddStatutJurAvenant.module.css";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { BounceLoader } from "react-spinners";

export default function OverlayAddStatutJurAvenant({
  openOverlayFn,
  inputValues
}) {
  const { convId, convVrs = "latest" } = useParams();
  const [prevJur, setNewPrevJur] = useState(inputValues["infos"]["tipmForjur"])
  const [newJur, setNewJur] = useState(inputValues["infos"]["tipmForjur"])
  const [newJurCL, setNewJurCL] = useState("")
  const [pageError, setPageError] = useState(false);
  const queryClient = useQueryClient();

  async function updateConvAv(data) {
    const link = `${process.env.REACT_APP_API_URL}/conv/avenantStatutJur`
    // const link = `http://localhost:8080/conv/avenantStatutJur`;
    const response = await fetch(link, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const dataReceived = await response.json();
    return dataReceived;
  }

  const mutationDataUpdate = useMutation({
    mutationFn: (data) => updateConvAv(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["Convention", convId, convVrs]);
      queryClient.invalidateQueries(["Convention", "Historique", convId]);
      openOverlayFn(false);
    },
  });

  const checkAndSend = () => {
    if (newJur == "" || (newJurCL == "" && newJur == "Champ libre")) {
      setPageError(true)
    } else {
      setPageError(false)
      mutationDataUpdate.mutate({
        prevData: inputValues,
        newJur: newJur != "Champ libre" ? newJur : newJurCL,
        typeAvenant: "Statut Juridique",
        convId: convId      
      });
    }
  };

  return ReactDOM.createPortal(
    <div className={styles.overlay} onClick={() => openOverlayFn(false)}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <p className={styles.textOverlayTitle}>AJOUTER AVENANT STATUT JURIDIQUE</p>
          <p>Attention, cette action va modifier la fiche Synthèse Tiers de la PM concernée.</p>
          <div className={styles.fieldContainer}>
            <label className={styles.label} htmlFor="tipmRso">
              Nouveau Statut Juridique*
            </label>
            <select
                      value={newJur}
                      onChange={(e) => setNewJur(e.target.value)}
                      name="tipmForjur"
                      id="tipmForjur"
                      className={styles.inputSelect}
                    >
                      <option value="">----------------------</option>
                      <option value="EI">EI</option>
                      <option value="EI micro">EI micro</option>
                      <option value="Association">Association</option>
                      <option value="EURL">EURL</option>
                      <option value="SARL">SARL</option>
                      <option value="SASU">SASU</option>
                      <option value="SAS">SAS</option>
                      <option value="SA">SA</option>
                      <option value="SNC">SNC</option>
                      <option value="SCS">SCS</option>
                      <option value="SCA">SCA</option>
                      {!["EI", "EI micro", "Association", "EURL", "SARL", "SASU"
                    ,"SAS", "SA", "SNC", "SCS", "SCA"].includes(newJur) && newJur && <option value={newJur}>{newJur}</option>}
                      <option value="Champ libre">Champ libre</option>
                    </select>
                    {newJur == "Champ libre" && (
                      <input
                        type="text"
                        id="champLibre"
                        name="champLibre"
                        value={inputValues["champLibre"]}
                        onChange={(e) => setNewJurCL(e.target.value)}
                        className={styles.input}
                        // style={{marginLeft: "1rem"}}
                      />
                    )}
              {(newJurCL == "" && newJur == "Champ libre" &&
                pageError == true) && (
                  <small style={{ marginLeft: "1rem" }}>
                    Ce champs est requis
                  </small>
                )}
                {(newJur == "" &&
                pageError == true) && (
                  <small style={{ marginLeft: "1rem" }}>
                    Ce champs est requis
                  </small>
                )}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <button className={styles.buttonValid} onClick={() => checkAndSend()} disabled={mutationDataUpdate.isPending}>
            Modifier
          </button>
          <button
            className={styles.buttonValid}
            onClick={() => openOverlayFn(false)}
          >
            Retour
          </button>
        </div>
        {mutationDataUpdate.isPending == true && (
          <div className={styles.bounce}>
            <BounceLoader color="black" />
          </div>
        )}
      </div>
    </div>,
    document.getElementById("overlay")
  );
}
