import styles from "./SortieVisu.module.css";

export default function SortieVisu({
  sortie
}) {

  return (
    <>
    { sortie.length != 0 && <div className={styles.table}>
        <table className={styles.tableSearch}>
          <thead>
            <tr>
            <th>
              <b>Date</b>
            </th>
            <th>
              <b>Motif</b>
            </th>
            <th>
             <b>Nouvelle Implantation</b> 
            </th>
            </tr>         
          </thead>
          <tbody>
              <tr>
                <td>{sortie[0]["sorDat"]?.split('-').reverse().join('-')}</td>
                <td>{sortie[0]["Motif"]}</td>
                <td>{sortie[0]["nouvImp"]}</td>
              </tr>
          </tbody>
        </table>
    </div>
} 
    </>
  )
}
