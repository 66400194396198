import download from "../../assets/download.png"
import styles from "./DownloadButton.module.css"

export default function DownloadButton ({ text, onClick = "" }) {

    return (
            <div className={styles.container}>
                {
                onClick != "" && <button className={styles.button} onClick={onClick}>
                <p className={styles.text}>{text}</p>             
                {text.includes("Télécharger") && <img src={download} className={styles.rightArrow} />}
                </button>
                }
            </div>
        )
}