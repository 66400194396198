import styles from "./SuiviAcc.module.css";
import { useContext, useEffect, useState } from "react";
import Pagination from "../../Pagination/Pagination";
import informationLogo from "../../../assets/informationLogo.png";
import filtre from "../../../assets/filtre.png";
import downloadGrey from "../../../assets/downloadGrey.png";
import OverlayInfoAcc from "../overlayInfoAcc/OverlayInfoAcc";
import { useParams } from "react-router-dom";
import { HeaderContext } from "../../../App";
import OverlayFilterSuivi from "../overlayFilterSuivi/overlayFilterSuivi";


export default function SuiviAcc({ suivi }) {
  const { id } = useParams()
  const [overlayFilter, setOverlayFilter] = useState("")
  const [numberElementsMax, setNumberElementsMax] = useState(10);
  const [prevNum, setPrevNum] = useState(0);
  const [nextNum, setNextNum] = useState(10);
  const [seeInfo, setSeeInfo] = useState(false);
  const [infos, setInfos] = useState("");
  const { filterFieldSuivi, setFilterFieldsSuivi } = useContext(HeaderContext);
  
  const sujets = ["Analyse financière", "Communication / Marketing", "Comptabilité / Gestion", "Conseils juridique",
  "Croissance / Développement", "Design", "Développement international", "Financement", "Fiscalité", "Gestion administrative",
  "Gestion des achats", "Gestion du temps", "Immobilier", "Design", "Innovation", "Management RH", "Posture entrepreneuriale",
  "Relation clients", "Relations publiques","Stratégie commerciale"]
  
  const filteredSuivi = suivi ? 
      suivi.filter(item => {
        const { sujet, dateDebut, dateFin } = filterFieldSuivi

        const date = new Date(item.DateAccSuivi)
        const startDate = dateDebut ? new Date(dateDebut) : new Date("1900-01-01")
        const endDate = dateFin ? new Date(dateFin) : new Date("9999-12-31")

        const sujetMatch = (sujet != "" && item.SujetAccSuivi.includes(sujet)) ? true : (sujet == "Autres" && !sujets.includes(item.SujetAccSuivi)) ? true : (sujet == "") ? true : false;
        const dateMatch = date >= startDate && date <= endDate

        return sujetMatch && dateMatch;
      }) : []

  const nextPage = () => {
    if (numberElementsMax >= filteredSuivi.length) {
      // setNextNum((prev) => prev + 10)
      setNumberElementsMax(10);
    } else {
      setNumberElementsMax((prev) => prev + 10);
    }
  };

  useEffect(() => {
    if (filteredSuivi.length == 0) {
      setNextNum(0);
    } else if (numberElementsMax > filteredSuivi.length) {
      setNextNum(filteredSuivi.length);
    } else {
      setNextNum(numberElementsMax);
    }
    if (numberElementsMax > filteredSuivi.length) {
      const numsCandidate = [
        filteredSuivi.length - 1,
        filteredSuivi.length - 2,
        filteredSuivi.length - 3,
        filteredSuivi.length - 4,
        filteredSuivi.length - 5,
        filteredSuivi.length - 6,
        filteredSuivi.length - 7,
        filteredSuivi.length - 8,
        filteredSuivi.length - 9,
      ];
      let smallerNumber;
      for (let i of numsCandidate) {
        if (i % 10 === 0) {
          smallerNumber = i + 1;
          break;
        }
      }
      if (smallerNumber == undefined) {
        setPrevNum(0);
      } else {
        setPrevNum(smallerNumber);
      }
    } else {
      if (filteredSuivi.length == 0) {
        setPrevNum(0);
      } else {
        setPrevNum(numberElementsMax - 9);
      }
    }
  }, [numberElementsMax, filteredSuivi]);

  const prevPage = () => {
    if (numberElementsMax == 10) {
      if (filteredSuivi.length % 10 == 0) {
        setNumberElementsMax((prev) => filteredSuivi.length);
      } else {
        const numsCandidate = [
          filteredSuivi.length + 1,
          filteredSuivi.length + 2,
          filteredSuivi.length + 3,
          filteredSuivi.length + 4,
          filteredSuivi.length + 5,
          filteredSuivi.length + 6,
          filteredSuivi.length + 7,
          filteredSuivi.length + 8,
          filteredSuivi.length + 9,
        ];
        let greatestNumber;
        for (let i of numsCandidate) {
          if (i % 10 === 0) {
            greatestNumber = i;
            break;
          }
        }
        setNumberElementsMax(greatestNumber);
      }
    } else {
      setNumberElementsMax((prev) => prev - 10);
    }
    // }
  };

  const openOverlayFn = (infos) => {
    if (seeInfo == false) {
      setInfos(infos);
    }
    setSeeInfo(!seeInfo);
  };

  function calculateTotalTime(dataArray) {
    let totalMinutes = 0;

    dataArray.forEach(item => {
        const startTime = item.HDebAccSuivi.split(':');
        const endTime = item.HFinAccSuivi.split(':');
        const startMinutes = parseInt(startTime[0]) * 60 + parseInt(startTime[1]);
        const endMinutes = parseInt(endTime[0]) * 60 + parseInt(endTime[1]);
        totalMinutes += (endMinutes - startMinutes);
    });

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}


  const downloadSearch = async () => {
    const link = `${process.env.REACT_APP_API_URL}/tiers/downloadSuivi/${id}`
    // const link = `http://localhost:8080/tiers/downloadSuivi/${id}`
    window.open(link, '_blank')
  }

  return (
    <>
      {overlayFilter == "filter" && <OverlayFilterSuivi status={overlayFilter} setFilter={setOverlayFilter} />} 
      <div className={styles.header}>
        <p className={styles.textHeader}>Total nombre d'entretien: {filteredSuivi.length}</p>
        <p className={styles.textHeader}>Total nombre d'heure(s): {calculateTotalTime(filteredSuivi)}</p>
        <img
          src={filtre}
          alt="filtre"
          className={styles.infoLogo}
          onClick={() => setOverlayFilter("filter")}
        />
        <img
          src={downloadGrey}
          alt="download"
          className={styles.infoLogo}
          onClick={() => downloadSearch()}
        />
      </div>
      <div className={styles.table}>
        {seeInfo && (
          <OverlayInfoAcc infos={infos} openOverlayFn={openOverlayFn} />
        )}
        <table className={styles.tableSearch}>
          <thead>
            <th>Date</th>
            <th>Type d'accompagnement</th>
            <th>Sujet abordé</th>
            <th style={{ width: "5rem" }}></th>
          </thead>
          <tbody>
            {filteredSuivi &&
              filteredSuivi
                .slice(numberElementsMax - 10, numberElementsMax)
                .map((data) => {
                  return (
                    <tr>
                      <td>{data["DateAccSuivi"]?.split('-').reverse().join('-')}</td>
                      <td>{data["TypeAccSuivi"]}</td>
                      <td>{data["SujetAccSuivi"]}</td>
                      <td onClick={() => openOverlayFn(data)}>
                        <img
                          src={informationLogo}
                          className={styles.infoLogo}
                        />
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
        <div>
          <Pagination nextPage={nextPage} prevPage={prevPage} />
          <p className={styles.nums}>
            {prevNum} à {nextNum} - Nombre Total d'éléments : {filteredSuivi.length}{" "}
          </p>
        </div>
      </div>
    </>
  );
}
