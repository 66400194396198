import styles from "./PMVisuNew.module.css";
import { useQuery } from "@tanstack/react-query";

export default function PMVisuNew({
  changeFn,
  editable,
  inputValues,
  pageError,
}) {

  async function getCodeAPE() {
    const link = `${process.env.REACT_APP_API_URL}/tiers/codeAPE`;
    // const link = "http://localhost:8080/tiers/codeAPE";
    const response = await fetch(link);
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const data = await response.json();
    return data;
  }

  const { data: codeAPEList = [] } = useQuery({
    queryKey: ["codeAPE"],
    queryFn: () => getCodeAPE(),
    staleTime:Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false
  });

  
  const codeAPEListDisplayed = codeAPEList
    ? codeAPEList
        .map((data) => {
          if (data["Code APE"] == inputValues["tipmCodape"]) {
            return `${data["Code APE"]} - ${data[" Intitulés de la NAF"]}`;
          }
        })
        .filter((item) => item != undefined)
    : "";

  return (
    <div className={styles.table}>
      <table className={styles.tableSearch}>
        <thead>
          <tr style={{height:"0rem"}}>
          <th style={{ width: "20%" }}></th>
          <th style={{ width: "30%" }}></th>
          <th style={{ width: "20%" }}></th>
          <th style={{ width: "30%" }}></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>Dénomination entreprise{editable == true && "*"}</b>
            </td>
            <td>
              {editable ? (
                <input
                  type="text"
                  id="tipmRso"
                  name="tipmRso"
                  value={inputValues["tipmRso"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
              ) : (
                <div className={styles.inputValue}>
                  {inputValues["tipmRso"]}
                </div>
              )}
              {(inputValues["tipmRso"] == undefined ||
                inputValues["tipmRso"] == "") &&
                pageError == true && (
                  <small style={{ marginLeft: "1rem" }}>
                    Ce champs est requis
                  </small>
                )}
            </td>
            <td>
              <b>Sigle</b>
            </td>
            <td>
              {editable ? (
                <input
                  type="text"
                  id="tipmSigle"
                  name="tipmSigle"
                  value={inputValues["tipmSigle"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
              ) : (
                <div className={styles.inputValue}>
                  {inputValues["tipmSigle"]}
                </div>
              )}
              {(inputValues["tipmSigle"] == undefined ||
                inputValues["tipmSigle"] == "") &&
                pageError == true && (
                  <small style={{ marginLeft: "1rem" }}>
                    Ce champs est requis
                  </small>
                )}
            </td>
          </tr>
          <tr>
            <td>
              <b>Statut juridique{editable == true && "*"}</b>
            </td>
            <td>
              {editable ? (
                
                    <>
                    <select
                      value={inputValues["tipmForjur"] ? inputValues["tipmForjur"] : ""}
                      onChange={(e) => changeFn(e)}
                      name="tipmForjur"
                      id="tipmForjur"
                      className={styles.inputSelect}
                    >
                      <option value="">----------------------</option>
                      <option value="EI">EI</option>
                      <option value="EI micro">EI micro</option>
                      <option value="Association">Association</option>
                      <option value="EURL">EURL</option>
                      <option value="SARL">SARL</option>
                      <option value="SASU">SASU</option>
                      <option value="SAS">SAS</option>
                      <option value="SA">SA</option>
                      <option value="SNC">SNC</option>
                      <option value="SCS">SCS</option>
                      <option value="SCA">SCA</option>
                      {!["EI", "EI micro", "Association", "EURL", "SARL", "SASU"
                    ,"SAS", "SA", "SNC", "SCS", "SCA"].includes(inputValues["tipmForjur"]) && inputValues["tipmForjur"] && <option value={inputValues["tipmForjur"]}>{inputValues["tipmForjur"]}</option>}
                      <option value="Champ libre">Champ libre</option>
                    </select>
                    {inputValues["tipmForjur"] == "Champ libre" && (
                      <input
                        type="text"
                        id="champLibre"
                        name="champLibre"
                        value={inputValues["champLibre"]}
                        onChange={(e) => changeFn(e)}
                        className={styles.input}
                        style={{marginLeft: "1rem"}}
                      />
                    )}
                    </>
              ) : (
                <div className={styles.inputValue}>
                  {inputValues["tipmForjur"]}
                </div>
              )}
              {(inputValues["tipmForjur"] == undefined ||
                inputValues["tipmForjur"] == "") &&
                pageError == true && (
                  <small style={{ marginLeft: "1rem" }}>
                    Ce champs est requis
                  </small>
                )}
            </td>
            <td>
              <b>Activité(s){editable == true && "*"}</b>
            </td>
            <td>
              {editable ? (
                <input
                  type="text"
                  id="tipmAct"
                  name="tipmAct"
                  value={inputValues["tipmAct"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
              ) : (
                <div className={styles.inputValue}>
                  {inputValues["tipmAct"]}
                </div>
              )}
              {(inputValues["tipmAct"] == undefined ||
                inputValues["tipmAct"] == "") &&
                pageError == true && (
                  <small style={{ marginLeft: "1rem" }}>
                    Ce champs est requis
                  </small>
                )}
            </td>
          </tr>
          <tr>
            <td>
              <b>Date de création{editable == true && "*"}</b>
            </td>
            <td>
              {editable ? (
                <input
                  type="date"
                  id="tipmDatcre"
                  name="tipmDatcre"
                  value={inputValues["tipmDatcre"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
              ) : (
                <div className={styles.inputValue}>
                  {inputValues["tipmDatcre"]?.split('-').reverse().join('-')}
                </div>
              )}
              {(inputValues["tipmDatcre"] == undefined ||
                inputValues["tipmDatcre"] == "") &&
                pageError == true && (
                  <small style={{ marginLeft: "1rem" }}>
                    Ce champs est requis
                  </small>
                )}
            </td>
            <td>
              <b>SIRET{editable == true && "*"}</b>
            </td>
            <td>
              {editable ? (
                <input
                  type="email"
                  id="tipmNumsir"
                  name="tipmNumsir"
                  value={inputValues["tipmNumsir"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
              ) : (
                <div className={styles.inputValue}>
                  {inputValues["tipmNumsir"]}
                </div>
              )}
              {(inputValues["tipmNumsir"] == undefined ||
                inputValues["tipmNumsir"] == "") &&
                pageError == true && (
                  <small style={{ marginLeft: "1rem" }}>
                    Ce champs est requis
                  </small>
                )}
            </td>
          </tr>
          <tr>
            <td>
              <b>Activité Principale Exercée (APE){editable == true && "*"}</b>
            </td>
            <td>
              {editable ? (
                <select
                  onChange={(e) => changeFn(e)}
                  value={inputValues["tipmCodape"]}
                  id="tipmCodape"
                  name="tipmCodape"
                  className={styles.input}
                >
                  <option value="">--------</option>
                  {codeAPEList.map((data) => {
                    return (
                      <option key={data["Code APE"]} value={data["Code APE"]}>
                        {data["Code APE"]} - {data[" Intitulés de la NAF"]}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <div className={styles.inputValue}>
                  {codeAPEListDisplayed}
                </div>
              )}

              {(inputValues["tipmCodape"] == undefined ||
                inputValues["tipmCodape"] == "") &&
                pageError == true && (
                  <small style={{ marginLeft: "1rem" }}>
                    Ce champs est requis
                  </small>
                )}
            </td>
            <td>
              <b>Adresse</b>
            </td>
            <td>
              <div className={styles.inputValue}>
                <div className={styles.inputValue}>
                    {(inputValues?.tipmnumVoie ? inputValues["tipmnumVoie"] : "")  + " " + (inputValues?.tipmtypVoie ? inputValues["tipmtypVoie"] : "") + " " + (inputValues?.tipmintVoie ? inputValues["tipmintVoie"] : "") + " " + (inputValues?.tipmcomplvoie ? inputValues["tipmcomplvoie"] : "")}
                </div>
              </div>
            </td>
          </tr>
          {editable && (
            <>
              <tr>
                <td>
                  <b>N° de voie{editable == true && "*"}</b>
                </td>
                <td>
                  <input
                    type="text"
                    id="tipmnumVoie"
                    name="tipmnumVoie"
                    value={inputValues["tipmnumVoie"]}
                    onChange={(e) => changeFn(e)}
                    className={styles.input}
                  />
                  {(inputValues["tipmnumVoie"] == undefined ||
                    inputValues["tipmnumVoie"] == "") &&
                    pageError == true && (
                      <small style={{ marginLeft: "1rem" }}>
                        Ce champs est requis
                      </small>
                    )}
                </td>
                <td>
                  <b>Type de voie{editable == true && "*"}</b>
                </td>
                <td>
                  <input
                    type="text"
                    id="tipmtypVoie"
                    name="tipmtypVoie"
                    value={inputValues["tipmtypVoie"]}
                    onChange={(e) => changeFn(e)}
                    className={styles.input}
                  />
                  {(inputValues["tipmtypVoie"] == undefined ||
                    inputValues["tipmtypVoie"] == "") &&
                    pageError == true && (
                      <small style={{ marginLeft: "1rem" }}>
                        Ce champs est requis
                      </small>
                    )}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Intitulé de voie{editable == true && "*"}</b>
                </td>
                <td>
                  <input
                    type="text"
                    id="tipmintVoie"
                    name="tipmintVoie"
                    value={inputValues["tipmintVoie"]}
                    onChange={(e) => changeFn(e)}
                    className={styles.input}
                  />
                  {(inputValues["tipmintVoie"] == undefined ||
                    inputValues["tipmintVoie"] == "") &&
                    pageError == true && (
                      <small style={{ marginLeft: "1rem" }}>
                        Ce champs est requis
                      </small>
                    )}
                </td>
                <td>
                  <b>Complément de voie</b>
                </td>
                <td>
                  <input
                    type="text"
                    id="tipmcomplvoie"
                    name="tipmcomplvoie"
                    value={inputValues["tipmcomplvoie"]}
                    onChange={(e) => changeFn(e)}
                    className={styles.input}
                  />
                </td>
              </tr>
            </>
          )}
          <tr>
            <td>
              <b>Code postal{editable == true && "*"}</b>
            </td>
            <td>
              {editable ? (
                <input
                  type="text"
                  id="tipmcodPos"
                  name="tipmcodPos"
                  value={inputValues["tipmcodPos"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
              ) : (
                <div className={styles.inputValue}>
                  {inputValues["tipmcodPos"]}
                </div>
              )}
              {(inputValues["tipmcodPos"] == undefined ||
                inputValues["tipmcodPos"] == "") &&
                pageError == true && (
                  <small style={{ marginLeft: "1rem" }}>
                    Ce champs est requis
                  </small>
                )}
            </td>
            <td>
              <b>Commune{editable == true && "*"}</b>
            </td>
            <td>
              {editable ? (
                <input
                  type="text"
                  id="tipmcomun"
                  name="tipmcomun"
                  value={inputValues["tipmcomun"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                  autoCapitalize="characters"
                />
              ) : (
                <div className={styles.inputValue}>
                  {inputValues["tipmcomun"]}
                </div>
              )}
              {(inputValues["tipmcomun"] == undefined ||
                inputValues["tipmcomun"] == "") &&
                pageError == true && (
                  <small style={{ marginLeft: "1rem" }}>
                    Ce champs est requis
                  </small>
                )}
            </td>
          </tr>
          <tr>
            <td>
              <b>QPV</b>
            </td>
            <td>
              {editable ? (
                <select
                  value={inputValues["tipmQpv"]}
                  onChange={(e) => changeFn(e)}
                  name="tipmQpv"
                  id="tipmQpv"
                  className={styles.inputSelect}
                >
                  <option value="">----------------------</option>
                  <option value="Oui">Oui</option>
                  <option value="Non">Non</option>
                </select>
              ) : (
                <div className={styles.inputValue}>
                  {inputValues["tipmQpv"]}
                </div>
              )}
            </td>
            <td>
              <b>ZFU</b>
            </td>
            <td>
              {editable ? (
                <select
                  value={inputValues["tipmZfu"]}
                  onChange={(e) => changeFn(e)}
                  name="tipmZfu"
                  id="tipmZfu"
                  className={styles.inputSelect}
                >
                  <option value="">----------------------</option>
                  <option value="Oui">Oui</option>
                  <option value="Non">Non</option>
                </select>
              ) : (
                <div className={styles.inputValue}>
                  {inputValues["tipmZfu"]}
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <b>Courriel{editable == true && "*"}</b>
            </td>
            <td>
              {editable ? (
                <input
                type="text"
                id="tipmEmail"
                name="tipmEmail"
                value={inputValues["tipmEmail"]}
                onChange={(e) => changeFn(e)}
                className={styles.input}
              />
              ) : (
                <div className={styles.inputValue}>
                  {inputValues["tipmEmail"]}
                </div>
              )}
              {(inputValues["tipmEmail"] == undefined || inputValues["tipmEmail"] == "")  && pageError == true && (
                <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>
              )}
            </td>
            <td>
              <b>Téléphone{editable == true && "*"}</b>
            </td>
            <td>
              {editable ? (
                <input
                type="text"
                id="tipmTelpor"
                name="tipmTelpor"
                value={inputValues["tipmTelpor"]}
                onChange={(e) => changeFn(e)}
                className={styles.input}
                />)
                :
                (
                  <div className={styles.inputValue}>{inputValues["tipmTelpor"]}</div>
                )
                }
            {(inputValues["tipmTelpor"] == undefined || inputValues["tipmTelpor"] == "")  && pageError == true && (
                <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <b>Montant du capital</b>
            </td>
            <td>
              {editable ? (<input
                type="number"
                id="tipmMntcap"
                name="tipmMntcap"
                value={inputValues["tipmMntcap"]}
                onChange={(e) => changeFn(e)}
                className={styles.input}
                min="0"
                />)
                :
                (
                  <div className={styles.inputValue}>{inputValues["tipmMntcap"]}</div>
                )
                }
            </td>
            <td>
              <b>Date de fin d'exercice</b>
            </td>
            <td>
              {editable ? (
                <input
                type="text"
                id="tipmDatFe"
                name="tipmDatFe"
                value={inputValues["tipmDatFe"]}
                onChange={(e) => changeFn(e)}
                className={styles.input}
              />
              ) : (
                <div className={styles.inputValue}>
                  {inputValues["tipmDatFe"]}
                </div>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
