import styles from "./CreationUG.module.css"
import Footer from "../../components/Footer"
import ImportButton from "../../components/ImportButton/ImportButton"
import DownloadButton from "../../components/downloadButton/DownloadButton"
import { useQuery } from "@tanstack/react-query";

export default function CreationUG () {

    const downloadTemplate = async () => {
        const link = `${process.env.REACT_APP_API_URL}/ug/downloadTemplate`
        // const link = 'http://localhost:8080/ug/downloadTemplate'
        const response = await fetch(link)
        if (!response.ok) {
            throw "il y a un pb"
        }
        const data = await response.json()
        window.open(data["downloadLink"], '_blank')   
    }

    async function lastValueUGID() {
        const link = `${process.env.REACT_APP_API_URL}/ug/lastValueUgId`
        // const link = "http://localhost:8080/ug/lastValueUgId"
        const response = await fetch(link)
        if (!response.ok) {
            throw "il y a un pb"
        }
        const data = await response.json()
        return data
    }

    const {data: lastValueID = {}} = useQuery({
        queryKey:["ugId", "last"],
        queryFn:() => lastValueUGID(),
        refetchOnWindowFocus: false
    })

    return (
        <div className={styles.textButContainer}>
            <p className={styles.text}>Veuillez, s'il vous plait, suivre la trame du template pour ajouter de nouveaux UGs. <br />  <br />
            Les champs obligatoires sont IDENTIFIANT UG (commencer <b>obligatoirement</b> avec l'identifiant <b>{lastValueID["ugId"] == undefined ? 1 : lastValueID["ugId"] + 1}</b>, et c'est obligatoire seulement pour la feuille UG LOCAUX), REFERENCE(<b>Exemple:</b> CXXX-EXX-BXX pour un bureau, etc. (voir template pour comprendre la logique)), INTITULE (vous êtes libre), NATURE (vous êtes libre) et DATE ENTREE.
            <br />  <br />
            Si vous voulez ajouter des équipements, les champs suivants sont obligatoires : IDENTIFIANT UG, NATURE EQUIPEMENT, REFERENCE EQUIPEMENT et DATE ENTREE.
            </p>
            <div className={styles.buttonsContainer}>
                <ImportButton text="Importer" from="Patrimoine" />
                <DownloadButton text="Télécharger le template" onClick={downloadTemplate} />
            </div>
        </div>
    )
}