import styles from "./CreationUGSeul.module.css";
import countryList from "react-select-country-list";
import { useMemo, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

export default function CreationUGSeul() {
  const [inputValues, setInputValues] = useState({"ugNat": "", "ugInt": "", "ugEtag": "", "ugRef": "", "ugSurf" : "", "pays": "France"})
  const [showMessage, setShowMessage] = useState(false)
  const [pageError, setPageError] = useState(false)
  const navigate = useNavigate()

  const options = useMemo(() => countryList().getData(), []);

  const changeFnLocal = (e) => {
    const { name, value, checked, type } = e.target;
    setInputValues((prev) => {
        const newState = JSON.parse(JSON.stringify(prev));

        if (name == "ugInt" || name == "ugRef" || name == "typVoie" || name == "intVoie" || name == "complvoie" || name == "comun") {
            newState[e.target.name] = value.toUpperCase()
        } else {
            newState[e.target.name] = value
        }
        return {...newState}
        
    });
  }

  const checkAndSend = (inputValues) => {
    if (inputValues["ugNat"] == "" || inputValues["ugInt"] == "" || inputValues["ugEtag"] == "" || inputValues["ugRef"] == ""
    || inputValues["ugSurf"] == "") {
    setPageError(true)
  } else {
    setPageError(false)
    mutationDataPost.mutate(inputValues)
    }
  }

  async function PostUG(data) {
    const link = `${process.env.REACT_APP_API_URL}/ug/createLocal`;
    // const link = `http://localhost:8080/ug/createLocal`
    const response = await fetch(link, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    const dataReceived = await response.json()
    if (!response.ok) {
        setShowMessage(true)
        setTimeout(() => {
        setShowMessage(false);
            }, 5000)    
        throw dataReceived;
    }
    return dataReceived;
  }

  const mutationDataPost = useMutation({
    mutationFn: (data) => PostUG(data),
    onSuccess: (data) => {
      navigate(`/pageConnecte/recherchePatrimoine/patrimoine/${data.ugId}`)
    },
  });



  return (
    <div className={styles.container}>
        <p className={styles.textTitle}>CRÉATION D'UN LOCAL</p>
        <div className={styles.table}>
        <table className={styles.tableSearch}>
            <thead>
                <tr>
                <th style={{ width: "30%" }}></th>
                <th style={{ width: "20%" }}></th>
                <th style={{ width: "30%" }}></th>
                <th style={{ width: "20%" }}></th>
                </tr>
            </thead>
            <tbody>
            <tr>
                <td>
                <b>Nature</b>
                </td>
                <td>
                    <select
                    value={inputValues["ugNat"]}
                    onChange={(e) => changeFnLocal(e)}
                    name="ugNat"
                    id="ugNat"
                    className={styles.inputSelect}
                    >
                            {/* <option value="BATIMENT">
                            Bâtiment
                            </option> */}
                            <option value="">
                            ---------------------
                            </option>
                            <option value="ETAGE">
                            Étage
                            </option>
                            <option value="BUREAU">
                            Bureau
                            </option>
                            <option value="COWORKING">
                            Coworking
                            </option>
                            <option value="TOILETTES">
                            Toilette
                            </option>
                            <option value="SALLE">
                            Salle
                            </option>
                            <option value="ESPACE">
                            Espace
                            </option>
                            <option value="LOCAL">
                            Local
                            </option>
                    </select>
                {(inputValues["ugNat"] == undefined || inputValues["ugNat"] == "")  && pageError == true && (
                    <small style={{marginLeft: "1rem", color:"red"}}>Ce champs est requis</small>
                )}
                </td>
                <td>
                <b>Intitulé</b>
                </td>
                <td>
                    <input
                    type="text"
                    id="ugInt"
                    name="ugInt"
                    value={inputValues["ugInt"]}
                    onChange={(e) => changeFnLocal(e)}
                    className={styles.input}
                    />
                {(inputValues["ugInt"] == undefined ||
                    inputValues["ugInt"] == "") &&
                    pageError == true && <small style={{marginLeft: "1rem", color:"red"}}>Ce champs est requis</small>}
                </td>
            </tr>
            <tr>
                <td>
                <b>Bâtiment</b>
                </td>
                <td>Espace Carco</td>
                <td>
                <b>Étage</b>
                </td>
                <td>
                    <input
                    type="number"
                    id="ugEtag"
                    name="ugEtag"
                    value={inputValues["ugEtag"]}
                    min="0"
                    onChange={(e) => changeFnLocal(e)}
                    className={styles.input}
                    />
                    {(inputValues["ugEtag"] == undefined ||
                    inputValues["ugEtag"] == "") &&
                    pageError == true && <small style={{marginLeft: "1rem", color:"red"}}>Ce champs est requis</small>}
                </td>
            </tr>
            <tr>
                <td>
                <b>Référence (Ex: C001-E04-B401, C001: Carco 1, E04: Étage 4, B401: Bureau 401  )</b>
                </td>
                <td>
                    <input
                    type="text"
                    id="ugRef"
                    name="ugRef"
                    value={inputValues["ugRef"]}
                    onChange={(e) => changeFnLocal(e)}
                    className={styles.input}
                    />
                    {(inputValues["ugRef"] == undefined ||
                    inputValues["ugRef"] == "") &&
                    pageError == true && <small style={{marginLeft: "1rem", color:"red"}}>Ce champs est requis</small>}
                </td>
                <td>
                <b>Surface (m²)</b>
                </td>
                <td>
                    <input
                    type="number"
                    id="ugSurf"
                    name="ugSurf"
                    value={inputValues["ugSurf"]}
                    onChange={(e) => changeFnLocal(e)}
                    className={styles.input}
                    min="0"
                    />
                    {(inputValues["ugSurf"] == undefined ||
                    inputValues["ugSurf"] == "") &&
                    pageError == true && <small style={{marginLeft: "1rem", color:"red"}}>Ce champs est requis</small>}

                </td>
            </tr>
            <tr>
                <td>
                <b>Date de construction</b>
                </td>
                <td>
                    <input
                    type="date"
                    id="ugDatcons"
                    name="ugDatcons"
                    value={inputValues["ugDatcons"]}
                    onChange={(e) => changeFnLocal(e)}
                    className={styles.input}
                    />
                </td>
                <td>
                <b>Date d'entrée</b>
                </td>
                <td>
                    <input
                    type="date"
                    id="ugDatent"
                    name="ugDatent"
                    value={inputValues["ugDatent"]}
                    onChange={(e) => changeFnLocal(e)}
                    className={styles.input}
                    />
                </td>
            </tr>
            <tr>
                <td>
                <b>N° Voie</b>
                </td>
                <td>
                    <input
                    type="text"
                    id="numVoie"
                    name="numVoie"
                    value={inputValues["numVoie"]}
                    onChange={(e) => changeFnLocal(e)}
                    className={styles.input}
                    />
                </td>
                <td>
                <b>Type Voie</b>
                </td>
                <td>
                    <input
                    type="text"
                    id="typVoie"
                    name="typVoie"
                    value={inputValues["typVoie"]}
                    onChange={(e) => changeFnLocal(e)}
                    className={styles.input}
                    />
                </td>
            </tr>
            <tr>
                <td>
                <b>Intitulé voie</b>
                </td>
                <td>
                    <input
                    type="text"
                    id="intVoie"
                    name="intVoie"
                    value={inputValues["intVoie"]}
                    onChange={(e) => changeFnLocal(e)}
                    className={styles.input}
                    />
                </td>
                <td>
                <b>Complémement voie</b>
                </td>
                <td>
                    <input
                    type="text"
                    id="complvoie"
                    name="complvoie"
                    value={inputValues["complvoie"]}
                    onChange={(e) => changeFnLocal(e)}
                    className={styles.input}
                    />
                </td>
            </tr>
            <tr>
                <td>
                <b>Code postal</b>
                </td>
                <td>
                    <input
                    type="text"
                    id="codPos"
                    name="codPos"
                    value={inputValues["codPos"]}
                    onChange={(e) => changeFnLocal(e)}
                    className={styles.input}
                    />
                </td>
                <td>
                <b>Commune</b>
                </td>
                <td>
               <input
                    type="text"
                    id="comun"
                    name="comun"
                    value={inputValues["comun"]}
                    onChange={(e) => changeFnLocal(e)}
                    className={styles.input}
                    />
                </td>
            </tr>
            <tr>
                <td>
                <b>Cedex</b>
                </td>
                <td>
                    <input
                    type="text"
                    id="cedex"
                    name="cedex"
                    value={inputValues["cedex"]}
                    onChange={(e) => changeFnLocal(e)}
                    className={styles.input}
                    />
                </td>
                <td>
                <b>Pays</b>
                </td>
                <td>
                    <select
                    onChange={(e) => changeFnLocal(e)}
                    value={inputValues["pays"]}
                    id="pays"
                    name="pays"
                    className={styles.inputSelect}
                    >
                    {options.map((option) => {
                        if (option.label != "France") {
                        return (
                            <option key={option.value} value={option.label}>
                            {option.label}
                            </option>
                        );
                        } else {
                        return (
                            <option
                            key={option.value}
                            value={option.label}
                            selected
                            >
                            {option.label}
                            </option>
                        );
                        }
                    })}
                    </select>
                </td>
            </tr>
            </tbody>
        </table>
        </div>
        <button
            className={styles.buttonValid}
            onClick={() => checkAndSend(inputValues)}
            disabled={mutationDataPost.isPending}
          >
            Enregistrer le local
          </button>
          {showMessage && <p className={styles.textError}>Une erreur s'est produite</p>}
    </div>
  );
}

