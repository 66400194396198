import styles from "./creerConv.module.css"
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { BounceLoader } from "react-spinners";
import { useMutation } from "@tanstack/react-query";
import InfosConv from "../../components/CreationConvVisu/InfosConv/InfosConv.js";
import SignatairesInfos from "../../components/CreationConvVisu/SignatairesInfos/SignatairesInfos.js";
import LocauxInfos from "../../components/CreationConvVisu/LocauxInfos/LocauxInfos.js";
import OverlayAddLocal from "../../components/CreationConvVisu/overlayAddLocal/OverlayAddLocal.js";
import OverlayAddEq from "../../components/CreationConvVisu/OverlayAddEq/OverlayAddEq.js";
import EquipementsInfos from "../../components/CreationConvVisu/EquipementsInfos/EquipementsInfos.js";

export default function CreerConv () {
    const { type } = useParams() //for testing set type = "pepiniere"
    const [pageError, setPageError] = useState(false)
    const [newLocal, setNewLocal] = useState(false)
    const [newEq, setNewEq] = useState(false)
    // react prefer to deal with either controlled and uncontrolled comp, so I need to specify the object infos
    const [inputValuesPep, setInputValuesPep] = useState( { "infos": {"convForm": type == "pepiniere" ? 'Formule Pépinière' : 'Formule Coworking', convDtd:"", convDatsig:"", convtipmId:""}, "signataires": {}, "locaux": [], "equipements":[]} )
    const navigate = useNavigate()

    const changePep = (e, key = "infos", tiId, idx) => {
            const { name, value, checked, type } = e.target;
            setInputValuesPep((prev) => {
              const newState = JSON.parse(JSON.stringify(prev));
              if (key === "infos") {
                newState.infos[name] = value
                if (name == "convtipmId" && value == "") {
                  newState.signataires = {}
                  newState.locaux = []
                  newState.equipements = []
                }
              } else if (key == "signataires") {
                newState.signataires[tiId] = checked 
              }
              return newState
            })
    }

    const removeLocal = (idx) => {
      setInputValuesPep(prev => {
          let newState = JSON.parse(JSON.stringify(prev));
          newState["locaux"] = [...newState["locaux"].slice(0, idx), ...newState["locaux"].slice(idx + 1, newState["locaux"].length)]
          const ugIds = newState["locaux"].map(data => data.ugId)
          newState["equipements"] = newState["equipements"].filter((data) => ugIds.includes(data.ugId.toString()))
          return newState
      })
    }

    const removeEq = (idx) => {
      setInputValuesPep(prev => {
          let newState = JSON.parse(JSON.stringify(prev));
          newState["equipements"] =  [...newState["equipements"].slice(0, idx), ...newState["equipements"].slice(idx + 1, newState["equipements"].length)]
          return newState
      })
    }

    const addLocal = (newLocal) => {
      setInputValuesPep((prev) => {
          let newState = JSON.parse(JSON.stringify(prev));
          let copyNewLocal = JSON.parse(JSON.stringify(newLocal));
          delete copyNewLocal["ugconvSurfMax"]
          newState["locaux"].push(copyNewLocal)
          return newState  
          
      })
    }

    const addEq = (newEq) => {
      setInputValuesPep((prev) => {
          let newState = JSON.parse(JSON.stringify(prev));
          let copyNewEq = JSON.parse(JSON.stringify(newEq));
          newState["equipements"].push(copyNewEq)
          return newState  
      })
    }



    
  async function sendForm(data) {
      const link = `${process.env.REACT_APP_API_URL}/conv/create`
      // const link = "http://localhost:8080/conv/create"
      const response = await fetch(link,{
          method:"POST",
          body:JSON.stringify(data),
          headers: {
              'Content-Type': 'application/json',
          },
          credentials:"include"
      })
      const dataReceived = await response.json()
      if (!response.ok) {
          throw dataReceived
      }
      return dataReceived
  }

  const mutationPostFormData = useMutation({
      mutationFn: (formData) => sendForm(formData),
      onSuccess: (data) => {
        navigate(`/pageConnecte/rechercheConvention/convention/${data.id}/latest`)
        window.scrollTo(0, 0);
      }
  })
  
    const checkAndSendPep = (data) => {
    if (!inputValuesPep?.infos?.convDtd || !inputValuesPep?.infos?.convDatsig || ![...new Set(Object.values(inputValuesPep["signataires"]))].includes(true)) {
      setPageError(true)
    } else {
      setPageError(false)
      mutationPostFormData.mutate(data)
      }
    }

    
    return (
        <div className={styles.container}>
            
                {type == "pepiniere" && 
                <>
                <div
                    className={styles.boxHeader}
                >
                    <p
                        className={styles.text}
                    >
                      Convention formule Pépinière
                    </p>
                </div>              
                {newLocal && (
                            <OverlayAddLocal
                                openOverlayFn={setNewLocal}
                                addLocal={addLocal}
                                ugAlready={inputValuesPep["locaux"].map(data => data.ugId)}
                            />
                )}
                {newEq && (
                            <OverlayAddEq
                                openOverlayFn={setNewEq}
                                addEq={addEq}
                                eqAlready={inputValuesPep["equipements"].map(data => data.eqRef)}
                                ugAlready={inputValuesPep["locaux"].map(data => data.ugId)}
                            />
                )}
                <InfosConv inputValues={inputValuesPep["infos"]} changeFn={changePep} pageError={pageError} />   
                {  inputValuesPep["infos"]["convtipmId"] && 
                <>
                <SignatairesInfos inputValues={inputValuesPep["signataires"]} changeFn={changePep} pageError={pageError} PMId={inputValuesPep["infos"]["convtipmId"]} />
                <LocauxInfos inputValues={inputValuesPep["locaux"]} removeLocalOverlayFn={removeLocal} addLocalOverlayFn={setNewLocal} />
                </>
                }
                {  inputValuesPep["locaux"].length != 0 &&
                <>
                <EquipementsInfos inputValues={inputValuesPep["equipements"]} removeEqOverlayFn={removeEq} addEqOverlayFn={setNewEq} />
                <button
                      className={styles.buttonValid}
                      onClick={() => checkAndSendPep(inputValuesPep)}
                      disabled={mutationPostFormData.isPending}
                  >
                      Créer la convention
                </button>
                </>
                }   
                </>
                }
                {type == "coworking" && 
                <>
                <div
                    className={styles.boxHeader}
                >
                    <p
                        className={styles.text}
                    >
                      Convention formule Coworking
                    </p>
                </div>              
                <InfosConv inputValues={inputValuesPep["infos"]} changeFn={changePep} pageError={pageError} />   
                {inputValuesPep["infos"]["convtipmId"] && 
                <>
                <SignatairesInfos inputValues={inputValuesPep["signataires"]} changeFn={changePep} pageError={pageError} PMId={inputValuesPep["infos"]["convtipmId"]} />
                <button
                      className={styles.buttonValid}
                      onClick={() => checkAndSendPep(inputValuesPep)}
                      disabled={mutationPostFormData.isPending}
                  >
                      Créer la convention
                </button>
                </>
                }
                </>
                }
                {mutationPostFormData.isPending == true && (
                    <div className={styles.bounce}>
                      <BounceLoader color="black" />
                    </div>
                )}
        </div>
    )
}