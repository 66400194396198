import styles from "./rechercheug.module.css";
import Footer from "../../components/Footer";
import Pagination from "../../components/Pagination/Pagination";
import { useContext, useEffect, useState } from "react";
import { HeaderContext } from "../../App";
import { BounceLoader } from "react-spinners";
import { useLocation, useNavigate } from "react-router-dom";
import SearchBox from "../../components/SearchBox/SearchBox";
import Box from "../../components/Box";

export default function RechercheUG() {
  const location = useLocation();
  const pathURL = location.pathname;  
  // const [numberElementsMax, setNumberElementsMax] = useState(10);
  const navigate = useNavigate();
  // const [prevNum, setPrevNum] = useState(0);
  // const [nextNum, setNextNum] = useState(10);
  const [searchClicked, setSearchClicked] = useState(true);
  const { searchDataPatrimoine, isLoadingPatrimoine, filterFieldPatrimoine, numberElementsMaxLocaux: numberElementsMax, setNumberElementsMaxLocaux: setNumberElementsMax, 
    prevNumLocaux : prevNum, setPrevNumLocaux : setPrevNum, nextNumLocaux : nextNum, setNextNumLocaux : setNextNum } = useContext(HeaderContext);

  const filteredSearchData = searchDataPatrimoine[0] != "empty" && searchDataPatrimoine[0] != "loading" ? searchDataPatrimoine.filter((data) => {
            if (!(data.ugNat.includes("BATIMENT") || data.ugNat.includes("ETAGE") || data.ugNat.includes("TOILETTES"))) {
              if (filterFieldPatrimoine.loué && filterFieldPatrimoine.disponible) {
                return true
              } else if (filterFieldPatrimoine.loué && !filterFieldPatrimoine.disponible && data["ugconvSurfOc"] != undefined) {
                return true
              } else if (!filterFieldPatrimoine.loué && filterFieldPatrimoine.disponible && data["ugconvSurfOc"] == undefined) {
                return true
              } else {
                return false
              }
            } })
            :[]

    // useEffect(() => {
    //   setNumberElementsMax(10);
    // }, [searchDataPatrimoine]);

    const nextPage = () => {
      if (numberElementsMax >= filteredSearchData.length) {
        // setNextNum((prev) => prev + 10)
        setNumberElementsMax(10);
      } else {
        setNumberElementsMax((prev) => prev + 10);
      }
    };

    useEffect(() => {
      if (isLoadingPatrimoine == false) {
        if (filteredSearchData.length == 0) {
          setNextNum(0);
        } else if (numberElementsMax > filteredSearchData.length) {
          setNextNum(filteredSearchData.length);
        } else {
          setNextNum(numberElementsMax);
        }
        if (numberElementsMax > filteredSearchData.length) {
          const numsCandidate = [
            filteredSearchData.length - 1,
            filteredSearchData.length - 2,
            filteredSearchData.length - 3,
            filteredSearchData.length - 4,
            filteredSearchData.length - 5,
            filteredSearchData.length - 6,
            filteredSearchData.length - 7,
            filteredSearchData.length - 8,
            filteredSearchData.length - 9,
          ];
          let smallerNumber;
          for (let i of numsCandidate) {
            if (i % 10 === 0) {
              smallerNumber = i + 1;
              break;
            }
          }
          if (smallerNumber == undefined) {
            setPrevNum(0);
          } else {
            setPrevNum(smallerNumber);
          }
        } else {
          if (filteredSearchData.length == 0) {
            setPrevNum(0);
          } else {
            setPrevNum(numberElementsMax - 9);
          }
        }  
      }
    }, [numberElementsMax, filteredSearchData]);

    const prevPage = () => {
      if (numberElementsMax == 10) {
        if (filteredSearchData.length % 10 == 0) {
          setNumberElementsMax((prev) => filteredSearchData.length);
        } else {
          const numsCandidate = [
            filteredSearchData.length + 1,
            filteredSearchData.length + 2,
            filteredSearchData.length + 3,
            filteredSearchData.length + 4,
            filteredSearchData.length + 5,
            filteredSearchData.length + 6,
            filteredSearchData.length + 7,
            filteredSearchData.length + 8,
            filteredSearchData.length + 9,
          ];
          let greatestNumber;
          for (let i of numsCandidate) {
            if (i % 10 === 0) {
              greatestNumber = i;
              break;
            }
          }
          setNumberElementsMax(greatestNumber);
        }
      } else {
        setNumberElementsMax((prev) => prev - 10);
      }
      // }
    };

    return (
    <div className={styles.container}>
        {/* {overlayFilter == "filter" && <FilterOverlay status={overlayFilter} setFilter={setOverlayFilter} />} */}
      <div className={styles.boxesContainer}>
        <div
          className={`${searchClicked ? styles.box : styles.boxNotClicked}`}
          onClick={() => setSearchClicked(true)}
        >
          <p
            className={`${searchClicked ? styles.text : styles.textNotClicked}`}
          >
            Locaux
          </p>
        </div>
        <div
          className={`${searchClicked ? styles.boxNotClicked : styles.box}`}
          onClick={() => setSearchClicked(false)}
        >
          <p
            className={`${searchClicked ? styles.textNotClicked : styles.text}`}
          >
            Ajouter
          </p>
        </div>
      </div>
      {searchClicked ? 
      <>
          <SearchBox pathURL={pathURL} />
      <div className={styles.table}>
          <table className={styles.tableSearch}>
            <thead>
              <tr>
{/* <th style={{width:"5%"}}>ID</th> */}
              {/* <th style={{width:"10%"}}>Référence</th> */}
              <th style={{ width: "15%" }}>Intitulé</th>
              {/* <th style={{ width: "10%" }}>Nature</th> */}
              <th style={{ width: "15%" }}>Etage</th>
              {/* <th style={{ width: "10%" }}>Utilisation</th> */}
              <th style={{ width: "40%" }}>Adresse</th>
              <th style={{ width: "15%" }}>Surf. Total (m²)</th>
              <th style={{ width: "15%" }}>Surf. Occ. (m²)</th>
              </tr>
            </thead>
            <tbody>
              {filteredSearchData.length != 0 &&
                filteredSearchData
                  .slice(numberElementsMax - 10, numberElementsMax)
                  .map((data) => {
                    let liste = [];
                    for (const [key, value] of Object.entries(data)) {
                      liste.push(value);
                    }
                    return (
                      <tr onClick={() => navigate(`./patrimoine/${liste[0]}`)} key={liste[0]}>
                        <td>{liste[2]}</td>
                        <td>{liste[4]}</td>
                        <td>{liste[6]}</td>
                        <td>{liste[7]}</td>
                        <td>{liste[8] ? liste[8] : liste[7] ? 0 : null}</td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
        { isLoadingPatrimoine || searchDataPatrimoine[0] == "loading" ? (
          <div className={styles.bounce}>
            <BounceLoader color="black" />
          </div>
        ) : searchDataPatrimoine[0] == "empty" ||
          filteredSearchData.length != 0 ? (
          <div>
            <Pagination nextPage={nextPage} prevPage={prevPage} />
            <p className={styles.nums}>
              {prevNum} à {nextNum} - Nombre Total d'éléments :{" "}
              {filteredSearchData.length}{" "}
            </p>
          </div>
        ) : null} 
      </>
        : 
        <div className={styles.addContainer}>
          <p className={styles.textAdd}>Que souhaitez-vous ajouter ?</p>
          <Box text="Un local" link="/pageConnecte/creerPatrimoine" />
          <Box text="Un fichier Excel" link="/pageConnecte/creerPatrimoineExcel" />
        </div>
        }
    </div>
  );
}
