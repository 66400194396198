import ReactDOM from "react-dom";
import styles from "./OverlayAddAvenantCow.module.css";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { BounceLoader } from "react-spinners";

export default function OverlayAddAvenantCow({
  openOverlayFn,
  inputValues,
  typeAvenant,
}) {
  const { convId, convVrs = "latest" } = useParams();
  const queryClient = useQueryClient();

  async function updateConvAv(data) {
    const link = `${process.env.REACT_APP_API_URL}/conv/avenantcow`
    // const link = `http://localhost:8080/conv/avenantcow`;
    const response = await fetch(link, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const dataReceived = await response.json();
    return dataReceived;
  }

  const mutationDataUpdate = useMutation({
    mutationFn: (data) => updateConvAv(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["Convention", convId, convVrs]);
      queryClient.invalidateQueries(["Convention", "Historique", convId]);
      openOverlayFn(false);
    },
  });

  const checkAndSend = () => {
    mutationDataUpdate.mutate({
      prevData: inputValues,
      typeAvenant: typeAvenant,
      convId: convId
    });
  };

  return ReactDOM.createPortal(
    <div className={styles.overlay} onClick={() => openOverlayFn(false)}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <p className={styles.textOverlayTitle}>AJOUTER AVENANT COWORKING</p>
        <div>
          <p>Voulez-vous vraiment ajouter un nouveau avenant coworking ?</p>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <button className={styles.buttonValid} disabled={mutationDataUpdate.isPending} onClick={() => checkAndSend()}>
            Ajouter
          </button>
          <button
            className={styles.buttonValid}
            onClick={() => openOverlayFn(false)}
          >
            Retour
          </button>
        </div>
        {mutationDataUpdate.isPending == true && (
          <div className={styles.bounce}>
            <BounceLoader color="black" />
          </div>
        )}
      </div>
    </div>,
    document.getElementById("overlay")
  );
}
