import { useState } from "react";
import styles from "./statistiques.module.css";
import { useQuery } from "@tanstack/react-query";
import PieChartFormules from "../../components/statsVisu/PieChartFormules/PieChartFormules.js";
import SexeChart from "../../components/statsVisu/SexeChart/SexeChart.js";
import BarChartPortPrj from "../../components/statsVisu/BarChartPortPrj/BarChartPortPrj.js";
import BarChartExtraMuros from "../../components/statsVisu/BarChartExtraMuros/BarChartExtraMuros.js";
import BarChartEntreePep from "../../components/statsVisu/BarChartEntreePep/BarChartEntreePep.js";
import BarChartSortiePep from "../../components/statsVisu/BarChartSortiePep/BarChartSortiePep.js";
import TreeLocaux from "../../components/statsVisu/TreeLocaux/TreeLocaux.js";
import BarChartSynOccu from "../../components/statsVisu/BarChartSynOccu/BarChartSynOccu.js";
import BarChartSujetEnt from "../../components/statsVisu/BarChartSujetEnt/BarChartSujetEnt.js";
import { BounceLoader } from "react-spinners";
import BarChartSujetAges from "../../components/statsVisu/BarChartAges/BarChartSujetAges.js";
import PieChartSex from "../../components/statsVisu/PieChartSex/PieChartSex.js";
import PieChartEdu from "../../components/statsVisu/PieChartEdu/PieChartEdu.js";
import PieChartCities from "../../components/statsVisu/PieChartCities/PieChartCities.js";
import BarChartActivite from "../../components/statsVisu/BarChartActivite/BarChartActivite.js";

const formatDate = (dateFin) => {
  let d = new Date(dateFin),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

function getFirstDayOfCurrentYear() {
  const currentYear = new Date().getFullYear();
  return `${currentYear}-01-01`;
}

export default function Statistiques() {
  const [statsClicked, setStatsClicked] = useState(true);
  const [dateDeb, setDateDeb] = useState(getFirstDayOfCurrentYear());
  const [dateYear, setDateYear] = useState(new Date().getFullYear());
  const [dateYearRef, setDateYearRef] = useState(new Date().getFullYear());
  const [dateFin, setDateFin] = useState(formatDate(new Date()));
  const [theme, setTheme] = useState("Formule Pépinière");

  async function getOverallStats() {
    const link = `${process.env.REACT_APP_API_URL}/stats/overall?dateFin=${dateFin}&dateDeb=${dateDeb}&dateYear=${dateYear}`;
    // const link = `http://localhost:8080/stats/overall?dateFin=${dateFin}&dateDeb=${dateDeb}&dateYear=${dateYear}`;
    const response = await fetch(link);
    const data = response.json();
    return data;
  }

  const { data: stats = {}, isLoading } = useQuery({
    queryKey: ["Statistiques", dateYear, dateDeb, dateFin],
    queryFn: () => getOverallStats(dateFin),
    refetchOnWindowFocus: false,
  });

  function calculateTotalTime(dataArray) {
    let totalMinutes = 0;

    dataArray.forEach((item) => {
      const startTime = item.HDebAccSuivi.split(":");
      const endTime = item.HFinAccSuivi.split(":");
      const startMinutes = parseInt(startTime[0]) * 60 + parseInt(startTime[1]);
      const endMinutes = parseInt(endTime[0]) * 60 + parseInt(endTime[1]);
      totalMinutes += endMinutes - startMinutes;
    });

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  }

  const downloadStats = async () => {
    const link = `${process.env.REACT_APP_API_URL}/stats/downloadBilan?dateYearRef=${dateYearRef}`
    // const link = `http://localhost:8080/stats/downloadBilan?dateYearRef=${dateYearRef}`;
    window.open(link, "_blank")
  }

  const downloadBilanAnte = async () => {
    const link = `${process.env.REACT_APP_API_URL}/stats/downloadBilanAnte?dateYearRef=${dateYearRef}`
    // const link = `http://localhost:8080/stats/downloadBilanAnte?dateYearRef=${dateYearRef}`;
    window.open(link, "_blank")
  }

  const downloadBilanExtraMuros = async () => {
    const link = `${process.env.REACT_APP_API_URL}/stats/downloadBilanExtraMuros?dateYearRef=${dateYearRef}`
    // const link = `http://localhost:8080/stats/downloadBilanExtraMuros?dateYearRef=${dateYearRef}`;
    window.open(link, "_blank")
  }

  return (
    <div className={styles.container}>
      <div className={styles.boxesContainer}>
        <div
          className={`${statsClicked ? styles.box : styles.boxNotClicked}`}
          onClick={() => setStatsClicked(true)}
        >
          <p
            className={`${statsClicked ? styles.text : styles.textNotClicked}`}
          >
            Tableau de bord
          </p>
          {/* {statsClicked && <div className={styles.downloadContainer}>
                    <img src={downloadBlanc} className={styles.logo} onClick={() => console.log("download")} />
                </div>} */}
        </div>
        <div
          className={`${statsClicked ? styles.boxNotClicked : styles.box}`}
          onClick={() => setStatsClicked(false)}
        >
          <p
            className={`${statsClicked ? styles.textNotClicked : styles.text}`}
          >
            Statistiques
          </p>
        </div>
      </div>
      {statsClicked && (
        <>
          <div className={styles.rowContainer}>
            <p className={styles.textTitle}>Date de début</p>
            <input
              type="date"
              name="dateDebStat"
              id="dateDebStat"
              value={dateDeb}
              onChange={(e) => setDateDeb(e.target.value)}
              className={styles.input}
              max={formatDate(new Date())}
            />
            <p className={styles.textTitle}>Date de Fin</p>
            <input
              type="date"
              name="dateFinStat"
              id="dateFinStat"
              value={dateFin}
              onChange={(e) => setDateFin(e.target.value)}
              className={styles.input}
              max={formatDate(new Date())}
              min={dateDeb}
            />
          </div>
          {/* <input
            type="number"
            min="1900"
            max={new Date().getFullYear()}
            step="1"
            value={dateYear}
            onChange={(e) => setDateYear(e.target.value)}
            className={styles.input}
          /> */}
          {isLoading == true && (
            <div className={styles.bounce}>
              <BounceLoader color="black" />
            </div>
          )}
          {Object.keys(stats).length != 0 && (
            <div className={styles.chartsContainer}>
              <div className={styles.chartContainer}>
                <p className={styles.textTitle}>
                  RÉPARTITION DES ENTREPRISES HÉBERGÉES
                </p>
                {stats["hostedCompanies"].length != 0 ? (
                  <PieChartFormules data={stats["hostedCompanies"]} />
                ) : (
                  <p className={styles.textTitle}>
                    Aucune donnée pour cette période
                  </p>
                )}
              </div>
              <div className={styles.chartContainer}>
                <p className={styles.textTitle}>
                  PARITÉ FEMME/HOMME DES ENTREPRISES HÉBERGÉES
                </p>
                {stats["hostedPPSex"].length != 0 ? (
                  <SexeChart data={stats["hostedPPSex"]} />
                ) : (
                  <p className={styles.textTitle}>
                    Aucune donnée pour cette période
                  </p>
                )}
              </div>
              <div className={styles.chartContainer}>
                <p className={styles.textTitle}>
                  PORTEURS DE PROJET ACCUEILLIS
                </p>
                {stats["hostedPorteurProjet"].length != 0 &&
                stats["hostedPorteurProjet"][0]["value"] != 0 ? (
                  <BarChartPortPrj data={stats["hostedPorteurProjet"]} />
                ) : (
                  <p className={styles.textTitle}>
                    Aucune donnée pour cette période
                  </p>
                )}
              </div>
              <div className={styles.chartContainer}>
                <p className={styles.textTitle}>ACCOMPAGNEMENT EXTRA-MUROS</p>
                {stats["accExtraMuros"].length != 0 &&
                stats["accExtraMuros"][0]["valeur"] != 0 ? (
                  <BarChartExtraMuros data={stats["accExtraMuros"]} />
                ) : (
                  <p className={styles.textTitle}>
                    Aucune donnée pour cette période
                  </p>
                )}
              </div>
              <div
                className={styles.chartContainer}
                // style={{ height: "300px" }}
              >
                <p className={styles.textTitle}>ENTRÉE À LA PÉPINIÈRE</p>
                {stats["entCompanies"].length != 0 ? (
                  <BarChartEntreePep data={stats["entCompanies"]} />
                ) : (
                  <p className={styles.textTitle}>
                    Aucune donnée pour cette période
                  </p>
                )}
              </div>
              <div
                className={styles.chartContainer}
                // style={{ height: "300px" }}
              >
                <p className={styles.textTitle}>SORTIE DE LA PÉPINIÈRE</p>

                {stats["sorCompanies"].length != 0 ? (
                  <BarChartSortiePep data={stats["sorCompanies"]} />
                ) : (
                  <p className={styles.textTitle}>
                    Aucune donnée pour cette période
                  </p>
                )}
              </div>
              <div className={styles.chartContainer}>
                <p className={styles.textTitle}>
                  SUJET DES ENTRETIENS RÉALISÉS AVEC LES PÉPINS 
                </p>
                {stats["accEntretiens"].length != 0 &&
                stats["accInfosEntretiens"] ? (
                  <>
                    <p className={styles.textInfos}>
                      Pour un total de {stats["accInfosEntretiens"].length}{" "}
                      entretiens qui représentent{" "}
                      {calculateTotalTime(stats["accInfosEntretiens"])} heures
                      d'accompagnement
                    </p>
                    <BarChartSujetEnt data={stats["accEntretiens"]} />
                  </>
                ) : (
                  <p className={styles.textTitle}>
                    Aucune donnée pour cette période
                  </p>
                )}
              </div>
              <div className={styles.chartContainer}>
                <p className={styles.textTitle}>
                  SYNTHÈSE LOCAUX DISPONIBLES (%)
                </p>
                {stats["LocDispo"].length != 0 &&
                dateYear == new Date().getFullYear() ? (
                  <BarChartSynOccu data={stats["LocDispo"]} />
                ) : (
                  <p className={styles.textTitle}>
                    Aucune donnée pour cette période
                  </p>
                )}
              </div>
              <div
                className={styles.chartContainer}
                style={{ height: "1300px" }}
              >
                <p className={styles.textTitle}>OCCUPATION DES LOCAUX</p>
                {stats["occLocaux"].length != 0 &&
                dateYear == new Date().getFullYear() ? (
                  <TreeLocaux data={stats["occLocaux"]} />
                ) : (
                  <p className={styles.textTitle}>
                    Aucune donnée pour cette période
                  </p>
                )}
              </div>
            </div>
          )}
        </>
      )}
      {!statsClicked && (
        <>
          <div className={styles.exportContainer}>
          <p className={styles.textTitle}>PÉRIODE</p>
          <div
            className={styles.rowContainerTwo}
          >
            <div className={styles.fieldContainer}>
              <p className={styles.textTitle}>
                Année de référence <br />
              </p>
              <input
                type="number"
                min="1900"
                max={new Date().getFullYear()}
                step="1"
                value={dateYearRef}
                onChange={(e) => setDateYearRef(e.target.value)}
                className={styles.input}
              />
            </div>
          </div>
          <div className={styles.rowContainerTwo}>
          <button
            onClick={() => downloadStats()}
            className={styles.buttonExport}
          >
            Exporter en fichier Excel - Bilan Formules
          </button>
          <button
            onClick={() => downloadBilanAnte()}
            className={styles.buttonExport}
          >
            Exporter en fichier Excel - Bilan Ante
          </button>
          <button
            onClick={() => downloadBilanExtraMuros()}
            className={styles.buttonExport}
          >
            Exporter en fichier Excel - Bilan Extra-Muros
          </button>
          </div>
          <div className={styles.rowContainerTwo}>
            <div className={styles.fieldContainer}>
              <p className={styles.textTitle}>Date de début</p>
              <input
                type="date"
                name="dateDebStat"
                id="dateDebStat"
                value={dateDeb}
                onChange={(e) => setDateDeb(e.target.value)}
                className={styles.input}
                max={formatDate(new Date())}
              />
            </div>
            <div className={styles.fieldContainer}>
              <p className={styles.textTitle}>Date de Fin</p>
              <input
                type="date"
                name="dateFinStat"
                id="dateFinStat"
                value={dateFin}
                onChange={(e) => setDateFin(e.target.value)}
                className={styles.input}
                max={formatDate(new Date())}
                min={dateDeb}
              />
            </div>
            <div className={styles.fieldContainer}>
            <p className={styles.textTitle}>Formule</p>
              <select
                name="theme"
                id="theme"
                onChange={(e) => setTheme(e.target.value)}
                className={styles.inputSelect}
                value={theme}
              >
                <option value="Formule Pépinière">
                  Pépinière
                </option>
                <option value="Porteurs de projet">Porteurs de projet</option>
                
                <option value="Formule Extra-Muros">
                  Extra-Muros
                </option>
              </select>
            </div>
          </div>
          {isLoading == true && (
            <div className={styles.bounce}>
              <BounceLoader color="black" />
            </div>
          )}
          </div>
          {Object.keys(stats).length != 0 && <div className={styles.chartsContainer}>
          {theme == "Formule Pépinière" &&
            <>
            <div className={styles.chartContainer}>
            <p className={styles.textTitle}>
              TRANCHES D'AGES - FORMULE PÉPINIÈRE
            </p>
            {stats["agesPep"].length != 0 &&
            stats["agesPep"] ? (
              <>
                <p className={styles.textInfos}>
                L’âge moyen des personnes accueillies est de {stats["moyenneAgePep"]} ans.
                </p>
                <BarChartSujetAges data={stats["agesPep"]} />
              </>
            ) : (
              <p className={styles.textTitle}>
                Aucune donnée pour cette période
              </p>
            )}
          </div>

          <div className={styles.chartContainer}>
            <p className={styles.textTitle}>
                POST PÉPINIÈRE
            </p>
            {stats["postPepCompanies"].length != 0 &&
            stats["postPepCompanies"] ? (
              <>
                <BarChartSujetAges data={stats["postPepCompanies"]} />
              </>
            ) : (
              <p className={styles.textTitle}>
                Aucune donnée pour cette période
              </p>
            )}
          </div>

          <div className={styles.chartContainer}>
            <p className={styles.textTitle}>
              SITUATION AVANT IMMATRICULATION - FORMULE PÉPINIÈRE
            </p>
            {stats["personsScpAvPrjPep"].length != 0 &&
            stats["personsScpAvPrjPep"] ? (
              <>
                <BarChartSujetAges data={stats["personsScpAvPrjPep"]} />
              </>
            ) : (
              <p className={styles.textTitle}>
                Aucune donnée pour cette période
              </p>
            )}
          </div>

          <div className={styles.chartContainer}>
            <p className={styles.textTitle}>
            STATUT JURIDIQUE - FORMULE PÉPINIÈRE
            </p>
            {stats["statutJurComp"].length != 0 ? (
              <PieChartEdu data={stats["statutJurComp"]} />
            ) : (
              <p className={styles.textTitle}>
                Aucune donnée pour cette période
              </p>
            )}
          </div>

          <div className={styles.chartContainer}>
            <p className={styles.textTitle}>
            NIVEAU DE QUALIFICATION - FORMULE PÉPINIÈRE
            </p>
            {stats["eduPep"].length != 0 ? (
              <PieChartEdu data={stats["eduPep"]} />
            ) : (
              <p className={styles.textTitle}>
                Aucune donnée pour cette période
              </p>
            )}
          </div>
          
          <div className={styles.chartContainer}>
            <p className={styles.textTitle}>
            RÉPARTITION FEMME/HOMME - FORMULE PÉPINIÈRE
            </p>
            {stats["hostedPPSexPourcentagePep"].length != 0 ? (
              <PieChartSex data={stats["hostedPPSexPourcentagePep"]} />
            ) : (
              <p className={styles.textTitle}>
                Aucune donnée pour cette période
              </p>
            )}
          </div>


          <div className={styles.chartContainer} style={{height:"1000px"}}>
            <p className={styles.textTitle}>
            ORIGINE GÉOGRAPHIQUE - FORMULE PÉPINIÈRE
            </p>
            {stats["comunPep"].length != 0 ? (
              <PieChartCities data={stats["comunPep"]} />
            ) : (
              <p className={styles.textTitle}>
                Aucune donnée pour cette période
              </p>
            )}
          </div>
            </> 
            } 
            {/* Extra muros */}
          {theme == "Formule Extra-Muros" && 
          <>
                    <div className={styles.chartContainer}>
            <p className={styles.textTitle}>
            NIVEAU DE QUALIFICATION - EXTRA-MUROS
            </p>
            {stats["eduMur"].length != 0 ? (
              <PieChartEdu data={stats["eduMur"]} />
            ) : (
              <p className={styles.textTitle}>
                Aucune donnée pour cette période
              </p>
            )}
          </div>


          <div className={styles.chartContainer}>
            <p className={styles.textTitle}>
              TRANCHES D'AGES - EXTRA-MUROS
            </p>
            {stats["agesMur"].length != 0 &&
            stats["agesMur"] ? (
              <>
                <p className={styles.textInfos}>
                L’âge moyen des personnes accueillies est de {stats["moyenneAgeMur"]} ans.
                </p>
                <BarChartSujetAges data={stats["agesMur"]} />
              </>
            ) : (
              <p className={styles.textTitle}>
                Aucune donnée pour cette période
              </p>
            )}
          </div>

          <div className={styles.chartContainer}>
            <p className={styles.textTitle}>
            STATUT JURIDIQUE - FORMULE EXTRA-MUROS
            </p>
            {stats["statutJurCompMur"].length != 0 ? (
              <PieChartEdu data={stats["statutJurCompMur"]} />
            ) : (
              <p className={styles.textTitle}>
                Aucune donnée pour cette période
              </p>
            )}
          </div>

          <div className={styles.chartContainer}>
            <p className={styles.textTitle}>
            RÉPARTITION FEMME/HOMME - EXTRA-MUROS
            </p>
            {stats["hostedPPSexPourcentageMur"].length != 0 ? (
              <PieChartSex data={stats["hostedPPSexPourcentageMur"]} />
            ) : (
              <p className={styles.textTitle}>
                Aucune donnée pour cette période
              </p>
            )}
          </div>

          </>
          }

            {/* porteur de projet */}
          {theme == "Porteurs de projet" && 
          <>
                    <div className={styles.chartContainer}>
            <p className={styles.textTitle}>
              TRANCHES D'AGES - PORTEURS DE PROJET
            </p>
            {stats["agesPrj"].length != 0 &&
            stats["agesPrj"] ? (
              <>
                <p className={styles.textInfos}>
                L’âge moyen des personnes accueillies est de {stats["moyenneAgePrj"]} ans.
                </p>
                <BarChartSujetAges data={stats["agesPrj"]} />
              </>
            ) : (
              <p className={styles.textTitle}>
                Aucune donnée pour cette période
              </p>
            )}
          </div>

          

          <div className={styles.chartContainer}>
            <p className={styles.textTitle}>
              SECTEURS D'ACTIVITÉ - PORTEURS DE PROJET
            </p>
            {stats["secteurComp"].length != 0 &&
            stats["secteurComp"] ? (
              <>
                <BarChartActivite data={stats["secteurComp"]} />
              </>
            ) : (
              <p className={styles.textTitle}>
                Aucune donnée pour cette période
              </p>
            )}
          </div>

          <div className={styles.chartContainer}>
            <p className={styles.textTitle}>
            NIVEAU DE QUALIFICATION - PORTEURS DE PROJET
            </p>
            {stats["eduPrj"].length != 0 ? (
              <PieChartEdu data={stats["eduPrj"]} />
            ) : (
              <p className={styles.textTitle}>
                Aucune donnée pour cette période
              </p>
            )}
          </div>

          <div className={styles.chartContainer}>
            <p className={styles.textTitle}>
            RÉPARTITION FEMME/HOMME - PORTEURS DE PROJET
            </p>
            {stats["hostedPPSexPourcentagePrj"].length != 0 ? (
              <PieChartSex data={stats["hostedPPSexPourcentagePrj"]} />
            ) : (
              <p className={styles.textTitle}>
                Aucune donnée pour cette période
              </p>
            )}
          </div>

          

          <div className={styles.chartContainer} style={{height:"1000px"}}>
            <p className={styles.textTitle}>
            ORIGINE GÉOGRAPHIQUE - PORTEURS DE PROJET
            </p>
            {stats["comunPrj"].length != 0 ? (
              <PieChartCities data={stats["comunPrj"]} />
            ) : (
              <p className={styles.textTitle}>
                Aucune donnée pour cette période
              </p>
            )}
          </div>

          </>}
          </div>
          }
          </>
      )}
    </div>
  )}