import ReactDOM from "react-dom";
import styles from "./overlayAddUpdateSortie.module.css";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { BounceLoader } from "react-spinners";

export default function OverlayAddUpdateSortie({ openOverlayFn, sortieToModify }) {
  const { id } = useParams();
  const [newSortie, setnewSortie] = useState({
    tipmId: id,
    sorDat: sortieToModify.length != 0 ? sortieToModify[0]["sorDat"] : "",
    Motif: sortieToModify.length == 0 ? ""  : ["Radiée", "Nouvelle Implantation", "Liquidation"].includes(sortieToModify[0]["Motif"]) == false ? "Champ Libre" : sortieToModify[0]["Motif"],
    nouvImp: sortieToModify.length != 0 ? sortieToModify[0]["nouvImp"] : "",
    champLibre: sortieToModify.length == 0 ? "" : ["Radiée", "Nouvelle Implantation", "Liquidation"].includes(sortieToModify[0]["Motif"]) ? "" : sortieToModify[0]["Motif"]
  });

  const [pageError, setPageError] = useState(false);
  const queryClient = useQueryClient();

  const changeSortie = (e) => {
    setnewSortie((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const checkAndSend = () => {
    if (
      newSortie["sorDat"] == "" ||
      newSortie["Motif"] == "" ||
      (newSortie["Motif"] == "Champ Libre" && newSortie["champLibre"] == "")
    ) {
      setPageError(true);
    } else {
      setPageError(false);
      if (sortieToModify.length == 0) {
        mutationPostSortie.mutate(newSortie);
      } else {
        mutationUpdateSortie.mutate(newSortie)
      }
    }
  };

  async function addSortie(data) {
    const link = `${process.env.REACT_APP_API_URL}/tiers/sortie`
    // const link = `http://localhost:8080/tiers/sortie`;
    const response = await fetch(link, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const dataReceived = await response.json();
    return dataReceived;
  }

  const mutationPostSortie = useMutation({
    mutationFn: (data) => addSortie(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["Tiers", id]);
      openOverlayFn((prev) => !prev);
    },
  });

  async function ModifySortie(data) {
    const link = `${process.env.REACT_APP_API_URL}/tiers/sortie`
    // const link = `http://localhost:8080/tiers/sortie`;
    const response = await fetch(link, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const dataReceived = await response.json();
    return dataReceived;
  }

  const mutationUpdateSortie = useMutation({
    mutationFn: (data) => ModifySortie(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["Tiers", id]);
      openOverlayFn((prev) => !prev);
    },
  });


  return ReactDOM.createPortal(
    <div className={styles.overlay} onClick={() => openOverlayFn(false)}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <p className={styles.textOverlayTitle}>
          {sortieToModify.length != 0 ? "MODIFIER" : "AJOUTER"}{" "}
          SORTIE PÉPINIÈRE
        </p>
        <div>
          <div className={styles.rowContainer}>
            <div className={styles.fieldContainer}>
              <label className={styles.label} htmlFor="sorDat">
                Date*
              </label>
              <input
                type="date"
                id="sorDat"
                name="sorDat"
                value={newSortie["sorDat"]}
                onChange={(e) => changeSortie(e)}
                className={styles.input}
              />
              {newSortie["sorDat"] == "" && pageError == true && (
                <small className={styles.small}>Ce champs est requis</small>
              )}
            </div>
          </div>
          <div className={styles.rowContainer}>
            <div className={styles.fieldContainer}>
              <label className={styles.label} htmlFor="Motif">
                Motif*
              </label>
              <select name="Motif" id="Motif" onChange={(e) => changeSortie(e)} value={newSortie["Motif"]} className={styles.inputSelect}>
                <option value="">-----------------</option>
                <option value="Nouvelle Implantation">Nouvelle Implantation</option>
                <option value="Radiée">Radiée</option>
                <option value="Liquidation">Liquidation</option>
                {!["", "Champ Libre", "Nouvelle Implantation", "Radiée", "Liquidation"].includes(newSortie["Motif"]) && <option value={newSortie["Motif"]}>{newSortie["Motif"]}</option>}
                <option value="Champ Libre">Champ Libre</option>
              </select>
              {newSortie["Motif"] == "Champ Libre" && 
                    <input
                    type="text"
                    id="champLibre"
                    name="champLibre"
                    value={newSortie["champLibre"]}
                    onChange={(e) => changeSortie(e)}
                    className={styles.input}
                  />
              }
              {newSortie["Motif"] == "" && pageError == true && (
                <small className={styles.small}>
                  Ce champs est requis
                </small>
              )}
              {(newSortie["Motif"] == "Champ Libre" && pageError == true && newSortie["champLibre"] == "") && (
                <small className={styles.small}>
                  Ce champs est requis
                </small>
              )}
            </div>
          </div>
          <div className={styles.rowContainer}>
            <div className={styles.fieldContainer}>
              <label className={styles.label} htmlFor="nouvImp">
                Nouvelle Implantation
              </label>
              <input
                type="text"
                id="nouvImp"
                name="nouvImp"
                value={newSortie["nouvImp"]}
                onChange={(e) => changeSortie(e)}
                className={styles.input}
              />
            </div>
          </div>
          
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          {sortieToModify.length == 0 ? (
            <button
              className={styles.buttonValid}
              onClick={() => checkAndSend()}
            >
              Ajouter
            </button>
          ) : (
            <button
              className={styles.buttonValid}
              onClick={() => checkAndSend()}
            >
              Modifier
            </button>
          )}
          <button
            className={styles.buttonValid}
            onClick={() => openOverlayFn(false)}
          >
            Retour
          </button>
        </div>
        {mutationPostSortie.isPending == true && (
          <div className={styles.bounce}>
            <BounceLoader color="white" />
          </div>
        )}
        {mutationUpdateSortie.isPending == true && (
          <div className={styles.bounce}>
            <BounceLoader color="white" />
          </div>
        )}
      </div>
    </div>,
    document.getElementById("overlay")
  );
}
