import { useEffect, useState } from "react";
import styles from "./SignatairesVisu.module.css";
import Pagination from "../../Pagination/Pagination";
import { useNavigate } from "react-router-dom";
import modifBleu from "../../../assets/modifbleu.png";
import poubBleu from "../../../assets/poubBleu.png";

export default function SignatairesVisu({
  signataires
}) {

  return (
    <>
        {signataires.length != 0 && <div className={styles.table}>
        <table className={styles.tableSearch}>
          <thead>
            <tr>
            <th style={{ width: "50%" }}>
              <b>Signataire</b>
            </th>
            <th style={{ width: "50%" }}>
              <b>Fonction</b>
            </th>
            {/* <th style={{ width: "10%" }}></th> */}
            </tr>
          </thead>
          <tbody>
            {signataires
              .map((signataire) => {
                return (
                  <tr key={signataire["tiLib"]}>
                    <td>{signataire["tiLib"]}</td>
                    <td>{signataire["relTyp"]}</td>
                    {/* <td>
                      <div className={styles.logoContainer}>
                        <img
                          src={modifBleu}
                          className={styles.logo}
                          onClick={() => {
                            modifDirOverlayFn(signataire);
                          }}
                        />
                        <img
                          src={poubBleu}
                          className={styles.logo}
                          onClick={() => removeDirOverlayFn(signataire)}
                        />
                      </div>
                    </td> */}
                  </tr>
                );
              })}
          </tbody>
        </table>
    </div>}

    </>
  );
}
