import ReactDOM from "react-dom";
import styles from "./overlayRemoveFile.module.css";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { BounceLoader } from "react-spinners";

export default function OverlayRemoveFile({ removeOverlayFn, fileToRemove }) {
  const { ugNum } = useParams();
  const queryClient = useQueryClient();
  const [isLoadingDel, setIsLoadingDel] = useState(false);


  const deleteFile = async (fileToRemove) => {
    setIsLoadingDel(true)
    let deleteList = [fileToRemove]
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/ug/files/${ugNum}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',  
        },
        body: JSON.stringify({"files":deleteList}), 
    })
  //   const response = await fetch(`http://localhost:8080/ug/files/${ugNum}`, {
  //     method: 'DELETE',
  //     headers: {
  //         'Content-Type': 'application/json',  
  //     },
  //     body: JSON.stringify({"files":deleteList}), 
  // })

        if (response.ok) {
            removeOverlayFn()
            queryClient.invalidateQueries(["Local", "Files", ugNum]);
        } else {
            console.error("File upload failed:", response.statusText);
        }
    } catch (error) {
        console.error("Error during file upload:", error);
    } finally {
      setIsLoadingDel(false)
    }   
}


  return ReactDOM.createPortal(
    <div className={styles.overlay} onClick={() => removeOverlayFn()}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <p className={styles.textOverlayTitle}>ÊTES-VOUS SÛR DE VOULOIR SUPPRIMER LE FICHIER ?</p>
        <div>
            <label htmlFor="NomFichier" className={styles.label}>
                Nom du fichier
            </label>
            <input
            type="text"
            id="NomFichier"
            name="NomFichier"
            readOnly={true}
            value={fileToRemove[2]}
            className={styles.input}
            />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <button
            className={styles.buttonValid}
            onClick={() => deleteFile(fileToRemove[2])}
          >
            Oui
          </button>
          <button
            className={styles.buttonValid}
            onClick={() => removeOverlayFn()}
          >
            Non
          </button>
        </div>
        {isLoadingDel == true && (
          <div className={styles.bounce}>
            <BounceLoader color="white" />
          </div>
        )}
      </div>
    </div>,
    document.getElementById("overlay")
  );
}
