import { useState } from "react";
import styles from "./prix.module.css";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BounceLoader } from "react-spinners";

export default function PrixPepiniere({ prix }) {
  const [prixPep, setPrixPep] = useState(prix);
  const [pageError, setPageError] = useState(false);
  const queryClient = useQueryClient();

  async function ModifPrix(data) { // data = ["2024-02-07", {"16":[250, 300, 350],"32":[300, 350, 400]}]
    const link = `${process.env.REACT_APP_API_URL}/admin/prixUGs/pepiniere`;
    // const link = "http://localhost:8080/admin/prixUGs/pepiniere"; 
    const response = await fetch(link, {
      method: "POST",
      body: JSON.stringify(data), 
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const dataReceived = await response.json();
    return dataReceived;
  }

  const mutationPrixPost = useMutation({
    mutationFn: (data) => ModifPrix(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["HistoriquePrix", "Admin"]);
      //   setIsValidEnabled(false);
    },
  });

  const change = (e, idx, surface) => {
    if (e.target.name == "prixDdeb") {
      setPrixPep((prev) => {
        const newState = JSON.parse(JSON.stringify(prev))
        newState[0] = e.target.value;
        return [...newState];      });
    } else {
      setPrixPep((prev) => {
        const newState = JSON.parse(JSON.stringify(prev))
        if (e.target.value === "") {// check if empty, input type number is weird and take into account strings
          newState[1][surface][idx] = "0"
        } else {
          const strValue = e.target.value.replace(/^0+/, '') || "0"
          newState[1][surface][idx] = strValue
        }
        return [...newState];
    });
    }
  };

    const validPrix = () => {
        if (prixPep[0] == undefined || prixPep[0] == "") {
            setPageError(true);
        } else {
            setPageError(false)
            mutationPrixPost.mutate(prixPep)
        }
  };


  return (
    <div className={styles.table}>
      <table className={styles.tableSearch}>
        <thead>
          <tr>
          <th style={{ width: "25%" }}></th>
          <th style={{ width: "25%" }}></th>
          <th style={{ width: "25%" }}></th>
          <th style={{ width: "25%" }}></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>Date de début</b>
            </td>
            <td>
              <input
                type="date"
                id="prixDdeb"
                name="prixDdeb"
                data-testid="prixDdeb"
                value={prixPep[0]}
                onChange={(e) => change(e)}
                className={styles.input}
              />
              {(prixPep[0] == undefined || prixPep[0] == "") &&
                  pageError == true && <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>}
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
              <b>Superficie</b>
            </td>
            <td>
              <b>Montant Année 1</b>
            </td>
            <td>
              <b>Montant Année 2</b>
            </td>
            <td>
              <b>Montant Année 3</b>
            </td>
          </tr>
          {prixPep.length != 0 &&
            Object.entries(prixPep[1]).map(([key, prices]) => {
              return (
                <tr key={key}>
                  <td>
                    <b>{key}m²</b>
                  </td>
                  <td>
                    <input
                      type="number"
                      id={`${key}prixAn1`}
                      name={`${key}prixAn1`}
                      data-testid={`${key}prixAn1`}
                      min="0"
                      value={prices[0]}
                      onChange={(e) => change(e, 0, key)}
                      className={styles.input}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      id={`${key}prixAn2`}
                      name={`${key}prixAn2`}
                      min="0"
                      data-testid={`${key}prixAn2`}
                      value={prices[1]}
                      onChange={(e) => change(e, 1, key)}
                      className={styles.input}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      id={`${key}prixAn3`}
                      name={`${key}prixAn3`}
                      min="0"
                      data-testid={`${key}prixAn3`}
                      value={prices[2]}
                      onChange={(e) => change(e, 2, key)}
                      className={styles.input}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <button className={styles.buttonValid} onClick={() => validPrix()} disabled={mutationPrixPost.isPending}>Enregistrer</button>
      <div className={styles.bounce}>
      {(mutationPrixPost.isPending == true) && 
        <BounceLoader color="black" />
        }
      </div>
    </div>
  );
}

