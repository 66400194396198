import styles from "./PMInfos.module.css";
import countryList from "react-select-country-list";
import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

export default function CreationPMInfos({
  changeFn,
  inputValues,
  pageError,
}) {

  async function getCodeAPE() {
    const link = `${process.env.REACT_APP_API_URL}/tiers/codeAPE`;
    // const link = "http://localhost:8080/tiers/codeAPE";
    const response = await fetch(link);
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const data = await response.json();
    return data;
  }

  const { data: codeAPEList = [] } = useQuery({
    queryKey: ["codeAPE"],
    queryFn: () => getCodeAPE(),
    staleTime:Infinity,
    cacheTime:Infinity,
    refetchOnWindowFocus: false
  });


  return (
    <div className={styles.container}>
    <p className={styles.textTitle}>INFORMATIONS SUR LA PERSONNE MORALE</p>
    <div className={styles.table}>
      <table className={styles.tableSearch}>
        <thead>
          <th style={{ width: "20%" }}></th>
          <th style={{ width: "30%" }}></th>
          <th style={{ width: "20%" }}></th>
          <th style={{ width: "30%" }}></th>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>Dénomination entreprise*</b>
            </td>
            <td>
                <input
                  type="text"
                  id="tipmRso"
                  name="tipmRso"
                  value={inputValues["tipmRso"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
              {(inputValues["tipmRso"] == undefined ||
                inputValues["tipmRso"] == "") &&
                pageError == true && (
                  <small style={{ marginLeft: "1rem" , color:"red"}}>
                    Ce champs est requis
                  </small>
                )}
            </td>
            <td>
              <b>Sigle</b>
            </td>
            <td>
                <input
                  type="text"
                  id="tipmSigle"
                  name="tipmSigle"
                  value={inputValues["tipmSigle"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
            </td>
          </tr>
          <tr>
            <td>
              <b>Statut juridique*</b>
            </td>
            <td>
                <select
                      value={inputValues["tipmForjur"]}
                      onChange={(e) => changeFn(e)}
                      name="tipmForjur"
                      id="tipmForjur"
                      className={styles.inputSelect}
                    >
                      <option value="">----------------------</option>
                      <option value="EI">EI</option>
                      <option value="EI micro">EI micro</option>
                      <option value="Association">Association</option>
                      <option value="EURL">EURL</option>
                      <option value="SARL">SARL</option>
                      <option value="SASU">SASU</option>
                      <option value="SAS">SAS</option>
                      <option value="SA">SA</option>
                      <option value="SNC">SNC</option>
                      <option value="SCS">SCS</option>
                      <option value="SCA">SCA</option>
                      <option value="Champ Libre">Champ libre</option>
                </select>
                {inputValues["tipmForjur"] == "Champ Libre" && (
                <input
                  type="text"
                  id="champLibre"
                  name="champLibre"
                  value={inputValues["champLibre"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                  style={{marginLeft : "1rem"}}
                />
              )}

              {(inputValues["tipmForjur"] == undefined ||
                inputValues["tipmForjur"] == "") &&
                pageError == true && (
                  <small style={{ marginLeft: "1rem", color:"red" }}>
                    Ce champs est requis
                  </small>
              )}
            </td>
            <td>
              <b>Activité(s)*</b>
            </td>
            <td>
                <input
                  type="text"
                  id="tipmAct"
                  name="tipmAct"
                  value={inputValues["tipmAct"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
              {(inputValues["tipmAct"] == undefined ||
                inputValues["tipmAct"] == "") &&
                pageError == true && (
                  <small style={{ marginLeft: "1rem", color:"red" }}>
                    Ce champs est requis
                  </small>
                )}
            </td>
          </tr>
          <tr>
            <td>
              <b>Date de création*</b>
            </td>
            <td>
                <input
                  type="date"
                  id="tipmDatcre"
                  name="tipmDatcre"
                  value={inputValues["tipmDatcre"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
              {(inputValues["tipmDatcre"] == undefined ||
                inputValues["tipmDatcre"] == "") &&
                pageError == true && (
                  <small style={{ marginLeft: "1rem", color:"red" }}>
                    Ce champs est requis
                  </small>
                )}
            </td>
            <td>
              <b>SIRET*</b>
            </td>
            <td>
                <input
                  type="email"
                  id="tipmNumsir"
                  name="tipmNumsir"
                  value={inputValues["tipmNumsir"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
              {(inputValues["tipmNumsir"] == undefined ||
                inputValues["tipmNumsir"] == "") &&
                pageError == true && (
                  <small style={{ marginLeft: "1rem", color:"red" }}>
                    Ce champs est requis
                  </small>
                )}
            </td>
          </tr>
          <tr>
            <td>
              <b>Activité Principale Exercée (APE)*</b>
            </td>
            <td>
                <select
                  onChange={(e) => changeFn(e)}
                  value={inputValues["tipmCodape"]}
                  id="tipmCodape"
                  name="tipmCodape"
                  className={styles.input}
                >
                  <option value="">--------</option>
                  {codeAPEList.map((data) => {
                    return (
                      <option key={data["Code APE"]} value={data["Code APE"]}>
                        {data["Code APE"]} - {data[" Intitulés de la NAF"]}
                      </option>
                    );
                  })}
                </select>
              {(inputValues["tipmCodape"] == undefined ||
                inputValues["tipmCodape"] == "") &&
                pageError == true && (
                  <small style={{ marginLeft: "1rem", color:"red" }}>
                    Ce champs est requis
                  </small>
                )}
            </td>
            <td>
              <b>Adresse</b>
            </td>
            <td>
              <div className={styles.inputValue}>
                <div className={styles.inputValue}>
                  {(inputValues["tipmnumVoie"] ? inputValues["tipmnumVoie"] : "") +
                    " " +
                    (inputValues["tipmtypVoie"] ? inputValues["tipmtypVoie"] : "") +
                    " " +
                    (inputValues["tipmintVoie"] ? inputValues["tipmintVoie"] : "") +
                    " " +
                    (inputValues["tipmcomplvoie"] ? inputValues["tipmcomplvoie"] : "")}
                </div>
              </div>
            </td>
          </tr>
            <>
              <tr>
                <td>
                  <b>N° de voie*</b>
                </td>
                <td>
                  <input
                    type="text"
                    id="tipmnumVoie"
                    name="tipmnumVoie"
                    value={inputValues["tipmnumVoie"]}
                    onChange={(e) => changeFn(e)}
                    className={styles.input}
                  />
                  {(inputValues["tipmnumVoie"] == undefined ||
                    inputValues["tipmnumVoie"] == "") &&
                    pageError == true && (
                      <small style={{ marginLeft: "1rem", color:"red" }}>
                        Ce champs est requis
                      </small>
                    )}
                </td>
                <td>
                  <b>Type de voie*</b>
                </td>
                <td>
                  <input
                    type="text"
                    id="tipmtypVoie"
                    name="tipmtypVoie"
                    value={inputValues["tipmtypVoie"]}
                    onChange={(e) => changeFn(e)}
                    className={styles.input}
                  />
                  {(inputValues["tipmtypVoie"] == undefined ||
                    inputValues["tipmtypVoie"] == "") &&
                    pageError == true && (
                      <small style={{ marginLeft: "1rem", color:"red" }}>
                        Ce champs est requis
                      </small>
                    )}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Intitulé de voie*</b>
                </td>
                <td>
                  <input
                    type="text"
                    id="tipmintVoie"
                    name="tipmintVoie"
                    value={inputValues["tipmintVoie"]}
                    onChange={(e) => changeFn(e)}
                    className={styles.input}
                  />
                  {(inputValues["tipmintVoie"] == undefined ||
                    inputValues["tipmintVoie"] == "") &&
                    pageError == true && (
                      <small style={{ marginLeft: "1rem", color:"red" }}>
                        Ce champs est requis
                      </small>
                    )}
                </td>
                <td>
                  <b>Complément de voie</b>
                </td>
                <td>
                  <input
                    type="text"
                    id="tipmcomplvoie"
                    name="tipmcomplvoie"
                    value={inputValues["tipmcomplvoie"]}
                    onChange={(e) => changeFn(e)}
                    className={styles.input}
                  />
                </td>
              </tr>
            </>
          <tr>
            <td>
              <b>Code postal*</b>
            </td>
            <td>
                <input
                  type="text"
                  id="tipmcodPos"
                  name="tipmcodPos"
                  value={inputValues["tipmcodPos"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
              {(inputValues["tipmcodPos"] == undefined ||
                inputValues["tipmcodPos"] == "") &&
                pageError == true && (
                  <small style={{ marginLeft: "1rem", color:"red" }}>
                    Ce champs est requis
                  </small>
                )}
            </td>
            <td>
              <b>Commune*</b>
            </td>
            <td>
                <input
                  type="text"
                  id="tipmcomun"
                  name="tipmcomun"
                  value={inputValues["tipmcomun"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
              {(inputValues["tipmcomun"] == undefined ||
                inputValues["tipmcomun"] == "") &&
                pageError == true && (
                  <small style={{ marginLeft: "1rem", color:"red" }}>
                    Ce champs est requis
                  </small>
                )}
            </td>
          </tr>
          <tr>
            <td>
              <b>QPV</b>
            </td>
            <td>
                <select
                  value={inputValues["tipmQpv"]}
                  onChange={(e) => changeFn(e)}
                  name="tipmQpv"
                  id="tipmQpv"
                  className={styles.inputSelect}
                >
                  <option value="">----------------------</option>
                  <option value="Oui">Oui</option>
                  <option value="Non">Non</option>
                </select>
            </td>
            <td>
              <b>ZFU</b>
            </td>
            <td>
                <select
                  value={inputValues["tipmZfu"]}
                  onChange={(e) => changeFn(e)}
                  name="tipmZfu"
                  id="tipmZfu"
                  className={styles.inputSelect}
                >
                  <option value="">----------------------</option>
                  <option value="Oui">Oui</option>
                  <option value="Non">Non</option>
                </select>
            </td>
          </tr>
          <tr>
            <td>
              <b>Courriel*</b>
            </td>
            <td>
                <input
                type="text"
                id="tipmEmail"
                name="tipmEmail"
                value={inputValues["tipmEmail"]}
                onChange={(e) => changeFn(e)}
                className={styles.input}
              />
             {(inputValues["tipmEmail"] == undefined || inputValues["tipmEmail"] == "")  && pageError == true && (
                <small style={{marginLeft: "1rem", color:"red"}}>Ce champs est requis</small>
              )}
            </td>
            <td>
              <b>Téléphone*</b>
            </td>
            <td>
                <input
                type="text"
                id="tipmTelpor"
                name="tipmTelpor"
                value={inputValues["tipmTelpor"]}
                onChange={(e) => changeFn(e)}
                className={styles.input}
                />
            {(inputValues["tipmTelpor"] == undefined || inputValues["tipmTelpor"] == "")  && pageError == true && (
                <small style={{marginLeft: "1rem", color:"red"}}>Ce champs est requis</small>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <b>Montant du capital</b>
            </td>
            <td>
              <input
                type="number"
                id="tipmMntcap"
                name="tipmMntcap"
                value={inputValues["tipmMntcap"]}
                onChange={(e) => changeFn(e)}
                className={styles.input}
                min="0"
                />
            </td>
            <td>
              <b>Date de fin d'exercice</b>
            </td>
            <td>
                <input
                type="text"
                id="tipmDatFe"
                name="tipmDatFe"
                value={inputValues["tipmDatFe"]}
                onChange={(e) => changeFn(e)}
                className={styles.input}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    </div>
  );
}
