import styles from "./ugEqVisu.module.css";
import poubBleu from "../../../assets/poubBleu.png"

export default function UgEqVisu({ inputValues, removeOverlayFn }) {
  
  return (
    <div className={styles.table}>
      {inputValues.length != 0 && 
      <table className={styles.tableSearch}>
        <thead>
          <tr>
          <th style={{width:"30%"}}>
              <b>Nature</b>
            </th>
            <th style={{width:"30%"}}>
            <b>Référence</b>
            </th>
            <th style={{width:"30%"}}>
            <b>Prix</b> 
            </th>
            <th style={{width:"10%"}}>
            </th>
          </tr>
        </thead>
        <tbody>
         {inputValues.map((data, idx) => {
           return (<tr key={data["eqRef"]}>
           <td>
             {data["eqNat"]}
           </td>
           <td>
            {data["eqRef"]}
           </td>
           <td>
            {data["eqPrix"]}
           </td>
           <td >
            <div className={styles.logoContainer}>
            {/* <img src={modifBleu} className={styles.logo} /> */}
            <img src={poubBleu} className={styles.logo} onClick={() => removeOverlayFn(inputValues[idx])} />
            </div>
           </td>
         </tr>)
         })}       
        </tbody>
      </table>}
  </div>

  );
}

// <div className={styles.container}>
// <div className={styles.fieldContainer}>
//   <p className={styles.label}>Nature</p>
//   {/* {editable ? (
//     <select value={inputValues["eqNat"]} onChange={(e) => changeFn(e, idx)} name="eqNat" id="eqNat" className={styles.input}>
//         <option value="" selected>---------------</option> 
//         {allNats.length != 0 && allNats.map((data) => {
//                 return <option value={data.eqNat} key={data.eqNat}>{data.eqNat}</option>
//         })}
//     </select>
//   ) : ( */}
//     <div className={styles.inputValue}>{inputValues["eqNat"]}</div>
//   {/* )} */}
// </div>
// <div className={styles.fieldContainer}>
//   <p className={styles.label}>Référence</p>
//   {/* {editable ? (
//     <input
//       type="text"
//       id="eqRef"
//       name="eqRef"
//       value={inputValues["eqRef"]}
//       onChange={(e) => changeFn(e, idx)}
//       className={styles.input}
//     />
//   ) : ( */}
//     <div className={styles.inputValue}>{inputValues["eqRef"]}</div>
//   {/* )} */}
// </div>
// <div className={styles.fieldContainer}>
//   <p className={styles.label}>Prix</p>
//   {editable ? (
//     <input
//       type="number"
//       id="eqPrix"
//       name="eqPrix"
//       value={inputValues["eqPrix"]}
//       onChange={(e) => changeFn(e, idx)}
//       className={styles.input}
//     />
//   ) : (
//     <div className={styles.inputValue}>{inputValues["eqPrix"]}</div>
//   )}
//     {((inputValues["eqPrix"] == undefined || inputValues["eqPrix"] == "")  && pageError == true) && <small>Ce champs est requis</small>}
// </div>
// <div className={styles.fieldContainer}>
//   <p className={styles.label}>Date début</p>
//   <div className={styles.inputValue}>{inputValues["eqDatdeb"] ? inputValues["eqDatdeb"].split('-').reverse().join('-') : null}</div>
// </div>
// <div className={styles.fieldContainer}>
//   <p className={styles.label}>Date fin</p>
//   {editable ? (
//     <input
//       type="date"
//       id="eqDatfin"
//       name="eqDatfin"
//       value={inputValues["eqDatfin"]}
//       onChange={(e) => changeFn(e, idx)}
//       className={styles.input}
//       style={{width: "100%"}}
//     />
//   ) : (
//     <div className={styles.inputValue}>{inputValues["eqDatfin"] ? inputValues["eqDatfin"].split('-').reverse().join('-') : null}</div>
//   )}
// </div>
// <div className={styles.fieldContainer}>
//   <p className={styles.label}>Activité</p>
//   <div className={styles.inputValue}>{inputValues["eqDatfin"] == "" ? "Actif" : new Date(inputValues["eqDatfin"]) < new Date(formattedDate) ?  "Inactif" : null }</div>
// </div>
// {/* <button className={styles.buttonRemove} onClick={() => mutationDataDelete.mutate(inputValues)}>Supprimer</button> */}
// </div>
