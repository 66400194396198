import ReactDOM from "react-dom";
import styles from "./overlayAddUpdateStatutPost.module.css";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { BounceLoader } from "react-spinners";

export default function OverlayAddUpdateStatutPost({
  openOverlayFn,
  statutPostToModify,
}) {
  const { id } = useParams();
  const [newStatutPost, setnewSortie] = useState({
    tipmId: id,
    actDat:
      statutPostToModify.length != 0 ? statutPostToModify[0]["actDat"] : "",
    statut:
      statutPostToModify.length == 0
        ? ""
        : ["Radiée", "En sommeil", "Liquidation"].includes(
            statutPostToModify[0]["statut"]
          ) == false
        ? "Champ Libre"
        : statutPostToModify[0]["statut"],
    champLibre:
      statutPostToModify.length == 0
        ? ""
        : ["Radiée", "En sommeil", "Liquidation"].includes(
            statutPostToModify[0]["statut"]
          )
        ? ""
        : statutPostToModify[0]["statut"],
  });

  const [pageError, setPageError] = useState(false);
  const queryClient = useQueryClient();

  const changeStatutPost = (e) => {
    const inputValue = e.target.value.trim();
    const formattedValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1).toLowerCase();
    setnewSortie((prev) => {
      return { ...prev, [e.target.name]: formattedValue };
    });
  };

  const checkAndSend = () => {
    if (
      newStatutPost["actDat"] == "" ||
      newStatutPost["statut"] == "" ||
      (newStatutPost["statut"] == "Champ Libre" &&
        newStatutPost["champLibre"] == "")
    ) {
      setPageError(true);
    } else {
      setPageError(false);
      if (statutPostToModify.length == 0) {
        mutationPostStatutPost.mutate(newStatutPost);
      } else {
        mutationUpdateStatutPost.mutate(newStatutPost);
      }
    }
  };

  async function addStatutPost(data) {
    const link = `${process.env.REACT_APP_API_URL}/tiers/statutPost`
    // const link = `http://localhost:8080/tiers/statutPost`;
    const response = await fetch(link, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const dataReceived = await response.json();
    return dataReceived;
  }

  const mutationPostStatutPost = useMutation({
    mutationFn: (data) => addStatutPost(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["Tiers", id]);
      openOverlayFn((prev) => !prev);
    },
  });

  async function ModifyStatutPost(data) {
    // const link = `https://carco-app.vercel.app/tiers/statutPost`
    const link = `${process.env.REACT_APP_API_URL}/tiers/statutPost`;
    const response = await fetch(link, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const dataReceived = await response.json();
    return dataReceived;
  }

  const mutationUpdateStatutPost = useMutation({
    mutationFn: (data) => ModifyStatutPost(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["Tiers", id]);
      openOverlayFn((prev) => !prev);
    },
  });

  console.log(newStatutPost)
  return ReactDOM.createPortal(
    <div className={styles.overlay} onClick={() => openOverlayFn(false)}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <p className={styles.textOverlayTitle}>
          {statutPostToModify.length != 0 ? "MODIFIER" : "AJOUTER"} STATUT
          POST-PÉPINIÈRE
        </p>
        <div>
          <div className={styles.rowContainer}>
            <div className={styles.fieldContainer}>
              <label className={styles.label} htmlFor="actDat">
                Date d'actualisation*
              </label>
              <input
                type="date"
                id="actDat"
                name="actDat"
                value={newStatutPost["actDat"]}
                onChange={(e) => changeStatutPost(e)}
                className={styles.input}
              />
              {newStatutPost["actDat"] == "" && pageError == true && (
                <small className={styles.small}>Ce champs est requis</small>
              )}
            </div>
          </div>
          <div className={styles.rowContainer}>
            <div className={styles.fieldContainer}>
              <label className={styles.label} htmlFor="statut">
                Statut*
              </label>
              <select
                name="statut"
                id="statut"
                onChange={(e) => changeStatutPost(e)}
                value={newStatutPost["statut"]}
                className={styles.inputSelect}
              >
                <option value="">-----------------</option>
                <option value="En sommeil">En sommeil</option>
                <option value="Radiée">Radiée</option>
                <option value="Liquidation">Liquidation</option>
                {!["", "En sommeil", "Radiée", "Liquidation"].includes(newStatutPost["statut"]) && <option value={newStatutPost["champLibre"]}>{newStatutPost["champLibre"]}</option>}
                <option value="Champ libre">Champ Libre</option>
              </select>
              {newStatutPost["statut"] == "Champ libre" && (
                <input
                  type="text"
                  id="champLibre"
                  name="champLibre"
                  value={newStatutPost["champLibre"]}
                  onChange={(e) => changeStatutPost(e)}
                  className={styles.input}
                  // style={{"textTransform": "uppercase"}}
                />
              )}
              {newStatutPost["statut"] == "" && pageError == true && (
                <small className={styles.small}>Ce champs est requis</small>
              )}
              {newStatutPost["statut"] == "Champ Libre" &&
                pageError == true &&
                newStatutPost["champLibre"] == "" && (
                  <small className={styles.small}>Ce champs est requis</small>
                )}
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          {statutPostToModify.length == 0 ? (
            <button
              className={styles.buttonValid}
              onClick={() => checkAndSend()}
            >
              Ajouter
            </button>
          ) : (
            <button
              className={styles.buttonValid}
              onClick={() => checkAndSend()}
            >
              Modifier
            </button>
          )}
          <button
            className={styles.buttonValid}
            onClick={() => openOverlayFn(false)}
          >
            Retour
          </button>
        </div>
        {mutationPostStatutPost.isPending == true && (
          <div className={styles.bounce}>
            <BounceLoader color="white" />
          </div>
        )}
        {mutationUpdateStatutPost.isPending == true && (
          <div className={styles.bounce}>
            <BounceLoader color="white" />
          </div>
        )}
      </div>
    </div>,
    document.getElementById("overlay")
  );
}
