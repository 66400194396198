import styles from "./visualisationTiers.module.css";
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import modifLogo from "../../assets/modifLogo.png";
import saveLogo from "../../assets/saveLogo.png";
import shareLogo from "../../assets/shareLogo.png";
import mailLogo from "../../assets/mailBlanc.png";
import { BounceLoader } from "react-spinners";
import PPVisuNew from "../../components/tiersVisu/PPVisuNew/PPVisuNew";
import InfosAccSouhait from "../../components/tiersVisu/InfosAccSouhait/InfosAccSouhait";
import AutresInfos from "../../components/tiersVisu/AutresInfos/AutresInfos";
import FormulePorteur from "../../components/tiersVisu/FormulePorteurProjet/FormulePorteur";
import FichiersVisu from "../../components/VisuUG/fichiersVisu/fichiersVisu";
import OverlayRemoveFileTiers from "../../components/tiersVisu/overlayRemoveFile/overlayRemoveFileTiers";
import importerBlanc from "../../assets/importerBlanc.png";
import wordLogo from "../../assets/word.png";
import excelLogo from "../../assets/excel.png";
import pdfLogo from "../../assets/pdf.png";
import docLambda from "../../assets/documentLambda.png";
import OverlayAddPrj from "../../components/tiersVisu/overlayAddPrj/overlayAddPrj";
import ProjetsVisu from "../../components/tiersVisu/ProjetsVisu/projetsVisu";
import OverlayRemovePr from "../../components/tiersVisu/overlayRemoveProjet/overlayRemovePr";
import SuiviAcc from "../../components/tiersVisu/SuiviAcc/SuiviAcc";
import AddAccSuivi from "../../components/tiersVisu/AddAccSuivi/AddAccSuivi";
import PMVisuNew from "../../components/tiersVisu/PMVisuNew/PMVisuNew";
import DirigeantsVisu from "../../components/tiersVisu/DirigeantsVisu/DirigeantsVisu";
import OverlayAddDir from "../../components/tiersVisu/overlayAddDirigeant/overlayAddDir";
import OverlayRemoveDir from "../../components/tiersVisu/overlayRemoveDir/overlayRemoveDir";
import EffcetifsVisu from "../../components/tiersVisu/EffectifsVisu/EffectifsVisu";
import OverlayAddEff from "../../components/tiersVisu/overlayAddEff/overlayAddEff";
import OverlayRemoveEff from "../../components/tiersVisu/overlayRemoveEff/overlayRemoveEff";
import OverlayAddCA from "../../components/tiersVisu/overlayAddCA/overlayAddCA";
import CAVisu from "../../components/tiersVisu/CAVisu/CAVisu";
import OverlayRemoveCA from "../../components/tiersVisu/overlayRemoveCA/overlayRemoveCA";
import OverlayAddForm from "../../components/tiersVisu/overlayAddForm/overlayAddForm";
import SortieVisu from "../../components/tiersVisu/SortieVisu/SortieVisu";
import StatutPostVisu from "../../components/tiersVisu/StatutPostVisu/StatutPost";
import OverlayAddUpdateSortie from "../../components/tiersVisu/overlayAddUpdateSortiePep/overlayAddUpdateSortie";
import OverlayAddUpdateStatutPost from "../../components/tiersVisu/overlayAddUpdateStatutPost/overlayAddUpdateStatutPost";
import OverlayRemoveForm from "../../components/tiersVisu/overlayRemoveForm/overlayRemoveForm";

export default function VisualisationTiers() {
  const { id } = useParams();
  const [editable, setEditable] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const [pageError, setPageError] = useState(false)
  const [ficheClicked, setFicheClicked] = useState(true);
  const [addAccClicked, setAddAccClicked] = useState(false);
  const [newFormule, setNewFormule] = useState(false);
  const [newProjet, setNewProjet] = useState(false);
  const [newDirigeant, setNewDirigeant] = useState(false);
  const [newEffectif, setNewEffectif] = useState(false);
  const [newSortie, setNewSortie] = useState(false);
  const [newStatutPost, setNewStatutPost] = useState(false);
  const [newCa, setNewCa] = useState(false);
  const [removeFile, setRemoveFile] = useState(false);
  const [fileToRemove, setFileToRemove] = useState([]);
  const [removeProjet, setRemoveProjet] = useState(false);
  const [removeEff, setRemoveEff] = useState(false);
  const [removeDir, setRemoveDir] = useState(false);
  const [removeFormule, setRemoveFormule] = useState(false);
  const [removeCa, setRemoveCa] = useState(false);
  const [prToRemove, setPrToRemove] = useState({});
  const [dirToRemove, setDirToRemove] = useState({});
  const [formuleToRemove, setFormuleToRemove] = useState({});
  const [effToRemove, setEffToRemove] = useState({});
  const [caToRemove, setCaToRemove] = useState({});
  const [IsLoadingPostFile, setIsLoadingPostFile] = useState(false);
  const inputFile = useRef(null)
  const queryClient = useQueryClient();
  const location = useLocation()
  const path = process.env.REACT_APP_API_URL == "production" ? "https://carcoapp.mbe-consult.fr" + location.pathname : "http://localhost:3000" + location.pathname


  async function updateP(data) {
    const link = `${process.env.REACT_APP_API_URL}/tiers/updateP`
    // const link = "http://localhost:8080/tiers/updateP";
    const response = await fetch(link, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }

    const dataReceived = await response.json();
    return dataReceived;
  }

  const mutationDataUpdate = useMutation({
    mutationFn: (data) => updateP(data),
    // onMutate: async (newData) => {
    //   await queryClient.cancelQueries({ queryKey: ["Tiers", id] });
    //   const prevInputValues = queryClient.getQueryData(["Tiers", id]);

    //   queryClient.setQueryData(["Tiers", id], {
    //     infos: newData["infos"],
    //     PP: newData["PP"],
    //     projets: newData["projets"],
    //     accSouhait: newData["accSouhait"]
    //   });

    //   return {
    //     previousInputValues: prevInputValues,
    //   };
    // },
    // onError: (err, newData, context) => {
    //   setInputValues(context.previousInputValues);
    //   setEditable(!editable)
    // },
    onSuccess: () => {
      queryClient.invalidateQueries(["Tiers", id]);
      setEditable(!editable)
    },
  });

  async function getFiles(id) {
    const link = `${process.env.REACT_APP_API_URL}/tiers/files/${id}`
    // const link = `http://localhost:8080/tiers/files/${id}`;
    const response = await fetch(link);
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const data = await response.json();
    const listFiles = [];
    const formatsWord = [
      ".doc",
      ".docx",
      ".dotx",
      ".dotm",
      ".docm",
      ".rtf",
      ".txt",
      ".xml",
    ];
    const excelFormats = [
      ".xls",
      ".xlsx",
      ".xlsm",
      ".xlsb",
      ".xlt",
      ".xltx",
      ".xltm",
      ".csv",
      ".xml",
    ];
    const pdfFormat = [".pdf"];
    for (let obj of data) {
      const link = obj["url"];
      const filename = obj["filename"];
      if (formatsWord.some((format) => filename.includes(format))) {
        listFiles.push([wordLogo, link, filename]);
      } else if (excelFormats.some((format) => filename.includes(format))) {
        listFiles.push([excelLogo, link, filename]);
      } else if (pdfFormat.some((format) => filename.includes(format))) {
        listFiles.push([pdfLogo, link, filename]);
      } else {
        listFiles.push([docLambda, link, filename]);
      }
    }
    return listFiles;
  }

  const { data: files = [], isLoading: isLoadingFiles } = useQuery({
    queryKey: ["Tiers", "Files", id],
    queryFn: () => getFiles(id),
    refetchOnWindowFocus: false,
  });

  const handleFileUpload = async (event) => {
    setIsLoadingPostFile(true);
    const files = event.target.files;
  
    try {
      const presignedUrlPromises = Array.from(files).map(file => {
        return fetch(`${process.env.REACT_APP_API_URL}/tiers/generatePresignedUrl/${id}/${encodeURIComponent(file.name)}`)
          .then(response => response.json());
      });

      const presignedUrls = await Promise.all(presignedUrlPromises);

      const uploadPromises = presignedUrls.map((presignedUrl, index) => {
        return fetch(presignedUrl.url, {
          method: 'PUT',
          headers: {
            'Content-Type': files[index].type,
          },
          body: files[index],
        });
      });

      const uploadResponses = await Promise.all(uploadPromises);
  
      const allUploadsSuccessful = uploadResponses.every(response => response.ok);
  
      if (allUploadsSuccessful) {
        queryClient.invalidateQueries(["Tiers", "Files", id]);
        // console.log('All files uploaded successfully');
      } else {
        console.error('File upload failed for some files');
      }
    } catch (error) {
      console.error('Error during file upload:', error);
    } finally {
      setIsLoadingPostFile(false);
    }
  };

  // const handleFileUpload = async (event) => {
  //   setIsLoadingPostFile(true);
  //   const files = event.target.files;
  //   const formData = new FormData();
  //   for (let i = 0; i < files.length; i++) {
  //     formData.append("files", files[i]);
  //   }
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_API_URL}/tiers/uploadFiles/${id}`, {
  //         method: 'POST',
  //         body: formData,
  //     });
  //     // const response = await fetch(
  //     //   `http://localhost:8080/tiers/uploadFiles/${id}`,
  //     //   {
  //     //     method: "POST",
  //     //     body: formData,
  //     //   }
  //     // );
  //     if (response.ok) {
  //       queryClient.invalidateQueries(["Tiers", "Files", id]);
  //     } else {
  //       console.error("File upload failed:", response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error during file upload:", error);
  //   } finally {
  //     setIsLoadingPostFile(false); // End loading
  //   }
  // };

  async function getInfos(id) {
    // setInputValues({})
    const link = `${process.env.REACT_APP_API_URL}/tiers/infos/${id}`
    // const link = `http://localhost:8080/tiers/infos/${id}`;
    const response = await fetch(link);
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const data = await response.json();
    // setInputValues({})
    setInputValues(data)
    return data;
  }

  const { data: infos = { }, isLoading } = useQuery({
    queryKey: ["Tiers", id],
    queryFn: () => getInfos(id),
    refetchOnWindowFocus: false,
  });

  // useEffect(() => {
  //   if (infos) { // && Object.keys(infos).length !== Object.keys(inputValues).length
  //     setInputValues(infos)
  //   }
  // }, [infos]);


  async function getSuivi(id) {
    const link = `${process.env.REACT_APP_API_URL}/tiers/suivi/${id}`
    // const link = `http://localhost:8080/tiers/suivi/${id}`;
    const response = await fetch(link);
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const data = await response.json();
    return data;
  }

  const { data: suivi = [], isLoadingSuivi } = useQuery({
    queryKey: ["Suivi", id],
    queryFn: () => getSuivi(id),
    refetchOnWindowFocus: false,
    enabled: inputValues["PP"] == true
  });

  const checkAndSend = (inputValues) => {
    if (inputValues["infos"]["tippCiv"] == "" || inputValues["infos"]["tippPrenom"] == "" || inputValues["infos"]["tippNom"] == "" //|| inputValues["infos"]["tippDatnai"] == ""
    || inputValues["infos"]["tippEmail"] == "" || inputValues["infos"]["tippTelpor"] == "" || inputValues["infos"]["codPos"] == "" || inputValues["infos"]["comun"] == "" || inputValues["infos"]["intVoie"] == "" || inputValues["infos"]["typVoie"] == "" || inputValues["infos"]["numVoie"] == "") {
    setPageError(true)
  } else {
    setPageError(false)
    mutationDataUpdate.mutate(inputValues)
    }
  }

  function copyTextToClipboard(text) {
    navigator.clipboard.writeText(text).then(() => {
      console.log("Text copied to clipboard successfully!");
    }).catch(err => {
      console.error("Failed to copy text to clipboard", err);
    });
  }

  const handleInputChange = (e, key = "infos") => {
    const { name, value, checked, type } = e.target;
  
    setInputValues((prev) => {
      const newState = JSON.parse(JSON.stringify(prev));
  
      if (key === "infos") {
        if (name == "tippPrenom" || name == "tippNom" || name == "tippNomnai" || name == "typVoie" || name == "intVoie" || name == "complvoie" || name == "comun") {
          newState.infos[name] = value.toUpperCase()
        } else {
          newState.infos[name] = value
        }
      } else if (key === "accSouhait") {
        if (name === "DatEntSouhait") {
          newState.accSouhait[name] = value;
        } else {
          const key2 = ["Bureau", "Coworking", "Extra-Muros", "Bureau Partagé"].includes(name) ? "FormSouhait" : "SurfSouhait";
          newState.accSouhait[key2][name] = type === 'checkbox' ? checked : value;
        }
      }
      return newState;
    });
  };

  const handleInputChangePM = (e, key = "infos") => {
    const { name, value, checked, type } = e.target;
  
    setInputValues((prev) => {
      const newState = JSON.parse(JSON.stringify(prev));
  
      if (key === "infos") {
        if (name == "tipmRso" || name == "tipmSigle" ||  name == "tipmtypVoie" || name == "tipmintVoie" || name == "tipmcomplvoie" || name == "tipmcomun") {
          newState.infos[name] = value.toUpperCase()
        } else {
          newState.infos[name] = value;
        }
      } 
      // else if (key === "accSouhait") {
      //   if (name === "DatEntSouhait") {
      //     newState.accSouhait[name] = value;
      //   } else {
      //     const key2 = ["Bureau", "Coworking", "Extra-Muros", "Bureau Partagé"].includes(name) ? "FormSouhait" : "SurfSouhait";
      //     newState.accSouhait[key2][name] = type === 'checkbox' ? checked : value;
      //   }
      // }
      return newState;
    });
  };

  const removeFileOverlayFn = (data = []) => {
    setFileToRemove(data);
    setRemoveFile(!removeFile);
  };

  const removePrOverlayFn = (data = {}) => {
    setPrToRemove(data);
    setRemoveProjet(!removeProjet);
  };

  const removeDirOverlayFn = (data = {}) => {
    setDirToRemove(data);
    setRemoveDir(!removeDir);
  };

  const removeFormuleOverlayFn = (data = {}) => {
    setFormuleToRemove(data);
    setRemoveFormule(!removeFormule);
  };


  const modifDirOverlayFn = (data = {}) => {
    setDirToRemove(data);
    setNewDirigeant(!newDirigeant);
  };

  const removeEffOverlayFn = (data = {}) => {
    setEffToRemove(data);
    setRemoveEff(!removeEff);
  };

  const modifEffOverlayFn = (data = {}) => {
    setEffToRemove(data);
    setNewEffectif(!newEffectif);
  };

  const removeCaOverlayFn = (data = {}) => {
    setCaToRemove(data);
    setRemoveCa(!removeCa);
  };

  const modifCaOverlayFn = (data = {}) => {
    setCaToRemove(data);
    setNewCa(!newCa);
  };

  const modifFormuleOverlayFn = (data = {}) => {
    // setFormuleToRemove(data);
    setNewFormule(!newFormule);
  };


  return (
    <div className={styles.container}>
      {(inputValues["infos"] && inputValues["PP"]) && (
        <>
        {removeFile && (
          <OverlayRemoveFileTiers
            removeOverlayFn={removeFileOverlayFn}
            fileToRemove={fileToRemove}
          />
        )}
        {removeProjet && (
          <OverlayRemovePr
            removeOverlayFn={removePrOverlayFn}
            prToRemove={prToRemove}
          />
        )}

        {removeFormule && (
        <OverlayRemoveForm
          removeOverlayFn={removeFormuleOverlayFn}
          formToRemove={formuleToRemove}
          formulesToKeep={inputValues["formules"].filter((data) => data.formId != formuleToRemove["formId"])}
        />
        )}

        {newProjet && (
          <OverlayAddPrj
            openOverlayFn={setNewProjet}
            nbprjs={inputValues["projets"].length}
          />
        )}
        {newFormule && (
          <OverlayAddForm
            openOverlayFn={modifFormuleOverlayFn}
            relations={inputValues["relations"]}
            formules={inputValues["formules"]}
          />
        )}


        
          <div className={styles.box}>
            <p className={styles.textHeader}>
            {inputValues["infos"]["tippNom"] +
                " " +
                inputValues["infos"]["tippPrenom"]}
            </p>
            <div className={styles.logoContainer}>
              {editable == false ? <img src={modifLogo} className={styles.logo} onClick={() => setEditable(!editable)} /> : <img src={saveLogo} className={styles.logo} onClick={() => {
    if (!mutationDataUpdate.isPending) {
      checkAndSend(inputValues)
    }
  }} />} 
            <img src={shareLogo} className={styles.logo} onClick={() => copyTextToClipboard(path)} />
            <a href={`mailto:${inputValues["infos"]["tippEmail"]}`}><img src={mailLogo} className={styles.logo} /></a>
            </div>
          </div>
          <div className={styles.boxesContainer}>
            <div
              className={`${ficheClicked ? styles.boxClicked : styles.boxNotClicked}`}
              onClick={() => setFicheClicked(true)}
            >
              <p
                className={`${ficheClicked ? styles.textClicked : styles.textNotClicked}`}
              >
                Fiche
              </p>
            </div>
            <div
              className={`${ficheClicked ? styles.boxNotClicked : styles.boxClicked}`}
              onClick={() => setFicheClicked(false)}
            >
              <p
                className={`${ficheClicked ? styles.textNotClicked : styles.textClicked}`}
              >
                Suivi Accompagnement
              </p>
            </div>
          </div>

          {ficheClicked == true ? 
          <>
          <div className={styles.mutationBounce}>
            <p className={styles.textTitle} >INFORMATIONS SUR LE PORTEUR DE PROJET</p>
                {mutationDataUpdate.isPending == true && (
                <div style={{height:"2rem"}}>
                  <BounceLoader color="black" />
                </div>
              )}
          </div>
          {/* <p className={styles.textTitle}>
            INFORMATIONS SUR LE PORTEUR DE PROJET
          </p>
          {(mutationDataUpdate.isPending == true) && 
              <div className={styles.bounce}>
                <BounceLoader color="black" />
              </div>
          } */}
          {inputValues["infos"] && (
            <PPVisuNew inputValues={inputValues["infos"]} editable={editable} changeFn={handleInputChange} pageError={pageError} />
          )}
          <p className={styles.textTitle}>
            INFORMATIONS SUR LE(S) PROJET(S)
          </p>
          <div className={styles.buttonAddContainer}>
            <button
              onClick={() => setNewProjet((prev) => !prev)}
              className={styles.buttonAdd}
            >
              Ajouter Projet
            </button>
          </div>
          {inputValues["projets"].length != 0 && (
            <ProjetsVisu projets={inputValues["projets"]} removeOverlayFn={removePrOverlayFn} />
          )}
          <p className={styles.textTitle}>
            INFORMATIONS ACCOMPAGNEMENT SOUHAITÉ
          </p>
          {inputValues["accSouhait"].length != 0 && (
            <InfosAccSouhait inputValues={inputValues["accSouhait"]} editable={editable} changeFn={handleInputChange} /> //A FINIR QUAND ILS MAURONT REPONDU
          )}
          <p className={styles.textTitle}>
            AUTRES INFORMATIONS
          </p>
          {inputValues["infos"] && (
            <AutresInfos inputValues={inputValues["infos"]} editable={editable} changeFn={handleInputChange} pageError={pageError} /> //A FINIR QUAND ILS MAURONT REPONDU
          )}
          <p className={styles.textTitle}>
            FORMULE DU PORTEUR DE PROJET          
          </p>
          <div className={styles.buttonAddContainer}>
            <button
              onClick={() => setNewFormule((prev) => !prev)}
              className={styles.buttonAdd}
            >
              Actualiser Formule
            </button>
          </div>
          {inputValues["formules"] && inputValues["formules"].length != 0 && (
            <FormulePorteur formules={inputValues["formules"]} removeOverlayFn={removeFormuleOverlayFn} />
          )}
          <p className={styles.textTitle}>FICHIERS</p>
          <input
            type="file"
            style={{ display: "none" }}
            ref={inputFile}
            onChange={handleFileUpload}
            multiple
          />
          <div
            className={styles.buttonAddContainer}
            style={{ marginBottom: `${files.length == 0 ? "10rem" : ""}` }}
          >
            <button
              onClick={() => inputFile.current.click()}
              className={styles.buttonImport}
            >
              <p>Importer</p>
              <img
                src={importerBlanc}
                className={styles.logo}
                style={{ height: "2rem" }}
              />
            </button>
          </div>
          {files.length != 0 && (
            <FichiersVisu fichiers={files} removeOverlayFn={removeFileOverlayFn} />
          )}
          {isLoadingFiles == true && (
            <div className={styles.bounce} style={{ marginBottom: "3rem" }}>
              <BounceLoader color="black" />
            </div>
          )}
          {IsLoadingPostFile == true && (
            <div className={styles.bounce} style={{ marginBottom: "3rem" }}>
              <BounceLoader color="black" />
            </div>
          )}
          
          </>
          :
          addAccClicked ?
          <>
            <AddAccSuivi setAddAccClicked={setAddAccClicked} />
          </>
          : 
          <>
          <div className={styles.buttonAddContainer} style={{marginTop:"2rem"}}>
            <button
              onClick={() => setAddAccClicked((prev) => !prev)}
              className={styles.buttonAdd}
            >
              Ajouter un accompagnement
            </button>
          </div>
          <SuiviAcc suivi={suivi} />
          {(isLoadingSuivi == true) && (
            <div className={styles.bounce}>
              <BounceLoader color="black" />
            </div>
          )}
          </>  
          }
          </> 
      )}
      {(inputValues["infos"] && inputValues["PP"] == false) && 
      <>
        {newDirigeant && (
          <OverlayAddDir
            openOverlayFn={modifDirOverlayFn}
            dirToModify={dirToRemove}
          />
        )}

        {newSortie && (
          <OverlayAddUpdateSortie
            openOverlayFn={setNewSortie}
            sortieToModify={inputValues["sortie"]}
          />
        )}

        {newStatutPost && (
          <OverlayAddUpdateStatutPost
            openOverlayFn={setNewStatutPost}
            statutPostToModify={inputValues["statutPost"]}
          />
        )}

                {newFormule && (
          <OverlayAddForm
            openOverlayFn={modifFormuleOverlayFn}
            relations={inputValues["dirigeants"]}
            formules={inputValues["formules"]}
          />
        )}

        {removeFormule && (
        <OverlayRemoveForm
          removeOverlayFn={removeFormuleOverlayFn}
          formToRemove={formuleToRemove}
          formulesToKeep={inputValues["formules"].filter((data) => data.formId != formuleToRemove["formId"])}
        />
        )}

        {removeDir && (
        <OverlayRemoveDir
          removeOverlayFn={removeDirOverlayFn}
          dirToRemove={dirToRemove}
        />
        )}
        {removeEff && (
        <OverlayRemoveEff
          removeOverlayFn={removeEffOverlayFn}
          effToRemove={effToRemove}
        />
        )}
        {newEffectif && (
          <OverlayAddEff
            openOverlayFn={modifEffOverlayFn}
            effToModify={effToRemove}
            effectifs={inputValues["effectifs"]}
          />
        )}
        {removeCa && (
        <OverlayRemoveCA
          removeOverlayFn={removeCaOverlayFn}
          caToRemove={caToRemove}
        />
        )}
        {newCa && (
          <OverlayAddCA
            openOverlayFn={modifCaOverlayFn}
            caToModify={caToRemove}
            cas={inputValues["cas"]}
          />
        )}
        {removeFile && (
          <OverlayRemoveFileTiers
            removeOverlayFn={removeFileOverlayFn}
            fileToRemove={fileToRemove}
          />
        )}
      <div className={styles.box}>
            <p className={styles.textHeader}>
            {inputValues["infos"]["tipmRso"]}
            </p>
            <div className={styles.logoContainer}>
              {editable == false ? <img src={modifLogo} className={styles.logo} onClick={() => setEditable(!editable)} /> : <img src={saveLogo} className={styles.logo} onClick={() => {
    if (!mutationDataUpdate.isPending) {
      checkAndSend(inputValues)
    }
  }} />} 
            <img src={shareLogo} className={styles.logo} onClick={() => copyTextToClipboard(path)} />
            <a href={`mailto:${inputValues["infos"]["tipmEmail"]}`}><img src={mailLogo} className={styles.logo} /></a>
            </div>
          </div>
          <div className={styles.boxesContainer}>
            <div
              className={`${ficheClicked ? styles.boxClicked : styles.boxNotClicked}`}
              onClick={() => setFicheClicked(true)}
            >
              <p
                className={`${ficheClicked ? styles.textClicked : styles.textNotClicked}`}
              >
                Fiche
              </p>
            </div>
            <div
              className={`${ficheClicked ? styles.boxNotClicked : styles.boxClicked}`}
              onClick={() => setFicheClicked(false)}
            >
              <p
                className={`${ficheClicked ? styles.textNotClicked : styles.textClicked}`}
              >
                Fichiers
              </p>
            </div>
          </div>
          {ficheClicked == true ? 
          <>

          <div className={styles.mutationBounce}>
            <p className={styles.textTitle} >INFORMATIONS SUR LA PERSONNE MORALE</p>
                {mutationDataUpdate.isPending == true && (
                <div style={{height:"2rem"}}>
                  <BounceLoader color="black" />
                </div>
              )}
          </div>
          {/* <p className={styles.textTitle}>
          INFORMATIONS SUR LA PERSONNE MORALE
          </p>
          {(mutationDataUpdate.isPending == true) && 
              <div className={styles.bounce}>
                <BounceLoader color="black" />
              </div>
          } */}
          {inputValues["infos"] && (
            <PMVisuNew inputValues={inputValues["infos"]} editable={editable} changeFn={handleInputChangePM} pageError={pageError} />
          )}
          <p className={styles.textTitle}>
            FORMULE          
          </p>
          <div className={styles.buttonAddContainer}>
            <button
              onClick={() => setNewFormule((prev) => !prev)}
              className={styles.buttonAdd}
            >
              Actualiser Formule
            </button>
          </div>
          {inputValues["formules"] && (
            <FormulePorteur formules={inputValues["formules"]} removeOverlayFn={removeFormuleOverlayFn} />
          )}
          <p className={styles.textTitle}>
          DIRIGEANT(S)          
          </p>
          <div className={styles.buttonAddContainer}>
            <button
              onClick={() => modifDirOverlayFn()}
              className={styles.buttonAdd}
            >
              Ajouter un dirigeant
            </button>
          </div>
          {inputValues["dirigeants"] && (
            <DirigeantsVisu dirigeants={inputValues["dirigeants"]} removeDirOverlayFn={removeDirOverlayFn} modifDirOverlayFn={modifDirOverlayFn} />
          )}

          <p className={styles.textTitle}>
          EFFECTIF(S)          
          </p>
          <div className={styles.buttonAddContainer}>
            <button
              onClick={() => modifEffOverlayFn()}
              className={styles.buttonAdd}
            >
              Actualiser l'effectif
            </button>
          </div>
          {inputValues["effectifs"] && (
            <EffcetifsVisu effectifs={inputValues["effectifs"]} removeDirOverlayFn={removeEffOverlayFn} modifDirOverlayFn={modifEffOverlayFn} />
          )}

          <p className={styles.textTitle}>
          CHIFFRE D'AFFAIRES        
          </p>
          <div className={styles.buttonAddContainer}>
            <button
              onClick={() => modifCaOverlayFn()}
              className={styles.buttonAdd}
            >
              Actualiser le chiffre d'affaires
            </button>
          </div>
          {inputValues["cas"] && (
            <CAVisu cas={inputValues["cas"]} removeDirOverlayFn={removeCaOverlayFn} modifDirOverlayFn={modifCaOverlayFn} />
          )}
          <p className={styles.textTitle}>SORTIE PÉPINIÈRE</p>
          <div className={styles.buttonAddContainer}>
            <button
              onClick={() => setNewSortie(!newSortie)}
              className={styles.buttonAdd}
            >
              Actualiser la sortie de la pépinière
            </button>
          </div>
          {inputValues["sortie"] && (
            <SortieVisu sortie={inputValues["sortie"]} />
          )}
          <p className={styles.textTitle}>STATUT POST-PÉPINIÈRE</p>
          <div className={styles.buttonAddContainer}>
            <button
              onClick={() => setNewStatutPost(!newStatutPost)}
              className={styles.buttonAdd}
            >
              Actualiser statut
            </button>
          </div>
          {inputValues["statutPost"] && (
            <StatutPostVisu statutPost={inputValues["statutPost"]} />
          )}
          </>
          :
          <>
          <p className={styles.textTitle}>FICHIERS</p>
          <input
            type="file"
            style={{ display: "none" }}
            ref={inputFile}
            onChange={handleFileUpload}
            multiple
          />
          <div
            className={styles.buttonAddContainer}
            style={{ marginBottom: `${files.length == 0 ? "10rem" : ""}` }}
          >
            <button
              onClick={() => inputFile.current.click()}
              className={styles.buttonImport}
            >
              <p>Importer</p>
              <img
                src={importerBlanc}
                className={styles.logo}
                style={{ height: "2rem" }}
              />
            </button>
          </div>
          {files.length != 0 && (
            <FichiersVisu fichiers={files} removeOverlayFn={removeFileOverlayFn} />
          )}
          {isLoadingFiles == true && (
            <div className={styles.bounce} style={{ marginBottom: "3rem" }}>
              <BounceLoader color="black" />
            </div>
          )}
          {IsLoadingPostFile == true && (
            <div className={styles.bounce} style={{ marginBottom: "3rem" }}>
              <BounceLoader color="black" />
            </div>
          )}
          </>
          }
      </>
      }
      {(isLoading == true) && (
            <div className={styles.bounce}>
              <BounceLoader color="black" />
            </div>
            )}
    </div>
  )

}