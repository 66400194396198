import styles from "./historiquePrix.module.css"
import { useEffect, useState } from "react";
import Pagination from "../../../components/Pagination/Pagination";
import informationLogo from "../../../assets/informationLogo.png"
import OverlayInfoPrix from "../overlayInfoPrix/OverlayInfoPrix";

export default function HistoriquePrix({ prix }) {
    const [numberElementsMax, setNumberElementsMax] = useState(10)
    const [prevNum, setPrevNum] = useState(0)
    const [nextNum, setNextNum] = useState(10)
    const [seeInfo, setSeeInfo] = useState(false)
    const [infos, setInfos] = useState("")

    const nextPage = () => {
        if (numberElementsMax >= prix.length) {
            // setNextNum((prev) => prev + 10)
            setNumberElementsMax(10)
        } else {
            setNumberElementsMax((prev) => prev + 10)
        }
    }

    useEffect(() => {
        if (prix.length == 0) {
            setNextNum(0)
        } else if (numberElementsMax > prix.length) {
            setNextNum(prix.length)
        } else {
            setNextNum(numberElementsMax)
        }
        if (numberElementsMax > prix.length) {
            const numsCandidate = [prix.length - 1, prix.length - 2,prix.length - 3, prix.length - 4,
                prix.length - 5, prix.length - 6,prix.length - 7, prix.length - 8,
                prix.length - 9]            
            let smallerNumber
            for (let i of numsCandidate) {
                if (i % 10 === 0) {
                smallerNumber = i + 1;
                break; 
                }
            }
            if (smallerNumber == undefined) {
                setPrevNum(0)
            } else {
                setPrevNum(smallerNumber)
            }
        } else {
            if (prix.length == 0) {
                setPrevNum(0)
            } else {
                setPrevNum(numberElementsMax - 9)
            }
        }
    }, [numberElementsMax, prix])

    const prevPage = () => {
            if (numberElementsMax == 10) {
                if (prix.length % 10 == 0) {
                    setNumberElementsMax((prev) => prix.length)
                } else {
                const numsCandidate = [prix.length + 1, prix.length + 2,prix.length + 3, prix.length + 4,
                        prix.length + 5, prix.length + 6,prix.length + 7, prix.length + 8,
                        prix.length + 9]            
                let greatestNumber
                for (let i of numsCandidate) {
                    if (i % 10 === 0) {
                    greatestNumber = i;
                    break; 
                    }
                }
                setNumberElementsMax(greatestNumber)
            }
            } else {
                setNumberElementsMax((prev) => prev - 10)
            }
        // }
    }

    const openOverlayFn = (infos) => {
        if (seeInfo == false) {
            setInfos(infos)
        } 
        setSeeInfo(!seeInfo)
    }

    return (
            <div className={styles.table}>
                {seeInfo && <OverlayInfoPrix infos={infos} openOverlayFn={openOverlayFn} />}
                <table className={styles.tableSearch}>
                    <thead>
                        <tr>
                        <th>Formule</th>
                        <th>Date de début</th>
                        <th>Date de fin</th>
                        <th style={{width:"5rem"}}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {prix.length != 0 && prix.slice(numberElementsMax-10,numberElementsMax).map((data) => {
                        return <tr key={`${data["prixDdeb"] + data["prixType"]}`}> 
                            <td>{data["prixType"]}</td>
                            <td>{data["prixDdeb"].split('-').reverse().join('-')}</td>
                            <td>{data["prixDfin"]?.split('-').reverse().join('-')}</td>
                            <td onClick={() => openOverlayFn(data)}><img src={informationLogo} className={styles.infoLogo} /></td>
                        </tr>
                        })}
                    </tbody>
                </table>
                <div>
                    <Pagination nextPage={nextPage} prevPage={prevPage} />
                    <p className={styles.nums}>{prevNum} à {nextNum} - Nombre Total d'éléments : {prix.length} </p>
                </div> 
            </div>
    )
}