import styles from "./tarificationLocal.module.css";

export default function TarificationLocal({ inputValues }) {
  return (
    <div className={styles.table}>
      <table className={styles.tableSearch}>
        {inputValues[0]["ugNat"] != "CO-WORKING" ? <tbody>
          <tr>
            <td style={{width:"20%"}}>
              <b>Nature Montant</b>
            </td>
            <td style={{textAlign:"start"}}>
                {inputValues[0]["prixNatfac"]}
            </td>
            <td>
            </td>
            <td>
            </td>
            <td>
            </td>
            <td>
            </td>
            <td>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td style={{width:"20%"}}>
              <b>Montant HT 1ère année</b>
            </td>
            <td>
                {inputValues[0]["prixAn1"]}
            </td>
            <td style={{width:"20%"}}>
              <b>Montant HT 2éme année</b>
            </td>
            <td>
                {inputValues[0]["prixAn2"]}
            </td>
            <td style={{width:"20%"}}>
              <b>Montant HT 3éme année</b>
            </td>
            <td>
                {inputValues[0]["prixAn3"]}
            </td>
            <td style={{width:"20%"}}>
              <b>Montant HT Centre d'affaires</b>
            </td>
            <td>
                {inputValues[1]["prixMnt"]}
            </td>
          </tr>
        </tbody>:
        <tbody>
         <tr>
            <td style={{width:"20%"}}>
            <b>Nature Montant</b>
            </td>
            <td style={{textAlign:"start"}}>
                {inputValues[0]["prixNatfac"]}
            </td>            
        </tr>
        <tr>
            <td style={{width:"10%"}}>
            <b>Formule 24h/mois</b>
            </td>
            <td style={{textAlign:"start"}}>
                {inputValues[0]["prixMnt"]}
            </td>
        </tr>
        </tbody>
        }
      </table>
    </div>
  );
}


