import styles from "./AutresInfos.module.css";

export default function CreationAutresInfos( {inputValues, pageError, changeFn} ) {

    
  return (
    <div className={styles.container}>
        <p className={styles.textTitle}>AUTRES INFORMATIONS</p>
        <div className={styles.table}>
        <table className={styles.tableSearch}>
        <tbody>
          <tr>
            <td style={{ width: "10%" }}>
              <b>Date de premier entretien*</b>
            </td>
            <td style={{ width: "22%" }}>
              <input
              type="date"
              name="premEntretienDate"
              id="premEntretienDate"
              onChange={(e) => changeFn(e, "autres")}
              value={inputValues["premEntretienDate"]}
              className={styles.input}
              
              />
              {(inputValues["premEntretienDate"] == undefined || inputValues["premEntretienDate"] == "")  && pageError == true && (
                <small style={{marginLeft: "1rem", color:"red"}}>Ce champs est requis</small>
                )}    
            </td>
            <td style={{ width: "10%" }}>
              <b>Heure de début*</b>
            </td>
            <td style={{ width: "22%" }}>
              <input
              type="time"
              name="premEntretienHDeb"
              id="premEntretienHDeb"
              onChange={(e) => changeFn(e, "autres")}
              value={inputValues["premEntretienHDeb"]}
              className={styles.input}
              />
              {(inputValues["premEntretienHDeb"] == undefined || inputValues["premEntretienHDeb"] == "")  && pageError == true && (
                <small style={{marginLeft: "1rem", color:"red"}}>Ce champs est requis</small>
                )}   
            </td>
            <td style={{ width: "10%" }}>
              <b>Heure de fin*</b>
            </td>
            <td style={{ width: "22%" }}>
              <input
              type="time"
              name="premEntretienHFin"
              id="premEntretienHFin"
              onChange={(e) => changeFn(e, "autres")}
              value={inputValues["premEntretienHFin"]}
              className={styles.input}
              // style={{width:"10%"}}
              />
               {(inputValues["premEntretienHFin"] == undefined || inputValues["premEntretienHFin"] == "")  && pageError == true && (
                <small style={{marginLeft: "1rem", color:"red"}}>Ce champs est requis</small>
                )} 
            </td>
          </tr>
          <tr>
            <td>
              <b>Prescripteur</b>
            </td>
            <td colSpan="5">
            <select
                  value={inputValues["Prescripteur"]}
                  onChange={(e) => changeFn(e, "autres")}
                  name="Prescripteur"
                  id="Prescripteur"
                  className={styles.inputSelect}
                  style={{width:"20%"}}
                >
                    <option value="">
                      ----------------------
                    </option>
                    <option value="Téléphone">
                      Téléphone
                    </option>
                    <option value="Site">
                      Site
                    </option>
                    <option value="Partenaires">
                    Partenaires
                    </option>
                    <option value="Bouche à oreille">
                    Bouche à oreille
                    </option>
                    <option value="Champ Libre">
                    Champ libre
                    </option>
                </select>
                {inputValues["Prescripteur"] == "Champ Libre" && (
                <input
                  type="text"
                  id="champLibre"
                  name="champLibre"
                  value={inputValues["champLibre"]}
                  onChange={(e) => changeFn(e, "autres")}
                  className={styles.input}
                  style={{marginLeft: "1rem", width: "20%"}}
                />
              )}
            </td>
          </tr>
          <tr>
            <td>
              <b>Commentaire(s)</b>
            </td>
            <td colSpan="5" style={{ padding: "2rem 0rem" }}>
                <textarea 
                name="premEntretienCom" 
                id="premEntretienCom" 
                rows="4"
                value={inputValues["premEntretienCom"]}
                onChange={(e) => changeFn(e, "autres")}
                className={styles.input}
                />         
            </td>
          </tr>
        </tbody>
            </table>
        </div>
    </div>
  );
}

