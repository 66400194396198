import { useMemo, useState } from "react";
import styles from "./BarChartEntreePep.module.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Rectangle,
  Tooltip,
  LabelList,
  CartesianGrid,
} from "recharts";

export default function BarChartEntreePep({ data }) {
  const [activeIndex, setActiveIndex] = useState(-1);
  
  const onBarEnter = (data, index) => {
    setActiveIndex(index);
  };

  const onBarLeave = () => {
    setActiveIndex(-1);
  };

  const CustomBarShape = (props) => {
    const { fill, x, y, width, height } = props;

    return <Rectangle className={styles.barchart} fill={fill} x={x} y={y} width={width} height={height} />;
  };

  const CustomTick = (props) => {
    const { x, y, payload } = props;
  
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={20} style={{ fontSize: "1rem"}} textAnchor="middle" fill="black">
          {payload.value}
        </text>
      </g>
    );
  };
  
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc', width:"200px", fontSize:"1rem" }}>
          <p style={{ margin: 0 }}>{label}</p>
          {payload.map((entry, index) => (
            <p key={`item-${index}`} style={{ color: entry.color, margin: 0 }}>
              Valeur: {entry.value}
            </p>
          ))}
        </div>
      );
    }
  
    return null;
  };

// This keeps the calculation efficient and prevents unnecessary recalculations
const maxValue = useMemo(() => { 
  const max = Math.max(...data.map(item => item.value));
  const marginAdded = max + (max * 0.1)
  return Math.ceil(marginAdded / 5) * 5
}, [data]);

const renderLabel = (value) => `${value}`;

console.log(maxValue)
  return (
    <BarChart 
  width={700} 
  height={300} 
  data={data} 
  layout="vertical"
  margin={{top: 5, right: 30, left: 40, bottom: 5}}
>
  <XAxis type="number" tick={<CustomTick />} interval={1} domain={[0, maxValue]}/>
  <YAxis type="category" dataKey="name" tick={{ fontSize: "1rem", fill:"black" }}  />
  <CartesianGrid horizontal={false} vertical={true} />
  <Tooltip content={<CustomTooltip />} />
  <Bar dataKey="value" fill="#8faedc" shape={CustomBarShape} onMouseEnter={onBarEnter} onMouseLeave={onBarLeave} >
  <LabelList dataKey="value" position="right" style={{ fill: 'black', fontSize: '1rem' }} formatter={renderLabel} />
  </Bar>
</BarChart>
  );
}
