import { useState } from "react";
import styles from "./BarChartActivite.module.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Rectangle,
  Tooltip,
  CartesianGrid,
  LabelList
} from "recharts";

export default function BarChartActivite({ data }) {
  const [activeIndex, setActiveIndex] = useState(-1);

  const onBarEnter = (data, index) => {
    setActiveIndex(index);
  };

  const onBarLeave = () => {
    setActiveIndex(-1);
  };

  const CustomBarShape = (props) => {
    const { fill, x, y, width, height } = props;
    return <Rectangle className={styles.barchart} fill={fill} x={x} y={y} width={width} height={height} />;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc', width:"200px", fontSize:"1rem" }}>
          <p style={{ margin: 0 }}>{label}</p>
          {payload.map((entry, index) => (
            <p key={`item-${index}`} style={{ color: entry.color, margin: 0 }}>
              Valeur: {entry.value}%
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  const renderLabel = (value) => `${value}%`;

  return (
    <BarChart 
      width={1000} 
      height={500} 
      data={data} 
      layout="vertical" 
    >
      <XAxis type="number" tick={{ fontSize: "1rem", fill:"black" }} domain={[0, 100]} />
      <YAxis type="category" dataKey="name" width={120} tick={{ fontSize: "1rem", fill:"black" }} />
      <CartesianGrid horizontal={true} vertical={false} />
      <Tooltip content={<CustomTooltip />} />
      <Bar dataKey="value" fill="#8faedc" shape={CustomBarShape} onMouseEnter={onBarEnter} onMouseLeave={onBarLeave}>
        <LabelList dataKey="value" position="right" style={{ fill: 'black', fontSize: '1rem' }} formatter={renderLabel} />
      </Bar>
    </BarChart>
  );
}