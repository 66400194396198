import styles from "./ugVisu.module.css";
import countryList from "react-select-country-list";
import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

export default function UgVisu({ changeFn, editable, inputValues, pageError }) {
  const options = useMemo(() => countryList().getData(), []);

  async function allNat() {
    const link = `${process.env.REACT_APP_API_URL}/ug/allNatsUG`;
    // const link = "http://localhost:8080/ug/allNatsUG";
    const response = await fetch(link);
    if (!response.ok) {
      throw "il y a un pb";
    }
    const data = await response.json();
    return data;
  }

  const { data: allNats = [] } = useQuery({
    queryKey: ["Nature", "Equipements", "UG"],
    queryFn: () => allNat(),
    staleTime: Infinity,
    refetchOnWindowFocus: false
  });


  return (
    <div className={styles.table}>
      <table className={styles.tableSearch}>
        <thead>
          <tr style={{height: "0rem"}}>
          <th style={{ width: "25%" }}></th>
          <th style={{ width: "25%" }}></th>
          <th style={{ width: "25%" }}></th>
          <th style={{ width: "25%" }}></th>
          </tr>
          
        </thead>
        <tbody>
          <tr>
            <td>
              <b>Nature</b>
            </td>
            <td>
              {editable ? (
                <select
                  value={inputValues["ugNat"]}
                  onChange={(e) => changeFn(e, 0)}
                  name="ugNat"
                  id="ugNat"
                  className={styles.inputSelect}
                >
                  {allNats.length != 0 &&
                    allNats.map((data) => {
                      if (inputValues["ugNat"] == data.ugNat) {
                        return (
                          <option value={data.ugNat} key={data.ugNat} selected>
                            {data.ugNat}
                          </option>
                        );
                      } else {
                        return (
                          <option value={data.ugNat} key={data.ugNat}>
                            {data.ugNat}
                          </option>
                        );
                      }
                    })}
                </select>
              ) : (
                <div className={styles.inputValue}>{inputValues["ugNat"]}</div>
              )}
              {inputValues["ugNat"] == undefined && pageError == true && (
                <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>
              )}
            </td>
            <td>
              <b>Intitulé</b>
            </td>
            <td>
              {editable ? (
                <input
                  type="text"
                  id="ugInt"
                  name="ugInt"
                  value={inputValues["ugInt"]}
                  onChange={(e) => changeFn(e, 0)}
                  className={styles.input}
                />
              ) : (
                <div className={styles.inputValue}>{inputValues["ugInt"]}</div>
              )}
              {(inputValues["ugInt"] == undefined ||
                inputValues["ugInt"] == "") &&
                pageError == true && <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>}
            </td>
          </tr>
          <tr>
            <td>
              <b>Bâtiment</b>
            </td>
            <td>Espace Carco</td>
            <td>
              <b>Étage</b>
            </td>
            <td>
              {editable ? (
                <input
                  type="number"
                  id="ugEtag"
                  name="ugEtag"
                  value={inputValues["ugEtag"]}
                  min="0"
                  onChange={(e) => changeFn(e, 0)}
                  className={styles.input}
                />
              ) : (
                <div className={styles.inputValue}>{inputValues["ugEtag"]}</div>
              )}
                {(inputValues["ugEtag"] == undefined ||
          inputValues["ugEtag"] == "") &&
          pageError == true && <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>}
            </td>
          </tr>
          <tr>
            <td>
              <b>Référence</b>
            </td>
            <td>
              {editable ? (
                <input
                  type="text"
                  id="ugRef"
                  name="ugRef"
                  value={inputValues["ugRef"]}
                  onChange={(e) => changeFn(e, 0)}
                  className={styles.input}
                />
              ) : (
                <div className={styles.inputValue}>{inputValues["ugRef"]}</div>
              )}
                {(inputValues["ugRef"] == undefined ||
            inputValues["ugRef"] == "") &&
            pageError == true && <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>}

            </td>
            <td>
              <b>Surface (m²)</b>
            </td>
            <td>
              {editable ? (
                <input
                  type="number"
                  id="ugSurf"
                  name="ugSurf"
                  value={inputValues["ugSurf"]}
                  onChange={(e) => changeFn(e, 0)}
                  className={styles.input}
                />
              ) : (
                <div className={styles.inputValue}>{inputValues["ugSurf"]}</div>
              )}
              {(inputValues["ugSurf"] == undefined ||
                    inputValues["ugSurf"] == "") &&
                    pageError == true && <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>}
            </td>
          </tr>
          <tr>
            <td>
              <b>Date de construction</b>
            </td>
            <td>
              {editable ? (
                <input
                  type="date"
                  id="ugDatcons"
                  name="ugDatcons"
                  value={inputValues["ugDatcons"]}
                  onChange={(e) => changeFn(e, 0)}
                  className={styles.input}
                />
              ) : (
                <div className={styles.inputValue}>
                  {inputValues["ugDatcons"] != null
                    ? inputValues["ugDatcons"]
                        .split("-")
                        .reverse()
                        .join("-")
                    : ""}
                </div>
              )}
            </td>
            <td>
              <b>Date d'entrée</b>
            </td>
            <td>
              {editable ? (
                <input
                  type="date"
                  id="ugDatent"
                  name="ugDatent"
                  value={inputValues["ugDatent"]}
                  onChange={(e) => changeFn(e, 0)}
                  className={styles.input}
                />
              ) : (
                <div className={styles.inputValue}>
                  {inputValues["ugDatent"] != null
                    ? inputValues["ugDatent"]
                        .split("-")
                        .reverse()
                        .join("-")
                    : ""}
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <b>N° Voie</b>
            </td>
            <td>
              {editable ? (
                <input
                  type="text"
                  id="numVoie"
                  name="numVoie"
                  value={inputValues["numVoie"]}
                  onChange={(e) => changeFn(e, 0)}
                  className={styles.input}
                />
              ) : (
                <div className={styles.inputValue}>
                  {inputValues["numVoie"]}
                </div>
              )}
            </td>
            <td>
              <b>Type Voie</b>
            </td>
            <td>
              {editable ? (
                <input
                  type="text"
                  id="typVoie"
                  name="typVoie"
                  value={inputValues["typVoie"]}
                  onChange={(e) => changeFn(e, 0)}
                  className={styles.input}
                />
              ) : (
                <div className={styles.inputValue}>
                  {inputValues["typVoie"]}
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <b>Intitulé voie</b>
            </td>
            <td>
              {editable ? (
                <input
                  type="text"
                  id="intVoie"
                  name="intVoie"
                  value={inputValues["intVoie"]}
                  onChange={(e) => changeFn(e, 0)}
                  className={styles.input}
                />
              ) : (
                <div className={styles.inputValue}>
                  {inputValues["intVoie"]}
                </div>
              )}
            </td>
            <td>
              <b>Complémement voie</b>
            </td>
            <td>
              {editable ? (
                <input
                  type="text"
                  id="complvoie"
                  name="complvoie"
                  value={inputValues["complvoie"]}
                  onChange={(e) => changeFn(e, 0)}
                  className={styles.input}
                />
              ) : (
                <div className={styles.inputValue}>
                  {inputValues["complvoie"]}
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <b>Code postal</b>
            </td>
            <td>
              {editable ? (
                <input
                  type="text"
                  id="codPos"
                  name="codPos"
                  value={inputValues["codPos"]}
                  onChange={(e) => changeFn(e, 0)}
                  className={styles.input}
                />
              ) : (
                <div className={styles.inputValue}>{inputValues["codPos"]}</div>
              )}
            </td>
            <td>
              <b>Commune</b>
            </td>
            <td>
              {editable ? (
                <input
                  type="text"
                  id="comun"
                  name="comun"
                  value={inputValues["comun"]}
                  onChange={(e) => changeFn(e, 0)}
                  className={styles.input}
                />
              ) : (
                <div className={styles.inputValue}>{inputValues["comun"]}</div>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <b>Cedex</b>
            </td>
            <td>
              {editable ? (
                <input
                  type="text"
                  id="cedex"
                  name="cedex"
                  value={inputValues["cedex"]}
                  onChange={(e) => changeFn(e, 0)}
                  className={styles.input}
                />
              ) : (
                <div className={styles.inputValue}>{inputValues["cedex"]}</div>
              )}
            </td>
            <td>
              <b>Pays</b>
            </td>
            <td>
              {editable ? (
                <select
                  onChange={(e) => changeFn(e, 0)}
                  value={inputValues["pays"]}
                  id="pays"
                  name="pays"
                  className={styles.inputSelect}
                >
                  {options.map((option) => {
                    if (option.label != "France") {
                      return (
                        <option key={option.value} value={option.label}>
                          {option.label}
                        </option>
                      );
                    } else {
                      return (
                        <option
                          key={option.value}
                          value={option.label}
                          selected
                        >
                          {option.label}
                        </option>
                      );
                    }
                  })}
                </select>
              ) : (
                <div className={styles.inputValue}>{inputValues["pays"]}</div>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

// <div className={styles.fieldContainer}>
//     <p className={styles.label}>N° Voie</p>
//     {editable ? <input
//         type="text"
//         id="numVoie"
//         name="numVoie"
//         value={inputValues["numVoie"]}
//         onChange={(e) => changeFn(e, 0)}
//         className={styles.input}
//     /> :
//         <div className={styles.inputValue}>{inputValues["numVoie"]}</div>
//     }
// </div>

// <div className={styles.container}>
// <div className={styles.fieldContainer}>
//     <p className={styles.label}>Nature</p>
//     {editable ?
//     <select value={inputValues["ugNat"]} onChange={(e) => changeFn(e, 0)} name="ugNat" id="ugNat" className={styles.input}>
//         {allNats.length != 0 && allNats.map((data) => {
//             if (inputValues["ugNat"] == data.ugNat) {
//                 return <option value={data.ugNat} key={data.ugNat} selected>{data.ugNat}</option>
//             } else {
//                 return <option value={data.ugNat} key={data.ugNat}>{data.ugNat}</option>
//             }
//         })}
//     </select>
//     :
//     <div className={styles.inputValue}>{inputValues["ugNat"]}</div>
//     }
//     {(inputValues["ugNat"] == undefined && pageError == true) && <small>Ce champs est requis</small>}
// </div>
// <div className={styles.fieldContainer}>
//     <p className={styles.label}>Intitulé</p>
//     {editable ? <input
//         type="text"
//         id="ugInt"
//         name="ugInt"
//         value={inputValues["ugInt"]}
//         onChange={(e) => changeFn(e, 0)}
//         className={styles.input}
//     /> :
//         <div className={styles.inputValue}>{inputValues["ugInt"]}</div>
//     }
//     {((inputValues["ugInt"] == undefined || inputValues["ugInt"] == "")  && pageError == true) && <small>Ce champs est requis</small>}
// </div>
// <div className={styles.fieldContainer}>
//     <p className={styles.label}>Date de construction</p>
//     {editable ? <input
//         type="date"
//         id="ugDatcons"
//         name="ugDatcons"
//         value={inputValues["ugDatcons"]}
//         onChange={(e) => changeFn(e, 0)}
//         className={styles.input}
//     /> :
//         <div className={styles.inputValue}>{inputValues["ugDatcons"] != null ? ExcelDateToJSDate(inputValues["ugDatcons"]).split('-').reverse().join('-') : ""}</div>
//     }
// </div>
// <div className={styles.fieldContainer}>
//     <p className={styles.label}>Date d'entrée</p>
//     {editable ? <input
//         type="date"
//         id="ugDatent"
//         name="ugDatent"
//         value={inputValues["ugDatent"]}
//         onChange={(e) => changeFn(e, 0)}
//         className={styles.input}
//     /> :
//         <div className={styles.inputValue}>{inputValues["ugDatent"] != null ? ExcelDateToJSDate(inputValues["ugDatent"]).split('-').reverse().join('-') : ""}</div>
//     }
//     {(inputValues["ugDatent"] == undefined && pageError == true) && <small>Ce champs est requis</small>}
// </div>
// <div className={styles.fieldContainer}>
//     <p className={styles.label}>Etage</p>
//     {editable ? <input
//         type="number"
//         id="ugEtag"
//         name="ugEtag"
//         value={inputValues["ugEtag"]}
//         min="0"
//         onChange={(e) => changeFn(e, 0)}
//         className={styles.input}
//     /> :
//         <div className={styles.inputValue}>{inputValues["ugEtag"]}</div>
//     }
// </div>
// <div className={styles.fieldContainer}>
//     <p className={styles.label}>Utilisation</p>
//     {editable ? <input
//         type="text"
//         id="ugUtil"
//         name="ugUtil"
//         value={inputValues["ugUtil"]}
//         onChange={(e) => changeFn(e, 0)}
//         className={styles.input}
//     /> :
//         <div className={styles.inputValue}>{inputValues["ugUtil"]}</div>
//     }
// </div>
// <div className={styles.fieldContainer}>
//     <p className={styles.label}>Utilisation</p>
//     {editable ? <select onChange={(e) => changeFn(e, 0)} value={inputValues["ugtemHand"]} id="ugtemHand" name="ugtemHand" className={styles.input}>
//         <option value={inputValues["ugtemHand"]}>{inputValues["ugtemHand"]}</option>
//         <option value={inputValues["ugtemHand"]}>{inputValues["ugtemHand"]}</option>
//     </select> :
//         <div className={styles.inputValue}>{inputValues["ugtemHand"]}</div>
//     }
// </div>
// <div className={styles.fieldContainer}>
//     <p className={styles.label}>N° Voie</p>
//     {editable ? <input
//         type="text"
//         id="numVoie"
//         name="numVoie"
//         value={inputValues["numVoie"]}
//         onChange={(e) => changeFn(e, 0)}
//         className={styles.input}
//     /> :
//         <div className={styles.inputValue}>{inputValues["numVoie"]}</div>
//     }
// </div>
// <div className={styles.fieldContainer}>
//     <p className={styles.label}>Type Voie</p>
//     {editable ? <input
//         type="text"
//         id="typVoie"
//         name="typVoie"
//         value={inputValues["typVoie"]}
//         onChange={(e) => changeFn(e, 0)}
//         className={styles.input}
//     /> :
//         <div className={styles.inputValue}>{inputValues["typVoie"]}</div>
//     }
// </div>
// <div className={styles.fieldContainer}>
//     <p className={styles.label}>Intitulé voie</p>
//     {editable ? <input
//         type="text"
//         id="intVoie"
//         name="intVoie"
//         value={inputValues["intVoie"]}
//         onChange={(e) => changeFn(e, 0)}
//         className={styles.input}
//     /> :
//         <div className={styles.inputValue}>{inputValues["intVoie"]}</div>
//     }
// </div>
// <div className={styles.fieldContainer}>
//     <p className={styles.label}>Complémement voie</p>
//     {editable ? <input
//         type="text"
//         id="complvoie"
//         name="complvoie"
//         value={inputValues["complvoie"]}
//         onChange={(e) => changeFn(e, 0)}
//         className={styles.input}
//     /> :
//         <div className={styles.inputValue}>{inputValues["complvoie"]}</div>
//     }
// </div>
// <div className={styles.fieldContainer}>
//     <p className={styles.label}>Code postal</p>
//     {editable ? <input
//         type="text"
//         id="codPos"
//         name="codPos"
//         value={inputValues["codPos"]}
//         onChange={(e) => changeFn(e, 0)}
//         className={styles.input}
//     /> :
//         <div className={styles.inputValue}>{inputValues["codPos"]}</div>
//     }
// </div>
// <div className={styles.fieldContainer}>
//     <p className={styles.label}>Commune</p>
//     {editable ? <input
//         type="text"
//         id="comun"
//         name="comun"
//         value={inputValues["comun"]}
//         onChange={(e) => changeFn(e, 0)}
//         className={styles.input}
//     /> :
//         <div className={styles.inputValue}>{inputValues["comun"]}</div>
//     }
// </div>
// <div className={styles.fieldContainer}>
//     <p className={styles.label}>Cedex</p>
//     {editable ? <input
//         type="text"
//         id="cedex"
//         name="cedex"
//         value={inputValues["cedex"]}
//         onChange={(e) => changeFn(e, 0)}
//         className={styles.input}
//     /> :
//         <div className={styles.inputValue}>{inputValues["cedex"]}</div>
//     }
// </div>
// <div className={styles.fieldContainer}>
//     <p className={styles.label}>Pays</p>
//     {editable ? <select onChange={(e) => changeFn(e, 0)} value={inputValues["pays"]} id="pays" name="pays" className={styles.input}>
//         {options.map((option) => {
//                 if (option.label != "France") {
//                     return <option key={option.value} value={option.label}>
//                         {option.label}
//                     </option>
//                 } else {
//                     return <option key={option.value} value={option.label} selected>
//                         {option.label}
//                     </option>
//                 }
//         })}
//     </select>:
//         <div className={styles.inputValue}>{inputValues["tipmpays"]}</div>
//     }
// </div>
// <div className={styles.fieldContainer}>
//     <p className={styles.label}>Surface</p>
//     {editable ? <input
//         type="number"
//         id="ugSurf"
//         name="ugSurf"
//         value={inputValues["ugSurf"]}
//         onChange={(e) => changeFn(e, 0)}
//         className={styles.input}
//     /> :
//         <div className={styles.inputValue}>{inputValues["ugSurf"]}</div>
//     }
// </div>
// <div className={styles.fieldContainer}>
//     <p className={styles.label}>Nature Montant</p>
//     <div className={styles.inputValue}>{inputValues["prixNatfac"]}</div>
// </div>
// <div className={styles.fieldContainer}>
//     <p className={styles.label}>Montant An 1</p>
//     <div className={styles.inputValue}>{inputValues["prixAn1"]}</div>
// </div>
// <div className={styles.fieldContainer}>
//     <p className={styles.label}>Montant An 2</p>
//     <div className={styles.inputValue}>{inputValues["prixAn2"]}</div>
// </div>
// <div className={styles.fieldContainer}>
//     <p className={styles.label}>Montant An 3</p>
//     <div className={styles.inputValue}>{inputValues["prixAn3"]}</div>
// </div>

// </div>
