import styles from "./notifications.module.css"
import { useQuery } from "@tanstack/react-query";
import { BounceLoader } from 'react-spinners'
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";

export default function Notifications() {
    const [numberElementsMax, setNumberElementsMax] = useState(10)
    const navigate = useNavigate()
    const [prevNum, setPrevNum] = useState(0)
    const [nextNum, setNextNum] = useState(10)


    const getNotifs = async () => {
        const link = `${process.env.REACT_APP_API_URL}/conv/notifs`
        // const link = 'http://localhost:8080/conv/notifs'
        const response = await fetch(link)
        if (!response.ok) {
            throw "il y a un pb"
        }
        const data = await response.json()
        return data 
    }

    const {data: notifs = [], isLoading} = useQuery({
        queryKey:["Notifications"],
        queryFn:() => getNotifs(),
        refetchOnWindowFocus: false
    })

    const nextPage = () => {
        if (numberElementsMax >= notifs.length) {
            // setNextNum((prev) => prev + 10)
            setNumberElementsMax(10)
        } else {
            setNumberElementsMax((prev) => prev + 10)
        }
    }

    useEffect(() => {
        if (notifs.length == 0) {
            setNextNum(0)
        } else if (numberElementsMax > notifs.length) {
            setNextNum(notifs.length)
        } else {
            setNextNum(numberElementsMax)
        }
        if (numberElementsMax > notifs.length) {
            const numsCandidate = [notifs.length - 1, notifs.length - 2,notifs.length - 3, notifs.length - 4,
                notifs.length - 5, notifs.length - 6,notifs.length - 7, notifs.length - 8,
                notifs.length - 9]            
            let smallerNumber
            for (let i of numsCandidate) {
                if (i % 10 === 0) {
                smallerNumber = i + 1;
                break; 
                }
            }
            if (smallerNumber == undefined) {
                setPrevNum(0)
            } else {
                setPrevNum(smallerNumber)
            }
        } else {
            if (notifs.length == 0) {
                setPrevNum(0)
            } else {
                setPrevNum(numberElementsMax - 9)
            }
        }
    }, [numberElementsMax, notifs])

    const prevPage = () => {
            if (numberElementsMax == 10) {
                if (notifs.length % 10 == 0) {
                    setNumberElementsMax((prev) => notifs.length)
                } else {
                const numsCandidate = [notifs.length + 1, notifs.length + 2,notifs.length + 3, notifs.length + 4,
                        notifs.length + 5, notifs.length + 6,notifs.length + 7, notifs.length + 8,
                        notifs.length + 9]            
                let greatestNumber
                for (let i of numsCandidate) {
                    if (i % 10 === 0) {
                    greatestNumber = i;
                    break; 
                    }
                }
                setNumberElementsMax(greatestNumber)
            }
            } else {
                setNumberElementsMax((prev) => prev - 10)
            }
        // }
    }
    return (
        <div className={styles.container}>
            <div className={styles.box}>
                <p className={styles.text}>Notifications</p>       
            </div>
            <div className={styles.table}>
                <table className={styles.tableSearch}>
                    <thead>
                        <th style={{width:"20%"}}>Date</th>
                        <th style={{width:"20%"}}>Intitulé</th>
                        <th style={{width:"20%"}}>Date de début</th>
                        <th style={{width:"20%"}}>Dénomination Version</th>
                        <th style={{width:"20%"}}>Formule</th>
                    </thead>
                    <tbody>
                        {notifs.length != 0 && notifs.slice(numberElementsMax-10,numberElementsMax).map((data) => {
                            const phrase = "Veuillez actualiser la convention : " + data["tiLib"]
                        return <tr onClick={() => navigate(`../rechercheConvention/convention/${data["convId"]}/latest`)}> 
                            <td>{data["dateNot"]}</td>
                            <td>{phrase}</td>
                            <td>{data["convDtd"]}</td>
                            <td>{data["convAv"]}</td>
                            <td>{data["convForm"]}</td>
                        </tr>
                        })}
                    </tbody>
                </table>
                {(isLoading == true) ?
                        <div className={styles.bounce}>
                        <BounceLoader color="black" />
                        </div> : <div>
                    <Pagination nextPage={nextPage} prevPage={prevPage} />
                    <p className={styles.nums}>{prevNum} à {nextNum} - Nombre Total d'éléments : {notifs.length} </p>
                    </div> 
                }
            </div>
        {/* <Footer /> */}
    </div>

    )
}