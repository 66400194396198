import ReactDOM from "react-dom";
import styles from "./overlayResiliation.module.css";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { BounceLoader } from "react-spinners";

export default function OverlayResiliation({ openOverlayFn, inputValues }) {
  const { convId, convVrs = "latest" } = useParams();
  const [dateFin, setNewDateFin] = useState(inputValues["infos"]["convDtf"]);
  const [pageError, setPageError] = useState(false);

  // const [pageError, setPageError] = useState(false);
  const queryClient = useQueryClient();

  async function updateConvAv(data) {
    const link = `${process.env.REACT_APP_API_URL}/conv/resilitation`
    // const link = `http://localhost:8080/conv/resilitation`;
    const response = await fetch(link, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const dataReceived = await response.json();
    return dataReceived;
  }

  const mutationDataUpdate = useMutation({
    mutationFn: (data) => updateConvAv(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["Convention", convId, convVrs]);
      queryClient.invalidateQueries(["Convention", "Historique", convId]);
      openOverlayFn(false);
    },
  });

  const checkAndSend = () => {
    // if (dateFin == "") {
    //   setPageError(true)
    // } else {
    //   setPageError(false)
      mutationDataUpdate.mutate({
        prevData: inputValues,
        dateFin: dateFin,
        typeAvenant: "Résiliation",
        convId: convId      
      });
    // }
  };



  return ReactDOM.createPortal(
    <div className={styles.overlay} onClick={() => openOverlayFn(false)}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <p className={styles.textOverlayTitle}>
          RÉSILIER LA CONVENTION
        </p>
        <div>
          <div className={styles.rowContainer}>
            <div className={styles.fieldContainer}>
              <label className={styles.label} htmlFor="convDtf">
                Date de fin
              </label>
              <input
                type="date"
                id="convDtf"
                name="convDtf"
                value={dateFin}
                onChange={(e) => setNewDateFin(e.target.value)}
                className={styles.input}
              />
               {/* {(dateFin == "" &&
                pageError == true) && (
                  <small style={{ marginLeft: "1rem" }}>
                    Ce champs est requis
                  </small>
                )} */}
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
            <button
              className={styles.buttonValid}
              onClick={() => checkAndSend()}
              disabled={mutationDataUpdate.isPending}
            >
              Valider
            </button>
          <button
            className={styles.buttonValid}
            onClick={() => openOverlayFn(false)}
          >
            Retour
          </button>
        </div>
        {mutationDataUpdate.isPending == true && (
          <div className={styles.bounce}>
            <BounceLoader color="white" />
          </div>
        )}
      </div>
    </div>,
    document.getElementById("overlay")
  );
}
