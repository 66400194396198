import styles from "./PPVisuNew.module.css";
import { useQuery } from "@tanstack/react-query";

export default function PPVisuNew({ changeFn, editable, inputValues, pageError }) {


  async function getSocioPro()  {
    const link = `${process.env.REACT_APP_API_URL}/tiers/categSocioPro`
    // const link = "http://localhost:8080/tiers/categSocioPro"
    const response = await fetch(link)
    if (!response.ok) {
        throw "Il y a un problème avec le serveur"
    }
    const data = await response.json()
    return data
}

  const { data:categSocioPro = [] } = useQuery({
      queryKey: ["categorieSocioPro"],
      queryFn: () => getSocioPro(),
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false
  })

  const categSocioProDisplayed = categSocioPro ? categSocioPro.map((data) => {
  if (data["Liste PCS-ESE"] == inputValues["scpId"]) {
      return `${data["Liste PCS-ESE"]} - ${data["Liste des professions et catégories socioprofessionnelles des emplois salariés des employeurs privés et publics"]}`
    }
  }).filter(item => item!= undefined) : ""  

  return (
    <div className={styles.table}>
      <table className={styles.tableSearch}>
        <thead >
          <tr style={{height:"0rem"}}>
          <th style={{ width: "20%" }}></th>
          <th style={{ width: "30%" }}></th>
          <th style={{ width: "20%" }}></th>
          <th style={{ width: "30%" }}></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>Civilité{editable == true && "*"}</b>
            </td>
            <td>
              {editable ? (
                <select
                  value={inputValues["tippCiv"]}
                  onChange={(e) => changeFn(e)}
                  name="tippCiv"
                  id="tippCiv"
                  className={styles.inputSelect}
                >
                    <option value="Mr">
                      Monsieur
                    </option>
                    <option value="Mme">
                      Madame
                    </option>
                </select>
              ) : (
                <div className={styles.inputValue}>{inputValues["tippCiv"] == "Mr" ? "Monsieur" : inputValues["tippCiv"] == "Mme" ? "Madame" : inputValues["tippCiv"]}</div>
              )}
              {(inputValues["tippCiv"] == undefined || inputValues["tippCiv"] == "")  && pageError == true && (
                <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>
              )}
            </td>
            <td>
              <b>Prénom{editable == true && "*"}</b>
            </td>
            <td>
              {editable ? (
                <input
                  type="text"
                  id="tippPrenom"
                  name="tippPrenom"
                  value={inputValues["tippPrenom"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
              ) : (
                <div className={styles.inputValue}>{inputValues["tippPrenom"]}</div>
              )}
              {(inputValues["tippPrenom"] == undefined ||
                inputValues["tippPrenom"] == "") &&
                pageError == true && <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>}
            </td>
          </tr>
          <tr>
            <td>
              <b>Nom{editable == true && "*"}</b>
            </td>
            <td>
            {editable ? (
                <input
                  type="text"
                  id="tippNom"
                  name="tippNom"
                  value={inputValues["tippNom"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
              ) : (
                <div className={styles.inputValue}>{inputValues["tippNom"]}</div>
              )}
              {(inputValues["tippNom"] == undefined ||
                inputValues["tippNom"] == "") &&
                pageError == true && <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>}
            </td>
            <td>
              <b>Nom de naissance</b>
            </td>
            <td>
            {editable ? (
                <input
                  type="text"
                  id="tippNomnai"
                  name="tippNomnai"
                  value={inputValues["tippNomnai"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
              ) : (
                <div className={styles.inputValue}>{inputValues["tippNomnai"]}</div>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <b>Date de naissance</b>
            </td>
            <td>
              {editable ? (
                <input
                  type="date"
                  id="tippDatnai"
                  name="tippDatnai"
                  value={inputValues["tippDatnai"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
              ) : (
                <div className={styles.inputValue}>{inputValues["tippDatnai"]?.split('-').reverse().join('-')}</div>
              )}
                {/* {(inputValues["tippDatnai"] == undefined ||
            inputValues["tippDatnai"] == "") &&
            pageError == true && <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>} */}
            </td>
            <td>
              <b>Courriel{editable == true && "*"}</b>
            </td>
            <td>
              {editable ? (
                <input
                  type="email"
                  id="tippEmail"
                  name="tippEmail"
                  value={inputValues["tippEmail"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
              ) : (
                <div className={styles.inputValue}>{inputValues["tippEmail"]}</div>
              )}
              {(inputValues["tippEmail"] == undefined ||
                    inputValues["tippEmail"] == "") &&
                    pageError == true && <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>}
            </td>
          </tr>
          <tr>
            <td>
              <b>Téléphone{editable == true && "*"}</b>
            </td>
            <td>
            {editable ?
                <input
                    type="text"
                    id="tippTelpor"
                    name="tippTelpor"
                    value={inputValues["tippTelpor"]}
                    onChange={(e) => changeFn(e)}
                    className={styles.input}
                />
                :
                (
                  <div className={styles.inputValue}>{inputValues["tippTelpor"]}</div>
                )
                }
            {(inputValues["tippTelpor"] == undefined || inputValues["tippTelpor"] == "")  && pageError == true && (
                <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>
              )}
            </td>
            <td>
              <b>Adresse</b>
            </td>
            <td>
                <div className={styles.inputValue}>
                  <div className={styles.inputValue}>{(inputValues?.numVoie ? inputValues["numVoie"] : "")  + " " + (inputValues?.typVoie ? inputValues["typVoie"] : "") + " " + (inputValues?.intVoie ? inputValues["intVoie"] : "") + " " + (inputValues?.complvoie ? inputValues["complvoie"] : "")}</div>
                </div>
            </td>
          </tr>
          {editable && 
          <>
            <tr>
            <td>
              <b>N° de voie{editable == true && "*"}</b>
            </td>
            <td>
                <input
                  type="text"
                  id="numVoie"
                  name="numVoie"
                  value={inputValues["numVoie"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
                {(inputValues["numVoie"] == undefined || inputValues["numVoie"] == "")  && pageError == true && (
                <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>
              )}
            </td>
            <td>
            <b>Type de voie{editable == true && "*"}</b>
            </td>
            <td>
                <input
                  type="text"
                  id="typVoie"
                  name="typVoie"
                  value={inputValues["typVoie"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
                {(inputValues["typVoie"] == undefined || inputValues["typVoie"] == "")  && pageError == true && (
                <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>
              )}
            </td>
          </tr>
          <tr>
            <td>
            <b>Intitulé de voie{editable == true && "*"}</b>
            </td>
            <td>
                <input
                  type="text"
                  id="intVoie"
                  name="intVoie"
                  value={inputValues["intVoie"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
                {(inputValues["intVoie"] == undefined || inputValues["intVoie"] == "")  && pageError == true && (
                <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>
              )}
            </td>
            <td>
                <b>Complément de voie</b>
            </td>
            <td>
                <input
                  type="text"
                  id="complvoie"
                  name="complvoie"
                  value={inputValues["complvoie"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
            </td>
          </tr>
          </>}
          <tr>
            <td>
              <b>Code postal{editable == true && "*"}</b>
            </td>
            <td>
              {editable ? (
                <input
                  type="text"
                  id="codPos"
                  name="codPos"
                  value={inputValues["codPos"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
              ) : (
                <div className={styles.inputValue}>
                  {inputValues["codPos"]}
                </div>
              )}
              {(inputValues["codPos"] == undefined || inputValues["codPos"] == "")  && pageError == true && (
                <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>
              )}
            </td>
            <td>
              <b>Commune{editable == true && "*"}</b>
            </td>
            <td>
              {editable ? (
                <input
                  type="text"
                  id="comun"
                  name="comun"
                  value={inputValues["comun"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
              ) : (
                <div className={styles.inputValue}>
                  {inputValues["comun"]}
                </div>
              )}
              {(inputValues["comun"] == undefined || inputValues["comun"] == "")  && pageError == true && (
                <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <b>QPV</b>
            </td>
            <td>
              {editable ? (
                <select
                  value={inputValues["tippQpv"]}
                  onChange={(e) => changeFn(e)}
                  name="tippQpv"
                  id="tippQpv"
                  className={styles.inputSelect}
                >
                  <option value="">
                      ----------------------
                    </option>
                    <option value="Oui">
                      Oui
                    </option>
                    <option value="Non">
                      Non
                    </option>
                </select>
              ) : (
                <div className={styles.inputValue}>{inputValues["tippQpv"]}</div>
              )}
            </td>
            <td>
              <b>Zfu</b>
            </td>
            <td>
              {editable ? (
                <select
                  value={inputValues["tippZfu"]}
                  onChange={(e) => changeFn(e)}
                  name="tippZfu"
                  id="tippZfu"
                  className={styles.inputSelect}
                >
                  <option value="">
                      ----------------------
                    </option>
                    <option value="Oui">
                      Oui
                    </option>
                    <option value="Non">
                      Non
                    </option>
                </select>
              ) : (
                <div className={styles.inputValue}>{inputValues["tippZfu"]}</div>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <b>Niveau d'étude</b>
            </td>
            <td>
              {editable ? (
                <select
                  value={inputValues["scpNivetud"]}
                  onChange={(e) => changeFn(e)}
                  name="scpNivetud"
                  id="scpNivetud"
                  className={styles.inputSelect}
                >
                  <option value="">
                      ----------------------
                    </option>
                    <option value="Arrêt avant 3ème">
                      Arrêt avant 3ème
                    </option>
                    <option value="Brevet">
                    Brevet
                    </option>
                    <option value="CAP">
                    CAP
                    </option>
                    <option value="BP">
                    BP
                    </option>
                    <option value="BAC">
                    BAC
                    </option>
                    <option value="BTS">
                    BTS
                    </option>
                    <option value="DUT">
                    DUT
                    </option>
                    <option value="Bachelor 1">
                    Bachelor 1
                    </option>
                    <option value="Bachelor 2">
                    Bachelor 2
                    </option>
                    <option value="Bachelor 3">
                    Bachelor 3
                    </option>
                    <option value="Master 1">
                    Master 1
                    </option>
                    <option value="Master 2">
                    Master 2
                    </option>
                    <option value="Doctorat">
                    Doctorat
                    </option>
                    <option value="Diplôme à l'étranger">
                    Diplôme à l'étranger
                    </option>
                </select>
              ) : (
                <div className={styles.inputValue}>{inputValues["scpNivetud"]}</div>
              )}
            </td>
            <td>
              <b>Situation avant projet</b>
            </td>
            <td>
            {editable ? (
              <>
                              <select
                  value={inputValues["scpAvPrj"]}
                  onChange={(e) => changeFn(e)}
                  name="scpAvPrj"
                  id="scpAvPrj"
                  className={styles.inputSelect}
                >
                  <option value="">
                      ----------------------
                    </option>
                    <option value="DE">
                    DE
                    </option>
                    <option value="DELD">
                    DELD
                    </option>
                    <option value="RSA">
                    RSA
                    </option>
                    <option value="AAH">
                    AAH
                    </option>
                    <option value="Femme isolée">
                    Femme isolée
                    </option>
                    <option value="Salarié">
                    Salarié
                    </option>
                    <option value="Étudiant">
                    Étudiant
                    </option>
                    <option value="Champ libre">
                    Champ libre
                    </option>
                    { !["" ,"DE", "DELD", "RSA", "AAH", "Femme isolée", "Salarié", "Étudiant","Champ libre"].includes(inputValues["scpAvPrj"]) && <option value={inputValues["scpAvPrj"]}>
                    {inputValues["scpAvPrj"]}
                    </option>}
                </select>
                {inputValues["scpAvPrj"] == "Champ libre" && (
                  <input
                    type="text"
                    id="champLibre"
                    name="champLibre"
                    value={inputValues["champLibre"]}
                    onChange={(e) => changeFn(e)}
                    className={styles.input}
                    style={{marginLeft: "1rem"}}
                  />
                )}
              </>
              ) : (
                <div className={styles.inputValue}>{inputValues["scpAvPrj"]}</div>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <b>Expérience professionnelle</b>
            </td>
            <td>
            {editable ?
            <select onChange={(e) => changeFn(e)} value={inputValues["scpId"]} id="scpId" name="scpId" className={styles.inputSelect}>
                    <option value="">--------</option>
                    {categSocioPro.map((data, idx) => {
                            if (idx != 0) {
                                return <option key={data["Liste PCS-ESE"]} value={data["Liste PCS-ESE"]}>
                                    {data["Liste PCS-ESE"]} - {data["Liste des professions et catégories socioprofessionnelles des emplois salariés des employeurs privés et publics"]}
                                </option>
                            }
                        }
                    )}
                </select>: 
                <div className={styles.inputValue}>{categSocioPro ? categSocioProDisplayed : ""}</div>
            }             
            </td>
            <td>
              <b>Autorisation droit à l'image</b>
            </td>
            <td>
            {editable ? (
                <select
                  value={inputValues["tippAutimg"]}
                  onChange={(e) => changeFn(e)}
                  name="tippAutimg"
                  id="tippAutimg"
                  className={styles.inputSelect}
                > 
                <option value="">
                      ----------------------
                    </option>
                    <option value="Oui">
                      Oui
                    </option>
                    <option value="Non">
                      Non
                    </option>
                </select>
              ) : (
                <div className={styles.inputValue}>{inputValues["tippAutimg"]}</div>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

