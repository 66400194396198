import { useEffect, useState } from "react";
import styles from "./CAVisu.module.css";
import Pagination from "../../Pagination/Pagination";
import { useNavigate } from "react-router-dom";
import modifBleu from "../../../assets/modifbleu.png";
import poubBleu from "../../../assets/poubBleu.png";

export default function CAVisu({
  cas,
  removeDirOverlayFn,
  modifDirOverlayFn,
}) {
  const [numberElementsMax, setNumberElementsMax] = useState(3);
  const navigate = useNavigate();
  const [prevNum, setPrevNum] = useState(0);
  const [nextNum, setNextNum] = useState(3);

  const nextPage = () => {
    if (numberElementsMax >= cas.length) {
      // setNextNum((prev) => prev + 3)
      setNumberElementsMax(3);
    } else {
      setNumberElementsMax((prev) => prev + 3);
    }
  };

  useEffect(() => {
    if (cas.length == 0) {
      setNextNum(0);
    } else if (numberElementsMax > cas.length) {
      setNextNum(cas.length);
    } else {
      setNextNum(numberElementsMax);
    }
    if (numberElementsMax > cas.length) {
      const numsCandidate = [cas.length - 1, cas.length - 2];
      let smallerNumber;
      for (let i of numsCandidate) {
        if (i % 3 === 0) {
          smallerNumber = i + 1;
          break;
        }
      }
      if (smallerNumber == undefined) {
        setPrevNum(0);
      } else {
        setPrevNum(smallerNumber);
      }
    } else {
      if (cas.length == 0) {
        setPrevNum(0);
      } else {
        setPrevNum(numberElementsMax - 2);
      }
    }
  }, [numberElementsMax, cas]);

  const prevPage = () => {
    if (numberElementsMax == 3) {
      if (cas.length % 3 == 0) {
        setNumberElementsMax(cas.length);
      } else {
        const numsCandidate = [cas.length + 1, cas.length + 2];
        let greatestNumber;
        for (let i of numsCandidate) {
          if (i % 3 === 0) {
            greatestNumber = i;
            break;
          }
        }
        setNumberElementsMax(greatestNumber);
      }
    } else {
      setNumberElementsMax((prev) => prev - 3);
    }
    // }
  };

  return (
    <>
        {cas.length != 0 && <div className={styles.table}>
        <table className={styles.tableSearch}>
          <thead>
            <tr>
            <th style={{ width: "45%" }}>
              <b>Année</b>
            </th>
            <th style={{ width: "45%" }}>
              <b>Chiffre d'affaires</b>
            </th>
            <th style={{ width: "10%" }}></th>
            </tr>
          </thead>
          <tbody>
            {cas
              .slice(numberElementsMax - 3, numberElementsMax)
              .map((ca) => {
                return (
                  <tr key={ca["caDat"]}>
                    <td>{ca["caDat"]}</td>
                    <td>{ca["ca"]}</td>
                    <td>
                      <div className={styles.logoContainer}>
                        <img
                          src={modifBleu}
                          className={styles.logo}
                          onClick={() => {
                            modifDirOverlayFn(ca);
                          }}
                        />
                        <img
                          src={poubBleu}
                          className={styles.logo}
                          onClick={() => removeDirOverlayFn(ca)}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      <div>
        <Pagination nextPage={nextPage} prevPage={prevPage} />
        <p className={styles.nums}>
          {prevNum} à {nextNum} - Nombre Total d'éléments : {cas.length}{" "}
        </p>
      </div>
    </div>}

    </>
  );
}
