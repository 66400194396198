import styles from "./creerTiers.module.css"
import { useNavigate, useParams } from "react-router-dom";
import { useRef, useState } from "react";
import CreationPorteurProjetInfos from "../../components/CreationTiersVisu/PorteurProjetInfos/PorteurProjet.js";
import CreationProjetInfos from "../../components/CreationTiersVisu/ProjetInfos/ProjetInfos.js";
import CreationAccSouhaitInfos from "../../components/CreationTiersVisu/AccsouhaitInfos/AccSouhaitInfos.js";
import CreationAutresInfos from "../../components/CreationTiersVisu/AutresInfos/AutresInfos.js";
import CreationFormuleInfos from "../../components/CreationTiersVisu/FormuleInfos/FormuleInfos.js";
import FichiersVisu from "../../components/VisuUG/fichiersVisu/fichiersVisu.js";
import wordLogo from "../../assets/word.png";
import excelLogo from "../../assets/excel.png";
import pdfLogo from "../../assets/pdf.png";
import docLambda from "../../assets/documentLambda.png";
import importerBlanc from "../../assets/importerBlanc.png";
import { BounceLoader } from "react-spinners";
import { useMutation, useQuery } from "@tanstack/react-query";
import CreationPMInfos from "../../components/CreationTiersVisu/PMInfos/PMInfos.js";
import CreationDirigeantsInfos from "../../components/CreationTiersVisu/DirigeantsInfos/DirigeantsInfos.js";
import CreationOverlayAddDir from "../../components/CreationTiersVisu/overlayAddDirigeant/overlayAddDir.js";
import CreationEffectifsInfos from "../../components/CreationTiersVisu/EffectifsInfos/EffectifsInfos.js";

export default function CreationTiers () {
    const { type = "PP" } = useParams()
    const inputFile = useRef(null) 
    const [boxClicked, setboxClicked] = useState(true)
    const [filesPP, setfilesPP] = useState([]);
    const [filesPM, setfilesPM] = useState([]);
    const [pageError, setPageError] = useState(false)
    const [inputValuesPP, setInputValuesPP] = useState( { "infos": {}, "projet": {}, "autres": {} , "accSouhait": {"FormSouhait": {"Bureau":false,"Bureau Partagé":false,"Extra-Muros":false,"Coworking":false}, "SurfSouhait": {"8":false,"16":false,"18":false,"21":false,"24":false,"25":false,"32":false,"35":false}, "DatEntSouhait": ""}, "formule": {}} )
    const [inputValuesPM, setInputValuesPM] = useState({ "infos": {}, "formule": {}, "dirigeants": [], "effectifs": {} })
    const [newDirigeant, setNewDirigeant] = useState(false)
    const navigate = useNavigate()

    const handleFileUploadPP = async (event) => {
        const files = event.target.files;
        setfilesPP((prev) => {
              const listfilesPP = [];
              const formatsWord = [
                ".doc",
                ".docx",
                ".dotx",
                ".dotm",
                ".docm",
                ".rtf",
                ".txt",
                ".xml",
              ];
              const excelFormats = [
                ".xls",
                ".xlsx",
                ".xlsm",
                ".xlsb",
                ".xlt",
                ".xltx",
                ".xltm",
                ".csv",
                ".xml",
              ];
              const pdfFormat = [".pdf"];
              for (let obj of files) {
                const filename = obj["name"];
                if (formatsWord.some((format) => filename.includes(format))) {
                  listfilesPP.push([wordLogo, "", filename, obj]);
                } else if (excelFormats.some((format) => filename.includes(format))) {
                  listfilesPP.push([excelLogo, "", filename, obj]);
                } else if (pdfFormat.some((format) => filename.includes(format))) {
                  listfilesPP.push([pdfLogo, "", filename, obj]);
                } else {
                  listfilesPP.push([docLambda, "", filename, obj]);
                }
              }
              return [...prev, ...listfilesPP];
        })
      };
    
    const handleFileUploadPM = async (event) => {
        const files = event.target.files;
        setfilesPM((prev) => {
              const listfilesPM = [];
              const formatsWord = [
                ".doc",
                ".docx",
                ".dotx",
                ".dotm",
                ".docm",
                ".rtf",
                ".txt",
                ".xml",
              ];
              const excelFormats = [
                ".xls",
                ".xlsx",
                ".xlsm",
                ".xlsb",
                ".xlt",
                ".xltx",
                ".xltm",
                ".csv",
                ".xml",
              ];
              const pdfFormat = [".pdf"];
              for (let obj of files) {
                const filename = obj["name"];
                if (formatsWord.some((format) => filename.includes(format))) {
                    listfilesPM.push([wordLogo, "", filename, obj]);
                } else if (excelFormats.some((format) => filename.includes(format))) {
                    listfilesPM.push([excelLogo, "", filename, obj]);
                } else if (pdfFormat.some((format) => filename.includes(format))) {
                    listfilesPM.push([pdfLogo, "", filename, obj]);
                } else {
                    listfilesPM.push([docLambda, "", filename, obj]);
                }
              }
              return [...prev, ...listfilesPM];
        })
      };


    const changePP = (e, key = "infos") => {
            const { name, value, checked, type } = e.target;
            setInputValuesPP((prev) => {
              const newState = JSON.parse(JSON.stringify(prev));
              if (key === "infos") {
                if (name == "tippPrenom" || name == "tippNom" || name == "tippNomnai" || name == "typVoie" || name == "intVoie" || name == "complvoie" || name == "comun") {
                  newState.infos[name] = value.toUpperCase()
                } else {
                  newState.infos[name] = value
                }
              } else if (key === "projet") {
                newState.projet[name] = value;
              } else if (key === "autres") {
                newState.autres[name] = value;
              } else if (key === "formule") {
                newState.formule[name] = value;
              } else if (key === "accSouhait") {
                if (name === "DatEntSouhait") {
                  newState.accSouhait[name] = value;
                } else {
                  const key2 = ["Bureau", "Coworking", "Extra-Muros", "Bureau Partagé"].includes(name) ? "FormSouhait" : "SurfSouhait";
                  newState.accSouhait[key2][name] = type === 'checkbox' ? checked : value;
                }
              }
              return newState;
            });
    };

    const changePM = (e, key = "infos") => {
        const { name, value, checked, type } = e.target;
        setInputValuesPM((prev) => {
          const newState = JSON.parse(JSON.stringify(prev));
          if (key === "infos") {
            if (name == "tipmRso" || name == "tipmSigle" || name == "tipmtypVoie" || name == "tipmintVoie" || name == "tipmcomplvoie" || name == "tipmcomun") {
              newState.infos[name] = value.toUpperCase()
            } else {
              newState.infos[name] = value;
            }
          } else if (key === "formule") {
            newState.formule[name] = value;
          } else if (key === "effectifs") {
            newState.effectifs[name] = value;
          }
          return newState;
        });
};
    
    async function PostTiers(data) {
        const link = `${process.env.REACT_APP_API_URL}/tiers/create`;
        // const link = `http://localhost:8080/tiers/create`
        const formData = new FormData();
        if (data.PP) {
          formData.append("PP", JSON.stringify(data.PP))
        }
        if (data.PM) {
          formData.append("PM", JSON.stringify(data.PM))
        }
        if (data.filesPP) {
          data.filesPP.forEach((file, index) => {
              formData.append(`filesPP[${index}]`, file[3]);
          });
        }

        if (data.filesPM) {
          data.filesPM.forEach((file, index) => {
              formData.append(`filesPM[${index}]`, file[3]);
          });
        }
        
        const response = await fetch(link, {
          method: "POST",
          body: formData,
          credentials: "include",
        });

        const dataReceived = await response.json(); 
        if (!response.ok) {
            throw dataReceived;
        }
        return dataReceived;
      }
    
    const mutationDataPost = useMutation({
        mutationFn: (data) => PostTiers(data),
        onSuccess: (data) => {
          navigate(`/pageConnecte/rechercheTiers/tiers/${data["id"]}`)
          window.scrollTo(0, 0);
        },
      });

    const removeOverlayFnPP = (idx) => {
    setfilesPP(prev => {
        return [...prev.slice(0, idx), ...prev.slice(idx + 1, prev.length)]
    })
    }

    const removeOverlayFnPM = (idx) => {
        setfilesPM(prev => {
            return [...prev.slice(0, idx), ...prev.slice(idx + 1, prev.length)]
        })
    }

    const removeOverlayFnPMDir = (idx) => {
        setInputValuesPM(prev => {
            const newState = JSON.parse(JSON.stringify(prev));
            newState["dirigeants"] =  [...newState["dirigeants"].slice(0, idx), ...newState["dirigeants"].slice(idx + 1, newState["dirigeants"].length)]
            return newState
        })
    }

    const addDirigeant = (newDir) => {
        setInputValuesPM((prev) => {
            const newState = JSON.parse(JSON.stringify(prev));
            let copyNewDir = JSON.parse(JSON.stringify(newDir));
            if (newDir["champLibre"] == "") {
              delete copyNewDir["champLibre"]
              newState["dirigeants"].push(copyNewDir)
              return newState  
            } else {
              delete copyNewDir["champLibre"]
              copyNewDir["relTyp"] = newDir["champLibre"]
              newState["dirigeants"].push(copyNewDir)
              return newState  
            }
        })
    }

    async function getPPs() {
        const link = `${process.env.REACT_APP_API_URL}/tiers/PPLib`
        // const link = `http://localhost:8080/tiers/PPLib`;
        const response = await fetch(link);
        if (!response.ok) {
          throw "Il y a un problème avec le serveur";
        }
        const data = await response.json();
        return data;
      }
    
      const { data: PPs = [] } = useQuery({
        queryKey: ["PPs"],
        queryFn: () => getPPs(),
        refetchOnWindowFocus: false
      });

    const changeDir = (e, cl) => {
        setInputValuesPM((prev) => {
            const newState = JSON.parse(JSON.stringify(prev));
            if (cl) {
              newState["dirigeants"][0][e.target.name] = e.target.value
              return newState
            } else {
              delete newState["dirigeants"][0]["champLibre"]
              newState["dirigeants"][0][e.target.name] = e.target.value
              return newState
            }
        })
    }

    const checkAndSendPPPM = (data) => {
        if (inputValuesPP["infos"]["tippCiv"] == "" || inputValuesPP["infos"]["tippCiv"] == undefined ||
        inputValuesPP["infos"]["tippPrenom"] == "" || inputValuesPP["infos"]["tippPrenom"] == undefined ||
        inputValuesPP["infos"]["tippNom"] == "" || inputValuesPP["infos"]["tippNom"] == undefined ||
        inputValuesPP["infos"]["tippEmail"] == "" || inputValuesPP["infos"]["tippEmail"] == undefined ||
        inputValuesPP["infos"]["tippTelpor"] == "" || inputValuesPP["infos"]["tippTelpor"] == undefined ||
        inputValuesPP["infos"]["numVoie"] == "" || inputValuesPP["infos"]["numVoie"] == undefined ||
        inputValuesPP["infos"]["typVoie"] == "" || inputValuesPP["infos"]["typVoie"] == undefined ||
        inputValuesPP["infos"]["intVoie"] == "" || inputValuesPP["infos"]["intVoie"] == undefined ||
        inputValuesPP["infos"]["codPos"] == "" || inputValuesPP["infos"]["codPos"] == undefined ||
        inputValuesPP["infos"]["comun"] == "" || inputValuesPP["infos"]["comun"] == undefined ||
        inputValuesPP["projet"]["ActPrj"] == "" || inputValuesPP["projet"]["ActPrj"] == undefined ||
        inputValuesPP["autres"]["premEntretienDate"] == "" || inputValuesPP["autres"]["premEntretienDate"] == undefined
        || inputValuesPP["autres"]["premEntretienHFin"] == "" || inputValuesPP["autres"]["premEntretienHFin"] == undefined
        || inputValuesPP["autres"]["premEntretienHDeb"] == "" || inputValuesPP["autres"]["premEntretienHDeb"] == undefined
        || inputValuesPP["formule"]["entDat"] == "" || inputValuesPP["formule"]["entDat"] == undefined
        || inputValuesPP["formule"]["formule"] == "" || inputValuesPP["formule"]["formule"] == undefined
        || inputValuesPM["infos"]["tipmRso"] == "" || inputValuesPM["infos"]["tipmRso"] == undefined
        || inputValuesPM["infos"]["tipmForjur"] == "" || inputValuesPM["infos"]["tipmForjur"] == undefined
        || inputValuesPM["infos"]["tipmAct"] == "" || inputValuesPM["infos"]["tipmAct"] == undefined
        || inputValuesPM["infos"]["tipmDatcre"] == "" || inputValuesPM["infos"]["tipmDatcre"] == undefined
        || inputValuesPM["infos"]["tipmNumsir"] == "" || inputValuesPM["infos"]["tipmNumsir"] == undefined
        || inputValuesPM["infos"]["tipmCodape"] == "" || inputValuesPM["infos"]["tipmCodape"] == undefined
        || inputValuesPM["infos"]["tipmnumVoie"] == "" || inputValuesPM["infos"]["tipmnumVoie"] == undefined
        || inputValuesPM["infos"]["tipmtypVoie"] == "" || inputValuesPM["infos"]["tipmtypVoie"] == undefined
        || inputValuesPM["infos"]["tipmintVoie"] == "" || inputValuesPM["infos"]["tipmintVoie"] == undefined
        || inputValuesPM["infos"]["tipmcodPos"] == "" || inputValuesPM["infos"]["tipmcodPos"] == undefined
        || inputValuesPM["infos"]["tipmcomun"] == "" || inputValuesPM["infos"]["tipmcomun"] == undefined
        || inputValuesPM["infos"]["tipmEmail"] == "" || inputValuesPM["infos"]["tipmEmail"] == undefined
        || inputValuesPM["infos"]["tipmTelpor"] == "" || inputValuesPM["infos"]["tipmTelpor"] == undefined
        || inputValuesPM["formule"]["entDat"] == "" || inputValuesPM["formule"]["entDat"] == undefined
        || inputValuesPM["formule"]["formule"] == "" || inputValuesPM["formule"]["formule"] == undefined
        // || inputValuesPM["effectifs"]["effDat"] == "" || inputValuesPM["effectifs"]["effDat"] == undefined
        // || inputValuesPM["effectifs"]["nbCdi"] == "" || inputValuesPM["effectifs"]["nbCdi"] == undefined
        // || inputValuesPM["effectifs"]["nbCdd"] == "" || inputValuesPM["effectifs"]["nbCdd"] == undefined
        // || inputValuesPM["effectifs"]["nbInt"] == "" || inputValuesPM["effectifs"]["nbInt"] == undefined
        // || inputValuesPM["effectifs"]["nbCaid"] == "" || inputValuesPM["effectifs"]["nbCaid"] == undefined
        // || inputValuesPM["effectifs"]["nbAlter"] == "" || inputValuesPM["effectifs"]["nbAlter"] == undefined
        // || inputValuesPM["effectifs"]["nbStag"] == "" || inputValuesPM["effectifs"]["nbStag"] == undefined
        ) {
        setPageError(true)
      } else {
        setPageError(false)
        mutationDataPost.mutate(data)
        }
      }
    
    const checkAndSendPP = (data) => {
    if (inputValuesPP["infos"]["tippCiv"] == "" || inputValuesPP["infos"]["tippCiv"] == undefined ||
    inputValuesPP["infos"]["tippPrenom"] == "" || inputValuesPP["infos"]["tippPrenom"] == undefined ||
    inputValuesPP["infos"]["tippNom"] == "" || inputValuesPP["infos"]["tippNom"] == undefined ||
    inputValuesPP["infos"]["tippEmail"] == "" || inputValuesPP["infos"]["tippEmail"] == undefined ||
    inputValuesPP["infos"]["tippTelpor"] == "" || inputValuesPP["infos"]["tippTelpor"] == undefined ||
    inputValuesPP["infos"]["numVoie"] == "" || inputValuesPP["infos"]["numVoie"] == undefined ||
    inputValuesPP["infos"]["typVoie"] == "" || inputValuesPP["infos"]["typVoie"] == undefined ||
    inputValuesPP["infos"]["intVoie"] == "" || inputValuesPP["infos"]["intVoie"] == undefined ||
    inputValuesPP["infos"]["codPos"] == "" || inputValuesPP["infos"]["codPos"] == undefined ||
    inputValuesPP["infos"]["comun"] == "" || inputValuesPP["infos"]["comun"] == undefined ||
    inputValuesPP["projet"]["ActPrj"] == "" || inputValuesPP["projet"]["ActPrj"] == undefined ||
    inputValuesPP["autres"]["premEntretienDate"] == "" || inputValuesPP["autres"]["premEntretienDate"] == undefined
    || inputValuesPP["autres"]["premEntretienHFin"] == "" || inputValuesPP["autres"]["premEntretienHFin"] == undefined
    || inputValuesPP["autres"]["premEntretienHDeb"] == "" || inputValuesPP["autres"]["premEntretienHDeb"] == undefined
    || inputValuesPP["formule"]["entDat"] == "" || inputValuesPP["formule"]["entDat"] == undefined
    || inputValuesPP["formule"]["formule"] == "" || inputValuesPP["formule"]["formule"] == undefined
    ) {
    setPageError(true)
    } else {
    setPageError(false)
    mutationDataPost.mutate(data)
    }
    }

    const checkAndSendPM = (data) => {
      if (inputValuesPM["infos"]["tipmRso"] == "" || inputValuesPM["infos"]["tipmRso"] == undefined
      || inputValuesPM["infos"]["tipmForjur"] == "" || inputValuesPM["infos"]["tipmForjur"] == undefined
      || inputValuesPM["infos"]["tipmAct"] == "" || inputValuesPM["infos"]["tipmAct"] == undefined
      || inputValuesPM["infos"]["tipmDatcre"] == "" || inputValuesPM["infos"]["tipmDatcre"] == undefined
      || inputValuesPM["infos"]["tipmNumsir"] == "" || inputValuesPM["infos"]["tipmNumsir"] == undefined
      || inputValuesPM["infos"]["tipmCodape"] == "" || inputValuesPM["infos"]["tipmCodape"] == undefined
      || inputValuesPM["infos"]["tipmnumVoie"] == "" || inputValuesPM["infos"]["tipmnumVoie"] == undefined
      || inputValuesPM["infos"]["tipmtypVoie"] == "" || inputValuesPM["infos"]["tipmtypVoie"] == undefined
      || inputValuesPM["infos"]["tipmintVoie"] == "" || inputValuesPM["infos"]["tipmintVoie"] == undefined
      || inputValuesPM["infos"]["tipmcodPos"] == "" || inputValuesPM["infos"]["tipmcodPos"] == undefined
      || inputValuesPM["infos"]["tipmcomun"] == "" || inputValuesPM["infos"]["tipmcomun"] == undefined
      || inputValuesPM["infos"]["tipmEmail"] == "" || inputValuesPM["infos"]["tipmEmail"] == undefined
      || inputValuesPM["infos"]["tipmTelpor"] == "" || inputValuesPM["infos"]["tipmTelpor"] == undefined
      || inputValuesPM["formule"]["entDat"] == "" || inputValuesPM["formule"]["entDat"] == undefined
      || inputValuesPM["formule"]["formule"] == "" || inputValuesPM["formule"]["formule"] == undefined
      // || inputValuesPM["effectifs"]["effDat"] == "" || inputValuesPM["effectifs"]["effDat"] == undefined
      // || inputValuesPM["effectifs"]["nbCdi"] == "" || inputValuesPM["effectifs"]["nbCdi"] == undefined
      // || inputValuesPM["effectifs"]["nbCdd"] == "" || inputValuesPM["effectifs"]["nbCdd"] == undefined
      // || inputValuesPM["effectifs"]["nbInt"] == "" || inputValuesPM["effectifs"]["nbInt"] == undefined
      // || inputValuesPM["effectifs"]["nbCaid"] == "" || inputValuesPM["effectifs"]["nbCaid"] == undefined
      // || inputValuesPM["effectifs"]["nbAlter"] == "" || inputValuesPM["effectifs"]["nbAlter"] == undefined
      // || inputValuesPM["effectifs"]["nbStag"] == "" || inputValuesPM["effectifs"]["nbStag"] == undefined
      ) {
      setPageError(true)
      } else {
      setPageError(false)
      mutationDataPost.mutate(data)
      }
      }

    return (
        <div className={styles.container}>
            <div
                className={styles.boxHeader}
                onClick={() => setboxClicked(true)}
                >
                <p
                    className={styles.text}
                >
                    Formulaire
                </p>
            </div>
                {type == "PP" && 
                <>
                <div className={styles.boxesContainer} style={{justifyContent: "start"}}>
                    <div
                        className={styles.box}
                    >
                    <p
                        className={styles.text}
                    >
                        Porteur de projet
                    </p>
                    </div>
                </div>
                  <CreationPorteurProjetInfos inputValues={inputValuesPP["infos"]} pageError={pageError} changeFn={changePP} />
                  <CreationProjetInfos inputValues={inputValuesPP["projet"]} pageError={pageError} changeFn={changePP} />
                  <CreationAccSouhaitInfos inputValues={inputValuesPP["accSouhait"]} pageError={pageError} changeFn={changePP} />
                  <CreationAutresInfos inputValues={inputValuesPP["autres"]} pageError={pageError} changeFn={changePP} />
                  <CreationFormuleInfos inputValues={inputValuesPP["formule"]} pageError={pageError} changeFn={changePP} />
                  <div className={styles.containerFiles}>
                      <p className={styles.textTitle}>FICHIERS</p>
                      <input
                          type="file"
                          style={{ display: "none" }}
                          ref={inputFile}
                          onChange={handleFileUploadPP}
                          multiple
                      />
                      <div
                          className={styles.buttonAddContainer}
                          style={{ marginBottom: `${filesPP.length == 0 ? "10rem" : ""}` }}
                      >
                          <button
                          onClick={() => inputFile.current.click()}
                          className={styles.buttonImport}
                          >
                          <p>Importer</p>
                          <img
                              src={importerBlanc}
                              className={styles.logo}
                              style={{ height: "2rem" }}
                          />
                          </button>
                      </div>
                  </div>
                  {filesPP.length != 0 && (
                      <FichiersVisu fichiers={filesPP} removeOverlayFn={removeOverlayFnPP} />
                  )}
                  {(pageError == true && mutationDataPost.isPending == false) && (
                          <p style={{color: "red", fontSize:"1rem"}}>Veuillez vérifier le formulaire</p>
                        )}
                  <button
                            className={styles.buttonValid}
                            onClick={() => checkAndSendPP({PP: inputValuesPP, filesPP: filesPP})}
                            disabled={mutationDataPost.isPending}
                        >
                            Enregistrer
                    </button>
                    
                </>
                }
                {type == "PM" && 
                <>
                <div className={styles.boxesContainer} style={{justifyContent: "end"}}>
                    <div
                    className={styles.box}
                    >
                    <p
                        className={styles.text}
                    >
                        Personne Morale
                    </p>
                    </div>
                </div>
                {newDirigeant && (
                            <CreationOverlayAddDir
                                openOverlayFn={setNewDirigeant}
                                addDirigeant={addDirigeant}
                                inputValues={inputValuesPM["dirigeants"]}
                                PPs={PPs}
                            />
                        )}
                        <CreationPMInfos inputValues={inputValuesPM["infos"]} pageError={pageError} changeFn={changePM} />
                        <CreationFormuleInfos inputValues={inputValuesPM["formule"]} pageError={pageError} changeFn={changePM} />
                        <CreationDirigeantsInfos inputValues={inputValuesPM["dirigeants"]} type={type} changeDir={changeDir} removeDirOverlayFn={removeOverlayFnPMDir} modifDirOverlayFn={setNewDirigeant} />
                        {/* <CreationEffectifsInfos inputValues={inputValuesPM["effectifs"]} pageError={pageError} changeFn={changePM} /> */}
                        <div className={styles.containerFiles}>
                            <p className={styles.textTitle}>FICHIERS</p>
                            <input
                                type="file"
                                style={{ display: "none" }}
                                ref={inputFile}
                                onChange={handleFileUploadPM}
                                multiple
                            />
                            <div
                                className={styles.buttonAddContainer}
                                style={{ marginBottom: `${filesPM.length == 0 ? "10rem" : ""}` }}
                            >
                                <button
                                onClick={() => inputFile.current.click()}
                                className={styles.buttonImport}
                                >
                                <p>Importer</p>
                                <img
                                    src={importerBlanc}
                                    className={styles.logo}
                                    style={{ height: "2rem" }}
                                />
                                </button>
                            </div>
                        </div>
                        {filesPM.length != 0 && (
                            <FichiersVisu fichiers={filesPM} removeOverlayFn={removeOverlayFnPM} />
                        )}
                        {(pageError == true && mutationDataPost.isPending == false) && (
                          <p style={{color: "red", fontSize:"1rem"}}>Veuillez vérifier le formulaire</p>
                        )}
                        <button
                            className={styles.buttonValid}
                            onClick={() => checkAndSendPM({PM: inputValuesPM, filesPM: filesPM})}
                            disabled={mutationDataPost.isPending}
                        >
                            Enregistrer
                        </button>
                </>
                }
                {type == "PPPM" && (
                    <>
                    <div className={styles.boxesContainer}>
                        <div
                        className={`${boxClicked ? styles.box : styles.boxNotClicked}`}
                        onClick={() => setboxClicked(true)}
                        >
                        <p
                            className={`${boxClicked ? styles.text :  styles.textNotClicked}`}
                        >
                            Porteur de projet
                        </p>
                        </div>
                        <div
                        className={`${boxClicked ? styles.boxNotClicked : styles.box}`}
                        onClick={() => {
                            const lib = inputValuesPP["infos"]["tippPrenom"] + " " + inputValuesPP["infos"]["tippNom"]
                            if (inputValuesPM["dirigeants"].length == 0) {
                                setInputValuesPM((prev) => {
                                    const newState = JSON.parse(JSON.stringify(prev));
                                    if ((inputValuesPP["infos"]["tippPrenom"] != undefined && inputValuesPP["infos"]["tippPrenom"] != "") && (inputValuesPP["infos"]["tippNom"] != undefined && inputValuesPP["infos"]["tippNom"] != "")) {
                                        newState["dirigeants"].push({tiLib: inputValuesPP["infos"]["tippPrenom"] + " " + inputValuesPP["infos"]["tippNom"], relTyp: "", relDtd: "", relDtf: ""})
                                        return newState
                                    } else {
                                        newState["dirigeants"].push({tiLib: "", relTyp: "", relDtd: "", relDtf: ""})
                                        return newState
                                    }
                                })
                            } else if ((inputValuesPP["infos"]["tippPrenom"] != undefined && inputValuesPP["infos"]["tippPrenom"] != "") && (inputValuesPP["infos"]["tippNom"] != undefined && inputValuesPP["infos"]["tippNom"] != "") && inputValuesPM["dirigeants"][0]["tiLib"] != lib) {
                                setInputValuesPM((prev)=> {
                                    const newState = JSON.parse(JSON.stringify(prev));
                                    newState["dirigeants"][0]["tiLib"] = lib
                                    return newState
                                })
                            }
                            setboxClicked(false)
                        }}
                        >
                        <p
                            className={`${boxClicked ? styles.textNotClicked : styles.text}`}
                        >
                            Personne Morale
                        </p>
                        </div>
                    </div>
                    {boxClicked ?
                    <>
                        <CreationPorteurProjetInfos inputValues={inputValuesPP["infos"]} pageError={pageError} changeFn={changePP} />
                        <CreationProjetInfos inputValues={inputValuesPP["projet"]} pageError={pageError} changeFn={changePP} />
                        <CreationAccSouhaitInfos inputValues={inputValuesPP["accSouhait"]} pageError={pageError} changeFn={changePP} />
                        <CreationAutresInfos inputValues={inputValuesPP["autres"]} pageError={pageError} changeFn={changePP} />
                        <CreationFormuleInfos inputValues={inputValuesPP["formule"]} pageError={pageError} changeFn={changePP} />
                        <div className={styles.containerFiles}>
                            <p className={styles.textTitle}>FICHIERS</p>
                            <input
                                type="file"
                                style={{ display: "none" }}
                                ref={inputFile}
                                onChange={handleFileUploadPP}
                                multiple
                            />
                            <div
                                className={styles.buttonAddContainer}
                                style={{ marginBottom: `${filesPP.length == 0 ? "10rem" : ""}` }}
                            >
                                <button
                                onClick={() => inputFile.current.click()}
                                className={styles.buttonImport}
                                >
                                <p>Importer</p>
                                <img
                                    src={importerBlanc}
                                    className={styles.logo}
                                    style={{ height: "2rem" }}
                                />
                                </button>
                            </div>
                        </div>
                        {filesPP.length != 0 && (
                            <FichiersVisu fichiers={filesPP} removeOverlayFn={removeOverlayFnPP} />
                        )}
                    </>
                    :
                    <>  
                        {newDirigeant && (
                            <CreationOverlayAddDir
                                openOverlayFn={setNewDirigeant}
                                addDirigeant={addDirigeant}
                                inputValues={inputValuesPM["dirigeants"]}
                                PPs={PPs}
                            />
                        )}
                        <CreationPMInfos inputValues={inputValuesPM["infos"]} pageError={pageError} changeFn={changePM} />
                        <CreationFormuleInfos inputValues={inputValuesPM["formule"]} pageError={pageError} changeFn={changePM} />
                        <CreationDirigeantsInfos inputValues={inputValuesPM["dirigeants"]} changeDir={changeDir} type={type} removeDirOverlayFn={removeOverlayFnPMDir} modifDirOverlayFn={setNewDirigeant} />
                        {/* <CreationEffectifsInfos inputValues={inputValuesPM["effectifs"]} pageError={pageError} changeFn={changePM} /> */}
                        <div className={styles.containerFiles}>
                            <p className={styles.textTitle}>FICHIERS</p>
                            <input
                                type="file"
                                style={{ display: "none" }}
                                ref={inputFile}
                                onChange={handleFileUploadPM}
                                multiple
                            />
                            <div
                                className={styles.buttonAddContainer}
                                style={{ marginBottom: `${filesPM.length == 0 ? "10rem" : ""}` }}
                            >
                                <button
                                onClick={() => inputFile.current.click()}
                                className={styles.buttonImport}
                                >
                                <p>Importer</p>
                                <img
                                    src={importerBlanc}
                                    className={styles.logo}
                                    style={{ height: "2rem" }}
                                />
                                </button>
                            </div>
                        </div>
                        {filesPM.length != 0 && (
                            <FichiersVisu fichiers={filesPM} removeOverlayFn={removeOverlayFnPM} />
                        )}
                        {(pageError == true && mutationDataPost.isPending == false) && (
                          <p style={{color: "red", fontSize:"1rem"}}>Veuillez vérifier le formulaire</p>
                        )}
                        <button
                            className={styles.buttonValid}
                            onClick={() => checkAndSendPPPM({PP: inputValuesPP, PM: inputValuesPM, filesPP: filesPP, filesPM: filesPM})}
                            disabled={mutationDataPost.isPending}
                        >
                            Enregistrer
                        </button>
                        {/* {showMessage && <p className={styles.textError}>Une erreur s'est produite</p>} */}

                    </>
                    }
                    </>
                )
                }
                {mutationDataPost.isPending == true && (
                    <div className={styles.bounce}>
                      <BounceLoader color="black" />
                    </div>
                )}
        </div>
    )
}