import styles from "./InfosVisu.module.css";

export default function InfosVisu({ changeFn, editable, inputValues, pageError }) {


  return (
    <div className={styles.table}>
      <table className={styles.tableSearch}>
        <thead>
          <tr>
          <th style={{ width: "20%" }}></th>
          <th style={{ width: "30%" }}></th>
          <th style={{ width: "20%" }}></th>
          <th style={{ width: "30%" }}></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>Entreprise{editable == true && "*"}</b>
            </td>
            <td>
              {editable ? (
                <div>
                  oui
                </div>
              ) : (
                <div className={styles.inputValue}>{inputValues["tipmRso"]}</div>
              )}
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
              <b>Date de début{editable == true && "*"}</b>
            </td>
            <td>
              {editable ? (
                <input
                  type="date"
                  id="convDtd"
                  name="convDtd"
                  value={inputValues["convDtd"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
              ) : (
                <div className={styles.inputValue}>{inputValues["convDtd"]?.split('-').reverse().join('-')}</div>
              )}
              {(inputValues["convDtd"] == undefined ||
                inputValues["convDtd"] == "") &&
                pageError == true && <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>}
            </td>
            <td>
              <b>Date de fin{editable == true && "*"}</b>
            </td>
            <td>
            {editable ? (
                <input
                  type="date"
                  id="convDtf"
                  name="convDtf"
                  value={inputValues["convDtf"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
              ) : (
                <div className={styles.inputValue}>{inputValues["convDtf"]?.split('-').reverse().join('-')}</div>
              )}
              {(inputValues["convDtf"] == undefined ||
                inputValues["convDtf"] == "") &&
                pageError == true && <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>}
            </td>
          </tr>
          <tr>
            <td>
                <b>Date de signature</b>
              </td>
              <td>
              {editable ? (
                  <input
                    type="date"
                    id="convDatsig"
                    name="convDatsig"
                    value={inputValues["convDatsig"]}
                    onChange={(e) => changeFn(e)}
                    className={styles.input}
                  />
                ) : (
                  <div className={styles.inputValue}>{inputValues["convDatsig"]?.split('-').reverse().join('-')}</div>
                )}
                  {(inputValues["convDatsig"] == undefined ||
                  inputValues["convDatsig"] == "") &&
                  pageError == true && <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>}
            </td>
            <td>
              <b>Formule</b>
            </td>
            <td>
              {editable ? (
                <input
                  type="text"
                  id="convForm"
                  name="convForm"
                  value={inputValues["convForm"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
              ) : (
                <div className={styles.inputValue}>{inputValues["convForm"]}</div>
              )}
                {(inputValues["convForm"] == undefined ||
            inputValues["convForm"] == "") &&
            pageError == true && <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>}
            </td>
            </tr>
        </tbody>
      </table>
    </div>
  );
}

