import ReactDOM from "react-dom";
import styles from "./overlayAddEff.module.css";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { BounceLoader } from "react-spinners";

export default function OverlayAddEff({
  openOverlayFn,
  effToModify,
  effectifs,
}) {
  const { id } = useParams();
  const [newEff, setnewEff] = useState({
    tipmId: id,
    effDat: effToModify["effDat"] ? effToModify["effDat"] : new Date().getFullYear(),
    nbCdi: effToModify["nbCdi"] ? effToModify["nbCdi"] : 0,
    nbCdd: effToModify["nbCdd"] ? effToModify["nbCdd"] : 0,
    nbInt: effToModify["nbInt"] ? effToModify["nbInt"] : 0,
    nbCaid: effToModify["nbCaid"] ? effToModify["nbCaid"] : 0,
    nbAlter: effToModify["nbAlter"] ? effToModify["nbAlter"] : 0,
    nbStag: effToModify["nbStag"] ? effToModify["nbStag"] : 0,
  });

  const allYears = effectifs.map((data) => data.effDat);

  const [pageError, setPageError] = useState(false);
  const queryClient = useQueryClient();

  const changeEff = (e) => {
    setnewEff((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const checkAndSend = () => {
    if (
        allYears.includes(newEff["effDat"].toString()) || newEff["effDat"] == ""
    ) {
      setPageError(true);
    } else {
      setPageError(false);
      mutationPostDir.mutate(newEff);
    }
  };

  async function addEff(data) {
    const link = `${process.env.REACT_APP_API_URL}/tiers/effectif`
    // const link = `http://localhost:8080/tiers/effectif`;
    const response = await fetch(link, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const dataReceived = await response.json();
    return dataReceived;
  }

  const mutationPostDir = useMutation({
    mutationFn: (data) => addEff(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["Tiers", id]);
      openOverlayFn((prev) => !prev);
    },
  });

  async function ModifyEff(data) {
    const link = `${process.env.REACT_APP_API_URL}/tiers/effectif`
    // const link = `http://localhost:8080/tiers/effectif`;
    const response = await fetch(link, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const dataReceived = await response.json();
    return dataReceived;
  }

  const mutationUpdateDir = useMutation({
    mutationFn: (data) => ModifyEff(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["Tiers", id]);
      openOverlayFn((prev) => !prev);
    },
  });


  return ReactDOM.createPortal(
    <div className={styles.overlay} onClick={() => openOverlayFn(false)}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <p className={styles.textOverlayTitle}>
          {Object.keys(effToModify).length != 0 ? "MODIFIER" : "AJOUTER"}{" "}
          EFFECTIF
        </p>
        <div>
            <div className={styles.rowContainer}>
                <div className={styles.fieldContainer}>
                    <label className={styles.label} htmlFor="effDat">
                    Année*
                    </label>
                    <input
                    type="number"
                    id="effDat"
                    name="effDat"
                    value={newEff["effDat"]}
                    readOnly={Object.keys(effToModify).length != 0}
                    onChange={(e) => changeEff(e)}
                    className={styles.input}
                    min="2000"
                    />
                    {newEff["effDat"] == "" && pageError == true && (
                    <small className={styles.small}>Ce champs est requis</small>
                    )}
                    {allYears.includes(newEff["effDat"].toString()) && pageError == true && (
                    <small className={styles.small}>
                        Cette année est déjà présente
                    </small>
                    )}
                </div>
                <div className={styles.fieldContainer}>
                    <label className={styles.label} htmlFor="nbCdi">
                    CDI(s)
                    </label>
                    <input
                    type="number"
                    id="nbCdi"
                    name="nbCdi"
                    value={newEff["nbCdi"]}
                    onChange={(e) => changeEff(e)}
                    className={styles.input}
                    min="0"
                    />
                </div>
            </div>
            <div  className={styles.rowContainer}>
                <div className={styles.fieldContainer}>
                    <label className={styles.label} htmlFor="nbCdd">
                    CDD(s)
                    </label>
                    <input
                    type="number"
                    id="nbCdd"
                    name="nbCdd"
                    value={newEff["nbCdd"]}
                    onChange={(e) => changeEff(e)}
                    className={styles.input}
                    min="0"
                    />
                </div>
                <div className={styles.fieldContainer}>
                    <label className={styles.label} htmlFor="nbInt">
                        Intérimaire(s)
                    </label>
                    <input
                    type="number"
                    id="nbInt"
                    name="nbInt"
                    value={newEff["nbInt"]}
                    onChange={(e) => changeEff(e)}
                    className={styles.input}
                    min="0"
                    />
                </div>
            </div>
            <div  className={styles.rowContainer}>
                <div className={styles.fieldContainer}>
                    <label className={styles.label} htmlFor="nbCaid">
                    Contrat(s) aidé(s)
                    </label>
                    <input
                    type="number"
                    id="nbCaid"
                    name="nbCaid"
                    value={newEff["nbCaid"]}
                    onChange={(e) => changeEff(e)}
                    className={styles.input}
                    min="0"
                    />
                    {newEff["relTyp"] == "" && pageError == true && (
                    <small className={styles.small}>Ce champs est requis</small>
                    )}
            </div>
            <div className={styles.fieldContainer}>
                <label className={styles.label} htmlFor="nbAlter">
                Alternant(s)
                </label>
                <input
                type="number"
                id="nbAlter"
                name="nbAlter"
                value={newEff["nbAlter"]}
                onChange={(e) => changeEff(e)}
                className={styles.input}
                min="0"
                />
            </div>
            </div>
            <div  className={styles.rowContainer}>
                <div className={styles.fieldContainer}>
                    <label className={styles.label} htmlFor="nbStag">
                    Stagiaire(s)
                    </label>
                    <input
                    type="number"
                    id="nbStag"
                    name="nbStag"
                    value={newEff["nbStag"]}
                    onChange={(e) => changeEff(e)}
                    className={styles.input}
                    min="0"
                    />
                </div>
            </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          {Object.keys(effToModify).length == 0 ? (
            <button
              className={styles.buttonValid}
              onClick={() => checkAndSend()}
              disabled={mutationPostDir.isPending}
            >
              Ajouter
            </button>
          ) : (
            <button
              className={styles.buttonValid}
              onClick={() => mutationUpdateDir.mutate(newEff)}
              disabled={mutationUpdateDir.isPending}
            >
              Modifier
            </button>
          )}
          <button
            className={styles.buttonValid}
            onClick={() => openOverlayFn(false)}
          >
            Retour
          </button>
        </div>
        {mutationPostDir.isPending == true && (
          <div className={styles.bounce}>
            <BounceLoader color="white" />
          </div>
        )}
        {mutationUpdateDir.isPending == true && (
          <div className={styles.bounce}>
            <BounceLoader color="white" />
          </div>
        )}
      </div>
    </div>,
    document.getElementById("overlay")
  );
}
