import { useEffect, useState } from "react";
import styles from "./BarChartPortPrj.module.css";
import { BarChart, Bar, LabelList } from "recharts";
import { useNavigate } from "react-router-dom";

export default function BarChartPortPrj({ data }) {
  const navigate = useNavigate()

  
  return (
  <BarChart width={100} height={500} data={data} className={styles.barchart}>
   <Bar dataKey="value" fill="#446aa2" onClick={(e) => {
          navigate(`../rechercheTiers/recherche/Porteur de projet`)
        }}
>
      <LabelList dataKey="value" position="inside" fill="#ffffff" className={styles.labelValue} />
    </Bar>
  </BarChart>
  )
}
