import ReactDOM from "react-dom";
import styles from "./overlayAddDir.module.css";
import { useState } from "react";

export default function CreationOverlayAddDir({
  openOverlayFn,
  addDirigeant,
  inputValues,
  PPs,
}) {
  const [newDir, setnewDir] = useState({
    tippId: "",
    tiLib: "",
    relDtd: "",
    relDtf: "",
    relTyp: "",
    champLibre: ""
  });
  const [pageError, setPageError] = useState(false);

  const changeDir = (e) => {
    if (e.target.name == "tippId") {
      const tiLib = PPs.filter((PP) => PP.tiId == e.target.value);
      setnewDir((prev) => {
        return {
          ...prev,
          [e.target.name]: e.target.value,
          tiLib: tiLib[0]["tiLib"]
        };
      });
    } else {
      setnewDir((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    }
  };

  const checkAndSend = () => {
    if (
      newDir["tippId"] == "" ||
      newDir["relDtd"] == "" ||
      newDir["relTyp"] == ""
    ) {
      setPageError(true);
    } else {
      setPageError(false);
      addDirigeant(newDir);
      openOverlayFn(false);
    }
  };

  const inputValuesFiltered = inputValues.filter((data) => data.tippId != undefined).map((data) => parseInt(data.tippId));
  
  return ReactDOM.createPortal(
    <div className={styles.overlay} onClick={() => openOverlayFn(false)}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <p className={styles.textOverlayTitle}>AJOUTER DIRIGEANT</p>
        <div>
          <div className={styles.fieldContainer}>
            <label className={styles.label} htmlFor="tippId">
              Porteur de projet*
            </label>
            <select
              value={newDir["tippId"]}
              onChange={(e) => changeDir(e)}
              name="tippId"
              id="tippId"
              className={styles.inputSelect}
            >
              <option value="">----------------------</option>
              {PPs.filter((PP) => !inputValuesFiltered.includes(PP["tiId"])).map(
                (PP) => {
                  return (
                    <option value={PP.tiId} key={PP.tiId}>
                      {PP.tiLib}
                    </option>
                  );
                }
              )}
            </select>
            {newDir["tippId"] == "" && pageError == true && (
              <small className={styles.small}>Ce champs est requis</small>
            )}
          </div>
          <div className={styles.fieldContainer}>
            <label className={styles.label} htmlFor="relDtd">
              Date de début de fonction*
            </label>
            <input
              type="date"
              id="relDtd"
              name="relDtd"
              value={newDir["relDtd"]}
              onChange={(e) => changeDir(e)}
              className={styles.input}
            />
            {newDir["relDtd"] == "" && pageError == true && (
              <small className={styles.small}>Ce champs est requis</small>
            )}
          </div>
          <div className={styles.fieldContainer}>
            <label className={styles.label} htmlFor="relDtf">
              Date de fin de fonction
            </label>
            <input
              type="date"
              id="relDtf"
              name="relDtf"
              value={newDir["relDtf"]}
              onChange={(e) => changeDir(e)}
              className={styles.input}
            />
          </div>
          <div className={styles.fieldContainer}>
            <label className={styles.label} htmlFor="relTyp">
              Fonction*
            </label>
            <select
              value={newDir["relTyp"]}
              onChange={(e) => changeDir(e)}
              name="relTyp"
              id="relTyp"
              className={styles.inputSelect}
            >
              <option value="">---------------</option>
              <option value="Président">Président</option>
              <option value="Directeur Général">Directeur Général</option>
              <option value="Gérant Majoritaire">Gérant Majoritaire</option>
              <option value="Gérant Minoritaire">Gérant Minoritaire</option>
              <option value="Gérant Non Associé">Gérant Non Associé</option>
              <option value="Chef d'entreprise">Chef d'entreprise</option>
              <option value="Président Non Associé">
                Président Non Associé
              </option>
              <option value="Champ Libre">Champ Libre</option>
            </select>
            {newDir["relTyp"] == "Champ Libre" && (
                <input
                  type="text"
                  id="champLibre"
                  name="champLibre"
                  value={newDir["champLibre"]}
                  onChange={(e) => changeDir(e)}
                  className={styles.input}
                />
              )}
            {newDir["relTyp"] == "" && pageError == true && (
              <small className={styles.small}>Ce champs est requis</small>
            )}
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <button className={styles.buttonValid} onClick={() => checkAndSend()}>
            Ajouter
          </button>
          <button
            className={styles.buttonValid}
            onClick={() => openOverlayFn(false)}
          >
            Retour
          </button>
        </div>
      </div>
    </div>,
    document.getElementById("overlay")
  );
}
