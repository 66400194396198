import styles from "./creationTiersExcel.module.css"
import ImportButton from "../../components/ImportButton/ImportButton"
import DownloadButton from "../../components/downloadButton/DownloadButton"
import { useQuery } from "@tanstack/react-query";

export default function CreationTiersExcel () {

    const downloadTemplate = async () => {
        const link = `${process.env.REACT_APP_API_URL}/tiers/downloadTemplate`
        const response = await fetch(link)
        if (!response.ok) {
            throw "il y a un pb"
        }
        const data = await response.json()
        window.open(data["downloadLink"], '_blank')   
    }

    async function lastValueTiersID() {
        const link = `${process.env.REACT_APP_API_URL}/tiers/lastValueTiId`
        const response = await fetch(link)
        if (!response.ok) {
            throw "il y a un pb"
        }
        const data = await response.json()
        return data
    }

    const {data: lastValueID = {}} = useQuery({
        queryKey:["tiId", "last"],
        queryFn:() => lastValueTiersID(),
        refetchOnWindowFocus: false
    })


    return (
        <div className={styles.container}>
            <div className={styles.textButContainer}>
                <p className={styles.text}>Veuillez, s'il vous plait, suivre la trame du template pour ajouter de nouveaux Tiers.  <br /> <br />
                Les champs obligatoires sont IDENTIFIANT TIERS (commencer <b>obligatoirement</b> avec l'identifiant <b>{lastValueID["tiId"] == undefined ? 1 : lastValueID["tiId"] + 1}</b>), LIBELLE DU TIERS (vous êtes libre), QUALITE DU TIERS (PM dans la feuille TIERS PERSONNES MORALES, PP dans la feuille TIERS PERSONNES PHYSIQUES).  <br /> <br />
                Pour la feuille TIERS PERSONNES PHYSIQUES, les champs obligatoires sont le NOM et le PRENOM. <br /> <br />
                Pour la feuille TIERS PERSONNES MORALES, les champs obligatoires sont la RAISON SOCIALE, la DATE ENTREE et SECTEUR ("Hors QPV" ou "QPV").   <br /> <br />
                Pour la feuille RELATION, les champs obligatoires sont Identifiant du tiers PP (tiId) et Identifiant du tiers PM (tiId). 
                </p>
                <div className={styles.buttonsContainer}>
                    <ImportButton text="Importer" from="Tiers" />
                    <DownloadButton text="Télécharger le template" onClick={downloadTemplate} />
                </div>
            </div>
        </div>
    )
}