function deepEqual(obj1, obj2) {
    if (obj1 === obj2) return true;
    
    if (obj1 == null || typeof obj1 != "object" || obj2 == null || typeof obj2 != "object") return false;
    
    let keys1 = Object.keys(obj1), keys2 = Object.keys(obj2);
    
    if (keys1.length !== keys2.length) return false;
    
    for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false;
    }
    
    return true;
  }
  
  function compareArraysOfObjects(arr1, arr2) { // [arr, arr] == [arr, arr] ?
    if (arr1.length !== arr2.length) return false;
    
    for (let i = 0; i < arr1.length; i++) {
      if (!deepEqual(arr1[i], arr2[i])) return false;
    }
    
    return true;
  }
  

export { compareArraysOfObjects }