import ReactDOM from "react-dom";
import styles from "./OverlayAddAvenant.module.css";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { BounceLoader } from "react-spinners";

export default function OverlayAddAvenant({
  openOverlayFn,
  inputValues,
  typeAvenant,
}) {
  const { convId, convVrs = "latest" } = useParams();
  const queryClient = useQueryClient();

  async function UGsPrix() {
    const link = `${process.env.REACT_APP_API_URL}/conv/ugsPrix/${typeAvenant}/${convId}`;
    // const link = `http://localhost:8080/conv/ugsPrix/${typeAvenant}/${convId}`;
    const response = await fetch(link);
    if (!response.ok) {
      throw "il y a un pb";
    }
    const data = await response.json();
    return data;
  }

  const { data: ugsPrix = {} } = useQuery({
    queryKey: ["Patrimoine", "Avenant", typeAvenant],
    queryFn: () => UGsPrix(),
    refetchOnWindowFocus: false,
  });

  async function updateConvAv(data) {
    const link = `${process.env.REACT_APP_API_URL}/conv/avenant`
    // const link = `http://localhost:8080/conv/avenant`;
    const response = await fetch(link, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const dataReceived = await response.json();
    return dataReceived;
  }

  const mutationDataUpdate = useMutation({
    mutationFn: (data) => updateConvAv(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["Convention", convId, convVrs]);
      queryClient.invalidateQueries(["Convention", "Historique", convId]);
      openOverlayFn(false);
    },
  });

  const checkAndSend = () => {
    mutationDataUpdate.mutate({
      prevData: inputValues,
      typeAvenant: typeAvenant,
      convId: convId,
      ugsPrix: ugsPrix
    });
  };

  return ReactDOM.createPortal(
    <div className={styles.overlay} onClick={() => openOverlayFn(false)}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <p className={styles.textOverlayTitle}>AJOUTER AVENANT</p>
        <div>
          <p>Voulez-vous vraiment ajouter un nouveau avenant {typeAvenant} ?</p>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          {Object.keys(ugsPrix).length != 0 && <button className={styles.buttonValid} disabled={mutationDataUpdate.isPending} onClick={() => checkAndSend()}>
            Ajouter
          </button>}
          <button
            className={styles.buttonValid}
            onClick={() => openOverlayFn(false)}
          >
            Retour
          </button>
        </div>
        {mutationDataUpdate.isPending == true && (
          <div className={styles.bounce}>
            <BounceLoader color="black" />
          </div>
        )}
      </div>
    </div>,
    document.getElementById("overlay")
  );
}
