import ReactDOM from "react-dom";
import styles from "./overlayRemovePr.module.css";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { BounceLoader } from "react-spinners";

export default function OverlayRemovePr({ removeOverlayFn, prToRemove }) {
  const { ugNum } = useParams();
  const queryClient = useQueryClient();

  async function removeEq(data) {
    const link = `${process.env.REACT_APP_API_URL}/tiers/Prj`
    // const link = "http://localhost:8080/tiers/Prj";
    const response = await fetch(link, {
      method: "DELETE",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const dataReceived = await response.json();
    return dataReceived;
  }

  const mutationDataDelete = useMutation({
    mutationFn: (data) => removeEq(data),
    onSuccess: (data) => {
      removeOverlayFn();
      queryClient.invalidateQueries(["Patrimoine", ugNum]);
    },
  });


  return ReactDOM.createPortal(
    <div className={styles.overlay} onClick={() => removeOverlayFn()}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <p className={styles.textOverlayTitle}>SUPPRIMER PROJET</p>
        <div>
            <label htmlFor="RsoPrj" className={styles.label}>
                Dénomination entreprise
            </label>
            <input
            type="text"
            id="RsoPrj"
            name="RsoPrj"
            readOnly={true}
            value={prToRemove["RsoPrj"]}
            className={styles.input}
            />
        </div>
        <div>
            <label htmlFor="ActPrj" className={styles.label}>
              Activité
            </label>
            <input
            type="text"
            id="ActPrj"
            name="ActPrj"
            readOnly={true}
            value={prToRemove["ActPrj"]}
            className={styles.input}
            />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <button
            className={styles.buttonValid}
            onClick={() => mutationDataDelete.mutate(prToRemove)}
          >
            Oui
          </button>
          <button
            className={styles.buttonValid}
            onClick={() => removeOverlayFn()}
          >
            Non
          </button>
        </div>
        {mutationDataDelete.isPending == true && (
          <div className={styles.bounce}>
            <BounceLoader color="white" />
          </div>
        )}
      </div>
    </div>,
    document.getElementById("overlay")
  );
}
