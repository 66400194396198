import styles from "./PorteurProjet.module.css";
import { useQuery } from "@tanstack/react-query";

export default function CreationPorteurProjetInfos( {inputValues, pageError, changeFn} ) {

    async function getSocioPro()  {
        const link = `${process.env.REACT_APP_API_URL}/tiers/categSocioPro`
        // const link = "http://localhost:8080/tiers/categSocioPro"
        const response = await fetch(link)
        if (!response.ok) {
            throw "Il y a un problème avec le serveur"
        }
        const data = await response.json()
        return data
    }
    
      const { data:categSocioPro = [] } = useQuery({
          queryKey: ["categorieSocioPro"],
          queryFn: () => getSocioPro(),
          cacheTime: Infinity,
          staleTime: Infinity,
          refetchOnWindowFocus: false
      })
    
    
  return (
    <div className={styles.container}>
        <p className={styles.textTitle}>INFORMATIONS SUR LE PORTEUR DE PROJET</p>
        <div className={styles.table}>
        <table className={styles.tableSearch}>
            <thead>
              <tr>
              <th style={{ width: "20%" }}></th>
                <th style={{ width: "30%" }}></th>
                <th style={{ width: "20%" }}></th>
                <th style={{ width: "30%" }}></th>
              </tr>
            </thead>
            <tbody>
            <tr>
                <td>
                <b>Civilité*</b>
                </td>
                <td>
                <select
                  value={inputValues["tippCiv"]}
                  onChange={(e) => changeFn(e)}
                  name="tippCiv"
                  id="tippCiv"
                  className={styles.inputSelect}
                >   
                    <option value="">
                      ---------------------
                    </option>
                    <option value="Mr">
                      Monsieur
                    </option>
                    <option value="Mme">
                      Madame
                    </option>
                </select>
                {(inputValues["tippCiv"] == undefined || inputValues["tippCiv"] == "")  && pageError == true && (
                <small style={{marginLeft: "1rem", color:"red"}}>Ce champs est requis</small>
                )}                
              </td>
                <td>
                <b>Prénom*</b>
                </td>
                <td>
                    <input
                    type="text"
                    id="tippPrenom"
                    name="tippPrenom"
                    value={inputValues["tippPrenom"]}
                    onChange={(e) => changeFn(e)}
                    className={styles.input}
                    />
                {(inputValues["tippPrenom"] == undefined ||
                    inputValues["tippPrenom"] == "") &&
                    pageError == true && <small style={{marginLeft: "1rem", color:"red"}}>Ce champs est requis</small>}
                </td>
            </tr>
            <tr>
                <td>
                <b>Nom*</b>
                </td>
                <td>
                    <input
                  type="text"
                  id="tippNom"
                  name="tippNom"
                  value={inputValues["tippNom"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />

              {(inputValues["tippNom"] == undefined ||
                inputValues["tippNom"] == "") &&
                pageError == true && <small style={{marginLeft: "1rem", color:"red"}}>Ce champs est requis</small>}</td>
                <td>
                <b>Nom de naissance</b>
                </td>
                <td>
                   <input
                  type="text"
                  id="tippNomnai"
                  name="tippNomnai"
                  value={inputValues["tippNomnai"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
                </td>
            </tr>
            <tr>
            <td>
              <b>Date de naissance</b>
            </td>
            <td>
                <input
                  type="date"
                  id="tippDatnai"
                  name="tippDatnai"
                  value={inputValues["tippDatnai"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
            </td>
            <td>
              <b>Courriel*</b>
            </td>
            <td>
                <input
                  type="email"
                  id="tippEmail"
                  name="tippEmail"
                  value={inputValues["tippEmail"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
              {(inputValues["tippEmail"] == undefined ||
                    inputValues["tippEmail"] == "") &&
                    pageError == true && <small style={{marginLeft: "1rem", color:"red"}}>Ce champs est requis</small>}
                </td>
          </tr>
            <tr>
                <td>
                <b>Téléphone*</b>
                </td>
                <td>
                <input
                    type="text"
                    id="tippTelpor"
                    name="tippTelpor"
                    value={inputValues["tippTelpor"]}
                    onChange={(e) => changeFn(e)}
                    className={styles.input}
                />
            {(inputValues["tippTelpor"] == undefined || inputValues["tippTelpor"] == "")  && pageError == true && (
                <small style={{marginLeft: "1rem", color:"red"}}>Ce champs est requis</small>
              )}
                </td>
                <td>
              <b>Adresse</b>
            </td>
            <td>
                <div className={styles.inputValue}>
                  <div className={styles.inputValue}>{(inputValues["numVoie"] ? inputValues["numVoie"] : "")  + " " + (inputValues["typVoie"] ? inputValues["typVoie"] : "") + " " + (inputValues["intVoie"] ? inputValues["intVoie"] : "") + " " + (inputValues["complvoie"] ? inputValues["complvoie"] : "") }</div>
                </div>
            </td>
            </tr>
            <tr>
                <td>
                <b>N° Voie*</b>
                </td>
                <td>
                    <input
                    type="text"
                    id="numVoie"
                    name="numVoie"
                    value={inputValues["numVoie"]}
                    onChange={(e) => changeFn(e)}
                    className={styles.input}
                    />
                    {(inputValues["numVoie"] == undefined || inputValues["numVoie"] == "")  && pageError == true && (
                    <small style={{marginLeft: "1rem", color:"red"}}>Ce champs est requis</small>
                )}
                </td>
                <td>
                <b>Type Voie*</b>
                </td>
                <td>
                    <input
                    type="text"
                    id="typVoie"
                    name="typVoie"
                    value={inputValues["typVoie"]}
                    onChange={(e) => changeFn(e)}
                    className={styles.input}
                    />
                    {(inputValues["typVoie"] == undefined || inputValues["typVoie"] == "")  && pageError == true && (
                    <small style={{marginLeft: "1rem", color:"red"}}>Ce champs est requis</small>
                    )}
                </td>
            </tr>
            <tr>
                <td>
                <b>Intitulé voie*</b>
                </td>
                <td>
                    <input
                    type="text"
                    id="intVoie"
                    name="intVoie"
                    value={inputValues["intVoie"]}
                    onChange={(e) => changeFn(e)}
                    className={styles.input}
                    />
                    {(inputValues["intVoie"] == undefined || inputValues["intVoie"] == "")  && pageError == true && (
                    <small style={{marginLeft: "1rem", color:"red"}}>Ce champs est requis</small>
                )}
                </td>
                <td>
                <b>Complémement voie</b>
                </td>
                <td>
                <input
                  type="text"
                  id="complvoie"
                  name="complvoie"
                  value={inputValues["complvoie"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
                </td>
            </tr>
            <tr>
                <td>
                <b>Code postal*</b>
                </td>
                <td>
                <input
                  type="text"
                  id="codPos"
                  name="codPos"
                  value={inputValues["codPos"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
              {(inputValues["codPos"] == undefined || inputValues["codPos"] == "")  && pageError == true && (
                <small style={{marginLeft: "1rem", color:"red"}}>Ce champs est requis</small>
              )}
                </td>
                <td>
                <b>Commune*</b>
                </td>
                <td>
                <input
                  type="text"
                  id="comun"
                  name="comun"
                  value={inputValues["comun"]}
                  onChange={(e) => changeFn(e)}
                  className={styles.input}
                />
              
              {(inputValues["comun"] == undefined || inputValues["comun"] == "")  && pageError == true && (
                <small style={{marginLeft: "1rem", color:"red"}}>Ce champs est requis</small>
              )}
                </td>
            </tr>
            <tr>
                <td>
                <b>QPV</b>
                </td>
                <td>
                    <select
                    value={inputValues["tippQpv"]}
                    onChange={(e) => changeFn(e)}
                    name="tippQpv"
                    id="tippQpv"
                    className={styles.inputSelect}
                    >
                    <option value="">
                        ----------------------
                        </option>
                        <option value="Oui">
                        Oui
                        </option>
                        <option value="Non">
                        Non
                        </option>
                    </select>
                </td>
                <td>
                <b>ZFU</b>
                </td>
                <td>
                    <select
                    value={inputValues["tippZfu"]}
                    onChange={(e) => changeFn(e)}
                    name="tippZfu"
                    id="tippZfu"
                    className={styles.inputSelect}
                    >
                    <option value="">
                        ----------------------
                        </option>
                        <option value="Oui">
                        Oui
                        </option>
                        <option value="Non">
                        Non
                        </option>
                    </select>
                </td>
            </tr>
            <tr>
                <td>
                <b>Niveau d'études</b>
                </td>
                <td>
                <select
                  value={inputValues["scpNivetud"]}
                  onChange={(e) => changeFn(e)}
                  name="scpNivetud"
                  id="scpNivetud"
                  className={styles.inputSelect}
                >
                  <option value="">
                      ----------------------
                    </option>
                    <option value="Arrêt avant 3ème">
                      Arrêt avant 3ème
                    </option>
                    <option value="Brevet">
                    Brevet
                    </option>
                    <option value="CAP">
                    CAP
                    </option>
                    <option value="BP">
                    BP
                    </option>
                    <option value="BAC">
                    BAC
                    </option>
                    <option value="BTS">
                    BTS
                    </option>
                    <option value="DUT">
                    DUT
                    </option>
                    <option value="Bachelor 1">
                    Bachelor 1
                    </option>
                    <option value="Bachelor 2">
                    Bachelor 2
                    </option>
                    <option value="Bachelor 3">
                    Bachelor 3
                    </option>
                    <option value="Master 1">
                    Master 1
                    </option>
                    <option value="Master 2">
                    Master 2
                    </option>
                    <option value="Doctorat">
                    Doctorat
                    </option>
                    <option value="Diplôme à l'étranger">
                    Diplôme à l'étranger
                    </option>
                </select>
                </td>
                <td>
                <b>Situation avant projet</b>
                </td>
                <td>
                    <select
                    value={inputValues["scpAvPrj"]}
                    onChange={(e) => changeFn(e)}
                    name="scpAvPrj"
                    id="scpAvPrj"
                    className={styles.inputSelect}
                    >
                    <option value="">
                        ----------------------
                        </option>
                        <option value="DE">
                        DE
                        </option>
                        <option value="DELD">
                        DELD
                        </option>
                        <option value="RSA">
                        RSA
                        </option>
                        <option value="AAH">
                        AAH
                        </option>
                        <option value="Femme isolée">
                        Femme isolée
                        </option>
                        <option value="Salarié">
                        Salarié
                        </option>
                        <option value="Étudiant">
                          Étudiant
                        </option>
                        <option value="Champ Libre">
                        Champ libre
                        </option>
                    </select>
                    {inputValues["scpAvPrj"] == "Champ Libre" && (
                      <input
                        type="text"
                        id="champLibre"
                        name="champLibre"
                        value={inputValues["champLibre"]}
                        onChange={(e) => changeFn(e)}
                        className={styles.input}
                        style={{marginLeft: "1rem"}}
                      />
                    )}

                </td>
            </tr>
            <tr>
                <td>
                <b>Expérience professionnelle</b>
                </td>
                <td>
                    <select onChange={(e) => changeFn(e)} value={inputValues["scpId"]} id="scpId" name="scpId" className={styles.inputSelect}>
                        <option value="">--------</option>
                        {categSocioPro.map((data, idx) => {
                                if (idx != 0) {
                                    return <option key={data["Liste PCS-ESE"]} value={data["Liste PCS-ESE"]}>
                                        {data["Liste PCS-ESE"]} - {data["Liste des professions et catégories socioprofessionnelles des emplois salariés des employeurs privés et publics"]}
                                    </option>
                                }
                            }
                        )}
                    </select>
                </td>
                <td>
                <b>Autorisation droit à l'image</b>
                </td>
                <td>
                    <select
                    value={inputValues["tippAutimg"]}
                    onChange={(e) => changeFn(e)}
                    name="tippAutimg"
                    id="tippAutimg"
                    className={styles.inputSelect}
                    > 
                    <option value="">
                        ----------------------
                        </option>
                        <option value="Oui">
                        Oui
                        </option>
                        <option value="Non">
                        Non
                        </option>
                    </select>
                </td>
            </tr>
            </tbody>
            </table>
        </div>
    </div>
  );
}

