import styles from "./DirigeantsInfos.module.css";
import poubBleu from "../../../assets/poubBleu.png"

export default function CreationDirigeantsInfos({
  inputValues,
  modifDirOverlayFn,
  removeDirOverlayFn,
  changeDir,
  type,
  pageError
}) {

  return (
    <div className={styles.container}>
      <p className={styles.textTitle}>
      DIRIGEANT(S)          
      </p>
      <div className={styles.buttonAddContainer}>
        <button
          onClick={() => modifDirOverlayFn(true)}
          className={styles.buttonAdd}
        >
          Ajouter un dirigeant
        </button>
      </div>
      <div className={styles.table}>
        <table className={styles.tableSearch}>
          <thead>
            <th style={{ width: "30%" }}>
              <b>Libellé</b>
            </th>
            <th style={{ width: "30%" }}>
              <b>Fonction</b>
            </th>
            <th style={{ width: "15%" }}>
              <b>Date de début de fonction</b>
            </th>
            <th style={{ width: "15%" }}>
              <b>Date de fin de fonction</b>
            </th>
            <th style={{ width: "10%" }}></th>
          </thead>
          { type == "PPPM" &&        
          <tbody>
              {(inputValues[0]["tiLib"] != "") && <tr> 
                <td>{inputValues[0]["tiLib"]}</td>
                <td>
                  <select
                  value={inputValues[0]["relTyp"]}
                  onChange={(e) => changeDir(e, false)}
                  name="relTyp"
                  id="relTyp"
                  className={styles.inputSelect}
                >
                  <option value="">---------------</option>
                  <option value="Président">Président</option>
                  <option value="Directeur Général">Directeur Général</option>
                  <option value="Gérant Majoritaire">Gérant Majoritaire</option>
                  <option value="Gérant Minoritaire">Gérant Minoritaire</option>
                  <option value="Gérant Non Associé">Gérant Non Associé</option>
                  <option value="Chef d'entreprise">Chef d'entreprise</option>
                  <option value="Président Non Associé">
                    Président Non Associé
                  </option>
                  <option value="Champ Libre">Champ Libre</option>
                </select>
                {inputValues[0]["relTyp"] == "Champ Libre" && (
                <input
                  type="text"
                  id="champLibre"
                  name="champLibre"
                  value={inputValues[0]["champLibre"]}
                  onChange={(e) => changeDir(e, true)}
                  className={styles.input}
                  style={{marginLeft: "1rem"}}
                />
              )}
                {inputValues[0]["relTyp"] == "" && pageError == true && (
              <small className={styles.small}>Ce champs est requis</small>
              )}
                </td>
                <td>
                  <input
                    type="date"
                    id="relDtd"
                    name="relDtd"
                    value={inputValues[0]["relDtd"]}
                    onChange={(e) => changeDir(e, true)}
                    className={styles.input}
                  />
                  {inputValues[0]["relDtd"] == "" && pageError == true && (
                    <small className={styles.small}>Ce champs est requis</small>
                  )}
                </td>
                <td>
                <input
                    type="date"
                    id="relDtf"
                    name="relDtf"
                    value={inputValues[0]["relDtf"]}
                    onChange={(e) => changeDir(e, true)}
                    className={styles.input}
                  />
                  {inputValues[0]["relDtf"] == "" && pageError == true && (
                    <small className={styles.small}>Ce champs est requis</small>
                  )}
                </td>
                <td>
                  <div className={styles.logoContainer}>
                    {/* <img src={modifBleu} className={styles.logo} onClick={() => {modifDirOverlayFn(dirigeant)}} /> */}
                    {/* <img src={poubBleu} className={styles.logo} onClick={() => removeDirOverlayFn(idx)} /> */}
                  </div>
                </td>
              </tr>}
            {(inputValues.slice(1, inputValues.length).length != 0) && inputValues.slice(1, inputValues.length).map((dirigeant, idx) => {
              return (
                <tr> 
                  <td>{dirigeant["tiLib"] != undefined ? dirigeant["tiLib"] : ""}</td>
                  <td>{dirigeant["relTyp"]}</td>
                  <td>{dirigeant["relDtd"].split('-').reverse().join('-')}</td>
                  <td>{dirigeant["relDtf"].split('-').reverse().join('-')}</td>
                  <td>
                    <div className={styles.logoContainer}>
                      {/* <img src={modifBleu} className={styles.logo} onClick={() => {modifDirOverlayFn(dirigeant)}} /> */}
                      <img src={poubBleu} className={styles.logo} onClick={() => removeDirOverlayFn(idx + 1)} />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>}        
          {type == "PM" &&
          <tbody>
            {(inputValues.length != 0) && inputValues.map((dirigeant, idx) => {
              return (
                <tr> 
                  <td>{dirigeant["tiLib"] != undefined ? dirigeant["tiLib"] : ""}</td>
                  <td>{dirigeant["relTyp"]}</td>
                  <td>{dirigeant["relDtd"].split('-').reverse().join('-')}</td>
                  <td>{dirigeant["relDtf"].split('-').reverse().join('-')}</td>
                  <td>
                    <div className={styles.logoContainer}>
                      {/* <img src={modifBleu} className={styles.logo} onClick={() => {modifDirOverlayFn(dirigeant)}} /> */}
                      <img src={poubBleu} className={styles.logo} onClick={() => removeDirOverlayFn(idx)} />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
          }
        </table>
    </div>
      {/* {inputValues["dirigeants"].length != 0 && (
        <DirigeantsVisu dirigeants={inputValues["dirigeants"]} removeDirOverlayFn={removeDirOverlayFn} modifDirOverlayFn={modifDirOverlayFn} />
      )} */}
    </div>
  );
}
