import { Outlet, useLocation, useNavigate } from "react-router-dom";
// import Logo from "../../assets/carcoLogo.png";
import Logo from "../../assets/carcoAppOriginal.png"
import deconexionLogo from "../../assets/deconexionlogo.png";
import acceuilLogo from "../../assets/acceuilLogo.png";
import styles from "./header.module.css";
import retourButton from "../../assets/retourButton.png";
import Bell from "../../assets/bellWhite.svg";
import { useQuery } from "@tanstack/react-query";
import Footer from "../Footer";

export default function Header() {
  const location = useLocation();
  const pathURL = location.pathname;
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/users/logout`, {
        method: "POST",
        credentials: "include",
      });
      navigate("/");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  let linkCreate, linkSearch;
  if (pathURL.includes("Patrimoine")) {
    linkSearch = "/pageConnecte/recherchePatrimoine";
    linkCreate = "/pageConnecte/creerPatrimoine";
  } else if (pathURL.includes("Tiers")) {
    linkSearch = "/pageConnecte/rechercheTiers";
    linkCreate = "/pageConnecte/creerTiers";
  } else if (pathURL.includes("Convention")) {
    linkSearch = "/pageConnecte/rechercheConvention";
    linkCreate = "/pageConnecte/creerConvention";
  }

  const getNotifs = async () => {
    const link = `${process.env.REACT_APP_API_URL}/conv/notifs`;
    // const link = "http://localhost:8080/conv/notifs";
    const response = await fetch(link);
    if (!response.ok) {
      throw "il y a un pb";
    }
    const data = await response.json();
    return data;
  };

  const { data: notifs = [], isLoading } = useQuery({
    queryKey: ["Notifications"],
    queryFn: () => getNotifs(),
    refetchOnWindowFocus: false
  });

  const now = new Date();
  const options = {
    timeZone: "Europe/Paris",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  const formatter = new Intl.DateTimeFormat("en-GB", options);
  const parisDate = formatter.format(now);

  const dateParts = parisDate.split("/");
  const formattedParisDate = new Date(
    `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
  );

  const tenDaysAgo = new Date(formattedParisDate);
  tenDaysAgo.setDate(tenDaysAgo.getDate() - 10);

  const numNot =
    notifs.length !== 0
      ? notifs.filter((data) => {
          const notificationDate = new Date(data["dateNot"]);

          return notificationDate >= tenDaysAgo;
        }).length
      : null;
      return (
    <>
      {pathURL.includes("menu") ? (
        <div>
          <div className={styles.headerMenu}>
            <div className={styles.BienvenueContainer}>
              <p className={styles.bienvenueText}>BIENVENUE XXXX !</p>
              <div className={styles.bellContainer}>
                {numNot != null && numNot != 0 && (
                  <span className={styles.numNot}>{numNot}</span>
                )}
                <img
                  src={Bell}
                  alt="notifications"
                  onClick={() => navigate("/pageConnecte/Notifications")}
                  className={styles.bell}
                />
              </div>
            </div>
            <div className={styles.containerLogOutLogo}>
              <div className={styles.containerLogo}>
                <img
                  src={Logo}
                  alt="Logo Pépinière Carco"
                  className={styles.Logo}
                />
              </div>
              <div className={styles.deconnexion}>
                <span onClick={() => handleLogout()}>Quitter</span>
              </div>
            </div>
          </div>
          <Outlet />
          <Footer />
        </div>
      ) : null}
      {pathURL.includes("Notifications") ||
      pathURL.includes("administration") ||
      pathURL.includes("Convention") ||
      pathURL.includes("statistiques") ||
      pathURL.includes("Patrimoine") ||
      pathURL.includes("Tiers") ? (
        <div className={styles.notifContainer}>
          <div className={styles.headerSide}>
            {pathURL.includes("Patrimoine") || pathURL.includes("Tiers") || pathURL.includes("Convention") ? (
              <div className={styles.containerHomeBack}>
                <img
                  src={acceuilLogo}
                  className={styles.imageHomeBack}
                  onClick={() => navigate("/pageConnecte/menu")}
                />
                {(pathURL.includes("recherchePatrimoine/patrimoine") ||
                pathURL.includes("rechercheConvention/convention") ||
                  pathURL.includes("creerPatrimoine") ||
                  pathURL.includes("rechercheTiers/tiers") ||
                  pathURL.includes("creerTiers") ||
                  pathURL.includes("creerConvention")) && (
                  <img
                    src={retourButton}
                    className={styles.imageHomeBack}
                    onClick={() => navigate(-1)}
                  />
                )}
              </div>
            ) : (
              <img
                src={acceuilLogo}
                className={styles.image}
                onClick={() => navigate("/pageConnecte/menu")}
              />
            )}
            <img
              src={deconexionLogo}
              className={styles.imageDeco}
              onClick={() => handleLogout()}
            />
          </div>
          <div className={styles.mainContent}>
            <Outlet />
          </div>
          <Footer />
        </div>
      ) : null}
    </>
  );
}
