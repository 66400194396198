import Box from "../../components/Box"
import styles from "./menu.module.css"
import tiersLogo from "../../assets/tiers.png"
import patrimoineLogo from "../../assets/patrimoine.png"
import bailLogo from "../../assets/bail.png"
import statsLogo from "../../assets/stats.png"

function Menu () {
    const listMenus = [{text: "Bénéficiaires", image: tiersLogo, link:["../rechercheTiers/recherche/Porteur de projet", "../rechercheTiers/creer/tiers"]},{text: "Tableau de bord", image: statsLogo, link:"../statistiques"}
    ,{text: "Conventions", image: bailLogo, link:"../rechercheConvention"}, {text: "Locaux", image: patrimoineLogo, link:"../recherchePatrimoine"}]

    return (
        <div className={styles.container}>
            <div className={styles.containerBox} >
                <div className={styles["hexagon-border"]}>
                    <div className={styles["hexagon-content"]}>
                    </div>
                </div>
                <div className={styles.boxes} >
                    {listMenus.map((data) => {
                        return <Box text={data.text} image={data.image} link={data.link} key={data.text} />
                    })}
                </div>
            </div>
        </div>
    )
}

export default Menu