import { useEffect, useState } from "react";
import styles from "./EffectifsVisu.module.css";
import Pagination from "../../Pagination/Pagination";
import { useNavigate } from "react-router-dom";
import modifBleu from "../../../assets/modifbleu.png";
import poubBleu from "../../../assets/poubBleu.png";

export default function EffcetifsVisu({
  effectifs,
  removeDirOverlayFn,
  modifDirOverlayFn,
}) {
  const [numberElementsMax, setNumberElementsMax] = useState(3);
  const [prevNum, setPrevNum] = useState(0);
  const [nextNum, setNextNum] = useState(3);

  const nextPage = () => {
    if (numberElementsMax >= effectifs.length) {
      // setNextNum((prev) => prev + 3)
      setNumberElementsMax(3);
    } else {
      setNumberElementsMax((prev) => prev + 3);
    }
  };

  useEffect(() => {
    if (effectifs.length == 0) {
      setNextNum(0);
    } else if (numberElementsMax > effectifs.length) {
      setNextNum(effectifs.length);
    } else {
      setNextNum(numberElementsMax);
    }
    if (numberElementsMax > effectifs.length) {
      const numsCandidate = [effectifs.length - 1, effectifs.length - 2];
      let smallerNumber;
      for (let i of numsCandidate) {
        if (i % 3 === 0) {
          smallerNumber = i + 1;
          break;
        }
      }
      if (smallerNumber == undefined) {
        setPrevNum(0);
      } else {
        setPrevNum(smallerNumber);
      }
    } else {
      if (effectifs.length == 0) {
        setPrevNum(0);
      } else {
        setPrevNum(numberElementsMax - 2);
      }
    }
  }, [numberElementsMax, effectifs]);

  const prevPage = () => {
    if (numberElementsMax == 3) {
      if (effectifs.length % 3 == 0) {
        setNumberElementsMax(effectifs.length);
      } else {
        const numsCandidate = [effectifs.length + 1, effectifs.length + 2];
        let greatestNumber;
        for (let i of numsCandidate) {
          if (i % 3 === 0) {
            greatestNumber = i;
            break;
          }
        }
        setNumberElementsMax(greatestNumber);
      }
    } else {
      setNumberElementsMax((prev) => prev - 3);
    }
    // }
  };

  return (
 <>
 { effectifs.length != 0 && 
  <div className={styles.table}>
        <table className={styles.tableSearch}>
          <thead>
            <tr>
            <th style={{ width: "12.5%" }}>
              <b>Année</b>
            </th>
            <th style={{ width: "12.5%" }}>
              <b>CDI</b>
            </th>
            <th style={{ width: "12.5%" }}>
              <b>CDD</b>
            </th>
            <th style={{ width: "12.5%" }}>
              <b>Intérimaire(s)</b>
            </th>
            <th style={{ width: "12.5%" }}>Contrat(s) aidé(s)</th>
            <th style={{ width: "12.5%" }}>Alternant(s)</th>
            <th style={{ width: "12.5%" }}>Stagiaire(s)</th>
            <th style={{ width: "12.5%" }}></th>
            </tr>
          </thead>
          <tbody>
            {effectifs
              .slice(numberElementsMax - 3, numberElementsMax)
              .map((effectif) => {
                return (
                  <tr key={effectif["effDat"]}>
                    <td>{effectif["effDat"]}</td>
                    <td>{effectif["nbCdi"]}</td>
                    <td>{effectif["nbCdd"]}</td>
                    <td>{effectif["nbInt"]}</td>
                    <td>{effectif["nbCaid"]}</td>
                    <td>{effectif["nbAlter"]}</td>
                    <td>{effectif["nbStag"]}</td>
                    <td>
                      <div className={styles.logoContainer}>
                        <img
                          src={modifBleu}
                          className={styles.logo}
                          onClick={() => {
                            modifDirOverlayFn(effectif);
                          }}
                        />
                        <img
                          src={poubBleu}
                          className={styles.logo}
                          onClick={() => removeDirOverlayFn(effectif)}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      <div>
        <Pagination nextPage={nextPage} prevPage={prevPage} />
        <p className={styles.nums}>
          {prevNum} à {nextNum} - Nombre Total d'éléments : {effectifs.length}{" "}
        </p>
      </div>
    </div>
} 
</>
);
}
