import styles from "./rechercheConv.module.css";
import Pagination from "../../components/Pagination/Pagination";
import { useContext, useEffect, useState, useMemo } from "react";
import { HeaderContext } from "../../App";
import { BounceLoader } from "react-spinners";
import { useLocation, useNavigate } from "react-router-dom";
import SearchBox from "../../components/SearchBox/SearchBox";
import Box from "../../components/Box";

export default function RechercheConv() {
  const location = useLocation();
  // const pathURL = location.pathname;
  // const [numberElementsMax, setNumberElementsMax] = useState(10);
  const navigate = useNavigate();
  // const [prevNum, setPrevNum] = useState(0);
  // const [nextNum, setNextNum] = useState(10);
  const { searchDataConv, isLoadingConv, numberElementsMaxConv: numberElementsMax, setNumberElementsMaxConv: setNumberElementsMax, 
    prevNumConv : prevNum, setPrevNumConv : setPrevNum, nextNumConv : nextNum, setNextNumConv : setNextNum
   } = useContext(HeaderContext);
  const [searchClicked, setSearchClicked] = useState(true);


  const { filterFieldConv } = useContext(HeaderContext);

  const filteredsearchDataConv =
    searchDataConv[0] != "empty" && searchDataConv[0] != "loading"
      ? searchDataConv.filter((data) => {
        const now = new Date();
        const dateFinConv = data.convDtf
          ? new Date(data["convDtf"])
          : undefined;
        const dateDebConv = data.convDtd
          ? new Date(data["convDtd"])
          : undefined;
        const isNotExpired =
          dateFinConv != undefined
            ? now <= dateFinConv
            : data.length != 0 &&
              dateFinConv == undefined &&
              dateDebConv != undefined
            ? now >= dateDebConv
            : true;
      
        if (filterFieldConv.active && filterFieldConv.resilié) { 
          return true
        } else if (filterFieldConv.active && !filterFieldConv.resilié && isNotExpired) {
          return true
        } else if (!filterFieldConv.active && filterFieldConv.resilié && !isNotExpired) {
          return true
        } else {
          return false
        }
       })
      :[]

  

  const nextPage = () => {
    if (numberElementsMax >= filteredsearchDataConv.length) {
      setNumberElementsMax(10);
    } else {
      setNumberElementsMax((prev) => prev + 10);
    }
  };

  // useEffect(() => {
  //   if (isLoadingConv == false && numberElementsMax < searchDataConv.length && searchDataConv[0] != "loading") {
  //     setNumberElementsMax(10);
  //   }
  // }, [searchDataConv, isLoadingConv]);

  useEffect(() => {
    if (isLoadingConv == false) {
      if (filteredsearchDataConv.length == 0) {
        setNextNum(0);
      } else if (numberElementsMax > filteredsearchDataConv.length) {
        setNextNum(filteredsearchDataConv.length);
      } else {
        setNextNum(numberElementsMax);
      }


      if (numberElementsMax > filteredsearchDataConv.length) {
        const numsCandidate = [
          filteredsearchDataConv.length - 1,
          filteredsearchDataConv.length - 2,
          filteredsearchDataConv.length - 3,
          filteredsearchDataConv.length - 4,
          filteredsearchDataConv.length - 5,
          filteredsearchDataConv.length - 6,
          filteredsearchDataConv.length - 7,
          filteredsearchDataConv.length - 8,
          filteredsearchDataConv.length - 9,
        ];
        let smallerNumber;
        for (let i of numsCandidate) {
          if (i % 10 === 0) {
            smallerNumber = i + 1;
            break;
          }
        }
        if (smallerNumber == undefined) {
          setPrevNum(0);
        } else {
          setPrevNum(smallerNumber);
        }
      } else {
        if (filteredsearchDataConv.length == 0) {
          setPrevNum(0);
        } else {
          setPrevNum(numberElementsMax - 9);
        }
      }
    } 
  }, [numberElementsMax, filteredsearchDataConv]);



  const prevPage = () => {
    if (numberElementsMax == 10) {
      if (filteredsearchDataConv.length % 10 == 0) {
        setNumberElementsMax(filteredsearchDataConv.length);
      } else {
        const numsCandidate = [
          filteredsearchDataConv.length + 1,
          filteredsearchDataConv.length + 2,
          filteredsearchDataConv.length + 3,
          filteredsearchDataConv.length + 4,
          filteredsearchDataConv.length + 5,
          filteredsearchDataConv.length + 6,
          filteredsearchDataConv.length + 7,
          filteredsearchDataConv.length + 8,
          filteredsearchDataConv.length + 9,
        ];
        let greatestNumber;
        for (let i of numsCandidate) {
          if (i % 10 === 0) {
            greatestNumber = i;
            break;
          }
        }
        setNumberElementsMax(greatestNumber);
      }
    } else {
      setNumberElementsMax((prev) => prev - 10);
    }
  };

  const now = new Date();
  const options = {
    timeZone: "Europe/Paris",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  const formatter = new Intl.DateTimeFormat("en-GB", options);
  const parisDate = formatter.format(now);
  const dateToday = parisDate.split("/").reverse().join("-");


  return (
    <div className={styles.container}>
      <div className={styles.boxesContainer}>
        <div
          className={`${searchClicked ? styles.box : styles.boxNotClicked}`}
          onClick={() => setSearchClicked(true)}
        >
          <p
            className={`${searchClicked ? styles.text : styles.textNotClicked}`}
          >
            Conventions
          </p>
        </div>
        <div
          className={`${searchClicked ? styles.boxNotClicked : styles.box}`}
          onClick={() => setSearchClicked(false)}
        >
          <p
            className={`${searchClicked ? styles.textNotClicked : styles.text}`}
          >
            Éditer
          </p>
        </div>
      </div>
      {searchClicked ? (
        <>
          <SearchBox />
          <div className={styles.table}>
            <table className={styles.tableSearch}>
              <thead>
                <tr>
                <th style={{ width: "20%" }}>Dénomination entreprise</th>
                <th style={{ width: "20%" }}>Formule</th>
                <th style={{ width: "20%" }}>Date de début</th>
                <th style={{ width: "20%" }}>Date de fin</th>
                <th style={{ width: "20%" }}>Active/Résiliée</th>
                </tr>
              </thead>
              <tbody>
                {filteredsearchDataConv.length != 0 &&
                  filteredsearchDataConv
                    .slice(numberElementsMax - 10, numberElementsMax)
                    .map((data) => {
                      //A REVOIR POUR FAIRE CE PROCESSING DANS LE BACKEND
                      let liste = [];
                      const dateFinConv = data?.convDtf
                        ? new Date(data["convDtf"]).toISOString().split("T")[0]
                        : undefined;
                      const dateDebConv = data?.convDtd
                        ? new Date(data["convDtd"]).toISOString().split("T")[0]
                        : undefined;
                      const isNotExpired =
                        dateFinConv != undefined
                          ? dateToday <= dateFinConv
                          : dateFinConv == undefined && dateDebConv != undefined
                          ? dateToday >= dateDebConv
                          : true;
                      for (const [key, value] of Object.entries(data)) {
                        liste.push(value);
                      }
                      return (
                        <tr
                          onClick={() =>
                            navigate(`./convention/${liste[0]}/latest`)
                          }
                          key={liste[0]}
                        >
                          <td>{liste[6]}</td>
                          <td>{liste[5]}</td>
                          <td>{liste[3].split('-').reverse().join('-')}</td>
                          <td>{liste[4]?.split('-').reverse().join('-')}</td>
                          <td>{isNotExpired ? "Active" : "Résiliée"}</td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
          {isLoadingConv || searchDataConv[0] == "loading" ? (
            <div className={styles.bounce}>
              <BounceLoader color="black" />
            </div>
          ) : searchDataConv[0] == "empty" ||
            filteredsearchDataConv.length != 0 ? (
            <div>
              <Pagination nextPage={nextPage} prevPage={prevPage} />
              <p className={styles.nums}>
                {prevNum} à {nextNum} - Nombre Total d'éléments :{" "}
                {filteredsearchDataConv.length}{" "}
              </p>
            </div>
          ) : null}
        </>
      ) : (
        <div className={styles.addContainer}>
          <p className={styles.textAdd}>Que souhaitez-vous ajouter ?</p>
          <Box
            text="Une convention formule Pépinière"
            link="/pageConnecte/creerConvention/pepiniere"
          />
          <Box
            text="Un convention formule Coworking"
            link="/pageConnecte/creerConvention/coworking"
          />
        </div>
      )}
    </div>
  );
}
