import styles from "./ImportButton.module.css"
import download from "../../assets/download.png"
import { useEffect, useRef, useState } from "react";
import Overlay from "../Overlay";

export default function ImportButton ({ text, from }) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [messageReceived, setMessageReceived] = useState({message: undefined, status:undefined})
    const fileInputRef = useRef(null);

    const handleFileChange = (e) => {
      const file = e.target.files[0];
      setSelectedFile(file);
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
      };


    const handleUpload = async () => {
      if (selectedFile) {
        const formData = new FormData();
        formData.append('file', selectedFile); 
        let link
        if (from == "Patrimoine") {
            link = `${process.env.REACT_APP_API_URL}/ug/importUGs`
        } else if (from = "Tiers") {
            link = `${process.env.REACT_APP_API_URL}/tiers/importTiers`
        }
        const response = await fetch(link, {
            method: 'POST',
            body: formData,
            credentials:"include"
        });
        if (!response.ok) {
            throw 'File upload failed.';
        } 
        const data = await response.json()
        setMessageReceived({message: data.message, status:data.status})
    }}

    useEffect(() => {
        handleUpload()
    }, [selectedFile])

    return (
        <div className={styles.container}>
            {messageReceived.message != undefined && <Overlay message={messageReceived.message} status={messageReceived.status} setFilter={setMessageReceived} />}
            <button className={styles.button} onClick={handleButtonClick}>
            <input type="file" accept=".xlsx" onChange={handleFileChange} ref={fileInputRef} style={{ display: 'none' }} />
            <p className={styles.text}>{text}</p>             
            <img src={download} className={styles.rightArrow} />
            </button>
        </div>
    )
}