import styles from "./BarChartExtraMuros.module.css";
import { BarChart, Bar, LabelList, YAxis, Rectangle, Legend } from "recharts";
import { useNavigate } from "react-router-dom";

export default function BarChartExtraMuros({ data }) {
  const navigate = useNavigate()

  const CustomBarShape = (props) => {
    const { fill, x, y, width, height } = props;
    return <Rectangle x={x} y={y} width={width} height={height} fill={fill} />;
  };
  return (
    <BarChart
      width={200}
      height={500}
      data={data}
      className={styles.barchartContainer}
    >
      <YAxis domain={[0, 20]} hide={true} />
      <Bar
        dataKey="valeur"
        width="100"
        fill="#446aa2"
        className={styles.barchartfirstBar}
        shape={<CustomBarShape />}
        onClick={(e) => {
          navigate(`../rechercheTiers/recherche/Extra-Muros`)
        }}
      >
        <LabelList
          dataKey="valeur"
          position="inside"
          fill="white"
          className={styles.labelValue}
        />
      </Bar>
      <Bar
        dataKey="objectif"
        width="100"
        fill="#8faedc"
        className={styles.barchartSecond}
        shape={<CustomBarShape />}
        onClick={(e) => {
          navigate(`../rechercheTiers/recherche/Extra-Muros`)
        }}

      >
        <LabelList
          dataKey="objectif"
          position="inside"
          fill="white"
          className={styles.labelValue}
        />
      </Bar>
      <Legend wrapperStyle={{ fontSize: '1rem' }} />
    </BarChart>
  );
}
