import ReactDOM from "react-dom";
import styles from "./OverlayAddEntiteAvenant.module.css";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { BounceLoader } from "react-spinners";

export default function OverlayAddEntiteAvenant({
  openOverlayFn,
  inputValues
}) {
  const { convId, convVrs = "latest" } = useParams();
  const [prevRso, setNewPrevRso] = useState(inputValues["infos"]["tipmRso"])
  const [newRso, setNewRso] = useState(inputValues["infos"]["tipmRso"])
  const [pageError, setPageError] = useState(false);
  const queryClient = useQueryClient();

  async function updateConvAv(data) {
    const link = `${process.env.REACT_APP_API_URL}/conv/avenantEntite`
    // const link = `http://localhost:8080/conv/avenantEntite`;
    const response = await fetch(link, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const dataReceived = await response.json();
    return dataReceived;
  }

  const mutationDataUpdate = useMutation({
    mutationFn: (data) => updateConvAv(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["Convention", convId, convVrs]);
      queryClient.invalidateQueries(["Convention", "Historique", convId]);
      openOverlayFn(false);
    },
  });

  const checkAndSend = () => {
    if (newRso == "" || newRso == prevRso) {
      setPageError(true)
    } else {
      setPageError(false)
      mutationDataUpdate.mutate({
        prevData: inputValues,
        newRso: newRso,
        typeAvenant: "Entité",
        convId: convId      
      });
    }
  };

  return ReactDOM.createPortal(
    <div className={styles.overlay} onClick={() => openOverlayFn(false)}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <p className={styles.textOverlayTitle}>AJOUTER AVENANT ENTITE</p>
          <p>Attention, cette action va modifier la fiche Synthèse Tiers de la PM concernée.</p>
          <div className={styles.fieldContainer}>
            <label className={styles.label} htmlFor="tipmRso">
              Nouveau Nom*
            </label>
            <input
              type="text"
              id="tipmRso"
              name="tipmRso"
              value={newRso}
              onChange={(e) => setNewRso(e.target.value.toUpperCase())}
              className={styles.input}
              autoCapitalize="characters"
            />
            {((newRso == "" || newRso == prevRso) && pageError == true) && (
              <small className={styles.small}>Ce champs est requis</small>
            )}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
         <button className={styles.buttonValid} disabled={mutationDataUpdate.isPending} onClick={() => checkAndSend()}>
            Modifier
          </button>
          <button
            className={styles.buttonValid}
            onClick={() => openOverlayFn(false)}
          >
            Retour
          </button>
        </div>
        {mutationDataUpdate.isPending == true && (
          <div className={styles.bounce}>
            <BounceLoader color="black" />
          </div>
        )}
      </div>
    </div>,
    document.getElementById("overlay")
  );
}
