import styles from "./AccSouhaitInfos.module.css";

export default function CreationAccSouhaitInfos( {inputValues, pageError, changeFn} ) {

    
  return (
    <div className={styles.container}>
        <p className={styles.textTitle}>INFORMATIONS ACCOMPAGNEMENT SOUHAITÉ</p>
        <div className={styles.table}>
        <table className={styles.tableSearch}>
            <tbody>
            <tr>
            <td rowSpan="2" style={{ width: "30%" }}>
              <b>Formule souhaitée</b>
            </td>
            <td>
              <input
                type="checkbox"
                id="Bureau"
                name="Bureau"
                checked={inputValues["FormSouhait"]["Bureau"]}
                onChange={(e) => changeFn(e, "accSouhait")}
                // className={styles.input}
              />
              <label htmlFor="Bureau">Bureau</label>
            </td>
            <td>
              <input
                type="checkbox"
                id="Bureau Partagé"
                name="Bureau Partagé"
                checked={inputValues["FormSouhait"]["Bureau Partagé"]}
                onChange={(e) => changeFn(e, "accSouhait")}
                // className={styles.input}
              />
              <label htmlFor="Bureau Partagé">Bureau Partagé</label>
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
              <input
                type="checkbox"
                id="Extra-Muros"
                name="Extra-Muros"
                checked={inputValues["FormSouhait"]["Extra-Muros"]}
                onChange={(e) => changeFn(e, "accSouhait")}
                // className={styles.input}
              />
              <label htmlFor="Extra-Muros">Extra-Muros</label>
            </td>
            <td>
              <input
                type="checkbox"
                id="Coworking"
                name="Coworking"
                checked={inputValues["FormSouhait"]["Coworking"]}
                onChange={(e) => changeFn(e, "accSouhait")}
                // className={styles.input}
              />
              <label htmlFor="Coworking">Coworking</label>
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td rowSpan="2" style={{ width: "20%" }}>
              <b>Superficie</b>
            </td>
            <td style={{ width: "20%" }}>
              <input
                type="checkbox"
                id="8"
                name="8"
                checked={inputValues["SurfSouhait"]["8"]}
                onChange={(e) => changeFn(e, "accSouhait")}
                // className={styles.input}
              />
              <label htmlFor="8">8 m²</label>
            </td>
            <td style={{ width: "20%" }}>
              <input
                type="checkbox"
                id="16"
                name="16"
                checked={inputValues["SurfSouhait"]["16"]}
                onChange={(e) => changeFn(e, "accSouhait")}
                // className={styles.input}
              />
              <label htmlFor="16">16 m²</label>
            </td>
            <td style={{ width: "20%" }}>
              <input
                type="checkbox"
                id="18"
                name="18"
                checked={inputValues["SurfSouhait"]["18"]}
                onChange={(e) => changeFn(e, "accSouhait")}
                // className={styles.input}
              />
              <label htmlFor="18">18 m²</label>
            </td>
            <td style={{ width: "20%" }}>
              <input
                type="checkbox"
                id="21"
                name="21"
                checked={inputValues["SurfSouhait"]["21"]}
                onChange={(e) => changeFn(e, "accSouhait")}
                // className={styles.input}
              />
              <label htmlFor="21">21 m²</label>
            </td>
          </tr>
          <tr>
            <td>
              <input
                type="checkbox"
                id="24"
                name="24"
                checked={inputValues["SurfSouhait"]["24"]}
                onChange={(e) => changeFn(e, "accSouhait")}
                // className={styles.input}
              />
              <label htmlFor="24">24 m²</label>
            </td>
            <td>
              <input
                type="checkbox"
                id="25"
                name="25"
                checked={inputValues["SurfSouhait"]["25"]}
                onChange={(e) => changeFn(e, "accSouhait")}
                // className={styles.input}
              />
              <label htmlFor="25">25 m²</label>
            </td>
            <td>
              <input
                type="checkbox"
                id="32"
                name="32"
                checked={inputValues["SurfSouhait"]["32"]}
                onChange={(e) => changeFn(e, "accSouhait")}
                // className={styles.input}
              />
              <label htmlFor="32">32 m²</label>
            </td>
            <td>
              <input
                type="checkbox"
                id="35"
                name="35"
                checked={inputValues["SurfSouhait"]["35"]}
                onChange={(e) => changeFn(e, "accSouhait")}
                // className={styles.input}
              />
              <label htmlFor="35">35 m²</label>
            </td>
          </tr>
          <tr>
            <td style={{ width: "30%", height: "6rem" }}>
              <b>Date prévisionnelle d'entrée</b>
            </td>
            <td colSpan="2">
                <input
                  type="date"
                  id="DatEntSouhait"
                  name="DatEntSouhait"
                  value={inputValues["DatEntSouhait"]}
                  onChange={(e) => changeFn(e, "accSouhait")}
                  className={styles.input}
                />
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
            </tbody>
            </table>
        </div>
    </div>
  );
}

