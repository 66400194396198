import { useEffect, useState } from "react";
import styles from "./PieChartSex.module.css";
import { PieChart, Pie, Cell, Sector } from "recharts";
import {  useNavigate } from "react-router-dom";

function hexToRGBA(hex, opacity) {
  let r = 0,
    g = 0,
    b = 0;
  // 3 digits
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    // 6 digits
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return `rgba(${r},${g},${b},${opacity})`;
}

export default function PieChartSex({ data }) {

  const navigate = useNavigate()
  const [activeIndex, setActiveIndex] = useState(-1);
  const [radiusOffset, setRadiusOffset] = useState(0);

  const COLORS = ["#8faedc", "#446aa2", "#315180"];

  const RADIAN = Math.PI / 180;

  const renderActiveShape = (props) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
      props;
    // cx, cy = x, y coordinate of the center of the pie
    // innerRadiuse = The radius of the inner circle of a donut chart. For a standard pie chart, this value is typically 0, making the pie chart a full circle. In a donut chart, increasing this value creates a larger "hole" in the center, hence the donut shape.
    // outerRadius: The radius of the outer circle of the chart. This determines how far from the center the edge of the pie (or donut) chart extends. Increasing this value makes the pie or donut chart larger in size.
    // startAngle and endAngle: These define the starting and ending angles for each slice of the pie or donut chart, in degrees. In SVG and D3.js, the angle is often measured in radians (where 2π radians equal 360 degrees), and the drawing starts from the 3 o'clock position, moving in a clockwise direction. The startAngle and endAngle determine the arc of each slice. For instance, for a slice representing 25% of a pie chart, the difference between endAngle and startAngle would be 90 degrees (or π/2 radians), assuming a full circle is 360 degrees (or 2π radians).
    // fill: This defines the color used to fill the slice. It can be any valid CSS color value (e.g., hex codes, color names, rgba values).
    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius + radiusOffset}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };
  

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    value,
    name,
  }) => {

    if (activeIndex !== -1 && activeIndex !== index) {
        return null
    }

    const x = cx + outerRadius * Math.cos(-midAngle * RADIAN) * 1.4;
    const y = cy + outerRadius * Math.sin(-midAngle * RADIAN) * 1.4;
    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        <tspan x={x} y={y} className={styles.labelName}>
          {name}
        </tspan>
        <tspan x={x} y={y + 20} className={styles.labelValue}>
          {value}%
        </tspan>
      </text>
    );
  };

  useEffect(() => {
    if (activeIndex !== -1) {
      const intervalId = setInterval(() => {
        setRadiusOffset((prev) => (prev < 10 ? prev + 1 : 10));
      }, 5);

      return () => clearInterval(intervalId);
    } else {
      // Reset the radiusOffset when not hovering
      setRadiusOffset(0);
    }
  }, [activeIndex]);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const onPieLeave = () => {
    setActiveIndex(-1);
  };

  const getFillColor = (index) => {
    if (activeIndex === -1) {
      return COLORS[index % COLORS.length];
    } else if (index === activeIndex) {
      return COLORS[index % COLORS.length];
    } else {
      const color = COLORS[index % COLORS.length];
      const rgbaColor = hexToRGBA(color, 0.5);
      return rgbaColor;
    }
  };

  const HIGHLIGHT_COLOR = "#FFFF4D";
  return (
    <PieChart width={800} height={500}>
      <defs>
        <filter id="shadow" height="130%">
          <feDropShadow
            dx="3"
            dy="3"
            stdDeviation="7"
            floodColor="black"
            floodOpacity="0.6"
          />
        </filter>
      </defs>
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        labelLine={false}
        activeIndex={activeIndex}
        label={renderCustomizedLabel}
        outerRadius={150}
        fill="#8884d8"
        dataKey="value"
        onMouseLeave={onPieLeave}
        onMouseEnter={onPieEnter}
        activeShape={renderActiveShape}
        isAnimationActive={false}
        filter="url(#shadow)"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={getFillColor(index)}   />
        ))}
      </Pie>
    </PieChart>
  );
}
