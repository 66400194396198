import ReactDOM from 'react-dom';
import styles from "./overlayAddEq.module.css"
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { BounceLoader } from "react-spinners";

export default function OverlayAddEqUG({ openOverlayFn }) {
    const { ugNum: ugId } = useParams()
    const [newEq, setnewEq] = useState({ugId: ugId, eqPrix: "", eqNat: "", eqRef: ""})
    const [pageError, setPageError] = useState(false);
    const queryClient = useQueryClient();

    const changeEQ = (e) => {
        setnewEq((prev) => {return {...prev, [e.target.name]: e.target.value}})
    }

    async function allRefs() {
        const link = `${process.env.REACT_APP_API_URL}/ug/allRefs`
        // const link = "http://localhost:8080/ug/allRefs"
        const response = await fetch(link)
        if (!response.ok) {
            throw "il y a un pb"
        }
        const data = await response.json()
        return data
    }

    const {data: allRefsArr = []} = useQuery({
        queryKey:["Refs", "Equipements"],
        queryFn:() => allRefs(),
        refetchOnWindowFocus: false
    })

    const checkAndSend = () => {
        if (newEq["eqNat"] == "" || newEq["eqPrix"] == "" || newEq["eqRef"] == "") {
            setPageError(true)
        } else if (allRefsArr.includes(newEq["eqRef"])) {
            setPageError(true)
        } else {
            setPageError(false)
            mutationPostEq.mutate(newEq)
        }
    }

    async function addEq(data) {
        const link = `${process.env.REACT_APP_API_URL}/ug/addEq`
        // const link = `http://localhost:8080/ug/addEq`
        const response = await fetch(link, {
            method:"POST",
            body:JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
            credentials:"include"
        })
        if (!response.ok) {
            throw 'Il y a un problème avec le serveur'
        }
        const dataReceived = await response.json()
        return dataReceived
    }

    const mutationPostEq = useMutation({
        mutationFn: (data) => addEq(data),
        onSuccess: () => {
                queryClient.invalidateQueries(["Patrimoine", ugId]);
                openOverlayFn((prev) => !prev)
        }
    })


    return ReactDOM.createPortal(
        <div className={styles.overlay} onClick={() => openOverlayFn(false)}>
            <div className={styles.container} onClick={(e) => e.stopPropagation()}>
                <p className={styles.textOverlayTitle}>AJOUT ÉQUIPEMENT</p>
                <div>
                    <div className={styles.fieldContainer}>
                        <label className={styles.label} htmlFor="eqNat">
                        Nature*
                        </label>
                        <select value={newEq["eqNat"]} onChange={(e) => changeEQ(e)} name="eqNat" id="eqNat" className={styles.input}>
                            <option value="">---------------</option> 
                            <option value="Clé">Clé</option>
                            <option value="Badge">Badge</option>
                        </select>
                        {((newEq["eqNat"] == "")  && pageError == true) && <small className={styles.small}>Ce champs est requis</small>}
                    </div>
                    <div className={styles.fieldContainer}>
                        <label className={styles.label} htmlFor="eqRef">
                        Référence*
                        </label>
                        <input
                            type="text"
                            id="eqRef"
                            name="eqRef"
                            value={newEq["eqRef"]}
                            onChange={(e) => changeEQ(e)}
                            className={styles.input}
                            min="0"
                        />
                        {((newEq["eqRef"] == "")  && pageError == true) && <small className={styles.small}>Ce champs est requis</small>}
                        {((allRefsArr.includes(newEq["eqRef"])) && pageError == true) && <small className={styles.small}>Cette référence existe déjà</small>}
                    </div>
                    <div className={styles.fieldContainer}>
                        <label className={styles.label} htmlFor="eqPrix">
                        Prix*
                        </label>
                        <input
                            type="number"
                            id="eqPrix"
                            name="eqPrix"
                            value={newEq["eqPrix"]}
                            onChange={(e) => changeEQ(e)}
                            className={styles.input}
                            min="0"
                        />
                        {(newEq["eqPrix"] == "" && pageError == true) && <small className={styles.small}>Ce champs est requis</small>}
                    </div>
                </div>
                <div style={{width:"100%", display:"flex", justifyContent:"space-evenly"}}>
                    <button className={styles.buttonValid} onClick={() => checkAndSend()} disabled={mutationPostEq.isPending}>Ajouter</button>       
                    <button className={styles.buttonValid} onClick={() => openOverlayFn(false)}>Retour</button>    
                </div>
                {mutationPostEq.isPending == true && (
                    <div className={styles.bounce}>
                        <BounceLoader color="white" />
                    </div>
                )}
            </div>
        </div>,
        document.getElementById('overlay')
        )
}